import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import TaxMaps from './TaxMaps';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class Taxes extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openTaxMapsDialog: false
		};
		//this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.editTaxMaps_clickHandler = this.editTaxMaps_clickHandler.bind(this);
		this.taxMapsDialog_closeHandler = this.taxMapsDialog_closeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchTaxes();
	}

	editTaxMaps_clickHandler(event) {
		event.preventDefault();

		this.setState({ openTaxMapsDialog: true, selectedTaxTypeCode: event.target.dataset.taxtypecode });
	}

	taxMapsDialog_closeHandler(event) {
		this.setState({ openTaxMapsDialog: false });
	}
	/*getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getappayment";
		let messageName = "AP Payments";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}*/
	fetchTaxes() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/taxes')
			.then((response) => {
				var data = response.data;
				var records = (data.records || []).map((e, i) => ({ ...e, rowId: i }));
				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			taxTypeCode: this.state.selectedTaxTypeCode,
			inProgress: this.props.inProgress
		}

		return (
			<div>
				<h1>Taxes</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							//title={"Last Updated: " + this.state.lastUpdated}
							//customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: 'Tax',
									field: 'taxCode',
									flex: 0.5,
								},
								{
									headerName: 'Tax Description',
									field: 'taxDesc',
									flex:1
								},
								{
									headerName: 'Tax Type',
									field: 'taxTypeCode',
									flex: 0.5,
								},
								{
									headerName: 'Tax Type Description',
									field: 'taxTypeDesc',
									flex:1
								},
								{
									headerName: 'Tax Amount (%)',
									field: 'otherTaxAmt',
									flex: 0.5,
									align: 'right',
									headerAlign:'right',
									type: "numeric",
									valueGetter: ({ value }) => (value && Number(value)) || 0,
								},
								{
									headerName: 'Is Tax Included',
									field: 'taxIncludedFlag',
									flex: 0.5,
									renderCell: rowData => { return rowData.row.taxIncludedFlag === 1 ? "Y" : "N"; },
								},
								{
									headerName: 'Is Tax Percentage',
									field: 'taxPrcFlag',
									flex: 0.5,
									renderCell: rowData => { return rowData.row.taxPrcFlag === 1 ? "Y" : "N"; },
								},
								{
									headerName: 'Mappings',
									headerAlign: 'center',
									align: 'center',
									field: 'mappings', resizable: false, sortable: false,
									width: 150,
									renderCell: rowData => <Link data-taxtypecode={rowData.row.taxTypeCode} href="#" onClick={this.editTaxMaps_clickHandler}>Edit</Link>
								}
							]}
							getRowId={(row) => { /*count += 1; return count +*/ return row.rowId; }}
							disableSelectionOnClick
						/>
					</div>
				</div>
				<Dialog open={this.state.openTaxMapsDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<TaxMaps info={info} onClose={this.taxMapsDialog_closeHandler} />
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}
