import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { maxSelectFile, checkMimeType, checkFileSize } from '../NonMappedInvoices/ManualUploadApi';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { httpDelete, httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import {  uuid } from '../Common/Utilities';
const Temp = '_Temp';
export default function InvoiceDocumentEditorAttachments(props) {
	//#region Initializers
	let [state, setState] = useState({
		isDirty: false,
		openDelete: false,
		//readOnly: props.info.mode === "View" || props.info.mode === "Approval" || props.info.mode === "Test",
		invoiceDocumentAttachments: props.invoiceDocument?.invoiceDocumentAttachments.map((e,i) => ({ ...e})) || [],
		deleteRowData: null,
		uuid:uuid(),
		invoiceDocument: props.invoiceDocument
	});
	const cellEditable = props.editable;
	React.useImperativeHandle(props.stateRef, () => {
		return {
			attachments: state.invoiceDocumentAttachments.map((e) => {
				return {
					...e, file: '', companyCode: state.invoiceDocument.companyCode,
					seqId:0
				}
			})
		}
	}, [state, state.invoiceDocumentAttachments])

	const stateUpliftMethods = React.useMemo(() => {
		return { setParentState: props.setParentState }
	})

	//#endregion Initializers


	//#region apiCalls

	const uploadDocument = (filesObject) => {
		let _formData = new FormData();

		for (let fileObject of filesObject) {
			_formData.append(fileObject.attachmentName, fileObject.file);
			
		}
		_formData.append('invoicedocumentid', (state.invoiceDocument?.invoiceDocumentId !== 0 && state.invoiceDocument?.invoiceDocumentId) || (state.uuid + Temp));
		_formData.append('modelDefId', state.invoiceDocument.modelDefId);
		_formData.append('seqId', filesObject.map(e => e.seqId).join(','));
		_formData.append('uploadedBy', getName());
		
		props.info.inProgress(true);
		httpPost('/api/InvoiceDocument/uploadattachments', _formData)
			.then((response) => {
				if (state.invoiceDocument?.invoiceDocumentId) {
					for (let k of response.data) {
						let _old = Object.keys(k)[0]
						let fobject = filesObject.filter((e, i) => e.seqId == _old);
						if (fobject && fobject.length > 0) {
							filesObject[0].seqId = k[_old];
						}
					}
				}
				setState({ ...state, invoiceDocumentAttachments: [...state.invoiceDocumentAttachments, ...filesObject] });
				toast.success('Attachment uploaded successfully');
				props.info.inProgress(false);
			})
			.catch((xhr) => {
				console.log(xhr)
				toast.error('attachment uploaded failed');
				props.info.inProgress(false);
			})
		//invoiceDocumentId
		//modelDefId
	}

	const getDocument = (fileObject) => {
		props.info.inProgress(true);
		let invoicedocumentid = (state.invoiceDocument?.invoiceDocumentId !== 0 && state.invoiceDocument?.invoiceDocumentId) || (state.uuid + Temp);
		httpGet(`/api/InvoiceDocument/invoicedocument/${state.invoiceDocument.modelDefId}/${invoicedocumentid}/${fileObject.attachmentName}`)
			.then((response) => {
				let link = document.getElementById('filedownloadUrl');
				link.href = response.data['documentUrl']
				link.click();
				props.info.inProgress(false);
			})
			.catch((xhr) => {
				console.log(xhr)
				props.info.inProgress(false);
			})
	}

	//#endregion


	//#region fileUploads

	const fontSize = 12;
	const cellStyle = { fontSize: fontSize, padding: 8 };
	const headerStyle = { fontSize: fontSize, padding: 8 };
	const tableOptionsTopLeft = {

		minBodyHeight: 230,
		maxBodyHeight: 230,
		headerStyle: { fontSize: fontSize },
		actionsColumnIndex: -1,
		selection: true,
		showTextRowsSelected: false,
		selectionProps: (rowData) => {
			const checked = rowData.invoiceDocumentScanStatusId === 30 ? { checked: false } : {};
			return {
				color: 'default',
				disabled: rowData.invoiceDocumentScanStatusId === 30,
				...checked
			}
		}
	};

	const getName = () => {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return initialCap(userInfo.lastName) + ', ' + (userInfo.firstName);
		} catch { }
	}

	const initialCap = React.useCallback((data) => {
		return data[0].toUpperCase() + data.slice(1);
	},[])
	const fileAttachmentUpload_changeHandler = (event) => {

		//let invoiceDocumentAttachments = state.invoiceDocumentAttachments;
		const files = Array.from(event.target.files);
		var fileList = [];
		for (var x = 0; x < files.length; x++) {
			const fileOriginal = files[x];
			var filename = fileOriginal.name.replace(/[^\w\.]/gi, '');
			var file = new File([fileOriginal], filename, { type: fileOriginal.type });
			files[x] = file;

			let obj = {
				file: file,
				attachmentName: filename,
				attachmentDescription: filename,
				cellStyle: cellStyle,
				attachmentFullFilePath: '',
				uploadDate: new Date(),
				uploadedBy: getName(),
				seqId: 5000 + state.invoiceDocumentAttachments.length + (fileList?.length || 0),
				invoiceDocumentId: (state.invoiceDocument?.invoiceDocumentId !== 0 && state.invoiceDocument?.invoiceDocumentId) || (state.uuid + Temp)
			};
			fileList = [...fileList, obj];
			//invoiceDocumentAttachments = [...invoiceDocumentAttachments, obj];
		};

		if (maxSelectFile(event, toast) && checkMimeType(event, toast) && checkFileSize(event, toast)) {
			// if return true allow to setState
			let exist = checkIfFIleAlreadyExist(fileList);
			if (fileList.length > 0 && !exist) {
				stateUpliftMethods.setParentState({ isDirty: true });
				uploadDocument(fileList);
			}else {
				toast.error(exist && `files with the names ${exist} already exist` || 'unknown error reuploading the file');
			}
			event.target.value = null;
			//setState((prev) => ({ ...prev, invoiceDocumentAttachments: invoiceDocumentAttachments }));
		} else {
			event.target.value = null;
		}
	}

	const checkIfFIleAlreadyExist = (fileList) => {
		var dupFiles = [];
		for (let item of fileList) {
			if (state.invoiceDocumentAttachments?.some((e) => e.attachmentName == item.attachmentName && !e.deleted)) {
				dupFiles.push(item.attachmentName)
			}
		}
		return dupFiles?.join(',') || false;
	}

	const fileAttachmentUpload_clickHandler = (event) => {
		document.getElementById("invoice-button-file").click();
	}
	const getAttachmentUploadButton = () => {
		return <IconButton
			variant="contained"
			onClick={fileAttachmentUpload_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
		>
			<CloudUploadIcon />
		</IconButton>;
	}

	const closeDialog = (event) => {
		setState((prev) => ({ ...prev, openDelete: false, deleteRowData: null }));
	}

	const deleteYes_clickHandler = (event) => {
		if (state.invoiceDocument?.invoiceDocumentId) {
			deleteFile(event);
		} else {
			updateDeleteList();
		}
		stateUpliftMethods.setParentState({ isDirty: true });
		//closeDialog(event);
	}
	const updateDeleteList = () => {
		let invoiceDocumentAttachments = state.invoiceDocumentAttachments;
		invoiceDocumentAttachments = invoiceDocumentAttachments.map(x => x.seqId === state.deleteRowData.seqId ? { ...x, deleted: true } : x);
		setState((prev) => ({ ...prev, invoiceDocumentAttachments: invoiceDocumentAttachments, openDelete: false, deleteRowData: null }));
	}

	const deleteFile = (event) => {
		props.info.inProgress(true);
		setState((prev) => ({ ...prev, openDelete: false }));
		httpPost('/api/InvoiceDocument/DeleteAttachment/' + state.invoiceDocument.modelDefId, state.deleteRowData)
			.then((response) => {
				updateDeleteList();
				toast.success('Attachment deleted successfully');
				props.info.inProgress(false);
			}).catch((xhr) => {
				toast.error('Attachment deleted failed');
				props.info.inProgress(false);
			})
	}

	const columns = React.useMemo(() => {
		let _columns = [{
			headerName: 'File Name',
			field: 'attachmentName',
			flex: 1,
			cellStyle: cellStyle,
			headerStyle: headerStyle,
			renderCell: rowData => (
				<Link data-seqid={rowData.row.seqId} data-invoicedocumentid={rowData.row.invoiceDocumentId} onClick={() => { getDocument(rowData.row) }}>{rowData.row.attachmentName}</Link>
			)
		},
		{
			headerName: 'Description',
			field: 'attachmentDescription',
			cellStyle: cellStyle,
			headerStyle: headerStyle,
			flex: 0.3
		},
		{
			headerName: 'Full FilePath',
			field: 'attachmentFullFilePath',
			cellStyle: cellStyle,
			headerStyle: headerStyle,
			flex: 0.3
		},

		{
			headerName: 'Upload Date',
			field: 'uploadDate',
			type: 'date',
			valueGetter: ({ value }) => value && new Date(value),
			cellStyle: cellStyle,
			headerStyle: headerStyle,
			flex: 0.3
		},
		{
			headerName: 'Upload By',
			field: 'uploadedBy',
			cellStyle: cellStyle,
			headerStyle: headerStyle,
			flex: 0.3
		}];
		if (cellEditable) {
			_columns.push({
				headerName: 'Action',
				field: 'action',
				headerAlign: 'center',
				align: 'center',
				flex: 0.3, resizable: false, sortable: false,
				renderCell: rowData =>
					<div>
						<IconButton
							key={rowData.row.seqId}
							id={rowData.row.seqId}
							label="Delete"
							scope="row" value={rowData.row.seqId}
							onClick={() => { setState((prev) => ({ ...prev, openDelete: true, deleteRowData: rowData.row })); }}
							color="inherit"
						>
							<DeleteIcon />
						</IconButton>

					</div>
			})
		}
		return _columns;
	}, [state, cellEditable])


	return (
		(state.invoiceDocument && <div><EnhancedDataGrid
			rows={state.invoiceDocumentAttachments?.filter(e=>!e.deleted)}
			sx={{
				'& .MuiDataGrid-main': {
					height: 316
				}
			}}
			disableColumnMenu
			pagination
			title="Additional Attachments"
			pageSize={5}
			rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
			columnVisibilityModel={{
				// Hide columns 
				attachmentDescription: false,
				attachmentFullFilePath: false,
			}}
			columns={columns}
			customcomponent={
				(cellEditable &&  (<div>
					<input
						type="file"
						multiple
						accept="application/pdf, .jpg,. jpeg, .png"
						id="invoice-button-file"
						style={{ display: "none" }}
						onChange={fileAttachmentUpload_changeHandler} />
					{getAttachmentUploadButton()}
				</div>)) || <></>
			}

			options={tableOptionsTopLeft}
			getRowId={(row) => row.seqId}
			disableRowSelectionOnClick
		/>

			<Dialog
				open={state.openDelete}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					Delete
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this file?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={deleteYes_clickHandler} color="primary">
						Yes
					</Button>
					<Button autoFocus onClick={closeDialog} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
			<a id='filedownloadUrl' target="blank" style={{ display: 'none' }}></a>
		</div>) || <></>
	);
}