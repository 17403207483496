import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/MoreHoriz';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import Zoom from '@mui/material/Zoom';
import { LabelValueContainer } from './LabelValueContainer';
import { MappingInputField } from './MappingInputField';
import { InvoiceDocumentEditor } from '../../InvoiceDocument/InvoiceDocumentEditor';
import { VendorLookup } from '../../Lookups/VendorLookup';
import { CostCodesLookup } from '../../Lookups/CostCodesLookup';
import { ApprovalStampConfiguration } from './ApprovalStampConfiguration';
import { stringIsNullOrEmpty } from '../../Common/Utilities';
import { toast } from 'react-toastify';
import { httpGet, httpPost } from '../../../HttpRequestHandling/httpRequestHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class InvoiceModelEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDirty: false,
			openVendorDialog: false,
			openCostCodesDialog: false,
			openInvoiceDocumentEditorDialog: false,
			openAssignVendorConfirmation: false,
			showAssignVendorConfirmation: true,
			openVendorCurrencyConfirmation:false,
			vendor: null,
			readOnly: this.props.info.mode === "View"
		};

		this.input_changeHandler = this.input_changeHandler.bind(this);
		this.settings_clickHandler = this.settings_clickHandler.bind(this);
		this.editLocation_clickHandler = this.editLocation_clickHandler.bind(this);
		this.popover_closeHandler = this.popover_closeHandler.bind(this);
		this.save_clickHandler = this.save_clickHandler.bind(this);
		this.viewInvoiceTest_clickHandler = this.viewInvoiceTest_clickHandler.bind(this);
		this.cancel_clickHandler = this.cancel_clickHandler.bind(this);
		this.search_clickHandler = this.search_clickHandler.bind(this);
		this.searchCostCodes_clickHandler = this.searchCostCodes_clickHandler.bind(this);
		this.vendorDialog_closeHandler = this.vendorDialog_closeHandler.bind(this);
		this.costCodesDialog_closeHandler = this.costCodesDialog_closeHandler.bind(this);
		this.invoiceDocumentEditor_closeHandler = this.invoiceDocumentEditor_closeHandler.bind(this);
		this.assignVendorYes_clickHandler = this.assignVendorYes_clickHandler.bind(this);
		this.assignVendor_closeHandler = this.assignVendor_closeHandler.bind(this);
		this.vendorCurrency_closeHandler = this.vendorCurrency_closeHandler.bind(this);
		this.vendorCurrencyMap_clickHandler = this.vendorCurrencyMap_clickHandler.bind(this);
		this.vendorCurrencyAnotherVendor_clickHandler = this.vendorCurrencyAnotherVendor_clickHandler.bind(this);
		
	}

	componentDidMount() {
		var that = this;
		var masterDocumentModelId = this.props.info.rowData ? this.props.info.rowData.masterDocumentModelId : "0";

		this.props.info.inProgress(true);

		httpGet("/api/invoicemodels/invoicemodeleditor/" + this.props.info.modelDefId + "/" + masterDocumentModelId).then((response) => {
			var data = response.data;
			var isCopy = this.props.info.mode === "Copy";

			if (this.props.info.mode === "New" || this.props.info.mode === "AssignVendor") {
				data.invoiceDocumentSampleFileLocation = this.props.info.rowData.invoiceDocumentFileLocation;
				data.documentModelRequestId = this.props.info.rowData.documentModelRequestId;
			}

			if (isCopy) {
				data.masterDocumentModelId = 0;
				data.modelTag += " - Copy";
			}
			if (data.currencyValue.blocks.length>0) {
				data.currencyCode = data.currencyValue.blocks[0].text;
			}
			else
				data.currencyCode = "";
			that.setState({ invoiceModel: data, isDirty: isCopy });

			if (this.props.info.passInvoiceModelEditorToSiblings) {
				this.props.info.passInvoiceModelEditorToSiblings(data);
			}
			

			that.props.info.inProgress(false);
		}).catch((error) => {
			that.props.info.inProgress(false);
			toast.error('Error fetching invoice model');
		});
	}

	input_changeHandler(event, data) {
		var invoiceModel = this.state.invoiceModel;
		var key;
		var value;

		if (event) {
			key = event.target.name;

			if (event.target.type === "checkbox") {
				value = event.target.checked;
			}
			else if (event.target.tagName === "settings") {
				value = invoiceModel[key];
				value.settings = data;
			}
			else if (event.type === "drop" && (event.target.className === "multiSelectContainer" || (event.target.parentElement &&
				event.target.parentElement.parentElement &&
				event.target.parentElement.parentElement.className === "multiSelectContainer"))) {
				key = event.currentTarget.dataset.name;
				value = invoiceModel[key];
				value.blocks = data;

			}
			else if (event.currentTarget.tagName === "DIV" && (event.keyCode === 8 || event.keyCode === 46)) {
				key = event.currentTarget.parentElement.parentElement.dataset.name;
				value = invoiceModel[key];
				value.blocks = data;

			}
			else if (event.type === "click" && event.currentTarget.tagName === "svg") {
				key = event.currentTarget.parentElement.parentElement.parentElement.dataset.name;
				value = invoiceModel[key];
				value.blocks = data;

			}
			else {
				value = event.target.value;
			}

			invoiceModel[key] = typeof (invoiceModel[key]) === "number" ? parseInt(event.target.value, 10) : value;
		}

		if (this.props.info.passInvoiceModelEditorToSiblings) {
			this.props.info.passInvoiceModelEditorToSiblings(invoiceModel);
		}

		if (key == "currencyValue") {
			if (value.blocks.length>0)
				invoiceModel.currencyCode = value.blocks[0].text;
			else
				invoiceModel.currencyCode = "";
		}

		this.setState({ isDirty: true, invoiceModel: invoiceModel });
	}

	approval_clickHandler(event) {
		var invoiceModel = this.state.invoiceModel;
		invoiceModel.showApprovalStamp = true;

		if (this.props.info.passInvoiceModelEditorToSiblings) {
			this.props.info.passInvoiceModelEditorToSiblings(invoiceModel);
		}

		this.setState({ anchorEl: event.currentTarget, invoiceModel: invoiceModel });
	}

	settings_clickHandler(event) {
		var invoiceModel = this.state.invoiceModel;
		invoiceModel.showApprovalStamp = true;

		if (this.props.info.passInvoiceModelEditorToSiblings) {
			this.props.info.passInvoiceModelEditorToSiblings(invoiceModel);
		}

		this.setState({ anchorEl: event.currentTarget, invoiceModel: invoiceModel });
	}

	editLocation_clickHandler(event) {
		var invoiceModel = this.state.invoiceModel;
		invoiceModel.showApprovalStamp = !invoiceModel.showApprovalStamp;

		if (this.props.info.passInvoiceModelEditorToSiblings) {
			this.props.info.passInvoiceModelEditorToSiblings(invoiceModel);
		}

		this.setState({ invoiceModel: invoiceModel });
	}

	popover_closeHandler(event) {
		var invoiceModel = this.state.invoiceModel;
		invoiceModel.showApprovalStamp = false;

		if (this.props.info.passInvoiceModelEditorToSiblings) {
			this.props.info.passInvoiceModelEditorToSiblings(invoiceModel);
		}

		this.setState({ anchorEl: null, invoiceModel: invoiceModel });
	}

	save_clickHandler(event) {
		if (this.props.info.mode === "AssignVendor" && this.state.vendor != null && this.state.showAssignVendorConfirmation) {
			this.setState({ openAssignVendorConfirmation: true, showAssignVendorConfirmation: false, saveClicked: true });
		}
		else {
			this.saveInvoiceModel().then((response) => {
				this.cancel_clickHandler();
			}).catch((error) => {

			});
		}
	}

	viewInvoiceTest_clickHandler(event) {
		if (this.props.info.mode === "AssignVendor" && this.state.vendor != null && this.state.showAssignVendorConfirmation) {
			this.setState({ openAssignVendorConfirmation: true, showAssignVendorConfirmation: false, saveClicked: false });
		}
		else {
			this.saveInvoiceModel().then((response) => {
				this.setState({ openInvoiceDocumentEditorDialog: true });
			}).catch((error) => {

			});
		}
	}

	cancel_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event);
		}
	}

	search_clickHandler(event) {
		this.setState({ openVendorDialog: true });
	}

	searchCostCodes_clickHandler(event) {
		this.setState({ openCostCodesDialog: true });
	}

	vendorDialog_closeHandler(event, vendor) {
		this.setState({ openVendorDialog: false, vendor: vendor });

		var target = { name: "vendorCode", value: vendor ? vendor.vendorCode : "" };
		this.input_changeHandler({ target: target, currentTarget: target });

		target = { name: "siteId", value: vendor ? vendor.siteId : "" };
		this.input_changeHandler({ target: target, currentTarget: target });

		target = { name: "vendorCurrencyCode", value: vendor ? vendor.currencyCode : "" };
		this.input_changeHandler({ target: target, currentTarget: target });
	}

	costCodesDialog_closeHandler(event, costCodes) {
		var defaultValue = costCodes ? costCodes.resType.toString() : null;

		var settings = this.state.invoiceModel.resTypeValue.settings;

		if (settings.defaultValue !== defaultValue) {
			settings.defaultValue = defaultValue;

			this.input_changeHandler({ target: { name: "resTypeValue", tagName: "settings" } }, settings);
		}

		this.setState({ openCostCodesDialog: false });
	}

	invoiceDocumentEditor_closeHandler(event) {
		this.setState({ openInvoiceDocumentEditorDialog: false });
	}

	assignVendorYes_clickHandler(event) {
		this.setState({ openAssignVendorConfirmation: false });

		this.saveInvoiceModel(true).then((response) => {
			if (this.state.saveClicked === true) {
				this.cancel_clickHandler();
			} else if (this.state.saveClicked === false) {
				this.setState({ openInvoiceDocumentEditorDialog: true });
			}

			this.setState({ saveClicked: null });
		}).catch((error) => {

		});
	}

	assignVendor_closeHandler(event) {
		this.setState({ openAssignVendorConfirmation: false });

		this.saveInvoiceModel().then((response) => {
			if (this.state.saveClicked === true) {
				this.cancel_clickHandler();
			} else if (this.state.saveClicked === false) {
				this.setState({ openInvoiceDocumentEditorDialog: true });
			}

			this.setState({ saveClicked: null });
		}).catch((error) => {

		});
	}

	saveInvoiceModel(assignVendor) {
		var that = this;
		var invoiceModel = this.state.invoiceModel;

		if (assignVendor === true) {
			invoiceModel.assignVendorToInvoiceDocumentsWithSameModel = true;
		}

		return new Promise((resolve, reject) => {
			if (this.isSaveDisabled()) {
				resolve();
			}
			else {
				if (this.props.info.mode === "NewSample") {
					this.state.invoiceModel.InvoiceDocumentSampleFileLocation = this.props.info.invoiceDocumentSampleFileLocation;
				}

				this.props.info.inProgress(true);

				var prefix = this.props.info.modelDefId === 2 ? "production" : "media";

				httpPost("/api/invoicemodels/" + prefix + "invoicemodeleditor/", invoiceModel).then((response) => {
					toast.success("Invoice model saved successfully");
					that.props.info.inProgress(false);
					this.setState({ isDirty: false, invoiceModel: response.data });

					resolve("Promise resolved successfully");
				}).catch((error) => {
					that.props.info.inProgress(false);
					if (error.response.data.includes('Vendor currency does not match')) {
						this.setState({ openVendorCurrencyConfirmation: true });
					}
					else
						toast.error(error.response.data);

					reject(Error("Promise rejected"));
				});
			}
		});
	}
	vendorCurrencyMap_clickHandler(event) {
		this.setState({ openVendorCurrencyConfirmation: false });
		var model = this.state.invoiceModel;
		if (model.currencyValue.blocks.length > 0) {
			const currenciesMaps = [
				{
					"apamInvoiceCurrencyCode": model.currencyValue.blocks[0].text,
					"currencyCode": model.vendorCurrencyCode,
					"currencyCodeNavigation": null,
					"tableData": null
				}];
			var data = {
				currencyCode: model.vendorCurrencyCode,
				currenciesMaps: currenciesMaps
			};
			this.props.info.inProgress(true);
			httpPost("/api/setup/savecurrenciesmap/", data)
				.then((response) => {
					toast.success('Updated Successfully');
					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);
					//toast.error(<div>Currencies Update Failed<br />{error.response.data.detail}</div>);
					toast.error(<div>Currencies Update Failed<br />There is already a mapping exists with same currency selected.</div>);
					
				});
		}
		//this.saveInvoiceModel(true).then((response) => {
		//	if (this.state.saveClicked === true) {
		//		this.cancel_clickHandler();
		//	} else if (this.state.saveClicked === false) {
		//		this.setState({ openInvoiceDocumentEditorDialog: true });
		//	}

		//	this.setState({ saveClicked: null });
		//}).catch((error) => {

		//});
	}
	vendorCurrency_closeHandler(event) {
		this.setState({openVendorCurrencyConfirmation: false });
	}

	vendorCurrencyAnotherVendor_clickHandler(event) {		
		this.setState({ invoiceModel: { ...this.state.invoiceModel, vendor: null, vendorCode: "", siteId: "", vendorCurrencyCode: "" }, openVendorCurrencyConfirmation: false, openVendorDialog: true });
	}

	isSaveDisabled() {
		var returnValue = false;

		returnValue = !this.state.isDirty ||
			(this.state.invoiceModel.modelName.length === 0 || this.state.invoiceModel.modelStatus === -1) ||
			(this.props.info.mode === "NewSample" && !this.props.info.invoiceDocumentSampleFileLocation) ||
			(this.props.info.mode === "AssignVendor" && this.state.vendor === null);

		return returnValue;
	}

	isviewInvoiceTestDisabled() {
		var returnValue = false;

		returnValue = (this.state.invoiceModel.modelName.length === 0 || this.state.invoiceModel.modelStatus === -1) ||
			(this.props.info.mode === "NewSample" && !this.props.info.invoiceDocumentSampleFileLocation);

		return returnValue;
	}

	render() {
		if (!this.state.invoiceModel) {
			return <div></div>
		}

		var info = this.props.info;

		const costCodesInfo = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openCostCodesDialog,
			level2Key: "",
			level3Key: "",
			poCode: "",
			resType: this.props.info.modelDefId !== 2 ? null : stringIsNullOrEmpty(this.state.invoiceModel.resTypeValue.settings.defaultValue) ? null : parseInt(this.state.invoiceModel.resTypeValue.settings.defaultValue, 10)
		};

		var ioPOLabelValueContainer = <LabelValueContainer label={this.props.info.modelDefId !== 2 ? "IO/PO Number" : "PO Code"} name={this.props.info.modelDefId !== 2 ? "ioNumber" : "poCode"} info={info} showLabelTextAlignment="true" showValueTextAlignment="true" />;
		var ioNumberLabelValueContainer = this.props.info.modelDefId !== 2 ? ioPOLabelValueContainer : "";
		var poCodeLabelValueContainer = this.props.info.modelDefId === 2 ? ioPOLabelValueContainer : "";
		var serviceTermLabelValueContainer = this.props.info.modelDefId !== 2 ? <LabelValueContainer label="Service Term (Start/End Dates)" name="serviceTerm" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" showDateRange="true" /> : "";
		var ioDescriptionLabelValueContainer = this.props.info.modelDefId !== 2 ? <LabelValueContainer label="IO/PO Description" name="ioDescription" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" /> : "";

		var level2LabelValueContainer = this.props.info.modelDefId === 2 ? <LabelValueContainer label="Project/Job" name="level2" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" /> : "";
		var level3LabelValueContainer = this.props.info.modelDefId === 2 ? <LabelValueContainer label="Activity" name="level3" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" /> : "";
		var resTypeLabelValueContainer = this.props.info.modelDefId === 2 ? <LabelValueContainer label="Expense Type" name="resType" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" onDefaultValueClick={this.searchCostCodes_clickHandler} /> : "";
		var quantityLabelValueContainer = this.props.info.modelDefId === 2 ? <LabelValueContainer label="Quantity" name="quantity" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" /> : "";

		info.openDialog = this.state.openVendorDialog;
		info.onChange = this.input_changeHandler;
		info.invoiceModel = this.state.invoiceModel;
		info.mode = this.props.info.mode;

		var invoiceDocumentInfo = {
			invoiceModel: this.state.invoiceModel,
			modelDefId: this.props.info.modelDefId,
			inProgress: this.props.info.inProgress, mode: "Test"
		};

		invoiceDocumentInfo.invoiceModel.vendor = this.state.vendor ? this.state.vendor.vendorCode + " - " + this.state.vendor.shortName : (this.props.info.rowData ? this.props.info.rowData.vendor : "");

		return (
			<div className="mf-6 col-invoice-view col-left">
				<div className="mainTable box-container">
					<div className="mf-12">
						<h2>Model Properties</h2>
					</div>
					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>Model Name:</label>
							<div className="textBoxValidate searchFld">
								<input type="text" name="modelName" placeholder="Enter new model or select from list" readOnly={this.state.readOnly} value={info.invoiceModel.modelName} onChange={this.input_changeHandler} />
							</div>
						</div>
					</div>
					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>Model Tag:</label>
							<input type="text" name="modelTag" placeholder="Enter model tag" readOnly={this.state.readOnly} value={info.invoiceModel.modelTag} onChange={this.input_changeHandler} />
						</div>
					</div>
					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label htmlFor="status">Status:</label>
							<select id="status" name="modelStatus" disabled={this.state.readOnly} value={info.invoiceModel.modelStatus} onChange={this.input_changeHandler}>
								<option value="-1"></option>
								<option value="10">Not Mapped </option>
								<option value="20">Incomplete Mapping</option>
								<option value="30">Fully Mapped</option>
								<option value="40">Forced Mapping</option>
							</select>
						</div>
					</div>
					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>Active:</label>
							<div className="textBoxValidate">
								<div className="lblChkBox">
									<input type="checkbox" id="markActive" name="isActive" disabled={this.state.readOnly || info.invoiceModel.vendorCode === "" || info.invoiceModel.siteId === ""} checked={info.invoiceModel.isActive} value={info.invoiceModel.isActive} onChange={this.input_changeHandler} />
									<label htmlFor="markActive">
										<span></span>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>Approval Stamp:</label>
							<div className="textBoxValidate">
								<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" style={{ marginLeft: -15, marginTop: -8 }} onClick={this.editLocation_clickHandler}>
									<EditLocationIcon />
								</IconButton>
								<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" style={{ marginLeft: -15, marginTop: -8 }} onClick={this.settings_clickHandler}>
									<SettingsIcon />
								</IconButton>
							</div>
						</div>
					</div>
				</div>

				<div className="mainTable box-container mt-25 wSpace">

					<div className="mf-12">
						<h2>Vendor Information</h2>
					</div>

					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>ERP Vendor / Site:</label>
							<div className="textBoxValidate searchFld">
								<input
									type="text"
									id="txtVendor"
									name="vendor"
									readOnly={true}
									placeholder="Select ERP Vendor / Site from list"
									value={info.invoiceModel.vendorCode === "" || info.invoiceModel.siteId === "" ? "" : info.invoiceModel.vendorCode + " - " + info.invoiceModel.siteId}
									onChange={this.input_changeHandler} />
								<img src="images/images-invoicemodelsetup/search-icon.png" alt="" style={{ display: this.state.readOnly ? "none" : "" }} onClick={this.search_clickHandler} />
							</div>
						</div>
					</div>
					<div className="row-form row-ov-form">
						<div className="mf-12 field_full-7">
							<label>ERP Vendor Currency:</label>
							<div className="textBoxTwoField">
								<input
									type="text"
									id="txtVendorCurrency"
									name="vendorCurrency"
									readOnly={true}
									placeholder="Select ERP Vendor Currency Code"
									value={info.invoiceModel.vendorCurrencyCode}
									 />
							</div>
						</div>
					</div>
					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>ERP Vendor Identifier:</label>
							<div className="textBoxValidate">
								<MappingInputField name="vendorClassifier" info={info} showAlignment="true" />
							</div>
						</div>
					</div>

					<div className="mf-12 mt-25">
						<h2>Mapped Invoice Fields</h2>
					</div>

					<div className="blueBoxContainer">

						<div className="row-form row-header">
							<div className="mf-12 field_full-7">
								<label>&nbsp;</label>
								<div className="textBoxTwoField">
									<label>Label</label>
									<label>Value</label>
								</div>
							</div>
						</div>

						<LabelValueContainer label="Invoice Number" name="invoiceNumber" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" showStringFormat="true"/>

						{poCodeLabelValueContainer}

						<LabelValueContainer label="Invoice Date" name="invoiceDate" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" showDateFormat="true" />

						<LabelValueContainer label="Due Date" name="dueDate" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" showDateFormat="true" />

						{serviceTermLabelValueContainer}

						<LabelValueContainer label="Payment Term" name="paymentTerm" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" />

						<LabelValueContainer label="Currency" name="currency" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" />

						{ioNumberLabelValueContainer}

						{ioDescriptionLabelValueContainer}

						{level2LabelValueContainer}

						{level3LabelValueContainer}

						{resTypeLabelValueContainer}

						{quantityLabelValueContainer}

						<LabelValueContainer label={this.props.info.modelDefId !== 2 ? "IO/PO Net Amount" : "Amount"} name={this.props.info.modelDefId !== 2 ? "ioAmount" : "amount"} info={info} showLabelTextAlignment="true" showValueTextAlignment="true" showCurrencyFormat="true" />

						<LabelValueContainer label="Tax" name="tax" info={info} showLabelTextAlignment="true" showValueTextAlignment="true" />
					</div>

					<div className="row-form">
						<div className="mf-12 field_full-7">
							<label>Mark as Completed<br /> (Forced Mapped):</label>
							<div className="textBoxValidate">
								<div className="lblChkBox">
									<input type="checkbox" id="markAsCompleted" name="forcedMapped" disabled={this.state.readOnly} onChange={this.input_changeHandler} />
									<label htmlFor="markAsCompleted">
										<span></span>
									</label>
								</div>
							</div>
						</div>
					</div>

					<div style={{ display: 'none' }} className="row-form">
						<div className="mf-12 field_full-7">
							<label>Comments:</label>
							<textarea name="comments" readOnly={this.state.readOnly} value={info.invoiceModel.comments} onChange={this.input_changeHandler}></textarea>
						</div>
					</div>

					<div className="row-form row-btn">
						<div className="mf-12">
							<button className="btn-blue"
								disabled={this.isSaveDisabled()}
								style={{ display: this.state.readOnly ? "none" : "" }}
								onClick={this.save_clickHandler}>Save</button>
							<button className="btn-blue"
								disabled={this.isviewInvoiceTestDisabled()}
								onClick={this.viewInvoiceTest_clickHandler}>View Invoice (Test)</button>
							<button className="btn-grey" onClick={this.cancel_clickHandler}>{this.state.readOnly ? "Close" : "Cancel"} </button>
						</div>
					</div>
				</div>

				<VendorLookup info={info} onClose={this.vendorDialog_closeHandler} />
				<CostCodesLookup info={costCodesInfo} onClose={this.costCodesDialog_closeHandler} />
				<Dialog open={this.state.openInvoiceDocumentEditorDialog} TransitionComponent={Transition} fullWidth maxWidth="xl">
					<InvoiceDocumentEditor info={invoiceDocumentInfo} onClose={this.invoiceDocumentEditor_closeHandler} />
				</Dialog>

				<Dialog
					open={this.state.openAssignVendorConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Assign vendor on other invoices
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Do you want to assign vendor on other invoices having same model ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.assignVendorYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={this.assignVendor_closeHandler} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.openVendorCurrencyConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Vendor currency
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Vendor currency does not match the invoice currency. Do you want to map the invoice currency with system currency or select another vendor?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.vendorCurrencyMap_clickHandler} color="primary">
							Map Invoice Currency with System Currency
						</Button>
						<Button autoFocus onClick={this.vendorCurrencyAnotherVendor_clickHandler} color="primary">
							Select another Vendor
						</Button>
						<Button autoFocus onClick={this.vendorCurrency_closeHandler} color="primary">
							Cancel
						</Button>
					</DialogActions>
				</Dialog>


				<ApprovalStampConfiguration
					anchorEl={this.state.anchorEl}
					readOnly={this.state.readOnly}
					approvalStampConfiguration={this.state.invoiceModel.approvalStampConfiguration}
					onChange={this.input_changeHandler}
					onClose={this.popover_closeHandler} />
			</div>
		);
	}
}
