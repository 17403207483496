import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import CostCodes from './CostCodes';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class CostTypes extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openCostCodesDialog: false
		};
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.viewCostCodes_clickHandler = this.viewCostCodes_clickHandler.bind(this);
		this.costCodesDialog_closeHandler = this.costCodesDialog_closeHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getcosttype";
		let messageName = "Cost Types";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidMount() {
		this.fetchCostTypes();
	}

	viewCostCodes_clickHandler(event) {
		event.preventDefault();

		this.setState({ openCostCodesDialog: true, selectedCostType: event.target.dataset.costtype });
	}

	costCodesDialog_closeHandler(event) {
		this.setState({ openCostCodesDialog: false });
	}

	fetchCostTypes() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/costtypes/')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				let records = (data.records || []).map((e, i) => { let d = { ...e, rowId: i }; return d; });
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			costType: this.state.selectedCostType,
			inProgress: this.props.inProgress
		}

		return (
			<div>
				<h1>Cost Types</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							title={"Last Updated: " + this.state.lastUpdated}
							customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: 'Cost Type',
									field: 'costType',
									width: 50,
									flex: 0.5
								},
								{
									headerName: 'Name',
									field: 'costTypeName',
									width: 50,
									flex: 0.5
								},
								{
									headerName: 'Description',
									field: 'costTypeDescription',
									width: 250
								},
								{
									headerName: 'Status',
									field: 'costTypeStatus',
									renderCell: rowData => { return rowData.row.costTypeStatus === 1 ? "Y" : "N"; },
									flex: 0.5
								},
								{
									headerName: 'Effective Date',
									field: 'effectiveDate',
									type: 'date',
									valueGetter: ({ value }) => value && new Date(value),
									flex: 0.8
								},
								{
									headerName: 'Expiration Date',
									field: 'expirationDate',
									type: 'date',
									valueGetter: ({ value }) => value && new Date(value),
									flex: 0.8
								},
								{
									headerName: 'Expense Types',
									headerAlign: 'center',
									align: 'center',
									field: 'resources', resizable: false, sortable: false,
									flex:0.5,
									renderCell: rowData => <Link data-costtype={rowData.row.costType} href="#" onClick={this.viewCostCodes_clickHandler}>View</Link>
								}
							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
				<Dialog open={this.state.openCostCodesDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<CostCodes info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.costCodesDialog_closeHandler} color="primary">
							Close
					</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
