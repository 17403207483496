import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UpdateFavorites } from '../../store/action/favoritesAction';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { toast } from 'react-toastify';
class SubItems extends Component {

    constructor(props) {
        
        super(props);
        this.state = {
            faviconClass: "fav-icon-no"
        }
         this.className ="";
    }
    favIconClick = (item) => {
        httpPost("/api/layout/UpdateFavorites", item)
            .then((response) => {
                this.props.changeStateToReducer(response.value);
            }).catch((error) => {               
                toast.error(<div>Update Failed<br />{error.response.data.detail}</div>);
            });       
    }
   
    componentDidMount() {
        
    }
    render() {
        
        var favIconClass = this.props.subItem.isFavorite ? 'fav-icon-yes' : 'fav-icon-no';
        return (
            <tr className={this.props.isCurrClass ? 'selected' : ''} key={this.props.subItem.id}>
              
                    <td>
                    <Link onClick={this.props.setClass} to={this.props.subItem.path}>
                        <i className="material-icons">&#xE892;</i><span className="badge"></span>
                            {this.props.subItem.label}
                        </Link>
                    <span key={this.props.subItem.id}
                        //onClick={(e) => this.favIconClick(this.props.subItem)}
                        className={"fa fa-star fav-icon " + favIconClass} aria-hidden="true">
                        </span>
                    </td>
            </tr>
                           
        );
    }
}

function mapStateToProps(state) {
    
    return ({
        //userName: state.rootReducer.userName
    })
}
function mapDispatchToProps(dispatch) {
    return ({
        changeStateToReducer: (updatedValue) => {
            dispatch(UpdateFavorites(updatedValue));
        }
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(SubItems);