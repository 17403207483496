import * as React from 'react';
import { TextFieldWithLabel } from '../FormFieldControls/TextFieldWithLabel';
import './CompanyConfig.css'

export class GeneralInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const companyCode = {
            labelId: 'lblCompanyCode',
            labelText: 'Company Code',

            textboxPlaceholder: "Company Code",
            textboxId: "txtCompanyCode",
            readOnly: true,
            textboxValue: this.props.company.companyCode
        }
        const coShortName = {
            labelId: 'lblCoShortName',
            labelText: 'Company Short Name',

            textboxPlaceholder: "Company Short Name",
            textboxId: "txtCoShortName",
            readOnly: true,
            textboxValue: this.props.company.coShortName
        }
        const coName = {
            labelId: 'lblCoName',
            labelText: 'Company Name',

            textboxPlaceholder: "Company Name",
            textboxId: "txtCoName",
            readOnly: true,
            textboxValue: this.props.company.coName
        }
        const currencyCode = {
            labelId: 'lblCurrencyCode',
            labelText: 'Currency Code',

            textboxPlaceholder: "Currency Code",
            textboxId: "txtCurrencyCode",
            readOnly: true,
            textboxValue: this.props.company.currencyCode
        }
        const addrStreet1 = {
            labelId: 'lblAddrStreet1',
            labelText: 'Address Street 1',

            textboxPlaceholder: "Address Street 1",
            textboxId: "txtAddrStreet1",
            readOnly: true,
            textboxValue: this.props.company.addrStreet1
        }
        const addrStreet2 = {
            labelId: 'lblAddrStreet2',
            labelText: 'Address Street 2',

            textboxPlaceholder: "Address Street 2",
            textboxId: "txtAddrStreet2",
            readOnly: true,
            textboxValue: this.props.company.addrStreet2
        }
        const addrStreet3 = {
            labelId: 'lblAddrStreet3',
            labelText: 'Address Street 3',

            textboxPlaceholder: "Address Street 3",
            textboxId: "txtAddrStreet3",
            readOnly: true,
            textboxValue: this.props.company.addrStreet3
        }
        const addrCity = {
            labelId: 'lblAddrCity',
            labelText: 'Address City',

            textboxPlaceholder: "Address City",
            textboxId: "txtAddrCity",
            readOnly: true,
            textboxValue: this.props.company.addrCity
        }
        const addrStateProvince = {
            labelId: 'lblAddrStateProvince',
            labelText: 'Address State/Province',

            textboxPlaceholder: "Address State/Province",
            textboxId: "txtAddrStateProvince",
            readOnly: true,
            textboxValue: this.props.company.addrStateProvince
        }
        const addrZipPostcode = {
            labelId: 'lblZipPostcode',
            labelText: 'Zip Post Code',

            textboxPlaceholder: "Zip Post Code",
            textboxId: "txtZipPostcode",
            readOnly: true,
            textboxValue: this.props.company.addrZipPostcode
        }
        const telArea = {
            labelId: 'lblTelArea',
            labelText: 'Telephone Area',

            textboxPlaceholder: "Telephone Area",
            textboxId: "txtTelArea",
            readOnly: true,
            textboxValue: this.props.company.telArea
        }
        const telNumber = {
            labelId: 'lblTelNumber',
            labelText: 'Telephone Number',

            textboxPlaceholder: "Telephone Number",
            textboxId: "txtTelephoneNumber",
            readOnly: true,
            textboxValue: this.props.company.telNumber
        }

        return (
            <div className = "col-md-12 form-group zero-padding zero-margin" >
            <fieldset>
                <div className="form-group">
                    <TextFieldWithLabel info={companyCode} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={coShortName} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={coName} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={currencyCode} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={addrStreet1} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={addrStreet2} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={addrStreet3} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={addrCity} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={addrStateProvince} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={addrZipPostcode} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={telArea} />
                </div>
                <div className="form-group">
                    <TextFieldWithLabel info={telNumber} />
                </div>
            </fieldset>
            </div>
        );
    }
}
