import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { httpDelete, httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { PositionCategoryCodes } from './PositionCategoryCodes';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { useGridApiRef, GridRowModes, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import { userRoleModel } from '../../Models';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { Check } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';

var currentEditRow = null;

export default function UserRoles(props) {
	const [state, setState] = React.useState({
		data: [],
		company: {},
		positionCategoryList: [],
		userRolesInvoiceRightsList: [],
		openDeletetConfirmation: false,
		deleteRowData: null,
		edit:null
	});
	const [currentEditRowId, setCurrentEditRowId] = React.useState(null);
	const [pageSize, setPageSize] = React.useState(10);
	const [rowModesModel, setRowModesModel] = React.useState({});

	const apiGridRef = useGridApiRef();

	React.useEffect(() => {
		fetchUserRoles().then((response) => {
			const userInfo = JSON.parse(localStorage.userInfo);

			props.inProgress(true);

			httpGet("/api/company/" + userInfo.companyCode)
				.then((response) => {
					var data = response.data;

					props.inProgress(false);
					setState((prev)=>({...prev, company: data }));
				});

			props.inProgress(true);

			httpGet("/api/positioncategory/")
				.then((response) => {
					var positionCategoryList = response.data;

					props.inProgress(false);

					setState((prev) => ({ ...prev, positionCategoryList: positionCategoryList }));
				})

		}).catch((error) => {
			props.inProgress(false);

			toast.error('An error occurred while fetching the records');
			console.error('There was an error!', error);
		});
	}, [])

	const fetchUserRoles = React.useCallback(() => {

		props.inProgress(true);

		var promise = httpGet("/api/userroles/")
			.then((response) => {
				var data = response.data;
				var rowId = 1;

				data.map(row => { row.rowId = rowId++; convertToBooleanValues(row); });

				var userRolesInvoiceRightsList = data[0].userRolesInvoiceRightsList;

				props.inProgress(false);

				setState((prev) => ({ ...prev, data: data, userRolesInvoiceRightsList: userRolesInvoiceRightsList }));
			}).catch((error) => {
				props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});

		return promise;
	},[]);

	const saveUserRoles = React.useCallback((data, oldData) => {
		data.isAdminRole = data.isAdminRole ? "Y" : "N";
		data.allowRoutingSetup = data.allowRoutingSetup ? "Y" : "N";

		data.userRolesInvoiceRightsList.map(function (userRolesInvoiceRights, index) {
			var modelDefId = userRolesInvoiceRights.modelDefId;

			userRolesInvoiceRights.allowNonMappedInvoicesRequestProcessing = data["allowNonMappedInvoicesRequestProcessing_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceModelMapping = data["allowInvoiceModelMapping_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceAutoRouting = data["allowInvoiceAutoRouting_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceManualProcessing = data["allowInvoiceManualProcessing_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceDocumentManagement = data["allowInvoiceDocumentManagement_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceCorrection = data["allowInvoiceCorrection_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel1Approval = data["allowInvoiceLevel1Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel2Approval = data["allowInvoiceLevel2Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel3Approval = data["allowInvoiceLevel3Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel4Approval = data["allowInvoiceLevel4Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel5Approval = data["allowInvoiceLevel5Approval_" + modelDefId] ? "Y" : "N";
		});

		props.inProgress(true);

		httpPost("/api/userroles/", data)
			.then((response) => {
				props.inProgress(false);
				toast.success('Updated Successfully');
				currentEditRow = null;
				fetchUserRoles();
			}).catch((error) => {
				if (oldData?.isNew) {
					setState((prev) => ({ ...prev, data: state.data.filter(e => e.rowId !== oldData.rowId) }))
				} else {
					apiGridRef.current.updateRows([{ id: oldData.rowId, ...oldData }])
				}
				props.inProgress(false);
				toast.error(<div>User Role Update Failed<br />{error.response.data.detail}</div>);
			});
	}, [state.data])

	const deleteUserRoles = React.useCallback(() => {
		props.inProgress(true);
		let data = state.deleteRowData;
		setState((prev) => ({ ...prev, deleteRowData: null, openDeletetConfirmation :false}))

		httpDelete("/api/userroles/" + data.companyCode + "/" + data.userRoleId)
			.then((response) => {
				props.inProgress(false);
				toast.success('Deleted Successfully');
				//resolve();
				fetchUserRoles();
			}).catch((error) => {
				//reject();
				props.inProgress(false);
				toast.error(<div>Delete Failed<br />{error.response.data}</div>);
			});
	}, [state.deleteRowData])

	const isRecordValid = React.useCallback((data) => {
		var returnValue = true;
		var message = "";

		if (data.userRoleName === undefined || data.userRoleName.trim().length === 0) {
			message += "User Group Name";
		}

		if (message.length > 0) {
			toast.error("Please Provide " + message);
			returnValue = false;
		}

		return returnValue;
	}, [])

	const convertToBooleanValues = React.useCallback((row) => {
		row.isAdminRole = row.isAdminRole === 'Y';
		row.allowRoutingSetup = row.allowRoutingSetup === 'Y';

		row.userRolesInvoiceRightsList.map(function (userRolesInvoiceRights, index) {
			var modelDefId = userRolesInvoiceRights.modelDefId;

			row["allowNonMappedInvoicesRequestProcessing_" + modelDefId] = userRolesInvoiceRights.allowNonMappedInvoicesRequestProcessing === 'Y';
			row["allowInvoiceModelMapping_" + modelDefId] = userRolesInvoiceRights.allowInvoiceModelMapping === 'Y';
			row["allowInvoiceAutoRouting_" + modelDefId] = userRolesInvoiceRights.allowInvoiceAutoRouting === 'Y';
			row["allowInvoiceManualProcessing_" + modelDefId] = userRolesInvoiceRights.allowInvoiceManualProcessing === 'Y';
			row["allowInvoiceDocumentManagement_" + modelDefId] = userRolesInvoiceRights.allowInvoiceDocumentManagement === 'Y';
			row["allowInvoiceCorrection_" + modelDefId] = userRolesInvoiceRights.allowInvoiceCorrection === 'Y';
			row["allowInvoiceLevel1Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel1Approval === 'Y';
			row["allowInvoiceLevel2Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel2Approval === 'Y';
			row["allowInvoiceLevel3Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel3Approval === 'Y';
			row["allowInvoiceLevel4Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel4Approval === 'Y';
			row["allowInvoiceLevel5Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel5Approval === 'Y';
		});
	}, [])

	const getEmptyUserRolesInvoiceRightsList = React.useCallback(() => {
		var userRolesInvoiceRightsList = [];

		state.userRolesInvoiceRightsList.map(function (userRolesInvoiceRights, index) {
			userRolesInvoiceRightsList.push({ modelDefId: userRolesInvoiceRights.modelDefId });
		});

		return userRolesInvoiceRightsList;
	}, [state])

	const getPositionCategoryName = React.useCallback((apadUserRolePositionCategories,mode) => {
		var assignedUserRolePositionCategories = "";

		if (state.positionCategoryList.length > 0 && apadUserRolePositionCategories && apadUserRolePositionCategories.length > 0) {
			assignedUserRolePositionCategories = state.positionCategoryList.find(pc => pc.positionCategoryCode === apadUserRolePositionCategories[0].positionCategoryCode).positionCategoryName + (apadUserRolePositionCategories.length > 1 ? "..." : "");
		}

		return assignedUserRolePositionCategories;
	}, [state])

	const getPositionCategoryNameCSV = React.useCallback((apadUserRolePositionCategories) => {
		var assignedUserRolePositionCategories = [];
		

		if (state.positionCategoryList.length > 0 && apadUserRolePositionCategories && apadUserRolePositionCategories.length > 0) {
			apadUserRolePositionCategories.map((positionCategory, index) => (
				assignedUserRolePositionCategories.push(state.positionCategoryList.find(pc => pc.positionCategoryCode === positionCategory.positionCategoryCode).positionCategoryName)
			))
		}

		return assignedUserRolePositionCategories.join(", ");
	}, [state])

	const getPositionCategoryNameList = React.useCallback((apadUserRolePositionCategories) => {
		return (<ul key={2} style={{ margin: 0, pading: 0, listStyle: 'none' }}> {(apadUserRolePositionCategories)
			.map((e, ei) => {
				let rolename = state.positionCategoryList.find(pc => pc.positionCategoryCode === e.positionCategoryCode)?.positionCategoryName;
				return (rolename && <li key={ei}>{rolename}</li>) || <></>
			})}</ul>)
	}, [state])

	const selectedPositionCategoryCodes_onClose = React.useCallback((selectedPositionCategories) => {
		if (selectedPositionCategories) {
			var apadUserRolePositionCategories = [];

			selectedPositionCategories.map((positionCategory, index) => (
				apadUserRolePositionCategories.push({ companyCode: positionCategory.companyCode, userRoleId: currentEditRow.userRoleId, positionCategoryCode: parseInt(positionCategory.positionCategoryCode, 10) })
			));

			currentEditRow.apadUserRolePositionCategories = apadUserRolePositionCategories;

			setState((prev) => ({ ...prev, dummy: null, edit: { ...prev.edit, apadUserRolePositionCategories: apadUserRolePositionCategories } }));
		}
	}, [state,state.edit])

	const handleEditClick = React.useCallback((row) => {
		setCurrentEditRowId(row.rowId);
		setRowModesModel({ ...rowModesModel, [row.rowId]: { mode: GridRowModes.Edit } });
	})

	const handleSaveClick = (newData) => {
		setRowModesModel({ ...rowModesModel, [newData.rowId]: { mode: GridRowModes.View } });
	};

	const handleCancelClick = (id) => {
		setCurrentEditRowId(null)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});
		const editedRow = state.data.find((row) => row.rowId === id);
		//if (editedRow?.isNew) {
		//	apiGridRef.current.setPage(0);
		//}
		setState((prev) => {
			return (editedRow?.isNew) ?
				{ ...prev, edit: null, data: state.data.filter((row) => row.rowId !== id) }
				: { ...prev, edit: null }
		});
	};

	const getAddButton = React.useCallback(() => {
		return <IconButton key={1}
			variant="contained"
			onClick={handleAddClick}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			disabled={(currentEditRowId)?true:false }
		>
			<Tooltip key={1} title={'add'}><AddBoxIcon key={2} /></Tooltip>
		</IconButton>;
	}, [state.data, currentEditRowId])


	const handleAddClick = React.useCallback(() => {
		const id =  ([...state.data]?.sort((a,b)=>b.rowId-a.rowId)[0]?.rowId + 1) || 1;
		//apiGridRef.current.setPage(Math.ceil(state.data.length+1 / pageSize));
		let model = userRoleModel();
		let field = 'userRoleName';
		model.rowId = id;
		model.isNew = true;
		setCurrentEditRowId(id);
		setState((prev) => ({
			...prev, isDirty: true, data: [model,...prev.data]
		}));
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: field } })

	}, [state.data]);

	const processRowUpdate = (newrow, oldrow) => {
		let _newrow = structuredClone(newrow);
		if (_newrow.isNew) {
			_newrow.rowId = 0;
			_newrow.userRoleId = 0;
			_newrow.companyCode = parseInt(state.company.companyCode);
			_newrow.userRolesInvoiceRightsList = getEmptyUserRolesInvoiceRightsList();
		}
		_newrow.apadUserRolePositionCategories = state.edit?.apadUserRolePositionCategories
			|| currentEditRow.apadUserRolePositionCategories;
		setState((prev) => ({ ...prev, edit: null }))
		if (!isRecordValid(_newrow)) {
			setRowModesModel({ ...rowModesModel, [newrow.rowId]: { mode: GridRowModes.Edit } })
			throw new Error();
		}
		setCurrentEditRowId(null);
		//if (_newrow?.isNew) apiGridRef.current.setPage(0)
		saveUserRoles(_newrow, oldrow);
		return newrow;
	}
	const handlePageSizeChange = React.useCallback((newpagesize) => {
		setPageSize((prev) => (newpagesize));
	}, [state])

	const closeDeleteDialog = React.useCallback((event) => {
		setState((prev) => ({ ...prev, openDeletetConfirmation: false, deleteRowData: null }));
	}, [])

	const userRoles_DeleteClickHandler = React.useCallback((rowData) => {
		setState((prev) => ({ ...prev, openDeletetConfirmation: true, deleteRowData: rowData }));
	}, [])

	const columns = [
			{
				headerName: 'User Group Name',
				field: 'userRoleName',
				width:150,
				editable:true
			},
			{
				headerName: 'Role',
				field: 'apadUserRolePositionCategories',
				width: 150,
				renderCell: rowData => {
					return <div>
						<Tooltip title={ getPositionCategoryNameList(rowData?.row?.apadUserRolePositionCategories || [])}>
							<Typography variant="inherit">{getPositionCategoryName(rowData.row.apadUserRolePositionCategories)}</Typography>
						</Tooltip>
					</div>
				},
				renderEditCell: props => {
					currentEditRow = props.row;

					const info = {
						positionCategoryList: state.positionCategoryList,
						apadUserRolePositionCategories: props.row.apadUserRolePositionCategories ? props.row.apadUserRolePositionCategories : []
					};

					return <Box
						sx={{
							display: 'flex',
							flexDirection: 'row'
						}}>
						<Tooltip title={getPositionCategoryNameList((state.edit?.apadUserRolePositionCategories || currentEditRow.apadUserRolePositionCategories) || [])}>
							<Typography sx={{
								pt: '10px'
							}} variant="inherit">{getPositionCategoryName(state.edit?.apadUserRolePositionCategories ||currentEditRow.apadUserRolePositionCategories)}</Typography>
						</Tooltip>
						<PositionCategoryCodes info={info} onClose={selectedPositionCategoryCodes_onClose} />
					</Box>
				},
				editable: true
			},
			{
				headerName: 'Admin',
				field: 'isAdminRole',
				type: 'boolean',
				renderCell: (props) => {
					return (
						(props?.row['isAdminRole']) ?
							<Check key={props?.row.rowId }  color={'action'} /> :
							<RemoveIcon key={props?.row.rowId } color={'action'} />

					)
				},
				renderEditCell: props => (
					<Checkbox
						color="default"
						checked={props.value}
						onChange={e => {
							const newValue = e.target.checked;
							const { id, api, field, value } = props;// The new value entered by the user
							api.setEditCellValue({ id, field, value: newValue })

						}}
					/>
				),
				width: 110,
				editable: true
			},
			{
				headerName: 'Routing Setup',
				field: 'allowRoutingSetup',
				type: 'boolean',
				renderCell: (props) => {
					return (
						(props?.row['allowRoutingSetup']) ?
							<Check key={props?.row.rowId } color={'action'} /> :
							<RemoveIcon key={props?.row.rowId} color={'action'} />

					)
				},
				renderEditCell: props => (
					<Checkbox
						color="default"
						checked={props.value}
						onChange={e => {
							const newValue = e.target.checked;
							const { id, api, field, value } = props;// The new value entered by the user
							api.setEditCellValue({ id, field, value: newValue })

						}}
					/>
				),
				width: 110,
				editable: true
			}
		];

		if (state.company && state.company.companyApprovalConfigurations) {
			var approvalConfigs = state.company.companyApprovalConfigurations;
			approvalConfigs.map(function (approvalConfig, index) {
				var modelDefId = approvalConfig.modelDefId;
				var invoiceType = approvalConfig.modelDefId === 2 ? 'Production' : 'Media';

				columns.push(
					{
						headerName: invoiceType + ' Non Mapped Invoices Request Processing',
						field: 'allowNonMappedInvoicesRequestProcessing_' + modelDefId,
						type: 'boolean', 
						renderCell: (props) => {
							return (
								(props?.row['allowNonMappedInvoicesRequestProcessing_' + modelDefId]) ?
									<Check key={props?.row.rowId + modelDefId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId + modelDefId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					},
					{
						headerName: invoiceType + ' Model Mapping',
						field: 'allowInvoiceModelMapping_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceModelMapping_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					},
					{
						headerName: invoiceType + ' Receive Discrepant Invoice',
						field: 'allowInvoiceAutoRouting_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceAutoRouting_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					},
					{
						headerName: invoiceType + ' Manual Processing',
						field: 'allowInvoiceManualProcessing_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceManualProcessing_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					},
					{
						headerName: invoiceType + ' Document Management',
						field: 'allowInvoiceDocumentManagement_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceDocumentManagement_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					},
					{
						headerName: invoiceType + ' Correction',
						field: 'allowInvoiceCorrection_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceCorrection_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					},
					{
						headerName: invoiceType + ' Level 1 Approval',
						field: 'allowInvoiceLevel1Approval_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceLevel1Approval_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					});

				if (approvalConfig.routingNumberOfApprovalLevels >= 2) {

					columns.push({
						headerName: invoiceType + ' Level 2 Approval',
						field: 'allowInvoiceLevel2Approval_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceLevel2Approval_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					});
				}

				if (approvalConfig.routingNumberOfApprovalLevels >= 3) {
					columns.push({
						headerName: invoiceType + ' Level 3 Approval',
						field: 'allowInvoiceLevel3Approval_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceLevel3Approval_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					});
				}

				if (approvalConfig.routingNumberOfApprovalLevels >= 4) {
					columns.push({
						headerName: invoiceType + ' Level 4 Approval',
						field: 'allowInvoiceLevel4Approval_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceLevel4Approval_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'} />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					});
				}

				if (approvalConfig.routingNumberOfApprovalLevels >= 5) {
					columns.push({
						headerName: invoiceType + ' Level 5 Approval',
						field: 'allowInvoiceLevel5Approval_' + modelDefId,
						type: 'boolean',
						renderCell: (props) => {
							return (
								(props?.row['allowInvoiceLevel5Approval_' + modelDefId]) ?
									<Check key={props?.row.rowId} color={'action'} /> :
									<RemoveIcon key={props?.row.rowId} color={'action'}  />

							)
						},
						renderEditCell: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => {
									const newValue = e.target.checked;
									const { id, api, field, value } = props;// The new value entered by the user
									api.setEditCellValue({ id, field, value: newValue })

								}}
							/>
						),
						width: 110,
						editable: true
					});
				}
			});
	}
	columns.push({
		field: 'actions',
		type: 'actions',
		headerName: 'Actions',
		width: 100,
		cellClassName: 'actions',
		getActions: (rowData) => {
			const isInEditMode = rowModesModel[rowData.id]?.mode === GridRowModes.Edit;

			if (isInEditMode) {
				return [
					<GridActionsCellItem
						icon={<Tooltip key={rowData.id + 3} title={'Save'}><DoneIcon key={rowData.id + 1} /></Tooltip>}
						label="Save"
						color="inherit"
						onClick={() => { handleSaveClick(rowData.row) }}
					/>,
					<GridActionsCellItem
						icon={<Tooltip key={rowData.id + 2} title={'Cancel'}><CancelIcon key={rowData.id + 1} /></Tooltip>}
						label="Cancel"
						className="textPrimary"
						onClick={() => { handleCancelClick(rowData.id) }}
						color="inherit"
					/>,
				];
			}

			return [
				<GridActionsCellItem
					icon={<Tooltip key={rowData.id + 2} title={'Edit'}><EditIcon key={rowData.id + 1} /></Tooltip>}
					label="Edit"
					className="textPrimary"
					onClick={() => { handleEditClick(rowData.row) }}
					color="inherit"
					disabled={currentEditRowId && currentEditRowId !== rowData.id}
				/>,
				<GridActionsCellItem
					icon={<Tooltip key={rowData.id + 3} title={'Delete'}><DeleteIcon key={rowData.id + 1} /></Tooltip>}
					label="Delete"
					onClick={() => { userRoles_DeleteClickHandler(rowData.row)}}
					color="inherit"
					disabled={currentEditRowId && currentEditRowId !== rowData.id}
				/>,
			];
		},
	})

	const style = React.useMemo(() => {
		return {
			'& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within,& .MuiDataGrid-cell:focus-within': {
				outline: 'none !important', padding: '0 10px !important'
			},
			'& .MuiDataGrid-columnHeader': {
				outline: 'none !important'
			},
			'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
				height: '0.5em',
					width: '0.5em'
			},
			'& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:vertical , .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:horizontal': {
				background: '#B2b2b2', borderRadius: 2, opacity: '0.5'
			}
		}
	})

		return (
			<div>
				<h1>User Groups Management</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							apiRef={apiGridRef}
							sx={{
								'& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaders': {
									height: '125px !important',
									maxHeight: '125px !important'
								}
							}}
							editMode="row"							
							rowModesModel={rowModesModel}
							columns={columns}
							processRowUpdate={processRowUpdate}
							onProcessRowUpdateError={(err) => {} }
							onPageSizeChange={handlePageSizeChange}
							customcomponent={<>{getAddButton()}</> }
							editable={
								{
									onRowAdd: newData =>
										new Promise((resolve, reject) => {
											newData.rowId = 0;
											newData.userRoleId = 0;
											newData.companyCode = parseInt(state.company.companyCode);
											newData.userRolesInvoiceRightsList = getEmptyUserRolesInvoiceRightsList();
											newData.apadUserRolePositionCategories = currentEditRow.apadUserRolePositionCategories;

											if (isRecordValid(newData)) {
												saveUserRoles(newData, resolve, reject);
											}
											else {
												reject();
											}
										}),
									onRowUpdate: (newData, oldData) =>
										new Promise((resolve, reject) => {
											newData.apadUserRolePositionCategories = currentEditRow.apadUserRolePositionCategories;

											if (isRecordValid(newData)) {
												saveUserRoles(newData, resolve, reject);
											}
											else {
												reject();
											}
										}),
									onRowDelete: oldData =>
										new Promise((resolve, reject) => {
											deleteUserRoles(oldData, resolve, reject);
										}),
								}
							}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
				<Dialog
					open={state.openDeletetConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Delete Record
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete user group '{state.deleteRowData?.userRoleName}' ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={deleteUserRoles} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={closeDeleteDialog} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			
		);
}