import * as React from 'react';
import { InputText } from '../FormFieldControls/InputText';
import { LabelText } from '../FormFieldControls/LabelText';

export class TextFieldWithLabel extends React.Component {
    constructor() {
        super();
        this.state = { readOnly: true }
        this._click = this._click.bind(this);
    }
    _click() {

    }

    render() {
        return (
            <React.Fragment>
                <div className="labeldiv col-md-3">
                    <LabelText labelInfo={this.props.info} />
                </div>
                <div className="fielddiv col-md-3">
                    <InputText textboxInfo={this.props.info}
                        validate={this.props.validate}
                        errorMessage={this.props.errorMessage}
                        emptyMessage={this.props.emptyMessage}
                        changeHandler={this.props.changeHandler} />
                </div>
            </React.Fragment>
        );
    }
}
