import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import PODetails from './PODetails';
import POResources from './POResources';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Check } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';



const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class POHeaders extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			data: [],
			lastUpdated: "",
			openPODetailsDialog: false,
			openPOResourcesDialog: false
		};
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.viewPODetails_clickHandler = this.viewPODetails_clickHandler.bind(this);
		this.poDetailsDialog_closeHandler = this.poDetailsDialog_closeHandler.bind(this);
		this.viewPOResources_clickHandler = this.viewPOResources_clickHandler.bind(this);
		this.poResourcesDialog_closeHandler = this.poResourcesDialog_closeHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getpoheaders";
		if (this.props.modelDefId == 1)
			functionName = "getioheaders";
		let messageName = "PO Headers";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidMount() {
		this.fetchPOHeaders();
	}

	viewPODetails_clickHandler(event) {
		event.preventDefault();

		this.setState({ openPODetailsDialog: true, selectedPOCode: event.target.dataset.pocode });
	}

	poDetailsDialog_closeHandler(event) {
		this.setState({ openPODetailsDialog: false });
	}

	viewPOResources_clickHandler(event) {
		event.preventDefault();

		this.setState({ openPOResourcesDialog: true, selectedPOCode: event.target.dataset.pocode });
	}

	poResourcesDialog_closeHandler(event) {
		this.setState({ openPOResourcesDialog: false });
	}

	fetchPOHeaders() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/poheaders/' + this.props.modelDefId)
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				let records = (data.records || []).map((e, i) => { let d = { ...e, rowId: i }; return d; });
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		var currencyCode = this.state.data.length > 0 ? this.state.data[0].currencyCode : "";
		const info = {
			poCode: this.state.selectedPOCode,
			currencyCode: currencyCode,
			inProgress: this.props.inProgress
		}

		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>{this.props.modelDefId === 1 ? "Insertion" : "Purchase"} Orders</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							title={"Last Updated: " + this.state.lastUpdated}
							customcomponent={this.getRefreshButton()}
							columnVisibilityModel={{
								'resources': this.props.modelDefId === 1,//hide: this.props.modelDefId === 2,
								'campaignName': this.props.modelDefId === 1,
								'clientName': this.props.modelDefId === 1,
								'jobCode': this.props.modelDefId === 1,
								'vendorCode': this.props.modelDefId === 2,
								'vendorName': this.props.modelDefId === 2,
								'details': this.props.modelDefId === 2
							}}
							columns={[
								{ field: 'siteId', headerName: 'Site ID', width: 150 },
								{
									headerName: 'IO/PO Number',
									field: 'poCode',
									width: 150,
								},
								{
									headerName: 'IO/PO Amount',
									field: 'poAmount',
									type: 'currency',
									headerAlign: 'right',
									align: 'right',
									renderCell: rowData => { return formatNumber(rowData.row.poAmount) + " " + rowData.row.currencyCode },
									width: 250,
								},
								{
									headerName: 'Consumed Amount',
									field: 'consumedAmount',
									type: 'currency',
									headerAlign: 'right',
									align: 'right',
									renderCell: rowData => formatNumber(rowData.row.consumedAmount) + " " + rowData.row.currencyCode,
									width: 250
								},
								{
									headerName: 'Client Name',
									field: 'clientName',
									width: 300,
								}, {
									headerName: 'Vendor Code',
									field: 'vendorCode',
									width: 200,
								},  {
									headerName: 'Vendor Name',
									field: 'vendorName',
									flex: 1
								},
								{
									headerName: 'Job Code',
									field: 'jobCode',
									width: 150, flex: 1
								},
								{
									headerName: 'Campaign Name',
									field: 'campaignName',
									flex:1
								},
								{
									headerName: 'Active',
									field: 'activeFlag',
									renderCell: (props) => {
										return (
											(props?.row?.activeFlag) ?
												<Check key={props?.row?.rowId} color={'action'} /> :
												<RemoveIcon key={props?.row?.rowId} color={'action'} />

										)
									},
									width: 80
								},
								{
									headerName: 'Details',
									headerAlign: 'center',
									cellClassName: 'text-center',
									align:'center',
									field: 'details', resizable: false, sortable: false,

									renderCell: rowData =>  <Link data-pocode={rowData.row.poCode} href="#" onClick={this.viewPODetails_clickHandler}>View</Link>,
									width:150
								},
								{
									headerName: 'Resources',
									headerAlign: 'center',
									cellClassName:'text-center',
									field:'resources',
									resizable: false,
									sortable: false,
									align: 'center',
									
									renderCell: rowData => <Link data-pocode={rowData.row.poCode} href="#" onClick={this.viewPOResources_clickHandler}>View</Link>,
									width: 150
								}
							]}
							getRowId={(row) => row.rowId}

							disableSelectionOnClick
						/>
					</div>
				</div>
				<Dialog open={this.state.openPODetailsDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<PODetails info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.poDetailsDialog_closeHandler} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openPOResourcesDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<POResources info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.poResourcesDialog_closeHandler} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
