import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import BackIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage'
import '../InvoiceModels/InvoiceModelSetup/css/style.css';
import { toast } from 'react-toastify';
import PDFViewer from '../InvoiceModels/InvoiceModelSetup/PDFViewer';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export class InvoiceDocumentViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			documentDataUri: "",
			page: 1,
			pages: 1
		};

		this.load_Handler = this.load_Handler.bind(this);
		this.error_Handler = this.error_Handler.bind(this);
		this.documentLoadSuccess_Handler = this.documentLoadSuccess_Handler.bind(this);
		this.pageRenderSuccess_Handler = this.pageRenderSuccess_Handler.bind(this);

		window.addEventListener("resize", this.resize_Handler);

		this.fetchInvoiceDocument();
	}

	fetchInvoiceDocument() {
		var that = this;

		var modelDefId = this.props.info.modelDefId;
		var encodedFileLocation = escape(this.props.info.invoiceDocumentFileLocation);

		this.props.info.inProgress(true);

		httpGet("/api/invoicedocument/view/" + modelDefId + "/" + encodedFileLocation)
			.then((response) => {
				var data = response.data;

				this.setState({ documentDataUri: data });
			}).catch((error) => {
				this.setState({ documentDataUri: "" });

				this.error_Handler(null, error.response.data);
			});
	}

	load_Handler(event) {
		this.props.info.inProgress(false);
	}

	error_Handler(event, message) {
		this.props.info.inProgress(false);
		toast.error(message ? message : 'Invoice document not found');

		if (this.props.info.onClose) {
			this.props.info.onClose();
		}
	}

	documentLoadSuccess_Handler(pdfDocument) {
		this.setState({ pages: pdfDocument.numPages });
	}

	pageRenderSuccess_Handler(documentCanvas) {
		this.props.info.inProgress(false);

		documentCanvas.style.width = "100%";
		documentCanvas.style.position = "";
	}

	getMimeTypeFromBase64(data) {
		var returnValue = "";

		var mimeType = data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);

		if (mimeType) { return mimeType[0] };

		var base64 = data.replace(/^data:application\/(pdf);base64,/, '');

		switch (base64.charAt(0)) {
			case '/':
				returnValue = "image/jpeg";
				break;
			case 'R':
				returnValue = "image/gif";
				break;
			case 'i':
				returnValue = "image/png";
				break;
			case 'J':
				returnValue = "application/pdf";
				break;
			default:
				returnValue = "application/unknown";
				break;
		}

		return returnValue;
	}

	handlePrevious() {
		var page = this.state.page - 1 >= 1 ? this.state.page - 1 : this.state.page;

		this.setState({ page: page });
	}

	handleNext() {
		var page = this.state.page + 1 <= this.state.pages ? this.state.page + 1 : this.state.page;

		this.setState({ page: page });
	}

	render() {
		const isPDF = this.getMimeTypeFromBase64(this.state.documentDataUri) === "application/pdf";
		const src = isPDF ? "" : this.state.documentDataUri;
		const errorHandler = src === "" ? null : this.error_Handler;
		const position = this.props.info.inline ? "relative" : "absolute";

		return (
			<div className="mf-12 col-left" style={{ backgroundColor: "#7a7a7a" }}>
				<div className="col-pdf-view">
					<div className="pdfContainer">
						<div style={{ overflow: "auto", position: "relative" }}>
							<img id="imgInvoiceDocument" src={src} alt="" style={{ display: isPDF ? "none" : "" }} onLoad={this.load_Handler} onError={errorHandler} />
							<PDFViewer
								file={this.state.documentDataUri}
								page={this.state.page}
								scale={3}
								onDocumentLoadSuccess={this.documentLoadSuccess_Handler}
								onPageRenderSuccess={this.pageRenderSuccess_Handler}
								onError={this.error_Handler} />
						</div>
						<div style={{ display: isPDF ? "" : "none", position: position, bottom: 4, left: "40%" }}>
							<IconButton aria-label="First Page" onClick={() => this.setState({ page: 1 })}>
								<FirstPageIcon />
							</IconButton>
							<IconButton aria-label="Previous Page" onClick={() => this.handlePrevious()}>
								<BackIcon />
							</IconButton>
							<span style={{ margin: 5 }}>{this.state.page}</span>
							<span style={{ margin: 5 }}>/</span>
							<span style={{ margin: 5 }}>{this.state.pages}</span>
							<IconButton aria-label="Next Page" onClick={() => this.handleNext()}>
								<NextIcon />
							</IconButton>
							<IconButton aria-label="Last Page" onClick={() => this.setState({ page: this.state.pages })}>
								<LastPageIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
