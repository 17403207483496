import * as React from 'react';
import Button from '@mui/material/Button';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { toast } from 'react-toastify';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { GridRowModes, useGridApiRef } from '@mui/x-data-grid';
import { flushSync } from 'react-dom';

export default function CurrenciesMaps(props) {
	const [state, setCMState] = React.useState({
		data: [],
		selectedRows: [],
		isDirty: false,
		isRowInProcessMode: false,
		processedRowId:null
	});
	const [isCommited, setIsCommited] = React.useState(false);
	const [trigger, setTrigger] = React.useState(false);
	const [key, setKey] = React.useState(false);
	const apiGridRef = useGridApiRef();
	React.useEffect(() => {
		fetchCurrenciesMaps();
	}, [])


	React.useEffect(() => {
		if (state.isRowInProcessMode) {
			setCMState({ ...state, data: state.data.filter((e, i) => (e.apamInvoiceCurrencyCode || '').trim()), isRowInProcessMode: false });
			setIsCommited(false);
		}
		if (state.isDirty) setTrigger(true);
		
	}, [isCommited]);
	React.useEffect(() => {
		if (state.isDirty && key && !state.isRowInProcessMode) {
			currenciesMaps_saveHandler();
			setCMState((...prev) => {
				let _p = prev[0];
				return { ..._p, isDirty: false }
			});
			setKey(false);
		}
		setTrigger(false);

	}, [trigger,key]);


	const currenciesMaps_cancelHandler = React.useCallback((event) => {
		if (props.onClose) {
			props.onClose(event);
		}
	}, []);

	const saveChanges = React.useCallback(() => {
		flushSync(() => {
			if (state.processedRowId) {
				apiGridRef.current.stopRowEditMode({ id: state.processedRowId });
			}
			setKey(true);
		})
		
		
	}, [state.processedRowId, state.data])


	const currenciesMaps_saveHandler = React.useCallback((event) => {
		
		var data = {
			currencyCode: props.info.currencyCode,
			currenciesMaps: state.data
		};
		

		props.info.inProgress(true);

		
		httpPost("/api/setup/savecurrenciesmaps/", data)
			.then((response) => {
				toast.success('Updated Successfully');
				props.info.inProgress(false);
				currenciesMaps_cancelHandler(null);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error(<div>Currencies Update Failed<br />{error.response.data.detail}</div>);
			});
	}, [state.data])

	const fetchCurrenciesMaps = React.useCallback(() => {

		props.info.inProgress(true);

		httpGet('/api/setup/currenciesmaps/' + props.info.currencyCode)
			.then((response) => {
				var data = response.data;
				data = data.map((e, i) => { let d = { ...e, rowId: i }; return d; });
				setCMState((prev) => ({ ...prev, data: data }));

				props.info.inProgress(false);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}, [])
	const getAddButton = React.useCallback(() => {
		return <IconButton
			variant="contained"
			title={'add'}
			onClick={handleClick}
			size="small"
			disabled={state.isRowInProcessMode }
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
		>
			<AddBoxIcon />
		</IconButton>;
	}, [state.data, state.isRowInProcessMode])
	const getDeleteButton = React.useCallback(() => {
		return <IconButton
			title={'delete' }
			variant="contained"
			onClick={handleRowDelete}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			disabled={state.selectedRows.length === 0 || state.isRowInProcessMode}
		>
			<DeleteIcon />
		</IconButton>;
	}, [state.selectedRows, state.isRowInProcessMode])


	const handleClick = React.useCallback(() => {
		const id = ([...state.data]?.sort((a, b) => b.rowId - a.rowId)[0]?.rowId + 1) || 0;
		setCMState((prev) => ({
			...prev, isDirty: true,
			isRowInProcessMode: true,
			processedRowId:id,
			data: [{
				rowId: id,
				apamInvoiceCurrencyCode: "",
				isNew:true,
				currencyCode: props.info.currencyCode,
				currencyCodeNavigation: null
			}, ...prev.data]
		}));
		apiGridRef.current.startRowEditMode({ id: id, fieldToFocus: 'apamInvoiceCurrencyCode' });
	}, [state.data]);

	const processRowUpdate = (newrow, oldrow) => {
		let index = state.data.findIndex((e, i) => e.rowId == newrow.rowId);
		Object.assign(state.data[index], newrow);
		setCMState((...prev) => { let _p = prev[0]; return { ..._p, isDirty: true, processedRowId: null } })
		setIsCommited(true);
		return newrow;
	}

	const handleRowClick = React.useCallback((params, event) => {
		if (!params.isEditable) {
			return;
		}
		if (!event.currentTarget.contains(event.target)) {
			return;
		}
		if (params.cellMode == GridRowModes.View) //GridRowModes.Edit)
		{
			setCMState({ ...state, isRowInProcessMode: true, isDirty:true });
			apiGridRef.current.startRowEditMode({ id: params.id });
		}
	}, [state])
	const handleRowSelection = React.useCallback((rows) => {
		let selectedRows = rows.map((e, i) => e.rowId);
		setCMState((...prev) => {
			let _p = prev[0];
			return { ..._p, selectedRows: selectedRows }
		});
	}, [state])

	const handleRowDelete = React.useCallback(() => {
		setCMState((...prev) => {
			return { ...prev, isDirty:true, selectedRows: [], data: state.data.filter((e, i) => (state.selectedRows.indexOf(e.rowId) === -1)) }
		});
	}, [state])
		return (
			<div>
				<h1>Currency Mappings {props.info.currencyCode && <span>({props.info.currencyCode})</span>}</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							columns={[
								{
									headerName: 'Invoice Currency Code',
									field: 'apamInvoiceCurrencyCode',
									flex: 1,
									editable: true
								}
							]}
							

							getRowId={(row) => row.rowId}

							customcomponent={
								(<>
									{getAddButton()}
									{getDeleteButton()}
								</>)
							}
							apiRef={apiGridRef}
							editMode="row"
							onCellClick={handleRowClick}
							disableSelectionOnClick
							onSelectionChange={handleRowSelection}
							processRowUpdate={processRowUpdate}
						/>
					</div>
				</div>
				<Button color="primary" onClick={currenciesMaps_cancelHandler} style={{ float: "right" }}>
					Cancel
				</Button>
				<Button autoFocus color="primary" disabled={!state.isDirty} onClick={saveChanges} style={{ float: "right" }}>
					Save
				</Button>
			</div>
		);

}
