import * as React from 'react';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

export default class InvoicesApprovalHistoryReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
	}

	componentDidMount() {
		this.fetchVendors();
	}

	fetchVendors() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/vendors')
			.then((response) => {
				var data = response.data;
				
				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				var records = (data.records || []).map((e, i) => { let d = { ...e, rowId: i }; return d });
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Vendors</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							title={"Last Updated: " + this.state.lastUpdated}
							columns={[
								{
									headerName: 'Site ID',
									field: 'siteId',
									flex: 1
								},
								{
									headerName: 'Code',
									field: 'vendorCode',
									flex: 1
								},
								{
									headerName: 'Name',
									field: 'vendorName',
									width: 300,
									flex: 1
								},
								{
									headerName: 'Short Name',
									field: 'vendorName',
									width: 300,
									flex: 1
								},
								{
									headerName: 'Vendor Address',
									field: 'vendorAddress',
									width: 500,
									flex: 1
								}
								


							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670,
								columnsButton: true,
								exportButton: true,
								exportAllData: true,
								exportFileName: "Vendors"
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}
