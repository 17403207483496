import React, { Component } from "react";
var pdfjsLib = window['pdfjs-dist/build/pdf'];
pdfjsLib.GlobalWorkerOptions.workerSrc = './pdf.worker.js';
const cMapPath = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.0.379/cmaps/';
export default class PDFViewer extends Component {
	constructor(props) {
		super(props);
		this.documentLoading = false;
		this.currentPage = null;
		this.currentScale = null;
		this.erroneousFile = null;
		var canvasId = "the-canvas-" + Math.floor(Math.random() * 1000);
		this.state = {
			pdfDocument: null,
			file: this.props.file,
			canvasId: canvasId
		};
		this.renderPage = this.renderPage.bind(this);
	}
	componentDidUpdate() {
		var that = this;
		if (this.erroneousFile && this.props.file !== this.erroneousFile) {
			this.erroneousFile = null;
		}
		if (this.erroneousFile === null) {
			if (!this.state.pdfDocument || this.props.file !== this.state.file) {
				if (!this.documentLoading) {
					try {
						var file = this.getDocument(this.props.file);
						if (file) {
							this.documentLoading = true;
							this.currentPage = null;
							this.currentScale = null;
							this.erroneousFile = null;
							pdfjsLib.getDocument(file).promise.then(function (pdfDocument_) {
								if (that.props.onDocumentLoadSuccess) {
									that.props.onDocumentLoadSuccess(pdfDocument_);
								}
								that.documentLoading = false;
								that.setState((prev)=>({...prev, pdfDocument: pdfDocument_, file: that.props.file }));
								that.renderPage(that.props.page);
							}).catch((error) => {
								this.documentLoading = false;
								this.handleError("Error loading document");
							});
						}
					}
					catch (e) {
						this.handleError(e);
					}
				}
			}
			else {
				this.renderPage(this.props.page);
			}
		}
	}
	handleError(error) {
		this.erroneousFile = this.props.file;
		if (this.props.onError) {
			this.props.onError(null, error);
		}
	}
	renderPage(page) {
		var that = this;
		if (!that.state.pdfDocument) return;
		var scale = this.props.scale ? this.props.scale : 1;
		if (this.currentPage === page && this.currentScale === scale) {
			return;
		}
		this.currentPage = page;
		this.currentScale = scale;
		var canvas = document.getElementById(this.state.canvasId);
		var ctx = canvas.getContext('2d');
		this.state.pdfDocument.getPage(page).then(function (pageObject) {
			if (that.props.onPageLoadSuccess) {
				that.props.onPageLoadSuccess(pageObject);
			}
			var viewport = pageObject.getViewport({ scale: scale });
			canvas.height = viewport.height;
			canvas.width = viewport.width;
			canvas.style.display = "";
			//if (scale === 1) {
			//	canvas.style.width = "100%";
			//}
			var renderContext = {
				canvasContext: ctx,
				viewport: viewport
			};
			var renderTask = pageObject.render(renderContext);
			renderTask.promise.then(function () {
				if (that.props.onPageRenderSuccess) {
					that.props.onPageRenderSuccess(canvas);
				}
			});
		});
	}
	isUrl(s) {
		var regexp = /(ftp|http|https|blob:http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
		return regexp.test(s);
	}
	getMimeTypeFromBase64(data) {
		var returnValue = "";
		var mimeType = data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
		if (mimeType) { return mimeType[0] };
		var base64 = data.replace(/^data:application\/(pdf);base64,/, '');
		switch (base64.charAt(0)) {
			case '/':
				returnValue = "image/jpeg";
				break;
			case 'R':
				returnValue = "image/gif";
				break;
			case 'i':
				returnValue = "image/png";
				break;
			case 'J':
				returnValue = "application/pdf";
				break;
			default:
				returnValue = "application/unknown";
				break;
		}
		return returnValue;
	}
	getDocument(file) {
		var document = null;
		if (file) {
			if (file.type) {
				if (file.type === "application/pdf") {
					document = {
						url: URL.createObjectURL(file),
						cMapUrl: cMapPath,
						cMapPacked: true,
						pdfFindController: this.pdfFindController
					};
					URL.revokeObjectURL(file);
					
				}
			}
			else if (this.isUrl(file)) {
				if (file.indexOf(".pdf") > -1) {
					document = {
						url: file,
						cMapUrl: cMapPath,
						cMapPacked: true,
						pdfFindController: this.pdfFindController
					};
					
				}
			}
			else {
				var base64 = file.replace(/^data:application\/(pdf);base64,/, '');
				if (!base64 || base64.length === 0) {
					throw new Error('No file data');
				}
				if (this.getMimeTypeFromBase64(file) === "application/pdf") {
					document = {
						data: atob(base64),
						cMapUrl: cMapPath,
						cMapPacked : true,
						pdfFindController : this.pdfFindController
					};
					
				}
				
			}
			
		}
		return document;
	}
	render() {
		return (
			<canvas id={this.state.canvasId} style={{ position: "absolute", display: "none" }}></canvas>
		)
	}
}