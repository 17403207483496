import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import Level2Resources from './Level2Resources';
import Level3s from './Level3s';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class Level2s extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openLevel2ResourcesDialog: false,
			openLevel3Dialog: false
		};
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.viewLevel2Resources_clickHandler = this.viewLevel2Resources_clickHandler.bind(this);
		this.level2ResourcesDialog_closeHandler = this.level2ResourcesDialog_closeHandler.bind(this);
		this.viewLevel3_clickHandler = this.viewLevel3_clickHandler.bind(this);
		this.level3Dialog_closeHandler = this.level3Dialog_closeHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getlevel2";
		let messageName = "Level 2";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidMount() {
		this.fetchLevel2s();
	}

	viewLevel2Resources_clickHandler(event) {
		event.preventDefault();

		this.setState({ openLevel2ResourcesDialog: true, selectedLevel2Key: event.target.dataset.level2key });
	}

	level2ResourcesDialog_closeHandler(event) {
		this.setState({ openLevel2ResourcesDialog: false });
	}

	viewLevel3_clickHandler(event) {
		event.preventDefault();

		this.setState({ openLevel3Dialog: true, selectedLevel2Key: event.target.dataset.level2key });
	}

	level3Dialog_closeHandler(event) {
		this.setState({ openLevel3Dialog: false });
	}

	fetchLevel2s() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/level2s/')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				let records = (data.records || []).map((e, i) => { let d = { ...e, rowId: i }; return d; });
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			level2Key: this.state.selectedLevel2Key,
			inProgress: this.props.inProgress
		}
		let count = 1;

		return (
			<div>
				<h1>Projects</h1>
				<div className="form-group">
				<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							title={"Last Updated: " + this.state.lastUpdated}
							customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: 'Project',
									field: 'level2Key',
									width: 140
								},
								{
									headerName: 'Description',
									field: 'level2Description',
									width: 180
								},
								{
									headerName: 'Status',
									field: 'level2StatusName',
									width: 120
								},
								{
									headerName: 'Open Date',
									field: 'openDate',
									type: 'date',
									valueGetter: ({ value }) => value && new Date(value),
									width: 120
								},
								{
									headerName: 'Customer Code',
									field: 'customerCode',
									width: 150
								},
								{
									headerName: 'Customer Name',
									field: 'customerName',
									width: 160
								},
								{
									headerName: 'Customer PO Number',
									field: 'customerPONumber',
									width: 100
								},
								{
									headerName: 'PO Required',
									field: 'poRequiredFlag',
									width: 100
								},
								{
									headerName: 'Tolerance PO',
									field: 'tolerancePOFlag',
									width: 120
								},
								{
									headerName: 'Tolerance PO Amount',
									field: 'tolerancePOAmount',
									width: 50, headerAlign: 'right',
									align: 'right',
									width: 150
								},
								{
									headerName: 'Activities',
									headerAlign: 'center',
									align: 'center',
									field: 'activities', resizable: false, sortable: false,
									width: 100,
									renderCell: rowData => <Link data-level2key={rowData.row.level2Key} href="#" onClick={this.viewLevel3_clickHandler}>View</Link>
									
								},
								{
									headerName: 'Resources',
									headerAlign: 'center',
									align: 'center',
									field: 'resources', resizable: false, sortable: false,
									renderCell: rowData => <Link data-level2key={rowData.row.level2Key} href="#" onClick={this.viewLevel2Resources_clickHandler}>View</Link>,
									width: 100
								}
							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
				<Dialog open={this.state.openLevel2ResourcesDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<Level2Resources info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.level2ResourcesDialog_closeHandler} color="primary">
							Close
					</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openLevel3Dialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<Level3s info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.level3Dialog_closeHandler} color="primary">
							Close
					</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
