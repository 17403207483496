import * as React from 'react';
import './css/style.css';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import { Settings } from './Settings';

export class MappingInputField extends React.Component {
	constructor(props) {
		super(props);

		var blocks = [];
		var modelFieldDefId = 0;
		var settings = { alignment: 10, fieldFormat: null }

		if (this.props.info.invoiceModel[this.props.name]) {
			var json = this.props.info.invoiceModel[this.props.name].blocks;
			blocks = Array.isArray(json) ? json : new Array(json);

			settings = this.props.info.invoiceModel[this.props.name].settings;
			modelFieldDefId = this.props.info.invoiceModel[this.props.name].modelFieldDefId;
		}

		this.state = { readOnly: this.props.info.mode === "View" || this.props.info.mode === "AssignVendor", settings: settings, blocks: blocks, modelFieldDefId: modelFieldDefId };

		this.drop_Handler = this.drop_Handler.bind(this);
		this.dragOver_Handler = this.dragOver_Handler.bind(this);
		this.delete_Handler = this.delete_Handler.bind(this);
		this.settings_clickHandler = this.settings_clickHandler.bind(this);
		this.popover_handleClose = this.popover_handleClose.bind(this);
	}

	dragOver_Handler(event) {
		event.preventDefault();
	}

	drop_Handler(event) {
		event.preventDefault();

		var blocks = this.state.blocks;
		var block = JSON.parse(event.dataTransfer.getData("Block"));

		if (blocks.find(b => b.id === block.id)) {
			return;
		}

		blocks.push(block);

		this.setState({ blocks: blocks });

		if (this.props.info.onChange) {
			this.props.info.onChange(event, blocks);
		}
	}

	delete_Handler(event) {
		var id = event.keyCode === 8 || event.keyCode === 46 ? event.currentTarget.dataset.id : event.currentTarget.parentElement.dataset.id;
		var blocks = this.state.blocks;

		blocks.splice(blocks.findIndex(block => block.id === id), 1);

		this.setState({ blocks: blocks });

		if (this.props.info.onChange) {
			this.props.info.onChange(event, blocks);
		}
	}

	settings_clickHandler(event) {

		this.setState({ anchorEl: event.currentTarget });
	}

	popover_handleClose(event) {
		this.setState({ anchorEl: null, anchorElDateRangeFormat: null });
	}

	render() {
		var that = this;
		var blocks = this.state.blocks;
		var settings = this.state.settings;

		return (
			<div className="iconContainer" data-name={this.props.name} onDrop={this.drop_Handler} onDragOver={this.dragOver_Handler}>
				<div className="multiSelectContainer" style={{ minHeight: 34 }}>
					{
						blocks.map(function (block, index) {
							return <Chip
								variant="outlined"
								size="small"
								key={index}
								data-id={block.id}
								label={block.text}
								onDelete={that.state.readOnly ? null : that.delete_Handler}
								color="primary"
								style={{ marginTop: 3, marginRight: 4, maxWidth: 200 }} />
						})
					}
				</div>

				<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.settings_clickHandler}>
					<SettingsIcon/>
				</IconButton>

				<Popover
					open={this.state.anchorEl != null}
					anchorEl={this.state.anchorEl}
					onClose={this.popover_handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					<Settings
						settings={settings}
						name={this.props.name}
						readOnly={this.state.readOnly}
						onChange={this.props.info.onChange}
						showAlignment={this.props.showValueTextAlignment}
						showStringFormat={this.props.showStringFormat}
						showDateFormat={this.props.showDateFormat}
						showDateRange={this.props.showDateRange}
						showCurrencyFormat={this.props.showCurrencyFormat}
						onDefaultValueClick={this.props.onDefaultValueClick}
					/>
				</Popover>
			</div>
		);
	}
}
