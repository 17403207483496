import * as React from 'react';
import { InputNumber } from '../FormFieldControls/InputNumber';
import { LabelText } from '../FormFieldControls/LabelText';
import './CompanyConfig.css'

export class EmailReminderSetup extends React.Component {
	constructor(props) {
		super(props);

		this._changeHandler = this._changeHandler.bind(this);
	}

	_changeHandler(event) {
		var eventObject = {
			target: {
				name: "emailReminderCronExpression",
				value: "",
				dataset: {}
			}
		};

		var emailReminderCronExpression = this.props.companyInvoiceConfiguration.emailReminderCronExpression;
		var emailReminderCronExpressionArr = emailReminderCronExpression.split(" ");

		emailReminderCronExpressionArr[0] = "0"
		emailReminderCronExpressionArr[5] = "?"

		if (event.target.name === "recursEvery") {
			emailReminderCronExpressionArr[3] = "1/" + event.target.value;
		}
		else if (event.target.name === "hour") {
			emailReminderCronExpressionArr[2] = event.target.value;
		}
		else if (event.target.name === "minute") {
			emailReminderCronExpressionArr[1] = event.target.value;
		}

		if (emailReminderCronExpressionArr[2].length === 1) {
			emailReminderCronExpressionArr[2] = "0" + emailReminderCronExpressionArr[2];
		}

		if (emailReminderCronExpressionArr[1].length === 1) {
			emailReminderCronExpressionArr[1] = "0" + emailReminderCronExpressionArr[1];
		}

		if (emailReminderCronExpressionArr.length > 6) {
			emailReminderCronExpressionArr.pop();
		}

		var emailReminderCronExpression = emailReminderCronExpressionArr.join(" ");
		eventObject.target.value = emailReminderCronExpression;

		this.props.changeHandler(eventObject, this.props.companyInvoiceConfiguration.modelDefId, "companyInvoiceConfigurations");
	}

	render() {
		const modelDefId = this.props.companyInvoiceConfiguration.modelDefId;
		const emailReminderCronExpressionArr = this.props.companyInvoiceConfiguration.emailReminderCronExpression.split(" ");

		const recursEveryValue = isNaN(emailReminderCronExpressionArr[3].split("/")[1]) ? 0 : parseInt(emailReminderCronExpressionArr[3].split("/")[1]);
		const hourValue = isNaN(emailReminderCronExpressionArr[2]) ? 0 : parseInt(emailReminderCronExpressionArr[2]);
		const minuteValue = isNaN(emailReminderCronExpressionArr[1]) ? 0 : parseInt(emailReminderCronExpressionArr[1]);

		const recursEvery = {
			numberboxId: "txtRecursEvery_" + modelDefId,
			numberboxName: "recursEvery",
			numberboxValue: recursEveryValue,

			labelId: "lblRecursEvery_" + modelDefId,
			labelText: "Occurs Every"
		}

		const day = {
			labelId: "lblDay_" + modelDefId,
			labelText: "day(s)"
		}

		const occursOnceAt = {
			labelId: "lblOccursOnceAt_" + modelDefId,
			labelText: "At"
		};

		const hour = {
			numberboxId: "txtHour_" + modelDefId,
			numberboxName: "hour",
			numberboxValue: hourValue
		}

		const minute = {
			numberboxId: "txtMinute_" + modelDefId,
			numberboxName: "minute",
			numberboxValue: minuteValue
		}

		//<div className="form-group">
		//	<div className="labeldiv col-md-6">
		//		<Cron
		//			onChange={this._changeHandler}
		//			value={this.props.value}
		//			showResultText={true}
		//			options={{ headers: [HEADER.DAILY] }}
		//		/>
		//	</div>
		//</div>

		return (
			<div>
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 grid_row_2" style={{ marginBottom: 10 }}>
					<div className="col-lg-5 col-md-5 col-sm-5 col-xs-4 grid_mainheading">
						<h3>
							{this.props.companyInvoiceConfiguration.masterDefName}
						</h3>
					</div>
				</div>
				<div className="col-md-12 form-group zero-padding zero-margin">
					<fieldset>
						<div className="form-group">
							<div className="labeldiv col-md-1">
								<LabelText labelInfo={recursEvery} />
							</div>
							<div className="labeldiv col-sm-1">
								<InputNumber numberboxInfo={recursEvery} changeHandler={this._changeHandler} minValue={0} maxValue={31} />
							</div>
							<div className="labeldiv col-md-1">
								<LabelText labelInfo={day} />
							</div>
						</div>
						<div className="form-group">
							<div className="labeldiv col-md-1">
								<LabelText labelInfo={occursOnceAt} />
							</div>
							<div className="labeldiv col-sm-1">
								<InputNumber numberboxInfo={hour} changeHandler={this._changeHandler} minValue={0} maxValue={23} />
							</div>
							<div className="labeldiv col-sm-1">
								<InputNumber numberboxInfo={minute} changeHandler={this._changeHandler} minValue={0} maxValue={59} />
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}
}
