import React, { Component } from 'react';
import SubItems from './SubItems';

export class MenuItems extends Component {
    constructor(props) {

        super(props);
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">
                        <a className="accordion-toggle non-auth" data-toggle="collapse" href={"#" + this.props.items.id}><i className="material-icons">{this.props.items.iconStr}</i>{this.props.items.label}</a>
                    </h4>
                </div>
                <div id={this.props.items.id} className="panel-collapse collapse" data-parent="#accordion">
                    <div className="panel-body">
                        <table className="table">
                            <tbody>
                                {
                                    this.props.items.items.map((subItem) => (
                                        <SubItems subItem={subItem} isCurrClass={window.location.pathname === subItem.path} setClass={this.props.setClass} updateLeftMenu={this.props.updateLeftMenu} key={subItem.id} />
                                    ), this)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}