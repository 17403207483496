import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import MenuItem from '@mui/material/MenuItem';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { isNullOrUndefined, stringIsNullOrEmpty } from '../Common/Utilities';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { InputSearch } from '../FormFieldControls/InputSearch';
import { InputSelect } from '../FormFieldControls/InputSelect';
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});
const initialState = {
	masterData: [],
	data: [],
	searchText: "",
	searchField: "All",
	searchPlaceholderText: "Search All",
	autoFocusSearchBar: true,
	selectedRows: [],
	comments: "",
	distinctResourceList: []
}
export class POHeaderResourceLookup extends React.Component {
	
	constructor(props) {
		super(props);
		this.vendorCode = null;
		this.siteId = null;
		this.inProgress = false;
		this.state = initialState;
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.comments_changeHandler = this.comments_changeHandler.bind(this);
		this.routeToEmployee_clickHandler = this.routeToEmployee_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}
	refresh_clickHandler(event) {
		this.props.inProgress(true);
		let functionName = "getporesources";
		let messageName = "PO Resources";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidUpdate() {
		//var that = this;
		if (this.props.info.vendorCode !== this.vendorCode || this.props.info.siteId !== this.siteId) {
			this.vendorCode = this.props.info.vendorCode;
			this.siteId = this.props.info.siteId;
			this.inProgress = false;
			this.setState({ masterData: [], data: [], selectedRows: [] });
		}
		if (this.props.info.openDialog && this.state.masterData.length === 0 && !this.inProgress) {
			this.inProgress = true;
			this.fetchRecords();
		}
	}
	checkbox_clickHandler(event) {
		var rowId = parseInt(event.target.value);
		var selectedRows = this.state.selectedRows;
		var rowIndex = selectedRows.findIndex(sr => sr.rowId === rowId);
		var rowData = this.getRowData(rowId);
		if (rowIndex < 0) {
			selectedRows.push(rowData);
		}
		else {
			selectedRows.splice(rowIndex, 1);
		}
		this.setState({ selectedRows: selectedRows });
	}
	comments_changeHandler(event) {
		this.setState({ comments: event.target.value, autoFocusSearchBar: false });
	}
	routeToEmployee_clickHandler(event) {
		if (this.props.onClose) {
			this.state.selectedRows.map(rowData => rowData.comments = this.state.comments);
			this.props.onClose(event, this.state.selectedRows);
			this.inProgress = false;
			this.setState({
				...initialState
			});
		}
	}
	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event, []);
			this.inProgress = false;
			this.setState({
				...initialState
			});
		}
	}
	fetchRecords() {
		if (this.state.data.length === 0) {
			var invoiceDocumentIdParam = !isNullOrUndefined(this.props.info.invoiceDocumentId) && this.props.info.invoiceDocumentId > 0 ? this.props.info.invoiceDocumentId.toString() : "";
			this.props.info.inProgress(true);
			httpGet("/api/lookup/poheaderandresourcelistbyvendor/" + encodeURIComponent(this.props.info.vendorCode) + "/" + encodeURIComponent(this.props.info.siteId) + "/" + invoiceDocumentIdParam)
				.then((response) => {
					var masterData = response.data;
					var rowId = 1;
					masterData.map(row => row.rowId = rowId++);
					var data = JSON.parse(JSON.stringify(masterData))
					this.setState(
						{
							vendorCode: this.props.info.vendorCode,
							siteId: this.props.info.siteId,
							masterData: masterData,
							data: data,
							selectedRows: []
						});
					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);
					toast.error('Error fetching PO Header Resource Lookup');
					this.close_clickHandler();
				});
		}
	}
	getRowData(value) {
		var data = this.state.masterData;
		return data[data.findIndex(d => d.rowId === value)];
	}
	getDistinctResourceList() {
		var distinctResources = [];
		this.state.data.map(function (row, index) {
			var resource = {
				rowId: row.rowId,
				resourceId: row.resourceId,
				employeeName: row.employeeName
			};
			if (!distinctResources.some(r => r.resourceId === row.resourceId)) {
				distinctResources.push(resource);
			};
		});
		return distinctResources.sort((a, b) => (a.employeeName > b.employeeName ? 0 : -1));
	}
	getResourceListByResourceId(resourceId) {
		var filteredResources = [];
		filteredResources = resourceId === "-1" ? this.state.data : this.state.data.filter(resource => resource.resourceId === resourceId);
		return filteredResources;
	}
	onSearchChange(searchText) {
		var value = new RegExp(searchText, "i");
		var data = "";
		if (stringIsNullOrEmpty(this.state.searchField) || this.state.searchField === "All") {
			data = this.state.masterData.filter(row => stringIsNullOrEmpty(value) ||
				(!stringIsNullOrEmpty(row.employeeName) && row.employeeName.match(value)) ||
				(!stringIsNullOrEmpty(row.positionCategoryName) && row.positionCategoryName.match(value)) ||
				(!stringIsNullOrEmpty(row.clientCode) && row.clientCode.match(value)) ||
				(!stringIsNullOrEmpty(row.clientName) && row.clientName.match(value)) ||
				(!stringIsNullOrEmpty(row.jobCode) && row.jobCode.match(value)) ||
				(!stringIsNullOrEmpty(row.campaignName) && row.campaignName.match(value)) ||
				(!stringIsNullOrEmpty(row.activityCode) && row.activityCode.match(value)) ||
				(!stringIsNullOrEmpty(row.mediaPlanName) && row.mediaPlanName.match(value))
			);
		}
		else {
			data = this.state.masterData.filter(row => stringIsNullOrEmpty(value) ||
				(!stringIsNullOrEmpty(row[this.state.searchField]) && row[this.state.searchField].match(value))
			);
		}
		this.setState({ data: data, searchText: searchText, autoFocusSearchBar: true });
	}
	getDetailPanelContent(parentRowData) {
		return <div style={{ padding: 10 }}>
			<EnhancedDataGrid				
				columns={[
					{
						headerName: 'Role',
						field: 'positionCategoryName',
						flex: 1
					},
					{
						headerName: 'Client Code',
						field: 'clientCode',
						flex: 1
					},
					{
						headerName: 'Client Name',
						field: 'clientName',
						flex: 1
					},
					{
						headerName: 'Job Code',
						field: 'jobCode',
						flex: 1
					},
					{
						headerName: 'Campaign Name',
						field: 'campaignName',
						flex: 1
					},
					{
						headerName: 'Activity Code',
						field: 'activityCode',
						flex: 1
					},
					{
						headerName: 'Media Plan Name',
						field: 'mediaPlanName',
						flex: 1
					}
				]}
				title=""
				pageSize={5}
				rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
				nofilter
				pagination				
				getRowId={(row) => row.rowId}
				rows={this.getResourceListByResourceId(parentRowData.resourceId)}
				sx={{
					'& .MuiDataGrid-main': {						
						height: 300
					}
				}}
			/>
		</div>
	}
	getFilter() {
		var that = this;
		return <div style={{ padding: 10 }}>
			<InputSearch value={this.state.searchText}
				placeholder={this.state.searchPlaceholderText}
				wraperStyle={{ float: "right", width: 300, margin: "4px 0px 0px 15px"}}
				autoFocus={this.state.autoFocusSearchBar}
				onChange={(newValue) => this.onSearchChange(newValue.target.value)}
			></InputSearch>
			<InputSelect
				inputStyle={{ float: "right", zIndex: 1, marginTop: 5, width: 180 }}
				wraperStyle={{ float: "right", width: 300 }}
				value={this.state.searchField}
				onChange={(event, value) => {
					this.setState({ searchPlaceholderText: "Search " + value.props.children, searchField: value.props.value });
					setTimeout(function () { that.onSearchChange(that.state.searchText); }, 10);
				}}
				inputLabelText="Search By: "
				labelStyle={{ float: "right", paddingTop: 10, paddingRight: 20 }}
			>
				<MenuItem value="All">All</MenuItem>
				<MenuItem value="employeeName">Employee Name</MenuItem>
				<MenuItem value="positionCategoryName">Role</MenuItem>
				<MenuItem value="clientCode">Client Code</MenuItem>
				<MenuItem value="clientName">Client Name</MenuItem>
				<MenuItem value="jobCode">Job Code</MenuItem>
				<MenuItem value="campaignName">Campaign Name</MenuItem>
				<MenuItem value="activityCode">Activity Code</MenuItem>
				<MenuItem value="mediaPlanName">Media Plan Name</MenuItem>
			</InputSelect>
		</div>;
	}
	

	render() {
		var that = this;
		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullScreen>
				<DialogTitle>
					Route to Employee
				</DialogTitle>
				<DialogContent>
					<div className="mainTable box-container" style={{ maxWidth: "none" }}>
						<div style={{ marginTop: 10, marginBottom: 10 }}>
							<EnhancedDataGrid rows={this.getDistinctResourceList()}
								disableColumnMenu
								pagination
								pageSize={10}
								nofilter		
								
								columns={[								
									{
										headerName: ' ',
										field: 'chkEmployee',
										renderCell: rowData =>
											<Checkbox
												color="default"
												name="chk"
												checked={this.state.selectedRows.findIndex(sr => sr.rowId === rowData.row.rowId) >= 0}
												value={rowData.row.rowId}
												onClick={this.checkbox_clickHandler}
											/>,
										width: 40
									},
									{
										headerName: 'Employee Name',
										field: 'employeeName',
										cellStyle: { width: 1700 },
										flex: 1
									}
								]}
								getRowId={(row) => row.rowId}
								disableSelectionOnClick
								options={{
									pageSize: 10,
									pageSizeOptions: [10, 20, 30, 40, 50],
									maxBodyHeight: 505,
									search: false
								}}
								title=""
								customcomponent={
									<div>
										{this.getFilter()}	
										{this.getRefreshButton()}
									</div>
								}
								
								rowThreshold={0}
								getDetailPanelHeight={() => 'auto'}
								getDetailPanelContent={({ row }) => this.getDetailPanelContent(row)}
								//onDetailPanelExpandedRowIdsChange={(newIds) => {
								//	that.setState({ detailPanelExpandedRowIds: newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds });
								//}}
								//detailPanelExpandedRowIds={this.state.detailPanelExpandedRowIds}
							/>							
						</div>
						<div className="row-form">
							<div className="mf-8 field_full-8">
								<label><span style={{ color: "red" }}>*</span>Comments: </label>
								<textarea name="comments" value={this.state.comments} autoFocus={!this.state.autoFocusSearchBar} onChange={this.comments_changeHandler}></textarea>
							</div>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button autoFocus disabled={this.state.selectedRows.length === 0 || this.state.comments.length === 0} onClick={this.routeToEmployee_clickHandler} color="primary">
						Route to Employee
					</Button>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
