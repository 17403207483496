import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { formatNumber, stringIsNullOrEmpty } from '../Common/Utilities';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

export default class VendorInvoicesWithPaymentStatusReport extends React.Component {
	constructor() {
		super();

		var userInfo = JSON.parse(localStorage["userInfo"]);
		var modelDefId = this.getDefaultModelDefId(userInfo);

		this.state = {
			data: [],
			modelDefId: modelDefId,
			userInfo: userInfo
		};

		this.modelDefId_changeHandler = this.modelDefId_changeHandler.bind(this);
		this.getDefaultModelDefId = this.getDefaultModelDefId.bind(this);
		this.getInvoiceTypeMenuItem = this.getInvoiceTypeMenuItem.bind(this);
	}

	getDefaultModelDefId(userInfo) {
		if (!stringIsNullOrEmpty(userInfo.invoiceType)) {
			if (userInfo.invoiceType.includes('1')) {
				return 1;
			}
			else if (userInfo.invoiceType.includes('2')) {
				return 2;
			}
		}
	}

	componentDidMount() {
		this.fetchVendorInvoicesWithPaymentStatus(this.state.modelDefId);
	}

	modelDefId_changeHandler(event) {
		var modelDefId = parseInt(event.target.value, 10);

		this.setState({ data: [], modelDefId: modelDefId });

		this.fetchVendorInvoicesWithPaymentStatus(modelDefId);
	}

	fetchVendorInvoicesWithPaymentStatus(modelDefId) {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/vendorinvoiceswithpaymentstatus/' + modelDefId)
			.then((response) => {
				//var data = response.data;
				var data = (response.data || []).map((e, i) => { let d = { ...e, rowId: i }; return d });
				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	getInvoiceTypeMenuItem(modelDefId, displayValue) {
		if (this.state.userInfo.invoiceType.includes('' + modelDefId)) {
			return <MenuItem value={modelDefId}>{displayValue}</MenuItem>
		}
	}
	displayVendorName(rowData) {
		return stringIsNullOrEmpty(rowData.vendorName) ? rowData.vendorCode : rowData.vendorName;
	}
	render() {
		//const lookup =
		//{
		//	1: 'Unpaid',
		//	2: 'Partially Paid',
		//	3: 'Fully Paid'
		//};

		const cellStyle = { paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };
		var columns = [
			{
				headerName: 'Vendor Name',
				field: 'vendorName',
				renderCell: rowData => { return this.displayVendorName(rowData.row); },
				flex: 1,
				hideable: false
			},
			{
				headerName: 'Vendor Invoice Number',
				field: 'invoiceNumber',
				flex: 1
			},
			{
				headerName: 'Vendor Invoice Date',
				field: 'invoiceDate',
				type: 'date',
				valueGetter: ({ value }) => value && new Date(value),
				flex: 1
			},
			{
				headerName: 'Last Approved By',
				field: 'approvedBy',
				flex: 1
			}];
		if (this.state.modelDefId === 2) {
			columns.push({
				headerName: 'Job',
				field: 'jobCode',
				//hide: (this.state.modelDefId !== 2),
				flex: 1
			},
				{
					headerName: 'Activity',
					field: 'activityCode',
					//hide: (this.state.modelDefId !== 2),
					flex: 1
				});
		}
		else {
			columns.push({
				headerName: 'Campaign Name',
				field: 'campaignName',
				//hide: (this.state.modelDefId === 2)
			},
				{
					headerName: 'Media Plan',
					field: 'mediaPlanName',
					//hide: (this.state.modelDefId === 2),
					flex: 1
				});
		}
		columns.push(
			{
				headerName: (this.state.modelDefId === 2 ? 'PO' : 'IO'),
				flex: 1,
				field: 'ioNumber'
			},
			{
				headerName: 'Amount',
				field: 'amount',
				type: 'currency',
				align: 'right',
				headerAlign: 'right',
				render: rowData => formatNumber(rowData.amount) + " " + rowData.currencyCode,
				flex: 1
			},
			{
				headerName: 'Posted Date',
				field: 'postedDate',
				type: 'date',
				valueGetter: ({ value }) => value && new Date(value),
				flex: 1
			},
			{
				headerName: 'Payment Status',
				field: 'paymentStatus',
				//lookup: lookup,
				//valueFormatter: ({ value }) => value && lookup[value],
				flex: 1
			},
			{
				headerName: 'Payment Amount',
				field: 'paymentAmount',
				type: 'currency',
				align: 'right',
				headerAlign: 'right',
				renderCell: rowData => formatNumber(rowData.row.paymentAmount) + " " + rowData.row.currencyCode,
				flex: 1
			},
			{
				headerName: 'Payment Info',
				field: 'paymentInfo',
				flex: 1
			},
			{
				headerName: 'Transaction Id',
				field: 'transactionId',
				flex: 1
			});
		return (
			<div>
				<h1>Vendor Invoices With Payment Status</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							columns={columns}
							customcomponent={
								<Select
									variant='standard'
									style={{ float: "right", zIndex: 1, marginTop: 2, marginRight: 10 }}
									value={this.state.modelDefId}
									onChange={this.modelDefId_changeHandler}
								>
									{this.getInvoiceTypeMenuItem(1, 'Media')}
									{this.getInvoiceTypeMenuItem(2, 'Production')}
								</Select>
							}
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670,
								filtering: true,
								columnsButton: true,
								exportButton: true,
								exportAllData: true,
								exportFileName: "VendorInvoicesWithPaymentStatus"
							}}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
			</div>
		);
	}
}