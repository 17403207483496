import React, { useCallback, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import UncollapseIcon from '@mui/icons-material/NavigateNext';
import CollapseIcon from '@mui/icons-material/NavigateBefore';
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
import { InvoiceDocumentViewer } from './InvoiceDocumentViewer';
import { InvoiceDocumentRoutingHistory } from './InvoiceDocumentRoutingHistory';
import { VendorLookup } from '../Lookups/VendorLookup';
import { POHeaderLookup } from '../Lookups/POHeaderLookup';
import { POHeaderResourceLookup } from '../Lookups/POHeaderResourceLookup';
import { Level2ResourceLookup } from '../Lookups/Level2ResourceLookup';
import { stringIsNullOrEmpty, blankStringIfNullOrEmpty, formatNumber, zeroIfNull, addDaysInDate, FormatToInputDate } from '../Common/Utilities';
import { toast } from 'react-toastify';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import InvoiceDocumentEditorGrid from './InvoiceDocumentEditorGrid';
import IconButton from '@mui/material/IconButton';
import InvoiceDocumentEditorAttachments from './InvoiceDocumentEditorAttachments';
import './../site.css';
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});
export function InvoiceDocumentEditor(props) {
	//#region Initializers

	var title;
	switch (props.info.mode) {
		case "View":
			title = "View Invoice";
			break;
		case "Test":
			title = "View Invoice (Test)";
			break;
		case "UserEntry":
			title = "User Entry";
			break;
		case "ManualEntry":
			title = "Manual Entry";
			break;
		case "MakeCorrections":
			title = "Make Corrections in Invoice";
			break;
		case "Replace":
			title = "Replace Invoice";
			break;
		default:
			title = "";
	}
	var userInfo = JSON.parse(localStorage.userInfo);
	let resourceId = userInfo.id;
	let isAdmin = userInfo.selectedUserRoles.isAdmin;
	let currentEditRow = null;
	const [state, setState] = useState({
		isDirty: false,
		readOnly: props.info.mode === "View" || props.info.mode === "Approval" || props.info.mode === "Test",
		title: title,
		openVendorDialog: false,
		openPOHeaderDialog: false,
		openLevel2Dialog: false,
		openLevel3Dialog: false,
		openCostCodesDialog: false,
		openPOHeaderResourceDialog: false,
		openLevel2ResourceDialog: false,
		openChangeVendorConfirmation: false,
		openReplaceInvoiceDocumentEditorDialog: false,
		openInvoiceDocumentRoutingHistoryDialog: false,
		openReplaceFileScanInProgress: (props.info.mode === "Replace" && true) || false,
		vendor: null,
		inProgress: false,
		replacedInvoiceDocument: null,
		serviceTermStartDate: null,
		serviceTermEndDate: null,
		isInvoiceNumberDuplicate: false,
		uncollapsed: true,
		displayInvoiceDocumentViewer: "",
		openDeleteConfirmation: false,
		deleteRowData: null,
		isGridDataValid: false,
		taxes: [],
		originalInvoiceDocumentStatus : 0
	})

	const [view, setView] = React.useState(0);
	const [taxTotal, setTaxTotal] = React.useState(0);
	const [grossTotal, setGrossTotal] = React.useState(0);
	const editorGridRef = React.useRef(null);
	const attachmentRef = React.useRef(null);
	const [activeTab, setActiveTab] = useState("tabInvoiceDetails");
	useEffect(() => {
		fetchInvoiceDocument();
	}, [])
	useEffect(() => {
		let invoiceDocumentEditorDetails = (editorGridRef?.current?.invoiceDocumentEditorDetails || state.invoiceDocument?.invoiceDocumentDetails || []);
		setTaxTotal(invoiceDocumentEditorDetails.length > 0 ? invoiceDocumentEditorDetails.map(item => item.taxAmount).reduce((prev, next) => prev + next) : 0.00);
		setGrossTotal(invoiceDocumentEditorDetails.length > 0 ? invoiceDocumentEditorDetails.map(item => parseFloat(item.ioInvoiceAmount || 0)).reduce((prev, next) => prev + next) : 0.00);
	}, [view, editorGridRef.current?.isGridDataValid]);
	//  Functions to handle Tab Switching
	const handleTabInvoiceDetails = () => {
		// update the state to tab1
		setActiveTab("tabInvoiceDetails");
	};
	const handleTabAttachments = () => {
		// update the state to tab2
		setActiveTab("tabAttachments");
	};

	//#endregion Initializers

	//#region Events
	const routeToEmployee_clickHandler = () => {
		var isProduction = state.invoiceDocument?.modelDefId === 2;
		setState((prev) => ({ ...prev, openPOHeaderResourceDialog: !isProduction, openLevel2ResourceDialog: isProduction }));
	}
	const accept_clickHandler = () => {
		if (!IsSaveDisabled()) {
			props.info.inProgress(true);
			var invoiceDocument = structuredClone(state.invoiceDocument);
			invoiceDocument.invoiceDocumentEditorDetails = (editorGridRef?.current?.invoiceDocumentEditorDetails || []);
			invoiceDocument.invoiceDocumentAttachments = (attachmentRef?.current?.attachments || []);
			httpPost("/api/invoicedocument/reviewaccept/", invoiceDocument)
				.then((response) => {
					toast.success('Accepted Successfully');
					props.info.inProgress(false);
					close_clickHandler(null);
				}).catch((error) => {
					props.info.inProgress(false);
					toast.error(<div>Accept Failed<br />{error.response.data}</div>);
				});
		}
		else {//toast.error('Please enter valid values in required fields to continue.');
			let validationResponse = getErrorMessage();
			toast.error(<div>{validationResponse.errorList.map(e => <div>{e}</div>)}</div>, { allowHtml: true });
		}
	}
	const reject_clickHandler = () => {
		if (!IsSaveDisabled()) {
			props.info.inProgress(true);
			var invoiceDocument = structuredClone(state.invoiceDocument);
			invoiceDocument.invoiceDocumentEditorDetails = (editorGridRef?.current?.invoiceDocumentEditorDetails || []);
			invoiceDocument.invoiceDocumentAttachments = (attachmentRef?.current?.attachments || []);
			httpPost("/api/invoicedocument/reviewreject/", invoiceDocument)
				.then((response) => {
					toast.success('Rejected Successfully');
					props.info.inProgress(false);
					close_clickHandler(null);
				}).catch((error) => {
					props.info.inProgress(false);
					toast.error('Reject Failed');
				});
		}
		else {//toast.error('Please enter valid values in required fields to continue.');
			let validationResponse = getErrorMessage();
			toast.error(<div>{validationResponse.errorList.map(e => <div>{e}</div>)}</div>, { allowHtml: true });
		}
	}
	const save_clickHandler = () => {
		var invoiceDocument = structuredClone(state.invoiceDocument);
		invoiceDocument.invoiceDocumentEditorDetails = (editorGridRef?.current?.invoiceDocumentEditorDetails || []);
		invoiceDocument.invoiceDocumentAttachments = (attachmentRef?.current?.attachments || []);

		if (IsSaveDisabled() || InvoiceDocumentDetailMissingInfo()) {
			if (props.info.mode === "ManualEntry" && invoiceDocument.invoiceDocumentStatusId === 50 && invoiceDocument.invoiceDocumentStatusAttribute == "M") {
				invoiceDocument.invoiceDocumentStatusId = 40;
			} else if (props.info.mode === "MakeCorrections" && invoiceDocument.invoiceDocumentStatusId === 50 && invoiceDocument.invoiceDocumentStatusAttribute == "C") {
				invoiceDocument.invoiceDocumentStatusId = 40;
			}
		}
		let validationResponse = getErrorMessage();
		if (validationResponse.isStopable) {
			toast.error(<div>{validationResponse.errorList.map(e => <div>{e}</div>)}</div>, { allowHtml: true });
			return;
		}
		 
		if (!isSaveBtnDisabled) {
			props.info.inProgress(true);
			httpPost("/api/invoicedocument/invoicedocumenteditor/", invoiceDocument)
				.then((response) => {
					//if (validationResponse.errors) {
					//	validationResponse.errorList.unshift('Updated Successfully with errors');
					//	toast.warn(<div>{validationResponse.errorList.map(e => <div>{e}</div>)}</div>, { allowHtml: true });
					//} else {
						toast.success('Updated Successfully');
					//}
					
					close_clickHandler(null);
					props.info.inProgress(false);
				}).catch((error) => {
					props.info.inProgress(false);
					toast.error(<div>Update Failed<br />{error?.response?.data?.detail}</div>);
				});
		}
		else {
			toast.error(<div>{validationResponse.errorList.map(e => <div>{e}</div>)}</div>, { allowHtml: true });
		}
		
	}
	const routingHistory_clickHandler = (event) => {
		setState((prev) => ({ ...prev, openInvoiceDocumentRoutingHistoryDialog: true }));
	}
	const invoiceDocumentRoutingHistoryDialog_closeHandler = (event) => {
		setState((prev) => ({ ...prev, openInvoiceDocumentRoutingHistoryDialog: false }));
	}
	const input_changeHandler = (event) => {
		var invoiceDocument = state.invoiceDocument;
		var targetName = event.currentTarget.name;
		invoiceDocument[event.currentTarget.name] = event.currentTarget.value;
		if (event.currentTarget.dataset && event.currentTarget.dataset.name) {
			invoiceDocument[event.currentTarget.dataset.name] = event.currentTarget.value;
		}

		//Reset if currency not matching
		if (targetName == "currencyCode" && event.currentTarget.value != invoiceDocument.currencyCode) {
			invoiceDocument.vendorCode = "";
			invoiceDocument.shortName = "";
			invoiceDocument.vendor = "";
			invoiceDocument.siteId = "";
			//invoiceDocument.currencyCode = "";
			setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument, isDirty: false }));
		}
		else if (targetName === 'invoiceDateString') {
			let nglDate = state.invoiceDocument?.glDateString;
			if (!nglDate) {
				nglDate = FormatToInputDate(event.currentTarget.value);
			}
			state.invoiceDocument.glDate = nglDate;
			state.invoiceDocument.glDateString = nglDate;
			setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument, isDirty: true }));
		}
		else
			setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument, isDirty: true }));
	}
	const onPaymentTermsChange = (event) => {
		input_changeHandler(event);
		var paymentTerm = state.invoiceDocument?.paymentTerms?.find(val => val.termsCode === state.invoiceDocument?.paymentTermCode);
		if (!paymentTerm) return;
		var daysDue = parseInt(paymentTerm.daysDue, 10);
		var invoiceDate = new Date(state.invoiceDocument?.invoiceDateString);
		var dueDate = addDaysInDate(invoiceDate, daysDue);
		var isValidDueDate = ((dueDate).toString()).indexOf('Invalid Date') === -1;
		var invoiceDocument = state.invoiceDocument;
		invoiceDocument.dueDate = dueDate;
		if (dueDate && isValidDueDate && dueDate?.toISOString().length > 10)
			invoiceDocument.dueDateString = dueDate.toISOString().slice(0, 10);
		setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument }));
	}

	const serviceTerm_blurHandler = (event) => {
		var pocodes = [];
		if (state.invoiceDocument?.invoiceDocumentEditorDetails.length > 0) {
			var invoiceDocument = state.invoiceDocument;
			var serviceTermStartDate = invoiceDocument.serviceTermStartDate;
			var serviceTermEndDate = invoiceDocument.serviceTermEndDate;
			if (stringIsNullOrEmpty(state.invoiceDocument?.serviceTermStartDate) || stringIsNullOrEmpty(state.invoiceDocument?.serviceTermEndDate)) {
				state.invoiceDocument?.invoiceDocumentEditorDetails.map(function (detail, index) {
					detail.deliveryAmount = 0.00;
				});
				if (currentEditRow) {
					currentEditRow.deliveryAmount = 0.00;
				}
			}
			else if (state.serviceTermStartDate !== state.invoiceDocument?.serviceTermStartDate || state.serviceTermEndDate !== state.invoiceDocument?.serviceTermEndDate) {
				var uniqueInvoiceDocumentEditorDetails = uniqueBy(state.invoiceDocument?.invoiceDocumentEditorDetails, ["ioNumber"]);
				uniqueInvoiceDocumentEditorDetails.map(function (detail, index) {
					if (!stringIsNullOrEmpty(detail.ioNumber)) pocodes.push(detail.ioNumber.trim());
				});
				var params = {
					pocodes: pocodes,
					serviceTermStartDate: stringIsNullOrEmpty(state.invoiceDocument?.serviceTermStartDate) ? null : state.invoiceDocument?.serviceTermStartDate,
					serviceTermEndDate: stringIsNullOrEmpty(state.invoiceDocument?.serviceTermEndDate) ? null : state.invoiceDocument?.serviceTermEndDate
				}
				props.info.inProgress(true);
				httpPost("/api/lookup/poheaderlistbypocodelist/", params)
					.then((response) => {
						var poHeaders = response.data;
						editorGridRef.current.setDetailsOnserviceTermBlur(poHeaders)
						if (currentEditRow) {
							currentEditRow.deliveryAmount = poHeaders.find(poHeader => poHeader.poCode.trim() === currentEditRow.ioNumber.trim()).deliveryAmount;
						}
						props.info.inProgress(false);
					}).catch((error) => {
						props.info.inProgress(false);
						if (error.response && error.response.data) {
							toast.error(<div>Delivery Amount update failed<br />{error.response.data.detail}</div>);
						}
					});
			}
			setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument, serviceTermStartDate: serviceTermStartDate, serviceTermEndDate: serviceTermEndDate }));
		}
	}
	const search_clickHandler = (event) => {
		setState((prev) => ({ ...prev, openVendorDialog: true }));
	}
	const vendorDialog_closeHandler = (event, vendor) => {
		if (vendor && !stringIsNullOrEmpty(state.invoiceDocument?.vendorCode) && state.invoiceDocument?.vendorCode !== vendor.vendorCode &&
			((state.invoiceDocument?.modelDefId === 1 && editorGridRef?.current?.invoiceDocumentEditorDetails.length > 0) ||
				(state.invoiceDocument?.modelDefId === 2 && !stringIsNullOrEmpty(state.invoiceDocument?.poCode)))
		) {
			setState((prev) => ({ ...prev, openVendorDialog: false, vendor: vendor, openChangeVendorConfirmation: true }));
		}
		else {
			setState((prev) => ({ ...prev, openVendorDialog: false, poHeaderLookup: [] }));
			setVendor(vendor);
		}
	}
	const close_clickHandler = (event) => {
		if (props.onClose) {
			props.onClose(event);
		}
	}
	const replace_clickHandler = (event) => {
		setState((prev) => ({ ...prev, openReplaceInvoiceDocumentEditorDialog: true }));
	}
	const collapseUncollapse_clickHandler = (event) => {
		var collapseState = !state.uncollapsed;
		setState((prev) => ({ ...prev, uncollapsed: collapseState, displayInvoiceDocumentViewer: collapseState ? "" : "none" }));
	}
	const changeVendorYes_clickHandler = (event) => {
		var invoiceDocument = state.invoiceDocument;
		if (invoiceDocument.modelDefId === 2) {
			invoiceDocument.poCode = "";
			invoiceDocument.isPOCodeValid = state.vendor.isNoPOVendor ? "Y" : "N";
			verifyProductionInvoiceDocumentDetails();
		}
		else {
			editorGridRef.current.setDetailsOnVendorChange();
		}
		setState((prev) => ({ ...prev, poHeaderLookup: [], invoiceDocument: invoiceDocument, openChangeVendorConfirmation: false }));
		setVendor(state.vendor);
	}
	const changeVendor_closeHandler = (event) => {
		setState((prev) => ({ ...prev, openChangeVendorConfirmation: false }));
	}
	const searchIONumber_clickHandler = useCallback((event) => {
		setState((prev) => ({ ...prev, openPOHeaderDialog: true }));
	});
	const deletePOCode_clickHandler = (event) => {
		var invoiceDocument = state.invoiceDocument;
		invoiceDocument.poCode = "";
		invoiceDocument.isPOCodeValid = 'Y';
		setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument, isDirty: true }));
		verifyProductionInvoiceDocumentDetails();
	}

	const poHeaderDialog_closeHandler = React.useCallback((event, poHeader) => {
		var invoiceDocument = state.invoiceDocument;       
		let isDirty = false;
		if (poHeader) {           
			isDirty = true;
			if (state.invoiceDocument.modelDefId === 2) {
				var doVerification = invoiceDocument.poCode !== poHeader.poCode;
				invoiceDocument.poCode = poHeader.poCode.trim();
				invoiceDocument.poAmount = poHeader.poAmount;
				invoiceDocument.consumedAmount = poHeader.consumedAmount;
				invoiceDocument.isPOCodeValid = 'Y';
				if (doVerification) {
					verifyProductionInvoiceDocumentDetails();
				}
			}
			else {
				editorGridRef.current.setDetailsOnPOChange(poHeader);
			}
		}
		//setState((prev) => ({ ...prev, openPOHeaderDialog: false }));
		setState((prev) => ({ ...prev, openPOHeaderDialog: false, isDirty: isDirty || state.isDirty }))
	});
	const poHeaderResourceDialog_closeHandler = (event, poHeaderResources) => {
		if (poHeaderResources.length > 0) {
			var routedToResources = [];
			poHeaderResources.map(function (poHeaderResource, poHeaderResourceIndex) {
				const invoiceDocumentResourceRoutingHistory = { invoiceDocumentId: state.invoiceDocument?.invoiceDocumentId, resourceId: poHeaderResource.resourceId, email: poHeaderResource.email, comments: poHeaderResource.comments };
				routedToResources.push(invoiceDocumentResourceRoutingHistory);
			});
			props.info.inProgress(true);
			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					var data = response.data;
					var invoiceDocument = state.invoiceDocument;
					invoiceDocument.routedToResourceId = data.resourceId;
					setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument }));
					props.info.inProgress(false);
					toast.success('Routed to Employee Successfully');
				}).catch((error) => {
					props.info.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}
		setState((prev) => ({
			...prev,
			openPOHeaderResourceDialog: false, selectedRows: [],
			comments: ""
		}));
	}
	const level2ResourceDialog_closeHandler = (event, level2Resources) => {
		if (level2Resources.length > 0) {
			var routedToResources = [];
			level2Resources.map(function (level2Resource, level2ResourceIndex) {
				const invoiceDocumentResourceRoutingHistory = { invoiceDocumentId: state.invoiceDocument?.invoiceDocumentId, resourceId: level2Resource.resourceId, email: level2Resource.email, comments: level2Resource.comments };
				routedToResources.push(invoiceDocumentResourceRoutingHistory);
			});
			props.info.inProgress(true);
			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					var data = response.data;
					var invoiceDocument = state.invoiceDocument;
					invoiceDocument.routedToResourceId = data.resourceId;
					setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument }));
					props.info.inProgress(false);
					toast.success('Routed to Employee Successfully');
				}).catch((error) => {
					props.info.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}
		setState((prev) => ({
			...prev,
			openLevel2ResourceDialog: false, selectedRows: [],
			comments: ""
		}));
	}

	//#endregion Events

	//#region Validations

	const POCodeValid = () => {
		var isValid = state.invoiceDocument?.modelDefId === 2 ? false : true;
		if (state.invoiceDocument?.modelDefId === 2) {
			if (VendorSelected()) {
				let invoiceDocumentDetails = editorGridRef?.current?.invoiceDocumentEditorDetails;
				isValid = (stringIsNullOrEmpty(state.invoiceDocument?.poCode) && ((invoiceDocumentDetails?.length || 0) === 0 || (invoiceDocumentDetails || []).every(d => d.poRequiredFlag === 'N')))
					|| (!stringIsNullOrEmpty(state.invoiceDocument?.poCode) && state.invoiceDocument?.isPOCodeValid === 'Y');
			}
		}
		return isValid;
	}
	const DueDateValid = () => {
		var isValid = stringIsNullOrEmpty(state.invoiceDocument?.dueDateString) && stringIsNullOrEmpty(state.invoiceDocument?.invoiceDateString) ? false : true;
		if (isValid) {
			var dueDate = new Date(state.invoiceDocument?.dueDateString);
			var invoiceDate = new Date(state.invoiceDocument?.invoiceDateString);
			isValid = dueDate >= invoiceDate;
		}
		return isValid;
	}
	const InvoiceNumberValid = () => {
		var isValid = !stringIsNullOrEmpty(state.invoiceDocument?.invoiceNumber) && state.invoiceDocument?.invoiceNumber.length <= 32;
		return isValid;
	}
	const ServiceTermStartValid = () => {
		var isValid = !stringIsNullOrEmpty(state.invoiceDocument?.serviceTermStartDateString);
		return isValid;
	}
	const ServiceTermEndValid = () => {
		var isValid = !stringIsNullOrEmpty(state.invoiceDocument?.serviceTermEndDateString);
		if (isValid) {
			var endDate = new Date(state.invoiceDocument?.serviceTermEndDateString);
			var startDate = new Date(state.invoiceDocument?.serviceTermStartDateString);
			isValid = endDate >= startDate;
		}
		return isValid;
	}
	//#region IO PO invalid Validators
	const IONumberValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument.modelDefId === 1 &&
			(stringIsNullOrEmpty(rowData.ioNumber) || (!stringIsNullOrEmpty(rowData.ioNumber) && rowData.isIONumberValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const Level2KeyValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument?.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.level2Key) || (!stringIsNullOrEmpty(rowData.level2Key) && rowData.isLevel2KeyValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const Level3KeyValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument?.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.level3Key) || (!stringIsNullOrEmpty(rowData.level3Key) && rowData.isLevel3KeyValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const CostCodesValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument?.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.resType) || (!stringIsNullOrEmpty(rowData.resType) && rowData.isCostCodesValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	//#endregion
	const InvoiceAmountValid = (rowData) => {
		var isValid = false;
		// -ve values are also acceptable in invoice details
		if (state.invoiceDocument?.modelDefId === 2) {
			isValid = rowData.ioInvoiceAmount != 0;
			if (isValid) {
				if (!stringIsNullOrEmpty(state.invoiceDocument?.poCode)) {
					var invoiceDocumentEditorDetails = structuredClone((editorGridRef.current?.invoiceDocumentEditorDetails || []));
					var index = invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId);
					if (index < 0) {
						invoiceDocumentEditorDetails.push(JSON.parse(JSON.stringify(rowData)));
					} else {
						invoiceDocumentEditorDetails[index] = JSON.parse(JSON.stringify(rowData));
					}
					invoiceDocumentEditorDetails[invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId)].ioInvoiceAmount = Number(Number(rowData.ioInvoiceAmount).toFixed(2));
					var groupedInvoiceDocumentDetails = groupAndSum(invoiceDocumentEditorDetails, ['level2Key', 'tolerancePOFlag', 'tolerancePOAmount', 'level3Key', 'resType', 'remainingAmount'], ['ioInvoiceAmount']);
					var groupedFilteredOnKeyFields = groupedInvoiceDocumentDetails.filter(d => d.level2Key === rowData.level2Key && d.level3Key === rowData.level3Key && d.resType === rowData.resType);
					isValid = !(groupedFilteredOnKeyFields.some(d => {
						var isInvoiceAmountInvalid = Number(zeroIfNull(parseFloat(d.ioInvoiceAmount || 0)).toFixed(2)) > Number(zeroIfNull(d.remainingAmount).toFixed(2));
						if (isInvoiceAmountInvalid) {
							if (d.tolerancePOFlag === 'N') {
								isInvoiceAmountInvalid = Number(zeroIfNull(parseFloat(d.ioInvoiceAmount || 0)).toFixed(2)) > Number((zeroIfNull(d.remainingAmount) + zeroIfNull(d.tolerancePOAmount)).toFixed(2));
							}
							else {
								var percentRemainingAmount = (zeroIfNull(d.tolerancePOAmount) * zeroIfNull(d.remainingAmount)) / 100.00;
								isInvoiceAmountInvalid = Number(Number(d.ioInvoiceAmount).toFixed(2)) > Number((d.remainingAmount + percentRemainingAmount).toFixed(2));
							}
						}
						return isInvoiceAmountInvalid;
					}));
				}
			}
		}
		else {
			if (currentEditRow !== null && currentEditRow.isIOUpdated && currentEditRow.seqId === rowData.seqId) {
				rowData.ioNumber = currentEditRow.ioNumber;
				rowData.poAmount = currentEditRow.poAmount;
				rowData.consumedAmount = currentEditRow.consumedAmount;
				rowData.deliveryAmount = currentEditRow.deliveryAmount;
				rowData.isIONumberValid = currentEditRow.isIONumberValid = 'Y';
				currentEditRow.isIOUpdated = false;
			}
			isValid = rowData.ioInvoiceAmount != 0;
			if (isValid) {
				var invoiceDocumentEditorDetails = structuredClone((editorGridRef.current?.invoiceDocumentEditorDetails || []));
				var index = invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId);
				if (index < 0) {
					invoiceDocumentEditorDetails.push(JSON.parse(JSON.stringify(rowData)));
				} else {
					invoiceDocumentEditorDetails[index] = JSON.parse(JSON.stringify(rowData));
				}
				invoiceDocumentEditorDetails[invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId)].ioInvoiceAmount = Number(Number(rowData.ioInvoiceAmount).toFixed(2));
				var groupedInvoiceDocumentDetails = groupAndSum(invoiceDocumentEditorDetails, ['ioNumber', 'poAmount', 'consumedAmount'], ['ioInvoiceAmount']);
				var groupedFilteredOnKeyFields = groupedInvoiceDocumentDetails.filter(d => d.ioNumber === rowData.ioNumber);
				isValid = !(groupedFilteredOnKeyFields.some(d => {
					var remainingAmount = (Number(zeroIfNull(parseFloat(d.poAmount || 0)).toFixed(2)) - Number(zeroIfNull(d.consumedAmount).toFixed(2)));
					var isInvoiceAmountInvalid = Number(Number(zeroIfNull(parseFloat(d.ioInvoiceAmount || 0))).toFixed(2)) > Number(remainingAmount.toFixed(2));
					return isInvoiceAmountInvalid;
				}));
			}
		}
		return isValid;
	}
	const InvoiceDocumentDetailValid = () => {
		let editorDetail = (editorGridRef?.current?.invoiceDocumentEditorDetails || []);
		var isValid = editorDetail.length > 0;
		if (isValid) {
			if (state.invoiceDocument?.modelDefId === 2) {
				isValid = editorDetail.some(d =>
					Level2KeyValid(d) &&
					Level3KeyValid(d) &&
					CostCodesValid(d) &&
					InvoiceAmountValid(d));
			}
			else {
				isValid = !(editorDetail.some(d => stringIsNullOrEmpty(d.ioNumber) || !InvoiceAmountValid(d)));
			}
		}
		return isValid;
	}

	const InvoiceDocumentDetailMissingInfo = () => {
		let editorDetail = (editorGridRef?.current?.invoiceDocumentEditorDetails || []);
		let isMissingInfo = editorDetail.length <= 0;
		if (!isMissingInfo) {
			if (state.invoiceDocument?.modelDefId === 2) {
				isMissingInfo = !editorDetail.every((e, i) => {
					return Level2KeyValid(e) &&
						Level3KeyValid(e) &&
						CostCodesValid(e) &&
						(parseFloat(e.quantity || 0) > 0) &&
						InvoiceAmountValid(e);
				});
			}
			else {
				isMissingInfo = !(editorDetail.every(d => !stringIsNullOrEmpty(d.ioNumber) && isIOValid(d) && InvoiceAmountValid(d) ));
			}
		}
		
		
		return isMissingInfo;
	}
	const isIOValid = (row) => {
		if (!stringIsNullOrEmpty(row.ioNumber) && row.isIONumberValid === 'Y') {
			return true;
		} else {
			return false;
		}
	}
	const getErrorMessage = () => {
		let errorRecordList = editorGridRef.current?.invalidRowsList || [];
		let details = editorGridRef.current?.invoiceDocumentEditorDetails || [];
		let dtlmsgs = editorGridRef.current?.invalidRowsMessage || {};
		let chkAllValid = props.info.mode !== "MakeCorrections" || state.originalInvoiceDocumentStatus > 40;
		let errors = [];
		let detialErrors = [];
		let consumeAmountErrors = [];
		if (!chkAllValid) {
			details?.forEach((e, i) => {
				if (props.info.modelDefId === 1) {
					/*if (e.isDirty && IONumberValid(e)) {*/
					/*if (e.isDirty) {*/
						if (parseFloat(e.ioInvoiceAmount || 0) !== 0 && dtlmsgs[e.seqId] && dtlmsgs[e.seqId].type == '1') {
							consumeAmountErrors.push(`Row ${i + 1} error : ${dtlmsgs[e.seqId] ? dtlmsgs[e.seqId].errorMsg : ''}`);
						//}
					}
				}
				//CostCodesValid Level3KeyValid Level2KeyValid
				if (props.info.modelDefId === 2) {
					let isPOValid = e.poRequiredFlag === 'Y' ? !stringIsNullOrEmpty(state.invoiceDocument.poCode) && state.invoiceDocument.isPOCodeValid === 'Y'
						: true;
					//if (e.isDirty && isPOValid && Level2KeyValid(e) && Level3KeyValid(e) && CostCodesValid(e) && dtlmsgs[e.seqId] &&
					//	(dtlmsgs[e.seqId].type == '1' || dtlmsgs[e.seqId].type == '3')) {
					if (/*e.isDirty && isPOValid &&*/ dtlmsgs[e.seqId] &&
						(dtlmsgs[e.seqId].type == '1' || dtlmsgs[e.seqId].type == '3')) {
						if (parseFloat(e.ioInvoiceAmount || 0) !== 0) {
							consumeAmountErrors.push(`Row ${i + 1} error : ${dtlmsgs[e.seqId] ? dtlmsgs[e.seqId].errorMsg : ''}`);
						}
					}
				}

			})
		}

		if (consumeAmountErrors.length <= 0) {
			if (stringIsNullOrEmpty(state.invoiceDocument?.vendor) || stringIsNullOrEmpty(state.invoiceDocument?.siteId)) {
				errors.push(`Error : Vendor/Site is required`);
				//toast.error(`Error : Vendor/Site is required`);
			}
			if (state.isInvoiceNumberDuplicate || !_isInvoiceNumberValid) {
				if (stringIsNullOrEmpty(state.invoiceDocument?.invoiceNumber)) {
					errors.push(`Error : Invoice Number is required`);
				} else {
					errors.push(`Error : Invoice Number is duplicate or Invalid`);
				}

				//toast.error(`Error : Invoice Number is duplicate or Invalid`);
			}
			if (props.info.modelDefId === 2 && !_isPOCodeValid) {
				errors.push(`Error : PO Code is invalid`);
				//toast.error(`Error : PO code is invalid`);
			}
			if (stringIsNullOrEmpty(state.invoiceDocument?.invoiceDateString)) {
				errors.push(`Error : Invoice Date is required`);
				//toast.error(`Error : Invoice date is required`);
			}
			if (stringIsNullOrEmpty(state.invoiceDocument?.paymentTermCode)) {
				errors.push(`Error : Payment Term is required`);
				//toast.error(`Error : Payment term is required`);
			}

			if (props.info.modelDefId === 1 && !ServiceTermStartValid()) {
				errors.push(`Error : Service Term Start is required`);
				//toast.error(`Error : Service term Start is required`);
			}
			if (props.info.modelDefId === 1 && !ServiceTermEndValid()) {
				errors.push(`Error : Service Term End is invalid`);
				//toast.error(`Error : Service term End is not valid`);
			}
			if (!_isDueDateValid) {
				errors.push(`Error : Due Date is not valid`);
				//toast.error(`Error : Due date is not valid`);
			}
			if (stringIsNullOrEmpty(state.invoiceDocument.currencyCode)) {
				errors.push(`Error : Currency Code is required`);
				//toast.error(`Error : Currency code is required`);
			}
			if (stringIsNullOrEmpty(state.invoiceDocument?.glDate)) {
				errors.push(`Error : GL Date is required`);
			}
			if ((details?.length || 0) == 0 && chkAllValid) {
				detialErrors.push(`Error : Detail Lines are required`);
			} else {
				details?.forEach((e, i) => {
					if (props.info.modelDefId === 1) {
						if (!IONumberValid(e)) {
							detialErrors.push(`Row ${i + 1} error : IO Number is invalid`);
							//toast.error(`Row ${i + 1} error : IO Number is invalid`);
						}
					}
					//CostCodesValid Level3KeyValid Level2KeyValid
					if (props.info.modelDefId === 2) {
						if (!Level2KeyValid(e)) {
							detialErrors.push(`Row ${i + 1} error : Project is invalid`);
							//toast.error(`Row ${i + 1} error : Project is invalid`);
						}
						if (!Level3KeyValid(e)) {
							detialErrors.push(`Row ${i + 1} error : Activity is invalid`);
							//toast.error(`Row ${i + 1} error : Activity is invalid`);
						}
						if (!CostCodesValid(e)) {
							detialErrors.push(`Row ${i + 1} error : Expense Type is invalid`);
							//toast.error(`Row ${i + 1} error : CostCode is invalid`);
						}
					}
					if (errorRecordList.has(e.seqId)) {
						detialErrors.push(`Row ${i + 1} error` + (dtlmsgs[e.seqId] ? " : " + dtlmsgs[e.seqId].errorMsg : ''));
						//toast.error(`Row ${i + 1} error` + (dtlmsgs[e.seqId] ? " : " + dtlmsgs[e.seqId] : ''));
					}

				})
			}
		}
		let aggregateErrors = [];
		aggregateErrors = [...errors, ...consumeAmountErrors, ...detialErrors];
		if (aggregateErrors.length <= 0 && details.length <=0 ) {
			aggregateErrors.push(`Error : Detail Lines are required`);
		}
		//if (aggregateErrors.length > 0) {
		//	toast.error(<div>{aggregateErrors.map(e => <div>{e}</div>)}</div>, { allowHtml: true });
		//}
		return {
			errorList: aggregateErrors,
			errors: aggregateErrors.length > 0,
			isStopable: consumeAmountErrors.length > 0
		}
	}
	//#endregion Validations

	//#region APICalls
	
	const verifyProductionInvoiceDocumentDetails = React.useCallback(() => {
		if (editorGridRef.current.invoiceDocumentEditorDetails.length === 0) {
			return;
		}
		props.info.inProgress(true);
		let invoiceDocument = structuredClone(state.invoiceDocument);
		invoiceDocument.invoiceDocumentEditorDetails = editorGridRef.current.invoiceDocumentEditorDetails;
		httpPost("/api/invoicedocument/verifyproductioninvoicedocumenteditor/", invoiceDocument)
			.then((response) => {
				var data = response.data;
				editorGridRef.current.setInvoiceDetailsOnVendorChange(state.invoiceDocument, data);
				//stateUpliftMethods.setParentState({ invoiceDocument: invoiceDocument });
				//setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument }));
				props.info.inProgress(false);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error('Verification of invoice details failed');
			});
	})
	const fetchInvoiceDocument = useCallback(() => {
		var modelDefId = props.info.modelDefId;
		var invoiceModel = props.info.invoiceModel;
		var uri;
		if (invoiceModel) {
			uri = "/api/invoicemodels/invoicemodeleditor/" + modelDefId + "/" + invoiceModel.masterDocumentModelId + "/" + invoiceModel.invoiceDocumentSampleFileLocation;
		}
		else {
			uri = "/api/invoicedocument/invoicedocumenteditor/" + modelDefId + "/" + (props.info.rowData ? props.info.rowData.invoiceDocumentId : "0");
		}
		props.info.inProgress(true);
		httpGet(uri)
			.then((response) => {
				var invoiceDocument = response.data;
				
				let orignalinvoicedocumnetstatus = invoiceDocument.invoiceDocumentStatusId || 0;
				if (props.info.isViaQueryString) { // all the code below is to be executed if the editor is being opened through query string in the URL
					if (props.info.mode !== "UserEntry" && props.info.rowData.invoiceDocumentId > 0) {
						if (invoiceDocument.invoiceDocumentId === 0) {
							throw new Error('Invoice Document not found');
						}
						else if (props.info.mode === "Approval" &&
							!(invoiceDocument.invoiceDocumentStatusId === 50 ||
								invoiceDocument.invoiceDocumentStatusId === 70 ||
								invoiceDocument.invoiceDocumentStatusId === 90 ||
								invoiceDocument.invoiceDocumentStatusId === 110 ||
								invoiceDocument.invoiceDocumentStatusId === 130)
						) {
							throw new Error('Invoice has been processed or status has changed');
						}
						else if ((props.info.mode === "MakeCorrections" || props.info.mode === "ManualEntry") &&
							!(invoiceDocument.invoiceDocumentStatusId <= 40 ||
								invoiceDocument.invoiceDocumentStatusId === 50 ||
								invoiceDocument.invoiceDocumentStatusId === 60 ||
								invoiceDocument.invoiceDocumentStatusId === 80 ||
								invoiceDocument.invoiceDocumentStatusId === 100 ||
								invoiceDocument.invoiceDocumentStatusId === 120 ||
								invoiceDocument.invoiceDocumentStatusId === 140 ||
								invoiceDocument.invoiceDocumentStatusId === 160 ||
								invoiceDocument.invoiceDocumentStatusId === 180)
						) {
							throw new Error('Invoice has been processed or status has changed');
						}
						else if (props.info.mode !== "View" && (!isNaN(props.info.rowData.invoiceDocumentStatusId) || props.info.rowData.invoiceDocumentStatusId !== 0) && props.info.rowData.invoiceDocumentStatusId !== invoiceDocument.invoiceDocumentStatusId) {
							throw new Error('Invoice has been processed or status has changed');
						}
					}
				}
				var title = state.title;
				var readOnly = state.readOnly;
				var serviceTermStartDate = invoiceDocument.serviceTermStartDate;
				var serviceTermEndDate = invoiceDocument.serviceTermEndDate;
				if (props.info.mode === "UserEntry" || props.info.mode === "Replace") {
					invoiceDocument.invoiceDocumentStatusAttribute = "M";
					invoiceDocument.vendorCode = "";
					invoiceDocument.vendor = "";
					invoiceDocument.siteId = "";
					invoiceDocument.documentModelName = "";
					invoiceDocument.documentModelNameTag = "";
					invoiceDocument.invoiceNumber = "";
					invoiceDocument.invoiceDateString = "";
					invoiceDocument.dueDateString = "";
					invoiceDocument.serviceTermStartDateString = "";
					invoiceDocument.serviceTermEndDateString = "";
					invoiceDocument.paymentTermCode = "";
					invoiceDocument.currencyCode = "";
					invoiceDocument.createDateString = "";
					if (props.info.mode === "Replace") {
						invoiceDocument.invoiceDocumentId = props.info.invoiceDocumentId;
					}
				} else if (props.info.mode === "ManualEntry") {
					invoiceDocument.invoiceDocumentStatusId = 50;
					invoiceDocument.invoiceDocumentStatusAttribute = "M";
				} else if (props.info.mode === "MakeCorrections") {
					if (invoiceDocument.invoiceDocumentStatusId === 80 ||
						invoiceDocument.invoiceDocumentStatusId === 100 ||
						invoiceDocument.invoiceDocumentStatusId === 120 ||
						invoiceDocument.invoiceDocumentStatusId === 140 ||
						invoiceDocument.invoiceDocumentStatusId === 160) {
						invoiceDocument.invoiceDocumentStatusId = (invoiceDocument.invoiceDocumentStatusId - 30);
					}
					else {
						invoiceDocument.invoiceDocumentStatusId = 50;
					}
					invoiceDocument.invoiceDocumentStatusAttribute = "C";
				} else if (props.info.mode === "Approval") {
					title = invoiceDocument.title;
					readOnly = !invoiceDocument.allowInvoiceEditing;
					if (invoiceDocument.invoiceDocumentStatusId === 100 ||
						invoiceDocument.invoiceDocumentStatusId === 120 ||
						invoiceDocument.invoiceDocumentStatusId === 140 ||
						invoiceDocument.invoiceDocumentStatusId === 160) {
						invoiceDocument.invoiceDocumentStatusId = (invoiceDocument.invoiceDocumentStatusId - 50);
					}
					else if (invoiceDocument.invoiceDocumentStatusId === 80) {
						invoiceDocument.invoiceDocumentStatusId = 50;
					}
					invoiceDocument.invoiceDocumentStatusAttribute = "C";
				}
				if (invoiceDocument.masterDocumentModelId === 0) {
					invoiceDocument.masterDocumentModelId = null;
				}
				if (invoiceModel) {
					if (stringIsNullOrEmpty(invoiceDocument.vendor) || stringIsNullOrEmpty(invoiceDocument.siteId)) {
						invoiceDocument.vendor = invoiceModel.vendor;
						invoiceDocument.siteId = invoiceModel.siteId;
					}
					invoiceDocument.documentModelName = invoiceModel.modelName;
					invoiceDocument.documentModelNameTag = invoiceModel.modelTag;
				}
				var taxes = [];
				if (invoiceDocument.taxes) {
					invoiceDocument.taxes.forEach(t => {
						if (taxes.find(tt => tt.taxCode === t.taxCode) == null) {
							taxes.push({ taxCode: t.taxCode, taxDesc: t.taxDesc });
						}
					});
				}
				props.info.inProgress(false);
				if (invoiceDocument?.invoiceDocumentEditorDetails?.length > 0) {
					invoiceDocument.invoiceDocumentEditorDetails = invoiceDocument?.invoiceDocumentEditorDetails?.map(e => {
						return {
							...e,
							isDirty: false
						};
					})
				}
				// this check is not confirmd yet
				//if (!invoiceDocument?.glDateString && prop.info.mode === 'Approval') {
				//	let nglDate = invoiceDocument?.invoiceDateString ? invoiceDocument?.invoiceDateString : FormatToInputDate(new Date());
				//	invoiceDocument?.glDateString = nglDate;
				//	invoiceDocument?.glDate = nglDate;
				//}
				// this check is not confirmd yet
				
				setState((prev) => ({
					...prev, invoiceDocument: invoiceDocument,
					taxes: taxes, title: title, readOnly: readOnly,
					serviceTermStartDate: serviceTermStartDate,
					serviceTermEndDate: serviceTermEndDate,
					isInvoiceNumberDuplicate: (invoiceDocument.duplicateInvoiceFlag === 'Y'),
					originalInvoiceDocumentStatus: orignalinvoicedocumnetstatus,
					isDirty: false
				}));
				setTaxTotal(invoiceDocument?.invoiceDocumentEditorDetails?.length > 0 ?
					invoiceDocument?.invoiceDocumentEditorDetails?.map(item => item.taxAmount).reduce((prev, next) => prev + next) : 0.00)
				setGrossTotal(invoiceDocument?.invoiceDocumentEditorDetails?.length > 0 ?
					invoiceDocument?.invoiceDocumentEditorDetails?.map(item => parseFloat(item.ioInvoiceAmount || 0)).reduce((prev, next) => prev + next) : 0.00)
			}).catch((error) => {
				setState((prev) => ({ ...prev, invoiceDocument: null }));
				props.info.inProgress(false);
				toast.error(stringIsNullOrEmpty(error.message) ? 'Error fetching invoice document' : error.message);
				close_clickHandler();
			});
	})

	const fetchInvoiceDocumentByFileName = useCallback((invoiceDocumentFileLocation) => {
		httpGet("/api/invoicedocument/invoicedocumenteditorbyfilename/" + escape(invoiceDocumentFileLocation))
			.then((response) => {
				var invoiceDocument = response.data;
				if (invoiceDocument.invoiceDocumentId > 0) {
					setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument, replacedInvoiceDocument: invoiceDocument, fetchInterval: null, inProgress: false }));
					clearInterval(state.fetchInterval);
					props.info.inProgress(false);
				}
				else {
					setState((prev) => ({ ...prev, inProgress: false }));
				}
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error('Error fetching replaced invoice document');
				close_clickHandler();
			});
	})
	const tryFetchingInvoiceDocument = useCallback(() => {
		if (!state.replacedInvoiceDocument && !state.inProgress) {
			setState((prev) => ({ ...prev, inProgress: true }));
			fetchInvoiceDocumentByFileName(state.invoiceDocument?.invoiceDocumentFileLocation);
		}
	})
	const fileUpload_changeHandler = (event) => {
		const formData = new FormData();
		const fileOriginal = event.target.files[0];
		if (!checkMimeType(event) || !checkFileSize(event)) {
			event.target.value = null
			return;
		}
		if (fileOriginal) {
			var filename = fileOriginal.name.replace(/[^\w\.]/gi, '');
			var file = new File([fileOriginal], filename, { type: fileOriginal.type });
			formData.append(0, file);
			setState({ ...state, selectedFile: file, documentContentType: file.type });
			var url = "/api/invoicedocument/";
			if (props.info.mode === "Replace") {
				url += "replace";
				formData.append("invoiceDocumentId", state.invoiceDocument?.invoiceDocumentId)
			}
			else {
				url += "upload";
			}
			formData.append("modelDefId", state.invoiceDocument?.modelDefId);
			const token = localStorage.token;
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
			props.info.inProgress(true, false, 0);
			httpPost(url, formData, {
				headers: headers,
				onUploadProgress: ProgressEvent => {
					props.info.inProgress(true, true, (ProgressEvent.loaded / ProgressEvent.total * 100));
				},
			}).then((response) => {
				var invoiceDocument = state.invoiceDocument;
				invoiceDocument.invoiceDocumentFileLocation = response.data.invoiceDocumentFileLocation;
				invoiceDocument.fileName = response.data.fileName;
				invoiceDocument.createDateString = response.data.createDateString;
				setTimeout(function () {
					setState({ ...state, invoiceDocument: invoiceDocument });
					props.info.inProgress(false, false, 0);
					toast.success('Uploaded Successfully');
					if (props.info.mode === "Replace") {
						setState({ ...state, fetchInterval: setInterval(tryFetchingInvoiceDocument, 15000) });
						props.info.inProgress(false);
						close_clickHandler();
					}
					else {
						props.info.inProgress(false);
					}
				}, 3000);
			}).catch((error) => {
				toast.error('Upload Failed');
				props.info.inProgress(false, false, 0);
			});
		}
		event.target.value = null;
	}
	const checkMimeType = (event) => {
		//getting file object
		let files = event.target.files
		//define message container
		let err = []
		// list allow mime type
		const types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
		// loop access array
		for (var x = 0; x < files.length; x++) {
			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container   
				err.push(files[x].name + ' of type \'' + files[x].type + '\' is not a supported format');
			}
		};
		if (err.length > 0) { // if message not same old that mean has error
			for (var i = 0; i < err.length; i++) {
				toast.error(err[i]);
			}
			return false;
		}
		return true;
	}
	const checkFileSize = (event) => {
		let files = event.target.files
		var size = 10 * 1024 * 1024; //10 MB size
		let err = "";
		for (var x = 0; x < files.length; x++) {
			if (files[x].size > size) {
				err += files[x].name + ' is too large, please pick a smaller file\n';
			}
		};
		if (err !== '') {
			toast.error(err);
			return false
		}
		return true;
	}
	const replaceFileInfoDialog_clickHandler = (event) => {
		setState((prev) => ({ ...prev, openReplaceFileScanInProgress: false }));
	}
	const invoiceNumber_blurHandler = (event) => {
		var invoiceDocument = state.invoiceDocument;
		if (VendorSelected() && !stringIsNullOrEmpty(invoiceDocument.invoiceNumber)) {
			props.info.inProgress(true);
			httpGet("/api/invoicedocument/validateduplicate/" + parseInt(encodeURIComponent(invoiceDocument.invoiceDocumentId)) + "/" + encodeURIComponent(invoiceDocument.vendorCode) + "/" + encodeURIComponent(invoiceDocument.invoiceNumber))
				.then((response) => {
					
					var isInvoiceNumberDuplicate = response.data;
					props.info.inProgress(false);
					setState((prev) => ({ ...prev, isInvoiceNumberDuplicate: isInvoiceNumberDuplicate }));
				}).catch((error) => {
					props.info.inProgress(false);
					toast.error(<div>Delivery Amount update failed<br />{error.response.data.detail}</div>);
				});
		}
		else {
			setState((prev) => ({ ...prev, isInvoiceNumberDuplicate: false }));
		}
	}

	//#endregion APICalls



	//#region renderer

	const uniqueBy = (arr, keyProps) => {
		const kvArray = arr.map(entry => {
			const key = keyProps.map(k => entry[k]).join('|');
			return [key, entry];
		});
		const map = new Map(kvArray);
		return Array.from(map.values());
	}
	const groupAndSum = (arr, groupKeys, sumKeys) => {
		return Object.values(
			arr.reduce((acc, curr) => {
				const group = groupKeys.map(k => curr[k]).join('-');
				acc[group] = acc[group] || Object.fromEntries(groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])));
				sumKeys.forEach(k => acc[group][k] += curr[k]);
				return acc;
			}, {})
		);
	}
	const setVendor = (vendor) => {
		var target = { name: "vendorCode", value: vendor ? vendor.vendorCode : "" };
		input_changeHandler({ target: target, currentTarget: target });
		target = { name: "shortName", value: vendor ? vendor.shortName : "" };
		input_changeHandler({ target: target, currentTarget: target });
		target = { name: "vendor", value: vendor ? vendor.vendorCode + " - " + vendor.shortName : "" };
		input_changeHandler({ target: target, currentTarget: target });
		target = { name: "siteId", value: vendor ? vendor.siteId : "" };
		input_changeHandler({ target: target, currentTarget: target });
		if (vendor) {
			target = { name: "currencyCode", value: vendor ? vendor.currencyCode : "" };
			input_changeHandler({ target: target, currentTarget: target });
		}
		if (vendor && state.invoiceDocument?.modelDefId === 2) {
			var invoiceDocument = state.invoiceDocument;
			invoiceDocument.noPoFlag = vendor.noPoFlag;
			invoiceDocument.isNoPOVendor = vendor.isNoPOVendor;
			setState((prev) => ({ ...prev, invoiceDocument: invoiceDocument }));
		}
		invoiceNumber_blurHandler(); //Validate invoice number for this vendor
	}
	const VendorSelected = () => {
		return !(stringIsNullOrEmpty(state.invoiceDocument?.vendor));
	}

	

	const getCollapseUncollapseButton = () => {
		return <IconButton
			variant="contained"
			onClick={collapseUncollapse_clickHandler}
			size="small">
			{state.uncollapsed ? <CollapseIcon /> : <UncollapseIcon />}
		</IconButton>;
	}
	const getFileUploadOrInvoiceDocumentViewer = React.useCallback(() => {
		var returnValue = "";
		if (state.invoiceDocument?.invoiceDocumentFileLocation && state.invoiceDocument?.invoiceDocumentFileLocation.length > 0) {
			const invoiceViewerInfo = {
				inProgress: props.info.inProgress,
				modelDefId: props.info.modelDefId,
				invoiceDocumentFileLocation: state.invoiceDocument?.invoiceDocumentFileLocation,
				inline: true
			}
			returnValue = <div><InvoiceDocumentViewer info={invoiceViewerInfo} /></div>
		}
		else {
			returnValue = <div className="file-drop-area">
				<label htmlFor="input-file">
					<div className="file-drop-container">
						<img src="images/drag-and-drop-icon.png" alt="" />
						<span className="file-msg">Drag & Drop Invoice Here <br /> or </span>
					</div>
					<input
						type="file"
						accept="application/pdf,image/jpg,image/jpeg,image/png"
						id="input-file"
						style={{ display: "none" }}
						onChange={fileUpload_changeHandler} />
					<div className="file-drop-browse">
						<label htmlFor="input-file">
							<Button variant="contained" color="inherit" component="span" size="large" startIcon={<CloudUploadIcon />}>Browse Invoice</Button>
						</label>
					</div>
				</label>
			</div>
		}
		return returnValue;
	})
	const IsSaveDisabled = () => {
		const isValidVendorSelected = VendorSelected();
		const isValidPOCode = POCodeValid();
		const isValidDueDate = DueDateValid();
		const isValidInvoiceDocumentDetail = InvoiceDocumentDetailValid();
		const isValidServiceTermStart = ServiceTermStartValid();
		const isValidServiceTermEnd = ServiceTermEndValid();
		const isValidInvoiceNumber = InvoiceNumberValid();
		const isValidGLDate = !stringIsNullOrEmpty(state?.invoiceDocument?.glDate);
		let checkgridValidity = props.info.mode !== "MakeCorrections" || state.originalInvoiceDocumentStatus > 40;
		checkgridValidity = checkgridValidity && !state.readOnly;
		const isvalid = (
			//state.isDirty === false ||
			stringIsNullOrEmpty(state.invoiceDocument?.invoiceDocumentFileLocation) ||
			(checkgridValidity && editorGridRef.current?.isGridDataValid === false) ||
			(!isValidVendorSelected) ||
			stringIsNullOrEmpty(state.invoiceDocument?.siteId) ||
			!isValidInvoiceNumber ||
			state.isInvoiceNumberDuplicate ||
			!isValidPOCode ||
			!isValidDueDate ||
			stringIsNullOrEmpty(state.invoiceDocument?.invoiceDateString) ||
			stringIsNullOrEmpty(state.invoiceDocument?.dueDateString) ||
			stringIsNullOrEmpty(state.invoiceDocument?.paymentTermCode) ||
			(state.invoiceDocument?.modelDefId === 1 && (!isValidServiceTermStart || !isValidServiceTermEnd)) ||
			stringIsNullOrEmpty(state.invoiceDocument?.currencyCode) ||
			isValidGLDate === false ||
			!isValidInvoiceDocumentDetail);
		return isvalid;
	}
	const SaveButtonEnable = React.useCallback((isValidVendorSelected) => {
		var isDueDateValidation = true;
		if (!stringIsNullOrEmpty(state.invoiceDocument?.dueDateString) && !stringIsNullOrEmpty(state.invoiceDocument?.invoiceDateString)) {
			var dueDate = new Date(state.invoiceDocument?.dueDateString);
			var invoiceDate = new Date(state.invoiceDocument?.invoiceDateString);
			isDueDateValidation = dueDate >= invoiceDate;
		}
		var isServiceTermValidation = true;
		if (state.invoiceDocument?.modelDefId === 1 && !stringIsNullOrEmpty(state.invoiceDocument?.serviceTermStartDateString) && !stringIsNullOrEmpty(state.invoiceDocument?.serviceTermEndDateString)) {
			var endDate = new Date(state.invoiceDocument?.serviceTermEndDateString);
			var startDate = new Date(state.invoiceDocument?.serviceTermStartDateString);
			isServiceTermValidation = endDate >= startDate;
		}
		const isValidGLDate = !stringIsNullOrEmpty(state?.invoiceDocument?.glDate) || state.originalInvoiceDocumentStatus < 50;
		let checkgridValidity = props.info.mode !== "MakeCorrections" || state.originalInvoiceDocumentStatus > 40;
		const isvalid = (
			state.isDirty === false ||
			(checkgridValidity && editorGridRef.current?.isGridDataValid === false) ||
			stringIsNullOrEmpty(state.invoiceDocument?.invoiceDocumentFileLocation) ||
			(!isValidVendorSelected) ||
			stringIsNullOrEmpty(state.invoiceDocument?.siteId) ||
			state.isInvoiceNumberDuplicate ||
			!isDueDateValidation ||
			isValidGLDate === false ||
			!isServiceTermValidation);
		return isvalid;
	})

	const setParentState = React.useCallback((obj) => {
		setState({
			...state,
			//isGridDataValid: (typeof obj.isGridDataValid == 'undefined' || obj.isGridDataValid == null) ? state.isGridDataValid : obj.isGridDataValid,
			isDirty: obj.isDirty || state.isDirty,
			invoiceDocument: ((typeof obj.invoiceDocument == 'undefined' || obj.invoiceDocument == null) ? state.invoiceDocument : obj.invoiceDocument)
		});
	})
	//just to update view
	const updateView = React.useCallback(() => {
		setView(view + 1);
	});
	const info = React.useMemo(() => {
		return {
			...props.info,
			openDialog: state.openVendorDialog,
			invoiceModel: { vendorCode: state.invoiceDocument?.vendorCode, siteId: state.invoiceDocument?.siteId, currencyCode: "" }
		}
	}, [state.invoiceDocument, state.openVendorDialog, editorGridRef.current?.isGridDataValid])
	const currencyCode = blankStringIfNullOrEmpty(state.invoiceDocument?.currencyCode);
	const vendorDialogConfirmationMessage = state.invoiceDocument?.modelDefId === 1 ? "Changing vendor will reset IO/PO Number from all invoice details, Do you wish to continue ?" : "Changing vendor will reset PO Code, Do you wish to continue ?";
	const poHeaderInfo = {
		inProgress: props.info.inProgress,
		openDialog: state.openPOHeaderDialog,
		vendorCode: state.invoiceDocument?.vendorCode,
		siteId: state.invoiceDocument?.siteId,
		serviceTermStartDate: state.invoiceDocument?.serviceTermStartDate,
		serviceTermEndDate: state.invoiceDocument?.serviceTermEndDate,
		modelDefId: state.invoiceDocument?.modelDefId,
		poCode: state.invoiceDocument?.modelDefId === 1 ? (currentEditRow && currentEditRow.ioNumber ? currentEditRow.ioNumber : null) : state.invoiceDocument?.poCode,
		invoiceDocumentId: state.invoiceDocument?.invoiceDocumentId
	};
	const poHeaderResourceInfo = {
		inProgress: props.info.inProgress,
		openDialog: state.openPOHeaderResourceDialog,
		vendorCode: state.invoiceDocument?.vendorCode,
		siteId: state.invoiceDocument?.siteId,
		invoiceDocumentId: state.invoiceDocument?.invoiceDocumentId
	};
	const level2ResourceInfo = {
		inProgress: props.info.inProgress,
		openDialog: state.openLevel2ResourceDialog,
		vendorCode: state.invoiceDocument?.vendorCode,
		siteId: state.invoiceDocument?.siteId,
		invoiceDocumentId: state.invoiceDocument?.invoiceDocumentId
	};
	const level2Info = {
		inProgress: props.info.inProgress,
		openDialog: state.openLevel2Dialog,
		poCode: blankStringIfNullOrEmpty(state.invoiceDocument?.poCode),
		noPoFlag: state.invoiceDocument?.noPoFlag,
		level2Key: currentEditRow ? currentEditRow.level2Key : null
	};
	const level3Info = {
		inProgress: props.info.inProgress,
		openDialog: state.openLevel3Dialog,
		level2Key: currentEditRow ? currentEditRow.level2Key : null,
		poCode: blankStringIfNullOrEmpty(state.invoiceDocument?.poCode),
		level3Key: currentEditRow ? currentEditRow.level3Key : null
	};
	const costCodesInfo = {
		inProgress: props.info.inProgress,
		openDialog: state.openCostCodesDialog,
		level2Key: currentEditRow ? currentEditRow.level2Key : null,
		level3Key: currentEditRow ? currentEditRow.level3Key : null,
		poCode: blankStringIfNullOrEmpty(state.invoiceDocument?.poCode),
		resType: currentEditRow ? currentEditRow.resType : null
	};
	const invoiceDocumentRoutingHistoryInfo = {
		rowData: props.info.rowData,
		inProgress: props.info.inProgress,
		openDialog: state.openInvoiceDocumentRoutingHistoryDialog
	};
	const replaceInfo = {
		rowData: null,
		invoiceDocumentId: state.invoiceDocument?.invoiceDocumentId,
		modelDefId: state.invoiceDocument?.modelDefId,
		mode: "Replace",
		inProgress: props.info.inProgress
	};

	const displayAccept = props.info.mode === "Approval" ? "" : "none";
	const displayReject = props.info.mode === "Approval" && state.invoiceDocument?.invoiceDocumentStatusId > 50 ? "" : "none";
	const displaySave = (state.readOnly || (props.info.mode === "Approval" && state.invoiceDocument?.allowInvoiceEditing === false)) ? "none" : "";
	const displayReplace = (displaySave === "none" || props.info.mode === "UserEntry" || props.info.mode === "Replace") ? "none" : "";
	const _isVendorSelected = VendorSelected();
	const _isPOCodeValid = POCodeValid();
	const _isDueDateValid = DueDateValid();
	const _isServiceTermStartValid = ServiceTermStartValid();
	const _isServiceTermEndValid = ServiceTermEndValid();
	const _isInvoiceNumberValid = InvoiceNumberValid();
	const isSaveBtnDisabled = props.info.mode === "Approval" ? IsSaveDisabled() : SaveButtonEnable(_isVendorSelected);
	const invoiceTotal = parseFloat(grossTotal || 0) + taxTotal;
	const cellEditable = !(state.readOnly || (!_isVendorSelected));
	const cellVisible = props.info.mode != 'Test'

	//#endregion renderer

	return (

		state.invoiceDocument &&
		<div>
			<h1 style={{ marginLeft: 15 }}>{state.title}</h1>
			<div className="col-invoice-view">
				<div className="mainTable box-container" style={{ maxWidth: "none", paddingBottom: 90 }}>
					<Grid container justify="center" style={{ flexGrow: 1 }} spacing={1}>
						<Slide direction="right" in={state.uncollapsed} mountOnEnter>
							<Grid key={1} item xs={state.uncollapsed ? 6 : 1} style={{ display: state.displayInvoiceDocumentViewer }}>
								{getFileUploadOrInvoiceDocumentViewer()}
							</Grid>
						</Slide>
						<Grid key={2} item xs={state.uncollapsed ? 6 : 12}>
							<Grid item xs>
								{getCollapseUncollapseButton()}
							</Grid>
							<Grid item xs>
								<Grid container justify="center">
									<Grid item xs={10}>
										<div className="row-form" style={{ display: stringIsNullOrEmpty(state.invoiceDocument?.fileName) ? "none" : "" }}>
											<div className="mf-10">
												<label>Invoice Document File:</label>
													{state.invoiceDocument && <input type="text" name="fileName" tabIndex="1" readOnly={true} value={state.invoiceDocument?.fileName + " " + new Date(state.invoiceDocument?.createDate).toLocaleString() || ''} />}
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
										<div className="row-form">
											<div className="mf-10">
												<label>ERP Vendor/Site:</label>
													<div className={`textBoxValidate searchFld`}>
														{state.invoiceDocument && <input type="text" name="vendor" tabIndex="2"
															className={`${stringIsNullOrEmpty(state.invoiceDocument?.vendor) || stringIsNullOrEmpty(state.invoiceDocument?.siteId) ? ' border-red' : '' }` }
															readOnly={true} value={stringIsNullOrEmpty(state.invoiceDocument?.vendor) || stringIsNullOrEmpty(state.invoiceDocument?.siteId) ? "" : state.invoiceDocument?.vendor + " / " + state.invoiceDocument?.siteId} />}
													<img src="images/images-invoicemodelsetup/search-icon.png" alt="Search Vendor" style={{ display: state.readOnly ? "none" : "" }} onClick={search_clickHandler} />
												</div>
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: stringIsNullOrEmpty(state.invoiceDocument?.vendor) || stringIsNullOrEmpty(state.invoiceDocument?.siteId) ? "visible" : "hidden" }}>*</span>*/}
											</div>
										</div>
										<div className="row-form" style={{ display: (!state.invoiceDocument?.documentModelName || !state.invoiceDocument?.documentModelNameTag) || props.info.mode === "UserEntry" || props.info.mode === "Replace" ? "none" : "" }}>
											<div className="mf-10">
												<label>Invoice Model/Tag:</label>
													{state.invoiceDocument && <input type="text" name="documentModelName" tabIndex="3" readOnly={true} value={state.invoiceDocument?.documentModelName + " - " + state.invoiceDocument?.documentModelNameTag || ''} />}
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
									</Grid>
									<Grid item xs={2}>
									</Grid>
								</Grid>
									{/*Header Column*/}
									<Grid container justify="flex-start">
									{/*column 1*/}
									<Grid item xs>
											<div className="row-form" data-row={ 1}>
											<div className="mf-10">
													<label>Invoice Number:</label>
													{state.invoiceDocument && <input type="text" name="invoiceNumber"
														title={_isInvoiceNumberValid && state.isInvoiceNumberDuplicate ? "Duplicate" : ""}
														className={`${state.isInvoiceNumberDuplicate || !_isInvoiceNumberValid ? 'border-red':''}` }
														tabIndex="4" readOnly={state.readOnly} value={state.invoiceDocument?.invoiceNumber || ''} maxLength={32} onChange={input_changeHandler} onBlur={invoiceNumber_blurHandler} />}
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span title={_isInvoiceNumberValid && state.isInvoiceNumberDuplicate ? "Duplicate" : ""} style={{ color: "red", visibility: state.isInvoiceNumberDuplicate || !_isInvoiceNumberValid ? "visible" : "hidden" }}>*</span>*/}
											</div>
											</div>
											
											<div className="row-form" style={{ display: state.invoiceDocument?.modelDefId === 2 ? "" : "none" }} data-row={2}>
											<div className="mf-10">
												<label>PO Code:</label>
												<div className="textBoxValidate searchFld">
														{state.invoiceDocument && <input type="text"
															className={`${!_isPOCodeValid  ? 'border-red' : ''}`}
															name="poCode" tabIndex="6" readOnly={true} value={stringIsNullOrEmpty(state.invoiceDocument?.poCode) ? "" : state.invoiceDocument?.poCode} />}
													<img src="images/cross.png" alt="Remove PO Code" style={{ padding: 6, marginRight: 30, display: state.readOnly || state.invoiceDocument?.modelDefId !== 2 || (!state.invoiceDocument?.isNoPOVendor && _isPOCodeValid) || stringIsNullOrEmpty(state.invoiceDocument?.poCode) ? "none" : "" }} onClick={deletePOCode_clickHandler} />
													<img src="images/images-invoicemodelsetup/search-icon.png" alt="Search PO Code" style={{ display: state.readOnly || stringIsNullOrEmpty(state.invoiceDocument?.vendor) || stringIsNullOrEmpty(state.invoiceDocument?.siteId) ? "none" : "" }} onClick={searchIONumber_clickHandler} />
												</div>
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: !_isPOCodeValid ? "visible" : "hidden" }}>*</span>*/}
											</div>
										</div>
											<div className="row-form" data-row={3}>
												<div className="mf-10">
													<label>Invoice Date:</label>
													{state.invoiceDocument && <input type="date"
														className={`${stringIsNullOrEmpty(state.invoiceDocument?.invoiceDateString) ? 'border-red' : ''}`}
														name="invoiceDateString" tabIndex="7" data-name="invoiceDate" readOnly={state.readOnly} value={state.invoiceDocument?.invoiceDateString || ''} onChange={onPaymentTermsChange} />}
												</div>
												<div style={{ width: 5, padding: 5 }}>
													{/*<span style={{ color: "red", visibility: stringIsNullOrEmpty(state.invoiceDocument?.invoiceDateString) ? "visible" : "hidden" }}>*</span>*/}
												</div>
											</div>
											<div className="row-form" style={{ display: cellVisible ? "" : "none" }} data-row={4}>
												<div className="mf-10">
													<label>GL Date:</label>
													<input type="date" name="glDateString"
														data-name="glDate"
														readOnly={state.readOnly}
														className={`${stringIsNullOrEmpty(state.invoiceDocument?.glDateString) ? 'border-red' : ''}`}
														value={state.invoiceDocument?.glDateString} onChange={input_changeHandler} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form" data-row={5}>
											<div className="mf-10">
												<label htmlFor="paymentTerms">Payment Term:</label>
													<select id="paymentTermCode" name="paymentTermCode"
														style={{ padding: '5px 10px 5px 10px' } }
														className={`${stringIsNullOrEmpty(state.invoiceDocument?.paymentTermCode) ? 'border-red' : ''}`}
														tabIndex="9" disabled={state.readOnly} value={stringIsNullOrEmpty(state.invoiceDocument?.paymentTermCode) ? "" : state.invoiceDocument?.paymentTermCode} onChange={onPaymentTermsChange}>
														<option key="paymentTermCode" value={(state.invoiceDocument?.paymentTerms?.length || 0) === 0 ? state.invoiceDocument?.paymentTermCode : ""}>{(state.invoiceDocument?.paymentTerms?.length || 0) === 0 ? state.invoiceDocument?.paymentTermCode : "Select Payment Term"}</option>
													{
														state.invoiceDocument?.paymentTerms?.map(function (item, index) {
															return <option key={index} value={item.termsCode}>{item.termsDesc}</option>
														})
													}
												</select>
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: stringIsNullOrEmpty(state.invoiceDocument?.paymentTermCode) ? "visible" : "hidden" }}>*</span>*/}
											</div>
										</div>
											<div className="row-form" style={{ display: state.invoiceDocument?.modelDefId === 2 ? "none" : "" }} data-row={6}>
											<div className="mf-10">
												<label>Service Term From:</label>
													{state.invoiceDocument && <input type="date"
														className={`${!_isServiceTermStartValid?'border-red':''}` }
														name="serviceTermStartDateString" tabIndex="11" data-name="serviceTermStartDate" readOnly={state.readOnly} value={state.invoiceDocument?.serviceTermStartDateString || ''} onChange={input_changeHandler} onBlur={serviceTerm_blurHandler} />}
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: !_isServiceTermStartValid ? "visible" : "hidden" }}>*</span>*/}
											</div>
										</div>
											<div className="row-form" style={{ display: state.invoiceDocument?.masterDocumentModelId == null ? "none" : "" }} data-row={7}>
											<div className="mf-10">
												<label>Scanned Total:</label>
													<input type="text" name="scannedTotal" readOnly={true} value={formatNumber(state.invoiceDocument?.scannedTotal) || ''}
													style={{ textAlign: 'right' }} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
											<div className="row-form" data-row={8}>
											<div className="mf-10">
												<label>Tax Total:</label>
												<input type="text" name="taxTotal" readOnly={true} value={formatNumber(taxTotal) || ''} onChange={input_changeHandler} style={{ textAlign: 'right' }} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
										</Grid>
									{/*column 2*/}
									<Grid item xs>
											<div className="row-form" style={{ visibility: props.info.mode === "UserEntry" || props.info.mode === "Replace" ? "hidden" : "visible" }}
												data-row={1} >
											<div className="mf-10">
												<label>Invoice Status:</label>
													<input type="text" name="status" tabIndex="5" readOnly={true} value={state.invoiceDocument.status || ''} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
											<div className="row-form" style={{ display: state.invoiceDocument.modelDefId === 2 ? "" : "none" }}
												data-row={2} >
											<div className="mf-10">
												<label>&nbsp;</label>
												<div className="textBoxValidate searchFld">
												</div>
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
											<div className="row-form" data-row={3}>
											<div className="mf-10">
													<label>Due Date:</label>
													<input type="date" name="dueDateString"
														className={`${!_isDueDateValid ? 'border-red' : ''}`}
														tabIndex="8" data-name="dueDate" readOnly={state.readOnly} value={state.invoiceDocument.dueDateString || ''} onChange={input_changeHandler} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: !_isDueDateValid ? "visible" : "hidden" }}>*</span>*/}
											</div>
											</div>
											<div className="row-form" style={{ display: cellVisible ? "" : "none", minHeight:'34px' }} data-row={4}>
												<div className="mf-10">
												{/*dummy row*/}
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form" data-row={5}>
											<div className="mf-10">
												<label htmlFor="currencies">Currency:</label>
													{/*<input type="text" id="currencyCode" name="currencyCode" tabIndex="10" readOnly={true} value={currencyCode} />*/}
													<select id="currencyCode"
														className={`${stringIsNullOrEmpty(state.invoiceDocument.currencyCode) ? 'border-red' : ''}`}
														name="currencyCode" tabIndex="10" disabled={state.readOnly || !stringIsNullOrEmpty(state.invoiceDocument.vendor)} value={state.invoiceDocument?.currencyCode} onChange={input_changeHandler}>
														<option key="currencyCode" value={(state.invoiceDocument?.currencies?.length || 0) === 0 ? state.invoiceDocument?.currencyCode : ""}>{(state.invoiceDocument?.currencies?.length || 0)===0 ? state.invoiceDocument?.currencyCode : ""}</option>
													{
															state.invoiceDocument?.currencies?.map(function (item, index) {
															return <option key={index} value={item.currencyCode}>{item.currencyName}</option>
														})
													}
												</select>
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: stringIsNullOrEmpty(state.invoiceDocument.currencyCode) ? "visible" : "hidden" }}>*</span>*/}
											</div>
										</div>
											<div className="row-form" style={{ display: state.invoiceDocument.modelDefId === 2 ? "none" : "" }}
												data-row={6}>
											<div className="mf-10">
												<label>Service Term To:</label>
													<input type="date"
														className={`${!_isServiceTermEndValid ? 'border-red' : ''}`}
														name="serviceTermEndDateString" tabIndex="12" data-name="serviceTermEndDate" readOnly={state.readOnly} value={state.invoiceDocument.serviceTermEndDateString || ''} onChange={input_changeHandler} onBlur={serviceTerm_blurHandler} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
												{/*<span style={{ color: "red", visibility: !_isServiceTermEndValid ? "visible" : "hidden" }}>*</span>*/}
											</div>
										</div>
											<div className="row-form" style={{ display: state.invoiceDocument?.masterDocumentModelId == null ? "none" : "" }}
												data-row={7}>
											<div className="mf-10">
												<label>Remaining Balance:</label>
													<input type="text" name="remainingBalance" readOnly={true} value={formatNumber(state.invoiceDocument?.scannedTotal - parseFloat(grossTotal || 0)) || ''} style={{ textAlign: 'right' }} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
											<div className="row-form" data-row={8}>
											<div className="mf-10">
												<label>Invoice Total:</label>
													<input type="text" name="invoiceTotal" readOnly={true} value={formatNumber(invoiceTotal) || ''} onChange={input_changeHandler} style={{ textAlign: 'right' }} />
											</div>
											<div style={{ width: 5, padding: 5 }}>
											</div>
										</div>
									</Grid>
									</Grid>
									<Grid style={{ display: props.info.mode === "Test" ? 'none' :'flex' }} container justify="center">
									<Grid item xs>
										<div className="row-form" >
											<div className="mf-11 field_full-9">
													<label>Comments:</label>
													<textarea name="headerComments" readOnly={props.info.mode === "Test" } onChange={input_changeHandler} value={state.invoiceDocument.headerComments || ''}></textarea>
											</div>
										</div>

									</Grid>
								</Grid>
								<Grid container justify="center">
									<Grid item xs>
											<div className="col-md-12 form-group" style={{ marginTop: 10, marginBottom: 10 }}>
												{(state.invoiceDocument &&
													//state.invoiceDocument?.modelDefId === 1 &&
													//(!!state.invoiceDocument?.invoiceDocumentId && state.invoiceDocument?.invoiceDocumentId !== 0) &&
													props?.info?.mode !== 'Test') &&
												<div className="form-group">
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub_navigations">
														<div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 nav-padding">
															<ul id="tabStrip">

																<li className={activeTab === "tabInvoiceDetails" ? "active" : ""}
																	onClick={handleTabInvoiceDetails}><a>Invoice Details</a></li>
																<li className={activeTab === "tabAttachments" ? "active" : ""}
																	onClick={handleTabAttachments}><a>Attachments</a></li>
															</ul>
														</div>
													</div>
												</div>}
											<div className="form-group">
													<div key={ 0} style={{ display: (activeTab === "tabInvoiceDetails") ? 'block' : 'none' }} className="col-md-12 zero-padding tabInvoiceDetails">
														<div key={10} style={{ marginTop: 10, marginBottom: 10 }}>
															<InvoiceDocumentEditorGrid key={100}
																poHeaderLookupInfo={poHeaderInfo}
																level2LookupInfo={level2Info}
																level3LookupInfo={level3Info}
																costCodesLookupInfo={costCodesInfo}
																editable={cellEditable}
																info={props.info}
																poCode={state.invoiceDocument.poCode}
																invoiceDocument={state.invoiceDocument}
																taxes={state.taxes}
																stateRef={editorGridRef}
																setParentState={setParentState}
																updateView={updateView}
																messageDialog={state.openReplaceFileScanInProgress}
																vendorDialog={state.openVendorDialog}
																duplicateCheck={state.isInvoiceNumberDuplicate}
																searchIONumber_clickHandler={searchIONumber_clickHandler}
																originalInvoiceDocumentStatus={state.originalInvoiceDocumentStatus}
																verifyProductionInvoiceDocumentDetails={verifyProductionInvoiceDocumentDetails}
															/>
														</div>
													</div>
													<div key={1} style={{ display: (activeTab !== "tabInvoiceDetails") ? 'block' : 'none' }} className="col-md-12 zero-padding tabAttachments">
														<div key={10}  style={{ marginTop: 10, marginBottom: 10 }}>
															<InvoiceDocumentEditorAttachments key={110} stateRef={attachmentRef} setParentState={setParentState}
																invoiceDocument={state.invoiceDocument} info={props.info} editable={cellEditable} />
														</div>
													</div>
												

											</div>
										</div>

									</Grid>
								</Grid>
								<Grid container justify="center">
									<Grid item xs>

										<div className="row-form" style={{ display: displayAccept }} >
											<div className="col-md-12 field_full-8">
												<label>{displayReject === "" ? "Approval/Rejection Comments:" : "Approval Comments:"}</label>
												<textarea name="comments" onChange={input_changeHandler}></textarea>
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<div className="floatingButtons">
						<div className="row-form row-btn">
							<div className="mf-12">
								<button className="btn-blue" style={{ display: displayReplace }} onClick={replace_clickHandler}>Replace Invoice</button>
								<button className="btn-blue" style={{
									display: state.readOnly === false &&
										props.info.mode !== "Approval" &&
										props.info.mode !== "UserEntry" &&
										(isAdmin || state.invoiceDocument.routedToResourceId == null || state.invoiceDocument.routedToResourceId === resourceId) &&
										!stringIsNullOrEmpty(state.invoiceDocument.invoiceDocumentFileLocation) ? "" : "none"
								}} disabled={!_isVendorSelected} onClick={routeToEmployee_clickHandler}>Route to Employee</button>
								<button className="btn-blue" style={{ display: displayAccept }} onClick={accept_clickHandler}>{state.invoiceDocument.acceptName}</button>
								<button className="btn-blue" style={{ display: displayReject }} onClick={reject_clickHandler}>{state.invoiceDocument.rejectName}</button>
									<button className="btn-blue" style={{ display: props.info.rowData && props.info.rowData.routingHistoryCount > 0 ? "" : "none" }} onClick={routingHistory_clickHandler}>View Approval History</button>
									<button className="btn-blue" style={{ display: displaySave }} disabled={!state.isDirty} onClick={save_clickHandler}>Save</button>
								{/*<button className="btn-blue" style={{ display: displaySave }} disabled={isSaveBtnDisabled} onClick={save_clickHandler}>Save</button>*/}
								<button className="btn-grey" onClick={close_clickHandler}>Close </button>
							</div>
						</div>
					</div>
				</div>
				<VendorLookup info={info} onClose={vendorDialog_closeHandler} />
				<POHeaderLookup info={poHeaderInfo} onClose={poHeaderDialog_closeHandler} />
				<POHeaderResourceLookup info={poHeaderResourceInfo} onClose={poHeaderResourceDialog_closeHandler} />
				<Level2ResourceLookup info={level2ResourceInfo} onClose={level2ResourceDialog_closeHandler} />
				<InvoiceDocumentRoutingHistory info={invoiceDocumentRoutingHistoryInfo} onClose={invoiceDocumentRoutingHistoryDialog_closeHandler} />
				<Dialog
					open={state.openChangeVendorConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Change vendor on invoice
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{vendorDialogConfirmationMessage}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={changeVendorYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={changeVendor_closeHandler} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={state.openReplaceFileScanInProgress}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Replacement
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							On file selection, the existing invoice will be replaced and the selected file will be uploaded for scanning
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={replaceFileInfoDialog_clickHandler} color="primary">
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={state.openReplaceInvoiceDocumentEditorDialog} TransitionComponent={Transition} fullScreen>
					<InvoiceDocumentEditor info={replaceInfo} onClose={close_clickHandler} />
				</Dialog>
			</div>
		</div>) || <></>
}