import * as React from 'react';
import { connect } from 'react-redux';
//import { ChangeState } from '../store/action/loginAction';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName:""
        }
    }
    _changeState() {
        this.props.changeStateToReducer(this.state.userName);
        this.setState({ userName: '' });
    }
    _updateValue(event) {
        this.setState({ userName: event.target.value });
    }
    render() {
        return (
            <div>
                <h1>Home </h1>
        </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        userName: state.rootReducer.userName
    })
}
function mapDispatchToProps(dispatch) {
    return ({
        changeStateToReducer: (updatedValue) => {
            //dispatch(ChangeState(updatedValue));
        }
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
