import * as React from 'react';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
//import { Height } from '@mui/icons-material';
export default class Vendors extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
		this.inProgress = false;
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
	}
	columns = [
		{ field: 'siteId', headerName: 'Site ID', minwidth: 150 ,flex:0.4 },
		{ field: 'vendorCode', headerName: 'Vendor Code', minwidth: 150, flex: 0.4 },
		{ field: 'vendorName', headerName: 'Vendor Name', minwidth: 300, flex: 0.6 },
		{ field: 'vendorAddress', headerName: 'Address', minwidth: 400, flex: 1 },
	];
	componentDidMount() {
		this.fetchVendors();
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}
	
	refresh_clickHandler(event) {
		this.props.inProgress(true);
		let functionName = "getvendors";
		let messageName = "Vendors";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	
	}
	fetchVendors() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/vendors')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Vendors</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							title={"Last Updated: " + this.state.lastUpdated}
							disableColumnMenu
							pagination
							pageSize={10}
							customcomponent={this.getRefreshButton()}
							columns={this.columns}
							getRowId={(row) => row.vendorCode + row.siteId}
							disableSelectionOnClick
						/>
					</div>
				</div>
			</div>
		);
	}
}
