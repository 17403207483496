import * as React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import DateRangeIcon from '@mui/icons-material/DateRange'
import LineStyleIcon from '@mui/icons-material/LineStyle'
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/lab/ToggleButton';
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
//import CurrencyIcon from '@mui/icons-material/LocalAtm';
import CurrencyIcon from '@mui/icons-material/AttachMoney';

export class Settings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			settings: this.props.settings
		};

		const sourceDictionary = [
			{ id: 10, name: 'Label - Page Defined' },
			{ id: 12, name: 'Value - Page Defined' },
			{ id: 14, name: 'Label - First Page' },
			{ id: 16, name: 'Value - First Page' },
			{ id: 18, name: 'Label - Any Page' },
			{ id: 20, name: 'Value - Any Page' },
			{ id: 21, name: 'Label - Any Page - Floating Top' },
			{ id: 22, name: 'Value - Any Page - Floating Top' },
			{ id: 23, name: 'Label - Any Page - Floating Left' },
			{ id: 24, name: 'Value - Any Page - Floating Left' },
			{ id: 110, name: 'Label - Any Page - Repeats' },
			{ id: 120, name: 'Value - Any Page - Repeats' },
			{ id: 26, name: 'Label - Last Page' },
			{ id: 28, name: 'Value - Last Page' },
			{ id: 30, name: 'Label-Table-Header' },
			{ id: 40, name: 'Value-Table-Header' },
			{ id: 50, name: 'Label-Table-Detail' },
			{ id: 60, name: 'Value-Table-Detail' },
			{ id: 70, name: 'Label-Table-Footer' },
			{ id: 80, name: 'Value-Table-Footer' },
			{ id: 90, name: 'Key-Value-Pair (Single)' },
			{ id: 100, name: 'Key-Value-Pair (Multi)' },
			{ id: 130, name: 'Label - Multi Value' },
			{ id: 140, name: 'Value - Multi Value' },
			{ id: 150, name: 'Label - Multi Value - Floating Top' },
			{ id: 160, name: 'Value - Multi Value - Floating Top' },
			{ id: 170, name: 'Label - Multi Value - Floating Left' },
			{ id: 180, name: 'Value - Multi Value - Floating Left' }
		];
		const fieldType = this.props.name === "vendorClassifier" ? "Label" : this.props.name.substring(this.props.name.length - 5, this.props.name.length);

		const stringFormatDictionary = [
			{ format: "[\\b\\d\\b]+", display: "Numbers Only" },
			{ format: "[\\b0-9a-zA-Z\\b]+", display: "Only Alphabets, Numbers and Space" },
			{ format: "\\b[\\w]+\\b", display: "Only Alphabets, Numbers, Space and Underscore" },
		];
		const showCurrencyFormat = [
			{ format: "###,###,###.##", display: "Format: Decimal is fractional separator, comma is thousand separator" },
			{ format: "###.###.###,##", display: "Format: Comma is fractional separator, decimal is thousand separator" },
			{ format: "### ### ###,##", display: "Format: Comma is fractional separator, space is thousand separator" },
			{ format: "### ### ###.##", display: "Format: Space is thousand separator" },
			{ format: "###'###'###,##", display: "Format: Comma is fractional separator, an apostrophe is thousand separator" },
			{ format: "###'###'###.##", display: "Format: An apostrophe is thousand separator" },
			{ format: "#########,##", display: "Format: Comma is fractional separator" },
			{ format: "#########.##", display: "Format: Decimal is fractional separator" }
		];

		const dateFormatDictionary = [
			{ format: "M d yyyy", display: "eg: 12/06/2019 | 12-06-2019 | 12.06.2019 | 12 06 2019 | 12062019 | 12/6/2019 | 12-6-2019 | 12.6.2019 | 12 6 2019" },
			{ format: "M d yy", display: "eg: 12/06/19 | 12-06-19 | 12.06.19 | 12 06 19 | 120619 | 12/6/19 | 12-6-19 | 12.6.19 | 12 6 19" },
			{ format: "MMM d yyyy", display: "eg: DEC 06, 2019 | DEC/06, 2019 | DEC.06, 2019 | DEC-06, 2019 | DEC06, 2019 | DEC 6, 2019 | DEC/6, 2019 | DEC.6, 2019 | DEC-6, 2019 | DEC06/2019 | DEC06-2019 | DEC06.2019 | DEC06 2019 | DEC062019 | DEC 06 2019 | DEC/06/2019 | DEC-06-2019 | DEC.06.2019" },
			{ format: "MMM d yy", display: "eg: DEC 06, 19 | DEC/06, 19 | DEC.06, 19 | DEC-06, 19 | DEC06, 19 | DEC 6, 19 | DEC/6, 19 | DEC.6, 19 | DEC-6, 19 | DEC06/19 | DEC06-19 | DEC06.19 | DEC06 19 | DEC0619 | DEC 06 19 | DEC/06/19 | DEC-06-19 | DEC.06.19" },
			{ format: "MMMM d yyyy", display: "eg: DECEMBER 06, 2019 | DECEMBER/06, 2019 | DECEMBER.06, 2019 | DECEMBER-06, 2019 | DECEMBER06, 2019 | DECEMBER 6, 2019 | DECEMBER/6, 2019 | DECEMBER.6, 2019 | DECEMBER-6, 2019 | DECEMBER06/2019 | DECEMBER06-2019 | DECEMBER06.2019 | DECEMBER06 2019 | DECEMBER062019 | DECEMBER 06 2019 | DECEMBER/06/2019 | DECEMBER-06-2019 | DECEMBER.06.2019" },
			{ format: "MMMM d yy", display: "eg: DECEMBER 06, 19 | DECEMBER/06, 19 | DECEMBER.06, 19 | DECEMBER-06, 19 | DECEMBER06, 19 | DECEMBER 6, 19 | DECEMBER/6, 19 | DECEMBER.6, 19 | DECEMBER-6, 19 | DECEMBER06/19 | DECEMBER06-19 | DECEMBER06.19 | DECEMBER06 19 | DECEMBER0619 | DECEMBER 06 19 | DECEMBER/06/19 | DECEMBER-06-19 | DECEMBER.06.19" },
			{ format: "d M yyyy", display: "eg: 06/12/2019 | 06-12-2019 | 06.12.2019 | 06 12 2019 | 06122019 | 6/12/2019 | 6-12-2019 | 6.12.2019 | 6 12 2019" },
			{ format: "d M yy", display: "eg: 06/12/19 | 06-12-19 | 06.12.19 | 06 12 19 | 061219 | 6/12/19 | 6-12-19 | 6.12.19 | 6 12 19" },
			{ format: "d MMM yyyy", display: "eg: 06/DEC/2019 | 06-DEC-2019 | 06.DEC.2019 | 06 DEC 2019 | 06DEC2019 | 6/DEC/2019 | 6-DEC-2019 | 6.DEC.2019 | 6 DEC 2019 | 6DEC2019" },
			{ format: "d MMM yy", display: "eg: 06/DEC/19 | 06-DEC-19 | 06.DEC.19 | 06 DEC 19 | 06DEC19 | 6/DEC/19 | 6-DEC-19 | 6.DEC.19 | 6 DEC 19 | 6DEC19" },
			{ format: "d MMMM yyyy", display: "eg: 06/DECEMBER/2019 | 06-DECEMBER-2019 | 06.DECEMBER.2019 | 06 DECEMBER 2019 | 06DECEMBER2019 | 6/DECEMBER/2019 | 6-DECEMBER-2019 | 6.DECEMBER.2019 | 6 DECEMBER 2019 | 6DECEMBER2019" },
			{ format: "d MMMM yy", display: "eg: 06/DECEMBER/19 | 06-DECEMBER-19 | 06.DECEMBER.19 | 06 DECEMBER 19 | 06DECEMBER19 | 6/DECEMBER/19 | 6-DECEMBER-19 | 6.DECEMBER.19 | 6 DECEMBER 19 | 6DECEMBER19" },
			{ format: "yyyy M d", display: "eg: 2019/12/06 | 2019-12-06 | 2019.12.06 | 2019 12 06 | 20191206 | 2019/12/6 | 2019-12-6 | 2019.12.6 | 2019 12 6" },
			{ format: "yy M d", display: "eg: 19/12/06 | 19-12-06 | 19.12.06 | 19 12 06 | 191206 | 19/12/6 | 19-12-6 | 19.12.6 | 19 12 6" },
			{ format: "yyyy MMM d", display: "eg: 2019/DEC/06 | 2019-DEC-06 | 2019.DEC.06 | 2019 DEC 06 | 2019DEC06 | 2019/DEC/6 | 2019-DEC-6 | 2019.DEC.6 | 2019 DEC 6" },
			{ format: "yy MMM d", display: "eg: 19/DEC/06 | 19-DEC-06 | 19.DEC.06 | 19 DEC 06 | 19DEC06 | 19/DEC/6 | 19-DEC-6 | 19.DEC.6 | 19 DEC 6" },
			{ format: "yyyy MMMM d", display: "eg: 2019/DECEMBER/06 | 2019-DECEMBER-06 | 2019.DECEMBER.06 | 2019 DECEMBER 06 | 2019DECEMBER06 | 2019/DECEMBER/6 | 2019-DECEMBER-6 | 2019.DECEMBER.6 | 2019 DECEMBER 6" },
			{ format: "yy MMMM d", display: "eg: 19/DECEMBER/06 | 19-DECEMBER-06 | 19.DECEMBER.06 | 19 DECEMBER 06 | 19DECEMBER06 | 19/DECEMBER/6 | 19-DECEMBER-6 | 19.DECEMBER.6 | 19 DECEMBER 6" }
		];

		const dateRangeFormats = [
			{ format: "MMM yyyy", display: "eg: DEC/2019 | DEC-2019 | DEC.2019 | DEC 2019 | DEC2019" },
			{ format: "MMM yy", display: "eg: DEC/19 | DEC-19 | DEC.19 | DEC 19 | DEC19" },
			{ format: "MMMM yyyy", display: "eg: DECEMBER/2019 | DECEMBER-2019 | DECEMBER.2019 | DECEMBER 2019 | DECEMBER2019" },
			{ format: "MMMM yy", display: "eg: DECEMBER/19 | DECEMBER-19 | DECEMBER.19 | DECEMBER 19 | DECEMBER19" },
			{ format: "M d yyyy", display: "eg: 12/01/2019 to 12/31/2019 | 12-01-2019 to 12-31-2019 | 12.01.2019 to 12.31.2019 | 12 01 2019 to 12 31 2019 | 12012019 - 12312019 | 12/01/2019 - 12/31/2019 | 12-01-2019 - 12-31-2019 | 12.01.2019 - 12.31.2019 | 12 01 2019 - 12 31 2019 | 12012019 - 12312019 | 12/01/2019 12/31/2019 | 12-01-2019 12-31-2019 | 12.01.2019 12.31.2019 | 12 01 2019 12 31 2019 | 12012019 12312019" },
			{ format: "M d yy", display: "eg: 12/01/19 to 12/31/19 | 12-01-19 to 12-31-19 | 12.01.19 to 12.31.19 | 12 01 19 to 12 31 19 | 120119 - 123119 | 12/01/19 - 12/31/19 | 12-01-19 - 12-31-19 | 12.01.19 - 12.31.19 | 12 01 19 - 12 31 19 | 120119 - 123119 | 12/01/19 12/31/19 | 12-01-19 12-31-19 | 12.01.19 12.31.19 | 12 01 19 12 31 19 | 120119 123119" },
			{ format: "MMM d yyyy", display: "eg: DEC/01/2019 to DEC/31/2019 | DEC-01-2019 to DEC-31-2019 | DEC.01.2019 to DEC.31.2019 | DEC 01 2019 to DEC 31 2019 | DEC012019 to DEC312019 | DEC/01/2019 - DEC/31/2019 | DEC-01-2019 - DEC-31-2019 | DEC.01.2019 - DEC.31.2019 | DEC 01 2019 - DEC 31 2019 | DEC012019 - DEC312019 | DEC/01/2019 DEC/31/2019 | DEC-01-2019 DEC-31-2019 | DEC.01.2019 DEC.31.2019 | DEC 01 2019 DEC 31 2019 | DEC012019 DEC312019 | DEC01/2019 to DEC31/2019 | DEC01-2019 to DEC31-2019 | DEC01.2019 to DEC31.2019 | DEC01 2019 to DEC31 2019 | DEC012019 to DEC312019 | DEC01/2019 - DEC31/2019 | DEC01-2019 - DEC31-2019 | DEC01.2019 - DEC31.2019 | DEC01 2019 - DEC31 2019 | DEC012019 - DEC312019 | DEC01/2019 DEC31/2019 | DEC-01-2019 DEC-31-2019 | DEC.01.2019 DEC.31.2019 | DEC 01 2019 DEC 31 2019 | DEC012019 DEC312019 | DEC/01, 2019 to DEC/31, 2019 | DEC01, 2019 to DEC31, 2019 | DEC-01, 2019 to DEC-31, 2019 | DEC.01, 2019 to DEC.31, 2019 | DEC 01, 2019 to DEC 31, 2019 | DEC01, 2019 to DEC31, 2019" },
			{ format: "MMM d yy", display: "eg: DEC/01/19 to DEC/31/19 | DEC01/19 to DEC31/19 | DEC-01-19 to DEC-31-19 | DEC.01.19 to DEC.31.19 | DEC 01 19 to DEC 31 19 | DEC0119 to DEC3119 | DEC/01, 19 to DEC/31, 19 | DEC01, 19 to DEC31, 19 | DEC-01, 19 to DEC-31, 19 | DEC.01, 19 to DEC.31, 19 | DEC 01, 19 to DEC 31, 19 | DEC01, 19 to DEC31, 19" },
			{ format: "MMMM d yyyy", display: "eg: DECEMBER/01/2019 to DECEMBER/31/2019 | DECEMBER01/2019 to DECEMBER31/2019 | DECEMBER-01-2019 to DECEMBER-31-2019 | DECEMBER.01.2019 to DECEMBER.31.2019 | DECEMBER 01 2019 to DECEMBER 31 2019 | DECEMBER012019 to DECEMBER312019 | DECEMBER/01, 2019 - DECEMBER/31, 2019 | DECEMBER01, 2019 - DECEMBER31, 2019 | DECEMBER-01, 2019 - DECEMBER-31, 2019 | DECEMBER.01, 2019 - DECEMBER.31, 2019 | DECEMBER 01, 2019 - DECEMBER 31, 2019 | DECEMBER01, 2019 - DECEMBER31, 2019" },
			{ format: "MMMM d yy", display: "eg: DECEMBER/01/19 to DECEMBER/31/19 | DECEMBER01/19 to DECEMBER31/19 | DECEMBER-01-19 to DECEMBER-31-19 | DECEMBER.01.19 to DECEMBER.31.19 | DECEMBER 01 19 to DECEMBER 31 19 | DECEMBER0119 to DECEMBER3119 | DECEMBER/01, 19 to DECEMBER/31, 19 | DECEMBER01, 19 to DECEMBER31, 19 | DECEMBER-01, 19 to DECEMBER-31, 19 | DECEMBER.01, 19 to DECEMBER.31, 19 | DECEMBER 01, 19 to DECEMBER 31, 19 | DECEMBER01, 19 to DECEMBER31, 19" },
			{ format: "d M yyyy", display: "eg: 01/12/2019 to 31/12/2019 | 01-12-2019 to 31-12-2019 | 01.12.2019 to 31.12.2019 | 01 12 2019 to 31 12 2019 | 01122019 - 31122019 | 01/12/2019 - 31/12/2019 | 01-12-2019 - 31-12-2019 | 01.12.2019 - 31.12.2019 | 01 12 2019 - 31 12 2019 | 01122019 - 31122019 | 01/12/2019 31/12/2019 | 01-12-2019 31-12-2019 | 01.12.2019 31.12.2019 | 01 12 2019 31 12 2019 | 01122019 31122019" },
			{ format: "d M yy", display: "eg: 01/12/19 to 31/12/19 | 01-12-19 to 31-12-19 | 01.12.19 to 31.12.19 | 01 12 19 to 31 12 19 | 011219 - 311219 | 01/12/19 - 31/12/19 | 01-12-19 - 31-12-19 | 01.12.19 - 31.12.19 | 01 12 19 - 31 12 19 | 011219 - 311219 | 01/12/19 31/12/19 | 01-12-19 31-12-19 | 01.12.19 31.12.19 | 01 12 19 31 12 19 | 011219 311219" },
			{ format: "d MMM yyyy", display: "eg: 01/DEC/2019 to 31/DEC/2019 | 01-DEC-2019 to 31-DEC-2019 | 01.DEC.2019 to 31.DEC.2019 | 01 DEC 2019 to 31 DEC 2019 | 01DEC2019 to 31DEC2019 | 01/DEC/2019 - 31/DEC/2019 | 01-DEC-2019 - 31-DEC-2019 | 01.DEC.2019 - 31.DEC.2019 | 01 DEC 2019 - 31 DEC 2019 | 01DEC2019 - 31DEC2019 | 01/DEC/2019 31/DEC/2019 | 01-DEC-2019 31-DEC-2019 | 01.DEC.2019 31.DEC.2019 | 01 DEC 2019 31 DEC 2019 | 01DEC2019 31DEC2019" },
			{ format: "d MMM yy", display: "eg: 01/DEC/19 to 31/DEC/19 | 01-DEC-19 to 31-DEC-19 | 01.DEC.19 to 31.DEC.19 | 01 DEC 19 to 31 DEC 19 | 01DEC19 to 31DEC19 | 01/DEC/19 - 31/DEC/19 | 01-DEC-19 - 31-DEC-19 | 01.DEC.19 - 31.DEC.19 | 01 DEC 19 - 31 DEC 19 | 01DEC19 - 31DEC19 | 01/DEC/19 31/DEC/19 | 01-DEC-19 31-DEC-19 | 01.DEC.19 31.DEC.19 | 01 DEC 19 31 DEC 19 | 01DEC19 31DEC19" },
			{ format: "d MMMM yyyy", display: "eg: 01/DECEMBER/2019 to 31/DECEMBER/2019 | 01-DECEMBER-2019 to 31-DECEMBER-2019 | 01.DECEMBER.2019 to 31.DECEMBER.2019 | 01 DECEMBER 2019 to 31 DECEMBER 2019 | 01DECEMBER2019 to 31DECEMBER2019 | 01/DECEMBER/2019 - 31/DECEMBER/2019 | 01-DECEMBER-2019 - 31-DECEMBER-2019 | 01.DECEMBER.2019 - 31.DECEMBER.2019 | 01 DECEMBER 2019 - 31 DECEMBER 2019 | 01DECEMBER2019 - 31DECEMBER2019 | 01/DECEMBER/2019 31/DECEMBER/2019 | 01-DECEMBER-2019 31-DECEMBER-2019 | 01.DECEMBER.2019 31.DECEMBER.2019 | 01 DECEMBER 2019 31 DECEMBER 2019 | 01DECEMBER2019 31DECEMBER2019" },
			{ format: "d MMMM yy", display: "eg: 01/DECEMBER/19 to 31/DECEMBER/19 | 01-DECEMBER-19 to 31-DECEMBER-19 | 01.DECEMBER.19 to 31.DECEMBER.19 | 01 DECEMBER 19 to 31 DECEMBER 19 | 01DECEMBER19 to 31DECEMBER19 | 01/DECEMBER/19 - 31/DECEMBER/19 | 01-DECEMBER-19 - 31-DECEMBER-19 | 01.DECEMBER.19 - 31.DECEMBER.19 | 01 DECEMBER 19 - 31 DECEMBER 19 | 01DECEMBER19 - 31DECEMBER19 | 01/DECEMBER/19 31/DECEMBER/19 | 01-DECEMBER-19 31-DECEMBER-19 | 01.DECEMBER.19 31.DECEMBER.19 | 01 DECEMBER 19 31 DECEMBER 19 | 01DECEMBER19 31DECEMBER19" },
			{ format: "yyyy M d", display: "eg: 2019/12/01 to 2019/12/31 | 2019-12-01 to 2019-12-31 | 2019.12.01 to 2019.12.31 | 2019 12 01 to 2019 12 31 | 20191201 to 20191231 | 2019/12/01 - 2019/12/31 | 2019-12-01 - 2019-12-31 | 2019.12.01 - 2019.12.31 | 2019 12 01 - 2019 12 31 | 20191201 - 20191231 | 2019/12/01 2019/12/31 | 2019-12-01 2019-12-31 | 2019.12.01 2019.12.31 | 2019 12 01 2019 12 31 | 20191201 20191231" },
			{ format: "yy M d", display: "eg: 19/12/01 to 19/12/31 | 19-12-01 to 19-12-31 | 19.12.01 to 19.12.31 | 19 12 01 to 19 12 31 | 191201 to 191231 | 19/12/01 - 19/12/31 | 19-12-01 - 19-12-31 | 19.12.01 - 19.12.31 | 19 12 01 - 19 12 31 | 191201 - 191231 | 19/12/01 19/12/31 | 19-12-01 19-12-31 | 19.12.01 19.12.31 | 19 12 01 19 12 31 | 191201 191231" },
			{ format: "yyyy MMM d", display: "eg: 2019/DEC/01 to 2019/DEC/31 | 2019-DEC-01 to 2019-DEC-31 | 2019.DEC.01 to 2019.DEC.31 | 2019 DEC 01 to 2019 DEC 31 | 2019DEC01 to 2019DEC31 | 2019/DEC/01 - 2019/DEC/31 | 2019-DEC-01 - 2019-DEC-31 | 2019.DEC.01 - 2019.DEC.31 | 2019 DEC 01 - 2019 DEC 31 | 2019DEC01 - 2019DEC31 | 2019/DEC/01 2019/DEC/31 | 2019-DEC-01 2019-DEC-31 | 2019.DEC.01 2019.DEC.31 | 2019 DEC 01 2019 DEC 31 | 2019DEC01 2019DEC31" },
			{ format: "yy MMM d", display: "eg: 19/DEC/01 to 19/DEC/31 | 19-DEC-01 to 19-DEC-31 | 19.DEC.01 to 19.DEC.31 | 19 DEC 01 to 19 DEC 31 | 19DEC01 to 19DEC31 | 19/DEC/01 - 19/DEC/31 | 19-DEC-01 - 19-DEC-31 | 19.DEC.01 - 19.DEC.31 | 19 DEC 01 - 19 DEC 31 | 19DEC01 - 19DEC31 | 19/DEC/01 19/DEC/31 | 19-DEC-01 19-DEC-31 | 19.DEC.01 19.DEC.31 | 19 DEC 01 19 DEC 31 | 19DEC01 19DEC31" },
			{ format: "yyyy MMMM d", display: "eg: 2019/DECEMBER/01 to 2019/DECEMBER/31 | 2019-DECEMBER-01 to 2019-DECEMBER-31 | 2019.DECEMBER.01 to 2019.DECEMBER.31 | 2019 DECEMBER 01 to 2019 DECEMBER 31 | 2019DECEMBER01 to 2019DECEMBER31 | 2019/DECEMBER/01 - 2019/DECEMBER/31 | 2019-DECEMBER-01 - 2019-DECEMBER-31 | 2019.DECEMBER.01 - 2019.DECEMBER.31 | 2019 DECEMBER 01 - 2019 DECEMBER 31 | 2019DECEMBER01 - 2019DECEMBER31 | 2019/DECEMBER/01 2019/DECEMBER/31 | 2019-DECEMBER-01 2019-DECEMBER-31 | 2019.DECEMBER.01 2019.DECEMBER.31 | 2019 DECEMBER 01 2019 DECEMBER 31 | 2019DECEMBER01 2019DECEMBER31" },
			{ format: "yy MMMM d", display: "eg: 19/DECEMBER/01 to 19/DECEMBER/31 | 19-DECEMBER-01 to 19-DECEMBER-31 | 19.DECEMBER.01 to 19.DECEMBER.31 | 19 DECEMBER 01 to 19 DECEMBER 31 | 19DECEMBER01 to 19DECEMBER31 | 19/DECEMBER/01 - 19/DECEMBER/31 | 19-DECEMBER-01 - 19-DECEMBER-31 | 19.DECEMBER.01 - 19.DECEMBER.31 | 19 DECEMBER 01 - 19 DECEMBER 31 | 19DECEMBER01 - 19DECEMBER31 | 19/DECEMBER/01 19/DECEMBER/31 | 19-DECEMBER-01 19-DECEMBER-31 | 19.DECEMBER.01 19.DECEMBER.31 | 19 DECEMBER 01 19 DECEMBER 31 | 19DECEMBER01 19DECEMBER31" }
		];

		this.fieldFormats = [];
		this.sources = [];
		this.currencyExchange = false;
		if (this.props.showStringFormat) {
			this.fieldFormats = stringFormatDictionary;
		} else if (this.props.showDateFormat) {
			this.fieldFormats = dateFormatDictionary;
		} else if (this.props.showDateRange) {
			this.fieldFormats = dateRangeFormats;
		} else if (this.props.showCurrencyFormat) {
			this.fieldFormats = showCurrencyFormat;
			this.currencyExchange = true;
		}

		this.sources = sourceDictionary.filter(function (item) {
			return item.name.startsWith(fieldType);
		});

		this.sources.forEach(item => {
			item.name = item.name.replace(item.name.substring(0, 5) + " - ", "");
			item.name = item.name.replace(item.name.substring(0, 5) + "-", "");
		});

		this.handleAlignment = this.handleAlignment.bind(this);
		this.handleFieldFormat = this.handleFieldFormat.bind(this);
		this.handleSource = this.handleSource.bind(this);
		this.handleFieldFormatClick = this.handleFieldFormatClick.bind(this);
		this.handleSourceClick = this.handleSourceClick.bind(this);
		this.handleDefaultValueClick = this.handleDefaultValueClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleAlignment(event, alignment) {
		var settings = this.state.settings;
		settings.alignment = alignment;

		this.setState({ settings: settings });

		this.onChange(settings);
	};

	handleFieldFormat(event) {
		var settings = this.state.settings;

		var index = parseInt(event.currentTarget.firstChild.id);
		if (index < 0) return;

		var fieldFormat = this.fieldFormats[index];
		settings.fieldFormat = fieldFormat.format;

		this.setState({ settings: settings, anchorElFieldFormat: null });

		this.onChange(settings);
	};

	handleSource(event) {
		var settings = this.state.settings;
		settings.source = parseInt(event.currentTarget.dataset.id);

		this.setState({ settings: settings, anchorElSource: null });

		this.onChange(settings);
	};

	handleFieldFormatClick(event) {
		this.setState({ anchorElFieldFormat: event.currentTarget });
	};

	handleSourceClick(event) {
		this.setState({ anchorElSource: event.currentTarget });
	};

	handleDefaultValueClick(event) {
		this.props.onDefaultValueClick();
	}

	handleClose(event) {
		this.setState({ anchorElFieldFormat: null, anchorElSource: null });
	};

	onChange(settings) {
		if (this.props.onChange) {
			this.props.onChange({ target: { name: this.props.name, tagName: "settings" } }, settings);
		}
	}

	render() {
		var that = this;

		return (
			<div>
				<Grid container alignItems="center">
					<ToggleButtonGroup
						size="small"
						value={this.state.settings.alignment}
						exclusive
						onChange={this.handleAlignment}
						aria-label="text alignment"
						style={{ margin: 2 }}
					>
						<ToggleButton value={10} aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value={20} aria-label="centered">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value={30} aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
					</ToggleButtonGroup>
					<Divider flexItem orientation="vertical" style={{ display: this.fieldFormats.length > 0 ? "" : "none" }} />
					<ToggleButtonGroup
						size="small"
						aria-label="text formatting"
						value={this.state.settings.fieldFormat}
						style={{ display: this.fieldFormats.length > 0 ? "" : "none" }}
					>
						<IconButton value="color" aria-label="Date Format" onClick={this.handleFieldFormatClick}>
							{this.props.showStringFormat ? <TextFormatIcon /> : this.currencyExchange ? <CurrencyIcon /> : <DateRangeIcon />}
							<ArrowDropDownIcon />
						</IconButton>
						<Menu
							id="fieldFormatMenu"
							anchorEl={this.state.anchorElFieldFormat}
							keepMounted
							open={Boolean(this.state.anchorElFieldFormat)}
							onClose={this.handleClose}
						>
							{
								this.fieldFormats.map(function (fieldFormat, index) {
									return (
										<Tooltip title={that.props.showStringFormat ? fieldFormat.format : fieldFormat.display} key={index}>
											<MenuItem
												key={index}
												onClick={that.handleFieldFormat}>
												<Typography key={index} id={index} variant="inherit">{that.props.showStringFormat ? fieldFormat.display : fieldFormat.format}</Typography>
												<ListItemIcon style={{ display: fieldFormat.format === that.state.settings.fieldFormat ? "" : "none" }}>
													<CheckIcon />
												</ListItemIcon>
											</MenuItem>
										</Tooltip>
									)
								})
							}
						</Menu>
					</ToggleButtonGroup>
					<Divider orientation="vertical" flexItem />
					<ToggleButtonGroup
						size="small"
						aria-label="text formatting"
						value={this.state.settings.source}
					>
						<IconButton value="color" aria-label="Field Source" onClick={this.handleSourceClick}>
							<LineStyleIcon />
							<ArrowDropDownIcon />
						</IconButton>
						<Menu
							id="sourceMenu"
							anchorEl={this.state.anchorElSource}
							keepMounted
							open={Boolean(this.state.anchorElSource)}
							onClose={this.handleClose}
						>
							{
								this.sources.map(function (source, index) {
									return (
										<MenuItem
											key={index}
											data-id={source.id}
											onClick={that.handleSource}>
											<Typography variant="inherit">{source.name}</Typography>
											<ListItemIcon style={{ display: source.id === that.state.settings.source ? "" : "none" }}>
												<CheckIcon />
											</ListItemIcon>
										</MenuItem>
									)
								})
							}
						</Menu>
					</ToggleButtonGroup>
					<Divider flexItem orientation="vertical" style={{ display: this.props.onDefaultValueClick ? "" : "none" }} />
					<ToggleButtonGroup
						size="small"
						aria-label="default value"
						value={this.state.settings.defaultValue}
						style={{ display: this.props.onDefaultValueClick ? "" : "none" }}
					>
						<IconButton value="color" aria-label="Default Value" onClick={this.handleDefaultValueClick}>
							<TextFormatIcon />
						</IconButton>
					</ToggleButtonGroup>
				</Grid>
			</div>
		);
	}
}