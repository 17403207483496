import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UpdateFavorites } from '../../store/action/favoritesAction';

class FavoriteMenu extends Component {
    constructor(props) {
        
        super(props);
        this.state = {
            faviconClass:"fav-icon-no"
        }
    }   
    //updateLeftMenu = (seletedItem) => {
    //    
    //    var leftMenu= this.updateSelectedItem(seletedItem);
    //    this.props.changeStateToReducer(leftMenu);
    //}
    //updateSelectedItem = (seletedItem) => {
    //    var favoritesMenu = [];
    //    var favorites = this.props.favorites.map(function (item) {
    //        {
    //            if (item.items != null) {
    //                item.items.map(function (child) {
    //                    if (child.id === seletedItem.id) {
    //                        child.isSelected = true;
    //                    }
    //                }, this);
    //            }
    //        }
    //    }, this);
    //    return this.props.favorites;
    //}
    filterFavorites(children, list) {
        
        children.map(function (child) 
            {
                if (child.isFavorite) {
                    list.push(child);
                }
        }, this);
}
    componentDidMount() {

    }
    render()
    {
        var favorites = [];
        var favoritesMenu = [];
        if (this.props.favorites && this.props.favorites.length > 0)
            favorites = this.props.favorites.map(function (item) {
                {
                    if (item.items != null) {
                        this.filterFavorites(item.items, favoritesMenu)
                    }
                }
            }, this);

        var favIconClass = favoritesMenu.length === 0 ? 'fav-icon-no' : 'fav-icon-yes'
        return (
            <li className="dropdown dropdown-extended dropdown-tasks dropdown-dark marginTop10" id="header_task_bar">
                <a className="dropdown-toggle" data-toggle={favoritesMenu.length === 0 ? "" : "dropdown"} data-hover="dropdown" data-close-others="true">
                    <i className={"fa fa-star-o " + favIconClass} key="tempsolution" aria-hidden="true"></i>
                </a>
                
                <ul className="dropdown-menu" style={{ display: favoritesMenu.length === 0 ? "none":"" } }>
                    <li>
                        <ul className="dropdown-menu-list" style={{ height: "250px" }} id="favorite-links">
                            {
                                favoritesMenu && favoritesMenu.length>0? favoritesMenu.map((favorite) => (
                                    <li key={favorite.id}>
                                        <Link onClick={() => { this.props.RenderHeader(favorite.path)}} to={favorite.path} >
                                            <span className="task"><span className="details">
                                                {favorite.label}</span></span>
                                        </Link>
                                    </li>
                                ), this)
                                :""
                            }
                        </ul>
                    </li>
                </ul>
            </li>
        );
    }
}
function mapStateToProps(state) {
    
    return ({
    
        favorites: state.favoriteReducer.favorites
        
    })
}
function mapDispatchToProps(dispatch) {

    return (
        {
            changeStateToReducer: (updatedValue) => {

                dispatch(UpdateFavorites(updatedValue));
            }
        }
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(FavoriteMenu);