import * as React from 'react';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { blankStringIfNullOrEmpty } from '../Common/Utilities';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import RemoveIcon from '@mui/icons-material/Remove';
import { Check } from '@mui/icons-material';

export default class EmployeesReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			userRolesLookup: {}
		};
	}

	componentDidMount() {
		this.fetchEmployees().then((response) => {
			this.props.inProgress(true);

			httpGet("/api/reports/userroles/")
				.then((response) => {
					var userRoles = response.data;
					var userRolesLookup = {};

					userRoles.map(ur => {
						userRolesLookup[ur.userRoleId] = ur.userRoleName;
					});

					this.state.data.map(e => {
						var assignedRoles = [];

						if (Object.values(userRolesLookup).length > 0) {
							for (var i = 0; i < e.apadUserRolesResource.length; i++) {
								assignedRoles.push(userRolesLookup[e.apadUserRolesResource[i].userRoleId]);
							}
						}

						e.userRoles = assignedRoles.join(", ");
					});

					this.props.inProgress(false);

					this.setState({ userRolesLookup: userRolesLookup });
				}).catch((error) => {
					this.props.inProgress(false);

					toast.error('An error occurred while fetching the records');
					console.error('There was an error!', error);
				});
		}).catch((error) => {
			this.props.inProgress(false);

			toast.error('An error occurred while fetching the records');
			console.error('There was an error!', error);
		});
	}

	fetchEmployees() {
		var that = this;

		this.props.inProgress(true);

		var promise = httpGet('/api/reports/employees/')
			.then((response) => {
				//var data = response.data;
				var data = (response.data || []).map((e, i) => { let d = { ...e, rowId: i }; return d });
				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});

		return promise;
	}

	render() {
		return (
			<div>
				<h1>Employees</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
					<EnhancedDataGrid rows={this.state.data}
						disableColumnMenu
						pagination
						pageSize={10}
						
							columns={[
								{
									headerName: 'User Group',
									field: 'userRoles',
									width: 200,
								},
								{
									headerName: 'Resource Id',
									field: 'resourceId',
									width: 200, hideable: false
								},
								{
									headerName: 'First Name',
									field: 'nameFirst',
									width: 200,
								},
								{
									headerName: 'Last Name',
									field: 'nameLast',
									width: 200,
								},
								{
									headerName: 'Name Initial',
									field: 'nameInit',
									width: 120,
								},
								{
									headerName: 'Title',
									field: 'title',
									width: 200
								},
								{
									headerName: 'Email',
									field: 'email',
									width: 200
								},
								{
									headerName: 'Login',
									field: 'loginId',
									width: 200
								},
								{
									headerName: 'Active',
									field: 'activeFlag',
									type: 'boolean',
									renderCell: (props) => {
										return (
											(props?.row?.activeFlag) ?
												<Check key={props?.row.rowId} color={'action'} /> :
												<RemoveIcon key={props?.row.rowId} color={'action'} />

										)
									},
									flex:1
								}
							

						]}
						getRowId={(row) => row.rowId}
						disableSelectionOnClick
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670,
								filtering: true,
								columnsButton: true,
								exportButton: true,
								exportAllData: true,
								exportFileName: "Employees"
							}}
					/>
				</div>
				</div>
			</div>
		);
	}
}
