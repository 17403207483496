import * as React from 'react';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

export default class Level2Resources extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.info.inProgress(true);

		let functionName = "getlevel2resource";
		let messageName = "Level 2 Resources";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.info.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidMount() {
		this.fetchLevel2Resources();
	}

	fetchLevel2Resources() {
		var that = this;

		this.props.info.inProgress(true);

		httpGet('/api/setup/level2Resources/' + this.props.info.level2Key)
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				let records = (data.records || []).map((e, i) => { let d = { ...e, rowId: i }; return d; });
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.info.inProgress(false);
			})
			.catch((error) => {
				this.props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Project Resources  {this.props.info.level2Key && <span>({this.props.info.level2Key})</span>}</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							title={"Last Updated: " + this.state.lastUpdated}
							customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: 'Resource',
									field: 'employeeName',
									flex:1
								},
								{
									headerName: 'Category',
									field: 'positionCategoryName',
									flex: 1
								},
								{
									headerName: 'Effective Date',
									field: 'effectiveDate',
									type: 'date',
									flex: 1,
									valueGetter: ({ value }) => value && new Date(value)
								}
							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
			</div>
		);
	}
}
