import React, { Component } from 'react';
import { MenuItems } from './MenuItems';
import { connect } from 'react-redux';
import { UpdateFavorites } from '../../store/action/favoritesAction';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { checkForUnAuthorizedException } from '../Common/Utilities';

class LeftMenu extends Component {
	constructor() {
		super();
		this.state = {
			//items: [],
			currClass: "",
			leftMenuItems: []
		};
	}

	rowClick = () => {
		this.setState({ currClass: "current" });
	}

	updateLeftMenu = (updatedItems) => {
		this.setState({ leftMenuItems: updatedItems });
	}

	update = () => {
		this.setState({ leftMenuItems: this.props.leftMenu });
	}

	componentDidMount() {
		const that = this;

		httpGet('/api/layout/GetAllMenu')
			.then((response) => {
				this.props.changeStateToReducer(response.data.leftMenu);
			})
			.catch((error) => {
				checkForUnAuthorizedException(that, error);
			});

		new window.gnMenu(document.getElementById('gn-menu'));
	}

	render() {
		var listItems = this.props.leftMenu.map(function (item) {
			{
				return <MenuItems items={item} setClass={this.rowClick} updateLeftMenu={this.updateLeftMenu} key={item.id} />
			}

		}, this);

		return (
			<ul id="gn-menu" className="gn-menu-main">
				<li className="gn-trigger">
					<a className="gn-icon-menu" style={{ height: "63px", paddingTop: "5px" }}><span className="fa fa-bars"></span></a>
					<div className="gn-menu-wrapper">
						<div className="gn-scroller" >
							<ul className="gn-menu">
								<div className="col-md-12 panel-group" id="accordion">
									{listItems}
								</div>
							</ul>
						</div>
					</div>
				</li>
			</ul>
		);
	}
}

function mapStateToProps(state) {
	return ({
		leftMenu: state.favoriteReducer.favorites
	})
}

function mapDispatchToProps(dispatch) {
	return ({
		changeStateToReducer: (updatedValue) => {
			dispatch(UpdateFavorites(updatedValue));
		}
	})
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);