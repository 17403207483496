//#region imports
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import { POHeaderLookup } from '../Lookups/POHeaderLookup';
import { Level2Lookup } from '../Lookups/Level2Lookup';
import { Level3Lookup } from '../Lookups/Level3Lookup';
import { CostCodesLookup } from '../Lookups/CostCodesLookup';
import { stringIsNullOrEmpty, blankStringIfNullOrEmpty, formatNumber, zeroIfNull } from '../Common/Utilities';
import { toast } from 'react-toastify';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
//import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { invoiceEditorDetailModel } from '../../Models';

//#endregion
//#region styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => {
	return {
		'& .MuiTableCell-root': {
			fontSize: '0.75rem',
			padding: '8px',
			color: 'inherit',
			border: 0,
			borderBottom: '1px solid rgba(224, 224, 224, 1)',
			cursor: 'pointer',
			margin: 0,
			outline: 0,
			padding: '0 10px 0 10px',
			userSelect: 'none',
			borderRadius: 0,
			textDecoration: 'none',
			backgroundColor: 'white',
			height: '52px'

		},
		'& .MuiTableRow-hover:hover': {
			backgroundColor: 'white !important'
		},
		'& .MuiTableCell-root .MuiInput-input,& .MuiTableCell-root select': {
			
			boxSizing:'border-box',
			color: '#414141',
			height: '34px',
			padding: '7px 10px 7px 10px',
			width: '66%',
			outline: 'none',
			border: '1px solid #c6c6c6',
			borderRadius: 6,
			transition: '0.3s ease-in-out all'
		},
		'& .MuiTableCell-root .MuiInput-input-error' : {

			border: '3px solid red !important',
		},
		'&::-webkit-scrollbar': {
			height: '0.3em',
			width: '0.2em'
		},
		'&::-webkit-scrollbar-thumb:vertical,&::-webkit-scrollbar-thumb:horizontal': {
			background: '#B2b2b2', borderRadius: 2, opacity: '0.5'
		},
		'& input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
		'& input[type=number]': {
			'-moz-appearance': 'textfield'
		}
	}
});


//TablePagination
const StyledTablePagination = styled(TablePagination)(({ theme }) => {
	return {
		'& p': {
			margin: 0
		}
	}
});

//#endregion 

//#region InvoiceDocumentEditorGrid
export default function InvoiceDocumentEditorGrid(props) {
	//#region Initializers
	//#region states
	let [state, setState] = useState({
		isDirty: false,
		openPOHeaderDialog: false,
		openLevel2Dialog: false,
		openLevel3Dialog: false,
		openCostCodesDialog: false,
		vendor: null,
		uncollapsed: true,
		openDeleteConfirmation: false,
		deleteRowData: null,
		invoiceDocument: props.invoiceDocument,
		taxes: props.taxes,
		addClickUpdate: false,
		deleteClickUpdate: false,
		openDeleteSelectedConfirmation:false
	});

	const [isGridDataValid, setIsGridDataValid] = React.useState(false);
	const [currentEditRow, setCurrentEditRow] = React.useState(null);
	const [selectedRows, setSelectedRows] = React.useState(new Set());
	const [sortedField, setSortedField] = React.useState('');
	const [orderBy, setOrderBy] = React.useState('');
	const [pageSize, setpageSize] = React.useState(5);
	const [page, setPage] = React.useState(0);
	const [searchText, setSearchText] = React.useState('');
	const [invalidRowsList, setInvalidRowsList] = React.useState(new Set());
	const [invalidRowsMessage, setInvalidRowMessage] = React.useState({});
	//#endregion


	React.useEffect(() => {
		if (props.editable) {
			validateAllRows();
		} else {
			setIsGridDataValid(true);
		}
		stateUpliftMethods.updateView();
	}, [stateUpliftMethods,
		state.invoiceDocument?.isPOCodeValid,
		state.invoiceDocument?.invoiceDocumentEditorDetails,
		state.invoiceDocument?.invoiceDocumentEditorDetails?.length,
		state.invoiceDocument?.vendor, isGridDataValid])

	React.useEffect(() => {
		stateUpliftMethods.updateView();
	}, [isGridDataValid])

	React.useEffect(() => {
		const lastPage = getLastPage();
		if (state.addClickUpdate) {
			setPage(lastPage-1);
			setState({ ...state, addClickUpdate :false})
		}
		if (state.deleteClickUpdate) {
			let p;
			if (page > lastPage - 1) {
				p = lastPage - 1;
			} else {
				p = page;
			}
			setPage(p < 0 ? 0 : p);
			setState({ ...state, deleteClickUpdate: false })
		}
	}, [state.addClickUpdate, state.deleteClickUpdate])




	const getLastPage = () => {
		const details = filteredRows;
		return Math.abs(Math.ceil(details.length / pageSize));
	}
	React.useEffect(() => {
		if (getLastPage) {
			const _lp = getLastPage() - 1;
			if (page > _lp) {
				 setPage(_lp < 0 ? 0 : _lp) }
			else { setPage(0) }
		}
	}, [pageSize])

	//#region Parent State

	React.useImperativeHandle(props.stateRef, () => {
		return {
			...state.invoiceDocument,
				invoiceDocumentEditorDetails: state.invoiceDocument?.invoiceDocumentEditorDetails
				.filter((e) => validateIfNotEmpty(e))
				.map((e, i) => { return { ...e } }), taxes: state.taxes, isDirty: state.isDirty,
			isGridDataValid: isGridDataValid,
			setDetailsOnVendorChange: setDetailsOnVendorChange,
			setDetailsOnserviceTermBlur: setDetailsOnserviceTermBlur,
			setInvoiceDetailsOnVendorChange: setInvoiceDetailsOnVendorChange,
			setDetailsOnPOChange: setDetailsOnPOChange,
			invalidRowsList: invalidRowsList,
			invalidRowsMessage: invalidRowsMessage
		}
	});

	const stateUpliftMethods = React.useMemo(() => {
		return {
			setParentState: props.setParentState, messageDialog: props.messageDialog, duplicateCheck: state.duplicateCheck, updateView: props.updateView,
			searchIONumber_clickHandler: props.searchIONumber_clickHandler
		}
	})

	

	const setDetailsOnVendorChange = React.useCallback(() => {
		const details = state.invoiceDocument.invoiceDocumentEditorDetails.map(function (detail, index) {
			detail.ioNumber = "";
			detail.poAmount = 0.00;
			detail.consumedAmount = 0.00;
			detail.deliveryAmount = 0.00;
			return detail;
		});
		setState({ ...state, invoiceDocument: { ...state.invoiceDocument, invoiceDocumentEditorDetails: details } })
	})

	const setDetailsOnserviceTermBlur = React.useCallback((poHeader) => {
		const details =  state.invoiceDocument.invoiceDocumentEditorDetails.map(function (detail, index) {
			var poHeader = poHeaders.find(poHeader => !stringIsNullOrEmpty(poHeader.poCode) && !stringIsNullOrEmpty(detail.ioNumber) && poHeader.poCode.trim() === detail.ioNumber.trim());
			if (poHeader) {
				detail.deliveryAmount = poHeader.deliveryAmount;
			}
		});
		setState({ ...state, invoiceDocument: { ...state.invoiceDocument, invoiceDocumentEditorDetails: details } })
	})

	const setInvoiceDetailsOnVendorChange = React.useCallback((parentInvoiceObject,data) => {
		if (stringIsNullOrEmpty(parentInvoiceObject.poCode)) {
			state.invoiceDocument.invoiceDocumentEditorDetails.map(function (invoiceDocumentEditorDetail, index) {
				var row = data.find(d => d.seqId === invoiceDocumentEditorDetail.seqId);
				invoiceDocumentEditorDetail.level2Key = ''; /*row.level2Key;*/
				invoiceDocumentEditorDetail.isLevel2KeyValid = 'N' /*row.isLevel2KeyValid;*/
				invoiceDocumentEditorDetail.level3Key = ''; /*row.level3Key;*/
				invoiceDocumentEditorDetail.isLevel3KeyValid = 'N' /*row.isLevel3KeyValid;*/
				invoiceDocumentEditorDetail.costType = null; /*row.costType;*/
				invoiceDocumentEditorDetail.resType = null;
				invoiceDocumentEditorDetail.rTypeName = ''; //row.rTypeName;
				invoiceDocumentEditorDetail.isCostCodesValid = 'N' /*row.isCostCodesValid;*/
				///////////////////////////////////////////////////
				invoiceDocumentEditorDetail.netCost = 0.00;
				invoiceDocumentEditorDetail.matchToDateNet = 0.00;
				invoiceDocumentEditorDetail.remainingAmount = 0.00;
				invoiceDocumentEditorDetail.poRequiredFlag = 'N';
				invoiceDocumentEditorDetail.tolerancePOFlag = 'N';
				invoiceDocumentEditorDetail.tolerancePOAmount = 0.00;

			});
		}
		else {
			if (state.invoiceDocument.invoiceDocumentEditorDetails.length === 0) {
				data.map(function (poDetail, index) {
					var invoiceDocumentEditorDetail = poDetail;
					invoiceDocumentEditorDetail.invoiceDocumentId = props.invoiceDocument?.invoiceDocumentId;
					invoiceDocumentEditorDetail.seqId = index + 1;
					invoiceDocumentEditorDetail.ioInvoiceAmount = poDetail.remainingAmount;
					state.invoiceDocument.invoiceDocumentEditorDetails.push(invoiceDocumentEditorDetail);
				});
			}
			else if (data.length === 1) {
				state.invoiceDocument.invoiceDocumentEditorDetails.map(function (invoiceDocumentEditorDetail, index) {
					invoiceDocumentEditorDetail.level2Key = data[0].level2Key;
					invoiceDocumentEditorDetail.isLevel2KeyValid = data[0].isLevel2KeyValid;
					invoiceDocumentEditorDetail.level3Key = data[0].level3Key;
					invoiceDocumentEditorDetail.isLevel3KeyValid = data[0].isLevel3KeyValid;
					invoiceDocumentEditorDetail.costType = data[0].costType;
					invoiceDocumentEditorDetail.resType = data[0].resType;
					invoiceDocumentEditorDetail.rTypeName = data[0].rTypeName;
					invoiceDocumentEditorDetail.isCostCodesValid = data[0].isCostCodesValid;
					invoiceDocumentEditorDetail.poRequiredFlag = data[0].poRequiredFlag;
					invoiceDocumentEditorDetail.tolerancePOFlag = data[0].tolerancePOFlag;
					invoiceDocumentEditorDetail.tolerancePOAmount = data[0].tolerancePOAmount;
					invoiceDocumentEditorDetail.netCost = data[0].netCost;
					invoiceDocumentEditorDetail.matchToDateNet = data[0].matchToDateNet;
					invoiceDocumentEditorDetail.remainingAmount = data[0].remainingAmount;
				});
			}
			else {
				state.invoiceDocument.invoiceDocumentEditorDetails.map(function (invoiceDocumentEditorDetail, index) {
					var verifiedRow = data.find(d => d.level2Key != null && d.level2Key === invoiceDocumentEditorDetail.level2Key &&
						d.level3Key != null && d.level3Key === invoiceDocumentEditorDetail.level3Key &&
						d.costType != null && d.costType === invoiceDocumentEditorDetail.costType &&
						d.resType != null && d.resType === invoiceDocumentEditorDetail.resType);
					if (verifiedRow != null) {
						return;
					}
					var defaultLevel2Key = "";
					var isLevel2KeyValid = 'N';
					var defaultPoRequiredFlag = 'N';
					var defaultTolerancePOFlag = 'N';
					var defaultTolerancePOAmount = 0.00;
					var defaultLevel3Key = "";
					var isLevel3KeyValid = 'N';
					var defaultCostType = null;
					var defaultResType = null;
					var defaultRTypeName = "";
					var isCostCodesValid = 'N';
					var defaultNetCost = 0.00;
					var defaultMatchToDateNet = 0.00;
					var defaultRemainingAmount = 0.00;
					var verifiedRows = data.filter(d => d.level2Key != null && d.level2Key === invoiceDocumentEditorDetail.level2Key);
					if (verifiedRows != null && verifiedRows.length > 0) {
						verifiedRow = verifiedRows[0];
						var distinctLevel2Keys = uniqueBy(verifiedRows, ["level2Key"]);
						if (distinctLevel2Keys.length === 1) {
							defaultLevel2Key = verifiedRow.level2Key;
							isLevel2KeyValid = 'Y';
							defaultPoRequiredFlag = verifiedRow.poRequiredFlag;
							defaultTolerancePOFlag = verifiedRow.tolerancePOFlag;
							defaultTolerancePOAmount = verifiedRow.tolerancePOAmount;
						}
						verifiedRows = verifiedRows.filter(idd => idd.level3Key != null && idd.level3Key === invoiceDocumentEditorDetail.level3Key);
						if (verifiedRows != null && verifiedRows.length > 0) {
							verifiedRow = verifiedRows[0];
							var distinctLevel3Keys = uniqueBy(verifiedRows, ["level3Key"]);
							if (distinctLevel3Keys.length === 1) {
								defaultLevel3Key = verifiedRow.level3Key;
								isLevel3KeyValid = 'Y';
							}
							verifiedRows = verifiedRows.filter(idd => idd.costType != null && invoiceDocumentEditorDetail.costType != null && idd.costType === invoiceDocumentEditorDetail.costType &&
								idd.resType != null && invoiceDocumentEditorDetail.resType != null && idd.resType === invoiceDocumentEditorDetail.resType);
							if (verifiedRows != null && verifiedRows.length > 0) {
								verifiedRow = verifiedRows[0];
								var distinctCostTypes = uniqueBy(verifiedRows, ["costType", "resType"]);
								if (distinctCostTypes.length === 1) {
									defaultCostType = verifiedRow.costType;
									defaultResType = verifiedRow.resType;
									defaultRTypeName = verifiedRow.rTypeName;
									isCostCodesValid = 'Y';
									defaultNetCost = verifiedRow.netCost;
									defaultMatchToDateNet = verifiedRow.matchToDateNet;
									defaultRemainingAmount = verifiedRow.remainingAmount;
								}
							}
							else {
								invoiceDocumentEditorDetail.costType = defaultCostType;
								invoiceDocumentEditorDetail.resType = defaultResType;
								invoiceDocumentEditorDetail.rTypeName = defaultRTypeName;
								invoiceDocumentEditorDetail.isCostCodesValid = isCostCodesValid;
								invoiceDocumentEditorDetail.netCost = defaultNetCost;
								invoiceDocumentEditorDetail.matchToDateNet = defaultMatchToDateNet;
								invoiceDocumentEditorDetail.remainingAmount = defaultRemainingAmount;
							}
						}
						else {
							invoiceDocumentEditorDetail.level3Key = defaultLevel3Key;
							invoiceDocumentEditorDetail.isLevel3KeyValid = isLevel3KeyValid;
							invoiceDocumentEditorDetail.costType = defaultCostType;
							invoiceDocumentEditorDetail.resType = defaultResType;
							invoiceDocumentEditorDetail.rTypeName = defaultRTypeName;
							invoiceDocumentEditorDetail.isCostCodesValid = isCostCodesValid;
							invoiceDocumentEditorDetail.netCost = defaultNetCost;
							invoiceDocumentEditorDetail.matchToDateNet = defaultMatchToDateNet;
							invoiceDocumentEditorDetail.remainingAmount = defaultRemainingAmount;
						}
					}
					else {
						invoiceDocumentEditorDetail.level2Key = defaultLevel2Key;
						invoiceDocumentEditorDetail.isLevel2KeyValid = isLevel2KeyValid;
						invoiceDocumentEditorDetail.poRequiredFlag = defaultPoRequiredFlag;
						invoiceDocumentEditorDetail.tolerancePOFlag = defaultTolerancePOFlag;
						invoiceDocumentEditorDetail.tolerancePOAmount = defaultTolerancePOAmount;
						invoiceDocumentEditorDetail.level3Key = defaultLevel3Key;
						invoiceDocumentEditorDetail.isLevel3KeyValid = isLevel3KeyValid;
						invoiceDocumentEditorDetail.costType = defaultCostType;
						invoiceDocumentEditorDetail.resType = defaultResType;
						invoiceDocumentEditorDetail.rTypeName = defaultRTypeName;
						invoiceDocumentEditorDetail.isCostCodesValid = isCostCodesValid;
						invoiceDocumentEditorDetail.netCost = defaultNetCost;
						invoiceDocumentEditorDetail.matchToDateNet = defaultMatchToDateNet;
						invoiceDocumentEditorDetail.remainingAmount = defaultRemainingAmount;
					}
				});
			}
		}
		setState({ ...state, invoiceDocument: { ...state.invoiceDocument, invoiceDocumentEditorDetails: state.invoiceDocument.invoiceDocumentEditorDetails.map(e=>e) } })
	})

	const setDetailsOnPOChange = React.useCallback((poHeader) => {
		currentEditRow.ioNumber = poHeader.poCode.trim();
		currentEditRow.poAmount = poHeader.poAmount;
		currentEditRow.consumedAmount = poHeader.consumedAmount;
		currentEditRow.deliveryAmount = poHeader.deliveryAmount;
		currentEditRow.isIONumberValid = 'Y';
		currentEditRow.isIOUpdated = true;
		currentEditRow.isDirty = true;
		//Check for tax code
		const invoiceDocument = state.invoiceDocument;
		var IOPO = invoiceDocument.invoiceDocumentEditorDetails;
		var invoiceDocumentEditorDetails = IOPO.filter(x => x.ioNumber == poHeader.poCode.trim());
		if (invoiceDocumentEditorDetails && invoiceDocumentEditorDetails.length > 0) {
			currentEditRow.taxCode = invoiceDocumentEditorDetails[0].taxCode;
			calculateTaxAmount(currentEditRow.taxCode, currentEditRow);
		}
		let _invoiceDocumentEditorDetails = state.invoiceDocument?.invoiceDocumentEditorDetails
			.map((e, i) => {
				if (e.seqId === currentEditRow.seqId) {
					return {
						...currentEditRow
					}
				}
				else if (selectedRows.has(e.seqId)) {
					calculateTaxAmount(currentEditRow.taxCode, e);
					return {
						...e, ioNumber: currentEditRow.ioNumber, poAmount: currentEditRow.poAmount, consumedAmount: currentEditRow.consumedAmount,
						deliveryAmount: currentEditRow.deliveryAmount, isIONumberValid: currentEditRow.isIONumberValid, isIOUpdated: currentEditRow.isIOUpdated,
						taxCode: currentEditRow.taxCode,isDirty:true
					}
				}
				else {
					return e;
				}
			})
		let someValid = validateAllRows(_invoiceDocumentEditorDetails) || _invoiceDocumentEditorDetails.length === 0
		setIsGridDataValid(someValid);
		stateUpliftMethods.setParentState({isDirty: true });
		setState((prev) => ({
			...prev, isDirty: true,
			invoiceDocument: { ...invoiceDocument, invoiceDocumentEditorDetails: _invoiceDocumentEditorDetails }
		}
		));
	})

	//#endregion

	//#endregion Initializers

	//#region Events

	const onTaxCodeChange = (event) => {
		let row = event.row;
		row.taxCode = event.currentTarget.value;
		calculateTaxAmount(row.taxCode, row);
	}
	const onIoInvoiceAmountBlur = (event, row) => {
		calculateTaxAmount(row.taxCode, row);
		setState((prev) => ({ ...prev, forceRender: true }));
	}


	//#endregion Events

	//#region GridEvents

	const searchIONumber_clickHandler = (event, rowProp) => {
		setCurrentEditRow(rowProp);
		stateUpliftMethods.searchIONumber_clickHandler(event);
	}
	const searchLevel2_clickHandler = (event, rowProp) => {
		setCurrentEditRow(rowProp);
		//currentEditRow = rowProp.row;
		setState((prev) => ({ ...prev, openLevel2Dialog: true }));
	}
	const searchLevel3_clickHandler = (event, rowProp) => {
		setCurrentEditRow(rowProp);
		setState((prev) => ({ ...prev, openLevel3Dialog: true }));
	}
	const searchCostCodes_clickHandler = (event, rowProp) => {
		setCurrentEditRow(rowProp);
		setState((prev) => ({ ...prev, openCostCodesDialog: true }));
	}


	
	const level2Dialog_closeHandler = (event, level2) => {
		if (level2) {
			for (let id of [currentEditRow.seqId, ...selectedRows]) {
				let index = state.invoiceDocument?.invoiceDocumentEditorDetails.findIndex((e) => e.seqId == id);
				let row = state.invoiceDocument?.invoiceDocumentEditorDetails[index];
				if (row) {
					if (row.level2Key !== level2.level2Key) {
						row.level3Key = null;
						row.costType = null;
						row.resType = null;
						row.rTypeName = null;
						row.netCost = 0.00;
						row.matchToDateNet = 0.00;
						row.remainingAmount = 0.00;
						row.isDirty = true;
						fetchLevel3(level2.level2Key, row);
					}
					row.level2Key = level2.level2Key;
					row.isLevel2KeyValid = 'Y';
					row.poRequiredFlag = level2.poRequiredFlag;
					row.tolerancePOFlag = level2.tolerancePOFlag;
					row.tolerancePOAmount = level2.tolerancePOAmount;
				}
			}

		}
		stateUpliftMethods.setParentState({ isDirty: true });
		setState((prev) => ({ ...prev, isDirty: true, openLevel2Dialog: false }));

	}
	const level3Dialog_closeHandler = React.useCallback((event, level3) => {
		if (level3) {
			for (let id of [currentEditRow.seqId, ...selectedRows]) {
				let index = state.invoiceDocument?.invoiceDocumentEditorDetails.findIndex((e) => e.seqId == id);
				let row = state.invoiceDocument?.invoiceDocumentEditorDetails[index]
				if (row.level3Key !== level3.level3Key) {
					row.resType = null;
					row.rTypeName = null;
					row.netCost = 0.00;
					row.matchToDateNet = 0.00;
					row.remainingAmount = 0.00;
					row.isDirty = true;
				}
				row.level3Key = level3.level3Key;
				row.costType = level3.costType;
				row.isLevel3KeyValid = 'Y';
				fetchCostCodes(level3.level2Key, level3.level3Key, row);
			}
		}
		setState((prev) => ({ ...prev, isDirty: true, openLevel3Dialog: false }));
	})
	const costCodesDialog_closeHandler = (event, costCodes) => {
		if (costCodes) {
			for (let id of [currentEditRow.seqId, ...selectedRows]) {
				let index = state.invoiceDocument?.invoiceDocumentEditorDetails.findIndex((e) => e.seqId == id);
				let row = state.invoiceDocument?.invoiceDocumentEditorDetails[index]
				row.resType = costCodes.resType;
				row.rTypeName = costCodes.rTypeName;
				row.isCostCodesValid = 'Y';
				row.netCost = costCodes.netCost;
				row.matchToDateNet = costCodes.matchToDateNet;
				row.remainingAmount = costCodes.remainingAmount;
				row.isDirty = true;
			}

		}
		setState((prev) => ({ ...prev, openCostCodesDialog: false }));
	}
	const getRefreshButton = () => {
		return <IconButton
			key={3}
			variant="contained"
			onClick={refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 2 }}
			disabled={state.invoiceDocument.modelDefId === 1 && !state.invoiceDocument.invoiceDocumentEditorDetails.some(d => !stringIsNullOrEmpty(d.ioNumber)) || state.invoiceDocument.modelDefId === 2 && stringIsNullOrEmpty(props.invoiceDocument?.poCode)}>
			<Tooltip key={1} title={'Refresh' }>
				<RefreshIcon key={2} />
			</Tooltip>
		</IconButton>;
	}
	const refresh_clickHandler = (event) => {
		var invoiceDocument = state.invoiceDocument;
		if (props.info.modelDefId === 1) {
			const ioNumbers = invoiceDocument.invoiceDocumentEditorDetails.map(item => item.ioNumber);
			const distinctIoNumbers = [...new Set(ioNumbers)];
			distinctIoNumbers.map(function (ioNumber) {
				if (!stringIsNullOrEmpty(ioNumber)) {
					getUpdatedData(null, ioNumber);
				}
			});
		}
		else if (props.info.modelDefId === 2 && !stringIsNullOrEmpty(props.poCode)) {
			getUpdatedData(null, props.poCode);
		}
	}

	// custom grid buttons 
	const getAddButton = React.useCallback(() => {
		return <IconButton
			key={1 }
			variant="contained"
			onClick={handleAddClick}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 2 }}
		>
			<Tooltip key={1} title={'Add' } >
				<AddBoxIcon key={ 2} />
			</Tooltip>
			
		</IconButton>;
	});
	const getDeleteButton = React.useCallback(() => {
		return <IconButton
			key={2}
			variant="contained"
			color="inherit"
			onClick={handleDeleteSelectedClick}
			size="small"
			disabled={selectedRows.size === 0}
			style={{ float: "right", zIndex: 1, marginTop: 2 }}
		>
			<Tooltip key={1} title={'Delete'}>
				<DeleteIcon key={2} />
			</Tooltip>

		</IconButton>;
	});
	// custom grid buttons

	const handleAddClick = React.useCallback(() => {
		const invoiceDocumentDetails = state.invoiceDocument?.invoiceDocumentEditorDetails;
		const id = ([...invoiceDocumentDetails]?.sort((a, b) => b.seqId - a.seqId)[0]?.seqId + 1) || 1;
		let model = invoiceEditorDetailModel();
		model.seqId = id;
		model.isNew = true;
		model.companyCode = state.invoiceDocument?.companyCode;
		model.invoiceDocumentId = state.invoiceDocument?.invoiceDocumentId;
		stateUpliftMethods.setParentState({ isDirty: true });
		setState((prev) => ({
			...prev, isDirty: true,
			addClickUpdate:true,
			invoiceDocument: { ...prev.invoiceDocument, invoiceDocumentEditorDetails: [...prev.invoiceDocument.invoiceDocumentEditorDetails, model] }
		}));
	});
	const handleDeleteSelectedClick = React.useCallback(() => {
		stateUpliftMethods.setParentState({ isDirty: true });
		setState((prev) => ({ ...prev, openDeleteSelectedConfirmation: true}));
	});
	//custom grid buttons events

	const closeDeleteDialog = React.useCallback((event) => {
		setState((prev) => ({ ...prev, openDeleteConfirmation: false, deleteRowData: null }));
	})
	const closeDeleteSelectedDialog = React.useCallback((event) => {
		setState((prev) => ({ ...prev, openDeleteSelectedConfirmation: false }));
	})

	const deleteRecord = React.useCallback(() => {
		let data = state.deleteRowData;
		stateUpliftMethods.setParentState({ isDirty: true });
		setSelectedRows(new Set([...selectedRows].filter((e, i) => e !== data.seqId)))
		setState((prev) => ({
			...prev, invoiceDocument: {
				...prev.invoiceDocument,
				invoiceDocumentEditorDetails: state.invoiceDocument.invoiceDocumentEditorDetails.filter((e, i) => e.seqId !== data.seqId)
			}, openDeleteConfirmation: false, deleteRowData: null, deleteClickUpdate:true
		}));
	})

	const deleteSelectedRecord = React.useCallback(() => {
		stateUpliftMethods.setParentState({ isDirty: true });
		setState((prev) => ({
			...prev, invoiceDocument: {
				...prev.invoiceDocument,
				invoiceDocumentEditorDetails: state.invoiceDocument.invoiceDocumentEditorDetails.filter((e, i) => !selectedRows.has(e.seqId))
			}, openDeleteSelectedConfirmation: false, deleteRowData: null, deleteClickUpdate: true
		}));
		setSelectedRows(new Set([...selectedRows].filter((e, i) => !selectedRows.has(e))))
	})

	//#endregion GridEvents

	//#region Validations
	const groupAndSum = (arr, groupKeys, sumKeys) => {
		return Object.values(
			arr.reduce((acc, curr) => {
				const group = groupKeys.map(k => curr[k]).join('-');
				acc[group] = acc[group] || Object.fromEntries(groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])));
				sumKeys.forEach(k => acc[group][k] += parseFloat(curr[k]));
				return acc;
			}, {})
		);
	}
	const POCodeNotEmptyAndValid = () => {
		return (!stringIsNullOrEmpty(props.poCode) && props?.invoiceDocument?.isPOCodeValid === 'Y')
	}
	const IONumberValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument.modelDefId === 1 &&
			(stringIsNullOrEmpty(rowData.ioNumber) || (!stringIsNullOrEmpty(rowData.ioNumber) && rowData.isIONumberValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const Level2KeyValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.level2Key) || (!stringIsNullOrEmpty(rowData.level2Key) && rowData.isLevel2KeyValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const Level3KeyValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.level3Key) || (!stringIsNullOrEmpty(rowData.level3Key) && rowData.isLevel3KeyValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const CostCodesValid = (rowData) => {
		var isValid = true;
		if (state.invoiceDocument.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.resType) || (!stringIsNullOrEmpty(rowData.resType) && rowData.isCostCodesValid !== 'Y'))) {
			isValid = false;
		}
		return isValid;
	}
	const TaxCodeValid = (newData) => {
		var isValid = true;
		const invoiceDocument = state.invoiceDocument;
		if (invoiceDocument && invoiceDocument.invoiceDocumentEditorDetails) {
			var IOPO = invoiceDocument.invoiceDocumentEditorDetails;
			var invoiceDocumentEditorDetails = IOPO.filter(x => x.ioNumber == newData.ioNumber && x.taxCode != newData.taxCode);
			if (invoiceDocumentEditorDetails && invoiceDocumentEditorDetails.length > 0) {
				isValid = false;
			}
		}
		return isValid;
	}
	function updateTaxCode(newData, invoiceDocument) {
		//const invoiceDocument = this.state.invoiceDocument;
		var IOPO = invoiceDocument.invoiceDocumentEditorDetails;
		var invoiceDocumentEditorDetails = IOPO.filter(x => x.ioNumber == newData.ioNumber && x.taxCode != newData.taxCode);
		for (const item of invoiceDocumentEditorDetails) {
			item.taxCode = newData.taxCode;
			calculateTaxAmount(newData.taxCode, item);
		}
		setState({ ...state });
	}
	const InvoiceAmountValid = (rowData) => {
		var isValid = false;
		// -ve values are also acceptable in invoice details
		if (state.invoiceDocument.modelDefId === 2) {
			isValid = rowData.ioInvoiceAmount != 0;
			if (isValid) {
				if (!stringIsNullOrEmpty(props.invoiceDocument?.poCode)) {
					var invoiceDocumentEditorDetails = JSON.parse(JSON.stringify(state.invoiceDocument.invoiceDocumentEditorDetails));
					var index = invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId);
					if (index < 0) {
						invoiceDocumentEditorDetails.push(JSON.parse(JSON.stringify(rowData)));
					} else {
						invoiceDocumentEditorDetails[index] = JSON.parse(JSON.stringify(rowData));
					}
					invoiceDocumentEditorDetails[invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId)].ioInvoiceAmount = Number(Number(rowData.ioInvoiceAmount).toFixed(2));
					var groupedInvoiceDocumentDetails = groupAndSum(invoiceDocumentEditorDetails, ['level2Key', 'tolerancePOFlag', 'tolerancePOAmount', 'level3Key', 'resType', 'remainingAmount'], ['ioInvoiceAmount']);
					var groupedFilteredOnKeyFields = groupedInvoiceDocumentDetails.filter(d => d.level2Key === rowData.level2Key && d.level3Key === rowData.level3Key && d.resType === rowData.resType);
					isValid = !(groupedFilteredOnKeyFields.some(d => {
						var isInvoiceAmountInvalid = Number(zeroIfNull(parseFloat(d.ioInvoiceAmount || 0)).toFixed(2)) > Number(zeroIfNull(d.remainingAmount).toFixed(2));
						if (isInvoiceAmountInvalid) {
							if (d.tolerancePOFlag === 'N') {
								isInvoiceAmountInvalid = Number(zeroIfNull(parseFloat(d.ioInvoiceAmount || 0)).toFixed(2)) > Number((zeroIfNull(d.remainingAmount) + zeroIfNull(d.tolerancePOAmount)).toFixed(2));
							}
							else {
								var percentRemainingAmount = (zeroIfNull(d.tolerancePOAmount) * zeroIfNull(d.remainingAmount)) / 100.00;
								isInvoiceAmountInvalid = Number(Number(parseFloat(d.ioInvoiceAmount || 0)).toFixed(2)) > Number((d.remainingAmount + percentRemainingAmount).toFixed(2));
							}
						}
						return isInvoiceAmountInvalid;
					}));
				}
			}
		}
		else {
			isValid = rowData.ioInvoiceAmount != 0;
			if (isValid) {
				var invoiceDocumentEditorDetails = JSON.parse(JSON.stringify(state.invoiceDocument.invoiceDocumentEditorDetails));
				var index = invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId);
				if (index < 0) {
					invoiceDocumentEditorDetails.push(JSON.parse(JSON.stringify(rowData)));
				} else {
					invoiceDocumentEditorDetails[index] = JSON.parse(JSON.stringify(rowData));
				}
				invoiceDocumentEditorDetails[invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId)].ioInvoiceAmount = Number(Number(rowData.ioInvoiceAmount).toFixed(2));
				var groupedInvoiceDocumentDetails = groupAndSum(invoiceDocumentEditorDetails, ['ioNumber', 'poAmount', 'consumedAmount'], ['ioInvoiceAmount']);
				var groupedFilteredOnKeyFields = groupedInvoiceDocumentDetails.filter(d => d.ioNumber === rowData.ioNumber);
				isValid = !(groupedFilteredOnKeyFields.some(d => {
					var remainingAmount = (Number(zeroIfNull(d.poAmount).toFixed(2)) - Number(zeroIfNull(d.consumedAmount).toFixed(2)));
					var isInvoiceAmountInvalid = Number(Number(zeroIfNull(parseFloat(d.ioInvoiceAmount || 0))).toFixed(2)) > Number(remainingAmount.toFixed(2));
					return isInvoiceAmountInvalid;
				}));
			}
		}
		return isValid;
	}

	//#endregion Validations

	//#region APICalls


	const getUpdatedData = (row, poCode) => {
		var invoiceDocument = state.invoiceDocument;
		props.info.inProgress(true);
		httpGet("/api/invoicedocument/refetchpoinfo/" + props.info.modelDefId + '/' + poCode)
			.then((response) => {
				var data = response.data;
				if (props.info.modelDefId === 1) {
					invoiceDocument.invoiceDocumentEditorDetails.forEach(item => {
						if (!stringIsNullOrEmpty(item.ioNumber) && item.ioNumber.trim() === data.poCode.trim()) {
							item.poAmount = data.poAmount;
							item.consumedAmount = data.consumedAmount;
							item.deliveryAmount = data.deliveryAmount;
							item.isIONumberValid = 'Y';
							item.isIOUpdated = true;
						}
					});
					var item = currentEditRow;
					if (item && !stringIsNullOrEmpty(item.ioNumber) && item.ioNumber.trim() === data.poCode.trim()) {
						item.poAmount = data.poAmount;
						item.consumedAmount = data.consumedAmount;
						item.deliveryAmount = data.deliveryAmount;
						item.isIONumberValid = 'Y';
						item.isIOUpdated = true;
					}
				}
				else {
					invoiceDocument.invoiceDocumentEditorDetails.forEach(item => {
						item.netCost = data.poAmount;
						item.matchToDateNet = data.consumedAmount;
						item.remainingAmount = data.poAmount - data.consumedAmount;
						item.isPOCodeValid = 'Y';
					});
					var item = currentEditRow;
					if (item) {
						item.poAmount = data.poAmount;
						item.consumedAmount = data.consumedAmount;
						item.remainingAmount = data.poAmount - data.consumedAmount;
						item.isPOCodeValid = 'Y';
					}
				}
				props.info.inProgress(false);
				setState((prev) => ({
					...prev, invoiceDocument: {
						...prev.invoiceDocument,
						invoiceDocumentEditorDetails: invoiceDocument.invoiceDocumentEditorDetails.map(e=>e)
					}
				}));
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error('Error fetching IO/PO info');
			});
	}


	const fetchLevel3 = (level2Key, row) => {
		var poCode = stringIsNullOrEmpty(props.invoiceDocument?.poCode) ? "" : "/" + props.invoiceDocument?.poCode;
		props.info.inProgress(true);
		httpGet("/api/lookup/level3list/" + level2Key + poCode)
			.then((response) => {
				var data = response.data;
				if (data.length === 1) {
					row.level3Key = data[0].level3Key;
					row.costType = data[0].costType;
					row.isLevel3KeyValid = 'Y';
					updateStateOnCallback();
					fetchCostCodes(row.level2Key, row.level3Key, row);
				}
				props.info.inProgress(false);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error('Error fetching Activity');
			});
	}
	const fetchCostCodes = (level2Key, level3Key, row) => {
		var poCode = stringIsNullOrEmpty(props.invoiceDocument?.poCode) ? "" : "/" + props.invoiceDocument?.poCode;
		props.info.inProgress(true);
		httpGet("/api/lookup/costcodeslist/" + level2Key + "/" + level3Key + poCode)
			.then((response) => {
				var data = response.data;
				if (data.length === 1) {
					row.resType = data[0].resType;
					row.rTypeName = data[0].rTypeName;
					row.isCostCodesValid = 'Y';
					row.netCost = data[0].netCost;
					row.matchToDateNet = data[0].matchToDateNet;
					row.remainingAmount = data[0].remainingAmount;
					updateStateOnCallback();
				}
				props.info.inProgress(false);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error('Error fetching Expense Type/Cost Codes');
			});
	}

	const updateStateOnCallback = React.useCallback(() => {
		let details = state.invoiceDocument?.invoiceDocumentEditorDetails;
		let someValid = validateAllRows(details) || details.length === 0
		stateUpliftMethods.setParentState({ isGridDataValid: someValid, isDirty: true });
		setIsGridDataValid(someValid);
		setState({ ...state, isDirty: true, openLevel2Dialog: false, openLevel3Dialog: false });
	})

	//#endregion APICalls
	//#region validation
	const setValidationonBlur = React.useCallback((row, notify) => {
		//if (validation(row, notify).isValid) {
		//	setIsGridDataValid(true)
		//}
		//else { validateAllRows(); };
		validateAllRows();
		stateUpliftMethods.setParentState({ isDirty: true })
		
	});
	const validateAllRows = React.useCallback((invoiceDocumentEditorDetails) => {
		let details = (invoiceDocumentEditorDetails || state.invoiceDocument?.invoiceDocumentEditorDetails).filter((e, i) => validateIfNotEmpty(e));
		let invalidRows = [];
		let message = {};
		details.forEach(e => {
			if (validateIfNotEmpty(e)) {
				let validationResponse = validation(e);
				if (validationResponse.isValid) {
					return true;
				}
				else {
					invalidRows.push(e.seqId);
					message[e.seqId] = validationResponse.details;
					return false;
				}
			}
		});
		let someValid = invalidRows.length == 0 && details.length !== 0;
		setInvalidRowsList((prev) => new Set([...invalidRows]));
		setInvalidRowMessage(message);
		if (invoiceDocumentEditorDetails) { return someValid }
		else {
			setIsGridDataValid(someValid);
			return false;
		}

	})
	const validation = React.useCallback((row, notify) => {
		let isValid = false;
		let msg = '';
		let type = '';
		if (state.invoiceDocument.modelDefId !== 2) {
			var isTaxCodeValid = TaxCodeValid(row);
			if (InvoiceAmountValid(row)) {
				var invoiceDocument = state.invoiceDocument;
				row.quantity = isNaN(parseInt(row.quantity, 10)) ? row.quantity: parseInt(row.quantity, 10);
				row.ioInvoiceAmount = isNaN(parseInt(row.ioInvoiceAmount, 10)) ? row.ioInvoiceAmount: parseFloat(row.ioInvoiceAmount);
				if (!isTaxCodeValid) {
					{ notify && validateIfNotEmpty(row) && toast.success("All IO Line(s) with same PO Code updated with new Tax."); }
					updateTaxCode(row, invoiceDocument);
					setState({ ...state });
				}
				isValid =true;
			}
			else {
				msg = 'Invoice Net Amount should be less than or equal to remaining amount: ' + formatNumber(row.poAmount - row.consumedAmount);
				type = '1';
				{ notify && validateIfNotEmpty(row) && toast.error(msg); }
			}
		}
		else {
			let chkAllValid = props.info.mode !== "MakeCorrections" || props.originalInvoiceDocumentStatus > 40;
			if (row.ioInvoiceAmount !== 0) {

				if (InvoiceAmountValid(row)) {
					var invoiceDocument = state.invoiceDocument;
					row.costType = zeroIfNull(parseInt(row.costType, 10));
					row.resType = zeroIfNull(parseInt(row.resType, 10));
					row.quantity = isNaN(zeroIfNull(parseInt(row.quantity, 10))) ? row.quantity : zeroIfNull(parseInt(row.quantity, 10));
					row.ioInvoiceAmount = isNaN(parseFloat(row.ioInvoiceAmount)) ? row.ioInvoiceAmount : parseFloat(row.ioInvoiceAmount);
					isValid = true;
				}
				else {
					type = '1';
					{
						msg = 'Invoice Amount should be less than or equal to remaining amount';
						notify && validateIfNotEmpty(row) && toast.error(msg);
					}
				}
				if (isValid) {
					if (row.quantity <= 0) {
						type = '2'
						msg = 'Invoice Quantity should be greater than 0';
						{
							notify && validateIfNotEmpty(row) && toast.error(msg)
						}
						isValid = false;
					}
					
				}
			}
			else {
				msg = 'Invoice Amount should be greater than 0';
				type = '1';
				{
					notify && validateIfNotEmpty(row) && toast.error(msg)
				}
			}
		}
		return {
			isValid: isValid, details: {errorMsg:msg,type : type}};
	})
	//check if all field are defaul or not
	const validateIfNotEmpty = React.useCallback((row) => {
		let isValid = false;
		if (state.invoiceDocument.modelDefId !== 1) {
			isValid = parseInt(row.quantity || '0', 10) !== 0 ||
				parseFloat(row.ioInvoiceAmount || '0') !== 0 ||
				row.taxCode
				|| row.level2Key
				|| row.level3Key
				|| row.rTypeName
				|| row.ioDescription?.trim()
		} else {
			isValid = parseFloat(row.ioInvoiceAmount||'0') !== 0 ||
				row.ioNumber ||
				row.taxCode ||
				parseFloat(row.remainingAmount || '0') !== 0 ||
				parseFloat(row.netCost || '0') !== 0 ||
				row.ioDescription?.trim()
			
		}
		return isValid

	})
	//#endregion

	//#region renderer

	const uniqueBy = (arr, keyProps) => {
		const kvArray = arr.map(entry => {
			const key = keyProps.map(k => entry[k]).join('|');
			return [key, entry];
		});
		const map = new Map(kvArray);
		return Array.from(map.values());
	}

	const calculateTaxAmount = (taxCode, row) => {
		row.taxAmount = 0.00;
		row.invoiceDocumentDetailTaxes = [];
		if (!stringIsNullOrEmpty(taxCode)) {
			var taxes = state.invoiceDocument.taxes.filter(t => t.taxCode === taxCode);
			taxes.forEach(tax => {
				row.invoiceDocumentDetailTaxes.push(
					{
						companyCode: row.companyCode,
						invoiceDocumentId: row.invoiceDocumentId,
						seqId: row.seqId,
						taxCode: tax.taxCode,
						taxTypeCode: tax.taxTypeCode,
						taxPrc: tax.otherTaxAmt,
						taxAmount: isNaN(row.ioInvoiceAmount) ? 0.00 : row.ioInvoiceAmount * (tax.otherTaxAmt / 100)
					});
			});
			if (row.invoiceDocumentDetailTaxes) {
				row.taxAmount = row.invoiceDocumentDetailTaxes.map(item => item.taxAmount).reduce((prev, next) => prev + next);				
			}
		}
		stateUpliftMethods.updateView();
	}
	

	var info = props.info;
	info.openDialog = state.openVendorDialog;

	info.invoiceModel = { vendorCode: props.invoiceDocument?.vendorCode, siteId: props.invoiceDocument?.siteId, currencyCode: "" };

	const level2Info = {
		inProgress: props.info.inProgress,
		openDialog: state.openLevel2Dialog,
		poCode: blankStringIfNullOrEmpty(props.invoiceDocument?.poCode),
		noPoFlag: props.invoiceDocument?.noPoFlag,
		level2Key: currentEditRow ? currentEditRow.level2Key : null
	};
	const level3Info = {
		inProgress: props.info.inProgress,
		openDialog: state.openLevel3Dialog,
		level2Key: currentEditRow ? currentEditRow.level2Key : null,
		poCode: blankStringIfNullOrEmpty(props.invoiceDocument?.poCode),
		level3Key: currentEditRow ? currentEditRow.level3Key : null
	};
	const costCodesInfo = {
		inProgress: props.info.inProgress,
		openDialog: state.openCostCodesDialog,
		level2Key: currentEditRow ? currentEditRow.level2Key : null,
		level3Key: currentEditRow ? currentEditRow.level3Key : null,
		poCode: blankStringIfNullOrEmpty(props.invoiceDocument?.poCode),
		resType: currentEditRow ? currentEditRow.resType : null
	};
	const cellStyle = { paddingLeft: 8, paddingRight: 28, textAlign: "right" };
	
	const cellEditable = props.editable;
	//#endregion renderer

	//#region columns
	const headCells = React.useMemo(() => {
		return [
			{
				headerName: 'IO/PO Number',
				field: 'ioNumber',
				cellStyle: { padding: 8 },
				editable: cellEditable,
				resizable: false,
				width: 150,
				renderCell: props => {
					if (cellEditable)
						return <Grid key={0} container className="iconContainer">
						<Grid key={0} item xs={9} style={{ wordBreak: 'break-word', alignSelf: 'center' }}>
							{props.row.ioNumber} <span style={{ color: IONumberValid(props.row) ? "" : "red", display: IONumberValid(props.row) ? "none" : "" }}>*</span>
						</Grid>
						<Grid key={1} item xs={3} style={{ alignSelf: 'center' }}>
							<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={(e) => {
								searchIONumber_clickHandler(e, props.row);
							}}>
								<SearchIcon />
							</IconButton>
						</Grid>
					</Grid>

					return <div xs={8}>
						<div>{props.row.ioNumber} <span style={{ color: IONumberValid(props.row) ? "" : "red", display: IONumberValid(props.row) ? "none" : "" }}>*</span></div>
					</div>
				},
				renderEditCell: props => {
					return <Grid key={0} container className="iconContainer">
						<Grid key={0} item xs={9} style={{ wordBreak: 'break-word', alignSelf: 'center' } }>
							{props.value} <span style={{ color: IONumberValid(props.row) ? "" : "red", display: IONumberValid(props.row) ? "none" : "" }}>*</span>
						</Grid>
						<Grid key={1} item xs={3} style={{ alignSelf:'center' }}>
							<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={(e) => {
								searchIONumber_clickHandler(e, props.row);
							}}>
								<SearchIcon />
							</IconButton>
						</Grid>
					</Grid>
				}
			},
			{
				headerName: 'IO/PO Description',
				field: 'ioDescription',
				cellStyle: { padding: 8 },
				editable: cellEditable, resizable: false,
				width: 250,
				renderEditCell: props => {
					var v = '';
					if (props && props.value)
						v = props.value;
					return <div style={{ fontSize: 13, width: 230 }}>
						<input
							type="text"
							aria-invalid="false"
							placeholder="IO/PO Description"
							value={v || ''}
							className="MuiInputBase-input MuiInput-input"
							style={{ width: 230 }}
							onChange={(event) => {
								const newValue = event.target.value;
								stateUpliftMethods.setParentState({ isDirty: true });
								stateUpliftMethods.updateView();
								setState({
									...state, isDirty: true, invoiceDocument:
									{
										...state.invoiceDocument,
										invoiceDocumentEditorDetails: state.invoiceDocument?.invoiceDocumentEditorDetails
											.map((e, i) => { if (e.seqId === props.id) { return { ...e, [props.field]: newValue } } else return e; })
									}
								})
							}
							}
						/>
					</div>
				}
			},
			{
				headerName: 'Project',
				field: 'level2Key',
				cellStyle: { padding: 8 },
				defaultGroupOrder: -1,
				editable: cellEditable, resizable: false, width: 150,
				renderCell: props => {
					if (cellEditable) {
						return <Grid key={0} container className="iconContainer">
							<Grid key={0} item xs={9} style={{ wordBreak: 'break-word', alignSelf: 'center' }}>
								{props.value} <span style={{ color: Level2KeyValid(props.row) ? "" : "red", display: Level2KeyValid(props.row) ? "none" : "" }}>*</span>
							</Grid>
							<Grid key={1} item xs={3} style={{ alignSelf: 'center' }}>
								<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={(e) => {
									searchLevel2_clickHandler(e, props.row);
								}}>
									<SearchIcon />
								</IconButton>
							</Grid>
						</Grid>
					}
					return <div style={{ wordBreak: 'break-word', alignSelf: 'center' }}>{props.row.level2Key} <span style={{ color: Level2KeyValid(props.row) ? "" : "red", display: Level2KeyValid(props.row) ? "none" : "" }}>*</span></div>
				},
				renderEditCell: props => {
					return <Grid key={0} container className="iconContainer">
						<Grid key={0} item xs={9} style={{ wordBreak: 'break-word', alignSelf: 'center' }}>
							{props.value} <span style={{ color: Level2KeyValid(props.row) ? "" : "red", display: Level2KeyValid(props.row) ? "none" : "" }}>*</span>
						</Grid>
						<Grid key={1} item xs={3} style={{ alignSelf: 'center' }}>
							<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={(e) => {
								searchLevel2_clickHandler(e, props.row);
							}}>
								<SearchIcon />
							</IconButton>
						</Grid>
					</Grid>
				}
			},
			{
				headerName: 'Activity',
				field: 'level3Key',
				cellStyle: { padding: 8 },
				defaultGroupOrder: -1, editable: cellEditable, resizable: false, width: 150,
				renderCell: props => <div style={{ wordBreak: 'break-word', alignSelf: 'center' }}>{props.row.level3Key} <span style={{ color: Level3KeyValid(props.row) ? "" : "red", display: Level3KeyValid(props.row) ? "none" : "" }}>*</span></div>,
				renderEditCell: props => {
					return <Grid key={0} container className="iconContainer">
						<Grid key={0} item xs={9} style={{ wordBreak: 'break-word', alignSelf: 'center' }}>
							{props.value} <span style={{ color: Level3KeyValid(props.row) ? "" : "red", display: Level3KeyValid(props.row) ? "none" : "" }}>*</span>
						</Grid>
						<Grid key={1} item xs={3} style={{ alignSelf: 'center' }}>
							<IconButton value="color" aria-label="Field Source" style={{ display: Level2KeyValid(props.row) ? "" : "none" }} className="textAlignmentIcon" onClick={(e) => {
								searchLevel3_clickHandler(e, props.row);
							}}>
								<SearchIcon />
							</IconButton>
						</Grid>
					</Grid>
				}
			},
			{
				headerName: 'Expense Type',
				field: 'rTypeName',
				cellStyle: { padding: 8 },
				defaultGroupOrder: -1,
				editable: cellEditable, resizable: false,
				width: 150,
				renderCell: props => <div style={{ wordBreak: 'break-word', alignSelf: 'center' }}>{props.row.rTypeName} <span style={{ color: CostCodesValid(props.row) ? "" : "red", display: CostCodesValid(props.row) ? "none" : "" }}>*</span></div>,
				renderEditCell: props => {
					//currentEditRow = props.row;
					return <Grid key={0} container className="iconContainer">
						<Grid key={0} item xs={9} style={{ wordBreak: 'break-word', alignSelf: 'center' }}>
							{props.value} <span style={{ color: CostCodesValid(props.row) ? "" : "red", display: CostCodesValid(props.row) ? "none" : "" }}>*</span>
						</Grid>
						<Grid key={1} item xs={3} style={{ alignSelf: 'center' }}>
							<IconButton value="color" aria-label="Field Source"  style={{ display: Level3KeyValid(props.row) ? "" : "none" }} className="textAlignmentIcon" onClick={(e) => {
								searchCostCodes_clickHandler(e, props.row);
							}}>
								<SearchIcon />
							</IconButton>
						</Grid>
					</Grid>
				}
			},
			{
				headerName: 'Quantity',
				field: 'quantity',
				type: 'numeric',
				align: 'right', headerAlign: 'right', width: 110,
				headerStyle: {
					paddingRight: 15,
				},
				renderCell: rowData => <div>{rowData.row.quantity} <span style={{ color: "", display: "none" }}>*</span></div>,
				renderEditCell: props => {
					return (<div >
						<input
							type="number"
							aria-invalid="false"
							placeholder={'Quantity'}
							value={props.value}
							className={`MuiInputBase-input MuiInput-input${validateIfNotEmpty(props.row) && props?.value <= 0 ? ' MuiInput-input-error' : ''}`}
							//className={`MuiInputBase-input MuiInput-input`}
							style={{ fontSize: 13, textAlign: 'right', width: '100%' }}
							onBlur={e => { setValidationonBlur(props.row, true); }}
							onChange={(event) => {
								const newValue = event.target.value;
								let value = newValue;
								stateUpliftMethods.setParentState({ isDirty: true });
								stateUpliftMethods.updateView();
								//if (isNaN(newValue) || parseInt(newValue) < 0) {
								//	value = ''
								//};
								setState({
									...state, isDirty: true, invoiceDocument:
									{
										...state.invoiceDocument,
										invoiceDocumentEditorDetails: state.invoiceDocument?.invoiceDocumentEditorDetails
											.map((e, i) => { return (e.seqId === props.id) ? { ...e, [props.field]: value,isDirty:true } : e })
									}
								})
							}
							}
						/>
					</div>)
				}
			},
			{
				headerName: state.invoiceDocument.modelDefId === 2 ? 'Amount' : 'Invoice Net Amount',
				field: 'ioInvoiceAmount',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.ioInvoiceAmount),
				renderEditCell: props => {
					return (<div >
						<input
							type="number"
							aria-invalid="false"
							placeholder={state.invoiceDocument.modelDefId === 2 ? 'Amount' : 'Invoice Net Amount'}
							value={props.value}
							className={`MuiInputBase-input MuiInput-input ${invalidRowsList.has(props.row.seqId) && invalidRowsMessage[props.row.seqId]?.type === '1' ? 'MuiInput-input-error':''}`}
							//className={`MuiInputBase-input MuiInput-input`}
							style={{ fontSize: 13, textAlign: 'right', width: '100%' }}
							onBlur={e => { onIoInvoiceAmountBlur(e, props.row); setValidationonBlur(props.row, true); }}
							onChange={(event) => {
								const newValue = event.target.value;
								let nValue = newValue.trim();
								//nValue = (isNaN(nValue) && nValue) || parseFloat(nValue) || 0;
								stateUpliftMethods.setParentState({ isDirty: true });
								stateUpliftMethods.updateView();
								setState({
									...state, isDirty: true, invoiceDocument:
									{
										...state.invoiceDocument,
										invoiceDocumentEditorDetails: state.invoiceDocument?.invoiceDocumentEditorDetails
											.map((e, i) => { return (e.seqId === props.id) ? { ...e, [props.field]: nValue,isDirty:true } : e })
									}
								})
							}
							}
						/>
					</div>)
				},
			},
			{
				headerName: 'Tax Code',
				field: 'taxCode',
				cellStyle: { padding: 8 },
				editable: cellEditable, resizable: false,
				width: 150,
				renderCell: rowData => {
					var taxDesc = "";
					if (state.taxes.length > 0) {
						var tax = state.taxes.filter(x => x.taxCode == rowData.row.taxCode)[0];
						if (tax) {
							taxDesc = tax.taxDesc;
						}
					}
					return <div>{taxDesc}</div>;
				},
				renderEditCell: props => {
					//currentEditRow = props.row;
					return <select id="ddlTaxes" name="taxCode" style={{ minWidth: 150, maxWidth: 150 }} data-seqid={props.row.seqId} value={props.row.taxCode}
						
						onChange={(event) => {
							let { field, row } = props;
							onTaxCodeChange({ ...event, row: row })
							setState({
								...state, isDirty: true, invoiceDocument:
								{
									...state.invoiceDocument,
									invoiceDocumentEditorDetails: state.invoiceDocument?.invoiceDocumentEditorDetails
										.map((e, i) => { if (e.seqId === props.id) { return { ...row,isDirty:true } } else return e; })
								}
							})
							stateUpliftMethods.setParentState({ isDirty: true }); stateUpliftMethods.updateView();
							setValidationonBlur(props.row, true);
						}}>
						<option key="0" value="">Select Tax Code</option>
						{
							state?.taxes?.map(function (item, index) {
								return <option key={index} value={item.taxCode}>{item.taxDesc}</option>
							})
						}
					</select>
				},
			},
			{
				headerName: 'Tax Amount',
				field: 'taxAmount',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.taxAmount),
				renderEditCell: props => { return formatNumber(props.value); },
			},
			{
				headerName: 'PO Amount',
				field: 'netCost',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.netCost),
				renderEditCell: props => { return formatNumber(props.value); },
			},
			{
				headerName: 'Consumed Amount',
				field: 'matchToDateNet',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.matchToDateNet),
				renderEditCell: props => { return formatNumber(props.value); },
			},
			{
				headerName: 'Remaining Amount',
				field: 'remainingAmount',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.remainingAmount),
				renderEditCell: props => { return formatNumber(props.value); },
			},
			{
				headerName: 'IO/PO Amount',
				field: 'poAmount',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.poAmount),
				renderEditCell: props => { return formatNumber(props.value); },
			},
			{
				headerName: 'Consumed Amount',
				field: 'consumedAmount',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.consumedAmount),
				renderEditCell: props => { return formatNumber(props.value); },
			},
			{
				headerName: 'Delivery Amount',
				field: 'deliveryAmount',
				type: 'currency',
				cellStyle: cellStyle,
				align: 'right', headerAlign: 'right', width: 110,
				editable: cellEditable, resizable: false,
				renderCell: rowData => formatNumber(rowData.row.deliveryAmount),
				renderEditCell: props => { return formatNumber(props.value); },
			},
		]
	}, [state, state.taxes, state.invoiceDocument, state.invoiceDocument.invoiceDocumentEditorDetails, stateUpliftMethods])
	//#endregion


	const columnVisibilityModel = React.useMemo(() => ({
		// Hide columns status and traderName, the other columns will remain visible
		ioNumber: !(state.invoiceDocument.modelDefId === 2),
		ioDescription: !(state.invoiceDocument.modelDefId === 2),
		level2Key: !(state.invoiceDocument.modelDefId !== 2),
		level3Key: !(state.invoiceDocument.modelDefId !== 2),
		rTypeName: !(state.invoiceDocument.modelDefId !== 2),
		quantity: !(state.invoiceDocument.modelDefId !== 2),
		netCost: !(state.invoiceDocument.modelDefId !== 2 || !POCodeNotEmptyAndValid()),
		matchToDateNet: !(state.invoiceDocument.modelDefId !== 2 || !POCodeNotEmptyAndValid()),
		remainingAmount: !(state.invoiceDocument.modelDefId !== 2 || !POCodeNotEmptyAndValid()),
		poAmount: !(state.invoiceDocument.modelDefId === 2),
		consumedAmount: !(state.invoiceDocument.modelDefId === 2),
		deliveryAmount: !(state.invoiceDocument.modelDefId === 2),
	}), [props]);

	const onSelectAllClick = React.useCallback((event) => {
		if (event.target.checked) {

			setSelectedRows(new Set(state.invoiceDocument?.invoiceDocumentEditorDetails.map(e => e.seqId)));
		}
		else {
			setSelectedRows(new Set());
		}
	}, [state])

	const visibleCells = React.useMemo(() => {
		return headCells.filter((e, i) => { return typeof columnVisibilityModel[e.field] == 'undefined' || columnVisibilityModel[e.field] }) || []

	}, [columnVisibilityModel, headCells])

	const filteredRows = React.useMemo(() => {
		return state.invoiceDocument?.invoiceDocumentEditorDetails.filter((e, i) => {

			return visibleCells.some((col) => (((e[col.field] || '') + '').indexOf(searchText) !== -1))
		})
			|| []
	}, [state.invoiceDocument, orderBy, sortedField, visibleCells, searchText])

	const visibleRows = React.useMemo(
		() => {
			if (filteredRows.length === 0 || (page > getLastPage()-1))
				return [];
			else {
				return (!sortedField ? filteredRows : stableSort(filteredRows, getComparator(orderBy, sortedField))).slice(
					page * pageSize,
					page * pageSize + pageSize,
				)
			}
			
		},
		[sortedField, page, orderBy, pageSize, filteredRows],
	);
	


	//#region camparators
	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}


	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	//#endregion
	//#endregion renderer

	return (
		
		<div style={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }} variant="outlined">
				<Grid xs={12} sx={{ pt: 1, pl: 1, pr: 1, pb: 1 }} container item>
					<Grid item xs={5}></Grid>
					<Grid item xs={7}>
						<Grid item container xs={12} justifyContent='end' alignItems='center'>
							<Grid item xs={4} container justifyContent='end' alignItems='end'>
								<TextField
									sx={{
										'&': {
											paddingRight: '10px',
											fontSize: '12px'
										},
										'& .MuiInput-underline:before': {
											borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important'
										},
										'& .MuiInputAdornment-root': {
											marginRight: '4px'
										}
									}}
									vlaue={searchText}
									onKeyUp={(event) => { setSearchText(event.target.value) }}
									InputProps={{
										startAdornment: <InputAdornment position="start">
											<SearchIcon style={{ width: 20, height: 20 }} />
										</InputAdornment>,
										endAdornment: (searchText && <IconButton
											size='small'
											onClick={() => { setSearchText('') }}
										>
											<ClearIcon style={{ width: 20, height: 20 }} />
										</IconButton>)
									}}
									placeholder="Search..." variant="standard" />
							</Grid>
							<Grid justifyContent='end' item>
								<>
									{getRefreshButton()}
									{cellEditable && getDeleteButton()}
									{cellEditable && getAddButton()}
								</>
							</Grid>
						</Grid>

					</Grid>
				</Grid>

				<StyledTableContainer sx={{ width: '100%', height: 316, marginTop: 2 }}>
					<Table stickyHeader
						sx={{ minWidth: 750 }}
						aria-labelledby="Invoice Document Details"
					>
						<TableHead>
							<TableRow>
								{(cellEditable && (<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										indeterminate={selectedRows.size > 0 && selectedRows.size < state.invoiceDocument?.invoiceDocumentEditorDetails.length}
										checked={selectedRows.size > 0 && selectedRows.size === state.invoiceDocument?.invoiceDocumentEditorDetails.length}
										onChange={onSelectAllClick}
										inputProps={{
											'aria-label': 'select all records',
										}}
									/>
								</TableCell>))}
								{visibleCells.map((headCell, i) => (
									<TableCell
										key={i}
										style={{ width: headCell.width || 'unset', minWidth: headCell.width || 'unset', maxWidth: headCell.width || 'unset' }}
										align={headCell.headerAlign}
										padding={headCell.disablePadding ? 'none' : 'normal'}
									>
										<TableSortLabel
											hideSortIcon={!(headCell.sortable || typeof headCell.sortable == 'undefined')}
											active={headCell.field === sortedField}
											direction={sortedField === headCell.field ? orderBy : 'asc'}
											onClick={(event) => {
												const isAsc = sortedField === headCell.field && orderBy === 'asc';
												setSortedField(sortedField === headCell.field && orderBy === 'desc' ? '' : headCell.field)
												setOrderBy(isAsc ? 'desc' : 'asc');

											}}
										>
											{headCell.headerName}
										</TableSortLabel>

									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{visibleRows.map((row, index) => {
								const isItemSelected = selectedRows.has(row.seqId);
								return (

									cellEditable ?
										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.seqId}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													onChange={(event) => {
														if (event.target.checked) { setSelectedRows(new Set([...selectedRows, row.seqId])) } else {
															setSelectedRows(new Set([...selectedRows].filter(e => e !== row.seqId)))
														}
													}}
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': 'select row',
													}}
												/>
											</TableCell>
											{visibleCells.map((e, i) => {
												return (
													<TableCell

														component="td"
														key={i}
														id={i}
														style={{ width: e.width || 'unset', minWidth: e.width || 'unset', maxWidth: e.width || 'unset' }}
														align={e.align}
														scope="row"
														padding="none"
													>
														{(e?.renderEditCell && e?.renderEditCell({ row: row, field: e.field, id: row.seqId, value: row[e.field] }))
															|| row[e.field]}
													</TableCell>
												)
											})}
											
										</TableRow>
										:

										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.seqId}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											{(cellEditable && (<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													onChange={(event) => {
														if (event.target.checked) { setSelectedRows(new Set([...selectedRows, row.seqId])) } else {
															setSelectedRows(new Set([...selectedRows].filter(e => e !== row.seqId)))
														}
													}}
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': 'select row',
													}}
												/>
											</TableCell>))}
											{visibleCells.map((e, i) => (
												<TableCell

													component="td"
													key={i}
													id={i}
													style={{ width: e.width || 'unset', minWidth: e.width || 'unset', maxWidth: e.width || 'unset' }}
													align={e.align}
													scope="row"
													padding="none"
												>
													{(e?.renderCell && e?.renderCell({ row: row, field: e.field, id: row.seqId, value: row[e.field] }))
														|| row[e.field]}
												</TableCell>
											))}
											
										</TableRow>
								);
							})}
							{state.invoiceDocument?.invoiceDocumentEditorDetails <= 0 && (
								<TableRow
								>
									<TableCell align='center' style={{ height: 350, borderBottom: 'none' }} colSpan={visibleCells.length + (cellEditable ? 2 : 0)} >
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '0px', right:'0px' }}>
											No rows
										</div>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</StyledTableContainer>
				<StyledTablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={filteredRows.length}
					rowsPerPage={pageSize}
					page={((getLastPage()-1 < page) || page < 0 ) ? 0 : page}
					onRowsPerPageChange={(event) => { setpageSize(event.target.value) }}
					onPageChange={(event, newpage) => { setPage(newpage) }}
				/>
			</Paper>
	
			<Level2Lookup info={level2Info} key="Level2LookupEditorGrid" onClose={level2Dialog_closeHandler} />
			<Level3Lookup info={level3Info} key="Level3LookupEditorGrid" onClose={level3Dialog_closeHandler} />
			<CostCodesLookup info={costCodesInfo} key="CostCodesLookupEditorGrid" onClose={costCodesDialog_closeHandler} />
			
			<Dialog key="DeleteRecordsEditorGrid"
				open={state.openDeleteConfirmation}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					Delete Records
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this record ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={deleteRecord} color="primary">
						Yes
					</Button>
					<Button autoFocus onClick={closeDeleteDialog} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog key="DeleteSelectedRecordsEditorGrid"
				open={state.openDeleteSelectedConfirmation}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					Delete Records
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete selected records ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={deleteSelectedRecord} color="primary">
						Yes
					</Button>
					<Button autoFocus onClick={closeDeleteSelectedDialog} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
//#endregion