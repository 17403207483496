import * as React from 'react';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class InvoiceDocumentRoutingHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		};

		this.inProgress = false;

		this.close_clickHandler = this.close_clickHandler.bind(this);
	}

	componentDidUpdate() {
		var that = this;

		if (this.props.info.openDialog && this.state.data.length === 0 && !this.inProgress) {
			this.inProgress = true;
			this.fetchInvoiceDocumentRoutingHistory();

			that.props.info.inProgress(true);
		}
	}

	fetchInvoiceDocumentRoutingHistory() {
		var that = this;

		httpGet('/api/invoicedocument/invoicedocumentroutinghistory/' + this.props.info.rowData.invoiceDocumentId)
			.then((response) => {
				var data = response.data;

				that.inProgress = false;
				that.setState({ data: data });
				this.props.info.inProgress(false);
			})
			.catch((error) => {
				that.inProgress = false;
				that.props.info.setInProgess(false);

				toast.error('An error occurred while fetching the records');
			});
	}

	close_clickHandler(event) {
		this.setState({ data: [] });

		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}

	render() {
		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Invoice Document Approval History
				</DialogTitle>
				<DialogContent>
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							
							columns={[
								{
									headerName: 'Approval Level',
									field: 'approvalLevel',
									flex:1
								},
								{
									headerName: 'Status',
									field: 'status',
									flex: 1
								},
								{
									headerName: 'Comments',
									field: 'approvalRejectionComment',
									flex: 1
								},
								{
									headerName: 'Timestamp',
									field: 'timestamp',
									flex: 1
								},
								{
									headerName: 'User',
									field: 'resourceName',
									flex: 1
								}
								
							]}
							options={{
								toolbar: false,
								sorting: false,
								draggable: false,
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 350
							}}
							getRowId={(row) => row.sequenceId}
							disableSelectionOnClick
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
