import actionType from '../constants/constants'

export function loginUser(userObj) {
	return dispatch => {
		
		dispatch({ type: actionType.LOGIN_USER, payload: userObj });
	}
	
}
export function logoutUser(userObj) {
	return dispatch => {
		
		dispatch({ type: actionType.LOGOUT_USER, payload: userObj });
	}

}