import * as React from 'react';
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

export default class InsertionOrdersReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			currentWidth: null,
			
		};
		
	}


	componentDidMount() {
		
		this.fetchInsertionOrders();
	}
	
	fetchInsertionOrders() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/insertionorders/')
			.then((response) => {
				//var data = response.data;
				var data = (response.data || []).map((e, i) => { let d = { ...e, rowId: i }; return d });
				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>Insertion Orders</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}

							columns={[
								{
									headerName: 'IO/PO Number',
									field: 'poCode',
									width: 150, hideable: false
								},
								{
									headerName: 'IO/PO Amount',
									field: 'poAmount',
									type: 'currency',
									align: 'right',
									headerAlign: 'right',
									renderCell: rowData => formatNumber(rowData.row.poAmount) + " " + rowData.row.currencyCode,
									width: 200
								},
								{
									headerName: 'Consumed Amount',
									field: 'consumedAmount',
									type: 'currency',
									align: 'right',
									headerAlign: 'right',
									renderCell: rowData => formatNumber(rowData.row.consumedAmount) + " " + rowData.row.currencyCode,
									flex: 1
								},
								{
									headerName: 'Client Name',
									field: 'clientName',
									flex: 1
								},
								{
									headerName: 'Job Code',
									field: 'jobCode',
									width: 150,
								},
								{
									headerName: 'Campaign Name',
									field: 'campaignName',
									width: 250,
								},
								{
									headerName: 'Media Plan',
									field: 'mediaPlanName',
									width: 200,
								}


							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670,
								filtering: true,
								columnsButton: true,
								exportButton: true,
								exportAllData: true,
								exportFileName: "InsertionOrders"
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}
