import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DirectionsIcon from '@mui/icons-material/Directions';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MessageIcon from '@mui/icons-material/Message';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ScannerIcon from '@mui/icons-material/Scanner';
import RouteToEmployeeIcon from '@mui/icons-material/AssignmentInd';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { formatNumber, defaultStringIfNullOrEmpty, stringIsNullOrEmpty, checkForUnAuthorizedException, formatDate } from '../Common/Utilities';
import { httpGet, httpPost, httpPut } from '../../HttpRequestHandling/httpRequestHandler';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import { InvoiceDocumentEditor } from '../InvoiceDocument/InvoiceDocumentEditor';
import { POHeaderResourceLookup } from '../Lookups/POHeaderResourceLookup';
import { Level2ResourceLookup } from '../Lookups/Level2ResourceLookup';
import { fileUpload } from '../NonMappedInvoices/ManualUploadApi';
import { green, orange } from '@mui/material/colors';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { dividerClasses } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});
//const HtmlTooltip = styled(({ className, ...props }) => (
//	<Tooltip {...props}/>
//));
export default class Dashboard extends React.Component {
	constructor() {
		super();
		this.isFullscreen = false;
		const userInfo = JSON.parse(localStorage.userInfo);
		this.isAdmin = userInfo.selectedUserRoles.isAdmin;
		this.selectedRows = [];
		this.scanSelectedRows = [];
		var openInvoiceDocumentEditorDialog = false;
		var rowData = null;
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const invoiceDocumentId = urlParams.get('invoicedocumentid');
		const status = urlParams.get('status');
		var mode = urlParams.get('mode');
		var modelDefId = urlParams.get('modeldefid');
		if (!stringIsNullOrEmpty(invoiceDocumentId)) {
			openInvoiceDocumentEditorDialog = true;
			rowData = { invoiceDocumentId: parseInt(invoiceDocumentId), invoiceDocumentStatusId: parseInt(status) };
			mode = stringIsNullOrEmpty(mode) ? "MakeCorrections" : mode;
			modelDefId = modelDefId;
		}
		this.state = {
			weeksToFilter1: 0,
			weeksToFilter2: 0,
			weeksToFilter3: 0,
			weeksToFilter4: 4,
			queuedInvoices: [],
			unmatchedInvoices: [],
			filteredUnmatchedInvoices: [],
			pendingApprovalInvoices: [],
			approvedInvoices: [],
			openInvoiceViewer: false,
			openInvoiceDocumentEditorDialog: openInvoiceDocumentEditorDialog,
			openPOHeaderResourceDialog: false,
			openLevel2ResourceDialog: false,
			rowData: rowData,
			mode: mode,
			modelDefId: modelDefId,
			isViaQueryString: !stringIsNullOrEmpty(invoiceDocumentId),
			selectedRoutedToResourceId: "-1",
			distinctRoutedToResourceList: [],
			selectedFiles: null,
			openDashViewer: false
		};

		this.invoiceQueueGridRef = React.createRef();

		this.weeksToFilter_changeHandler = this.weeksToFilter_changeHandler.bind(this);
		this.routedToResourceList_changeHandler = this.routedToResourceList_changeHandler.bind(this);
		this.bulkRouteToEmployee_clickHandler = this.bulkRouteToEmployee_clickHandler.bind(this);
		this.poHeaderResourceDialog_closeHandler = this.poHeaderResourceDialog_closeHandler.bind(this);
		this.level2ResourceDialog_closeHandler = this.level2ResourceDialog_closeHandler.bind(this);
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.fileUpload_clickHandler = this.fileUpload_clickHandler.bind(this);
		this.fileUpload_changeHandler = this.fileUpload_changeHandler.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.scan_clickHandler = this.scan_clickHandler.bind(this);
		this.scanAll_clickHandler = this.scanAll_clickHandler.bind(this);
		this.invoiceDocument_clickHandler = this.invoiceDocument_clickHandler.bind(this);
		this.suggestionFlag_clickHandler = this.suggestionFlag_clickHandler.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.invoiceDocumentEditor_closeHandler = this.invoiceDocumentEditor_closeHandler.bind(this);
		this.showFullScreen = this.showFullScreen.bind(this);
		this.invoiceDocumentRescan_clickHandler = this.invoiceDocumentRescan_clickHandler.bind(this);
		
	}
	fetchDashboard(gadgetNo, weeks) {
		const that = this;
		var modelDefId = this.props.modelDefId;
		var gadgetNumber = gadgetNo;
		var weeksToFilter = weeks;
		this.props.inProgress(true);
		httpGet("/api/dashboard/" + modelDefId + "/" + gadgetNumber + "/" + weeksToFilter)
			.then((response) => {
				var data = response.data;
				var weeksToFilter1 = that.state.weeksToFilter1;
				var weeksToFilter2 = that.state.weeksToFilter2;
				var weeksToFilter3 = that.state.weeksToFilter3;
				var weeksToFilter4 = that.state.weeksToFilter4;
				var queuedInvoices = that.state.queuedInvoices;
				var unmatchedInvoices = that.state.unmatchedInvoices;
				var filteredUnmatchedInvoices = that.state.filteredUnmatchedInvoices;
				var pendingApprovalInvoices = that.state.pendingApprovalInvoices;
				var approvedInvoices = that.state.approvedInvoices;
				var distinctRoutedToResourceList = that.state.distinctRoutedToResourceList;
				switch (gadgetNumber) {
					case 1:
						weeksToFilter1 = weeksToFilter;
						queuedInvoices = data.queuedInvoices;
						that.setRowId(queuedInvoices);
						queuedInvoices.map(row => row.invoiceDocumentFileLocation = row.documentManualUploadFileLocation);
						break;
					case 2:
						weeksToFilter2 = weeksToFilter;
						unmatchedInvoices = data.unmatchedInvoices;
						that.setRowId(unmatchedInvoices);
						distinctRoutedToResourceList = that.getDistinctRoutedToResourceList(unmatchedInvoices);
						filteredUnmatchedInvoices = that.getFilteredUnmatchedInvoices(unmatchedInvoices, that.state.selectedRoutedToResourceId);
						break;
					case 3:
						weeksToFilter3 = weeksToFilter;
						pendingApprovalInvoices = data.pendingApprovalInvoices;
						that.setRowId(pendingApprovalInvoices);
						break;
					case 4:
						weeksToFilter4 = weeksToFilter;
						approvedInvoices = data.approvedInvoices;
						that.setRowId(approvedInvoices);
						break;
					default:
						weeksToFilter1 = weeksToFilter;
						weeksToFilter2 = weeksToFilter;
						weeksToFilter3 = weeksToFilter;
						//weeksToFilter4 = weeksToFilter;
						queuedInvoices = data.queuedInvoices;
						unmatchedInvoices = data.unmatchedInvoices;
						pendingApprovalInvoices = data.pendingApprovalInvoices;
						approvedInvoices = data.approvedInvoices;
						that.setRowId(queuedInvoices);
						queuedInvoices.map(row => row.invoiceDocumentFileLocation = row.documentManualUploadFileLocation);
						that.setRowId(unmatchedInvoices);
						that.setRowId(pendingApprovalInvoices);
						that.setRowId(approvedInvoices);
						distinctRoutedToResourceList = that.getDistinctRoutedToResourceList(unmatchedInvoices);
						filteredUnmatchedInvoices = that.getFilteredUnmatchedInvoices(unmatchedInvoices, that.state.selectedRoutedToResourceId);
						break;
				}
				that.setState(
					{
						weeksToFilter1: weeksToFilter1,
						weeksToFilter2: weeksToFilter2,
						weeksToFilter3: weeksToFilter3,
						weeksToFilter4: weeksToFilter4,
						queuedInvoices: queuedInvoices,
						unmatchedInvoices: unmatchedInvoices,
						filteredUnmatchedInvoices: filteredUnmatchedInvoices,
						pendingApprovalInvoices: pendingApprovalInvoices,
						approvedInvoices: approvedInvoices,
						modelDefId: modelDefId,
						distinctRoutedToResourceList: distinctRoutedToResourceList
					});
				that.props.inProgress(false);
			}).catch((error) => {
				checkForUnAuthorizedException(that, error);
				that.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}
	setRowId(data) {
		var rowId = 1;
		data.map(row => row.rowId = rowId++);
	}
	componentDidMount() {
		this.fetchDashboard(0, 0);
	}
	
	showFullScreen(el, gadgetNo) {
		//this.setstate({ openDashViewer: true, gadgetNo: gadgetNo});
		//var prop = {};
		//var speed = 910;
		//if (!this.isFullscreen) { // MAXIMIZATION
		//	prop.width = "100%";
		//	prop.height = "100vh";
		//	this.isFullscreen = true;
		//	$(el.currentTarget).animate(prop, speed);
		//	setTimeout(function () {
		//		$(el.currentTarget).css("position", "absolute");
		//	}, 920);
		//}
		//else {
		//	prop.width = "50%";
		//	prop.height = "250px";
		//	this.isFullscreen = false;
		//	$(el.currentTarget).animate(prop, speed);
		//	setTimeout(function () {
		//		$(el.currentTarget).css("position", "relative");
		//	}, 920);
		//}
		//if (!document.fullscreenElement) {
		//	enterFullscreen(gadgetNo);
		//} else {
		//	exitFullscreen(gadgetNo);
		//}
	}
	//enterFullscreen(gadgetNo) {
	//	const elem = tableRef.current;
	//	if (elem.requestFullscreen) {
	//		elem.requestFullscreen();
	//	} else if (elem.mozRequestFullScreen) { // Firefox
	//		elem.mozRequestFullScreen();
	//	} else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
	//		elem.webkitRequestFullscreen();
	//	}
	//}

	//exitFullscreen(gadgetNo){
	//	if (document.exitFullscreen) {
	//		document.exitFullscreen();
	//	} else if (document.mozCancelFullScreen) { // Firefox
	//		document.mozCancelFullScreen();
	//	} else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
	//		document.webkitExitFullscreen();
	//	}
	//}

	//#region Components
	getRoutedToResources(selectedResources, invoiceDocumentId) {
		var routedToResources = [];
		selectedResources.map(function (selectedResource, selectedResourceIndex) {
			const invoiceDocumentResourceRoutingHistory = { invoiceDocumentId: invoiceDocumentId, resourceId: selectedResource.resourceId, email: selectedResource.email, comments: selectedResource.comments };
			routedToResources.push(invoiceDocumentResourceRoutingHistory);
		});
		return routedToResources;
	}
	getRefreshButton(gadgetNumber) {
		{/*style={{ float: "right", zIndex: 1, marginRight: 15, marginTop: 10 }}*/ }
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			data-gadgetnumber={gadgetNumber}
		>
			<Tooltip key={1} title={'Refresh'}>
				<RefreshIcon key={2} />
			</Tooltip>
		</IconButton>;
	}
	getUploadButton(gadgetNumber) {
		return <IconButton
			variant="contained"
			onClick={this.fileUpload_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			data-gadgetnumber={gadgetNumber}>
			<Tooltip key={1} title={'Upload'}>
				<CloudUploadIcon key={2} />
			</Tooltip>
		</IconButton>;
	}
	getScanButton(gadgetNumber) {
		return <IconButton
			variant="contained"
			onClick={this.scanAll_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			data-gadgetnumber={gadgetNumber}>
			<Tooltip key={1} title={'Scan Seleted'}>
				<ScannerIcon key={2} />
			</Tooltip>
		</IconButton>;
	}
	getRoutedToFilterDropDown() {
		return <Select style={{ float: "right", zIndex: 1, marginTop: 10, width: 100 }}
			variant="standard"
			value={this.state.selectedRoutedToResourceId}
			onChange={this.routedToResourceList_changeHandler}
		>
			<MenuItem value="-1">All Invoices</MenuItem>
			{
				this.state.distinctRoutedToResourceList.map(function (routedToResource, index) {
					return <MenuItem key={index} value={routedToResource.routedToResourceId}>{routedToResource.routedTo}</MenuItem>
				})
			}
		</Select>;
	}
	getWeeksToFilterDropDown(gadgetNumber) {
		return <Select
			variant="standard"
			style={{ float: "right", zIndex: 1, marginTop: 10, marginRight: 10, width: 100 }}
			value={this.state["weeksToFilter" + gadgetNumber]}
			onChange={(e) => {
				this.weeksToFilter_changeHandler({ e: { ...e }, target: { ...e.target }, currentTarget: { dataset: { gadgetnumber: gadgetNumber } } });
			}}
		>
			<MenuItem data-gadgetnumber={gadgetNumber} value={0}>No Filter</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={1}>1 Weeks</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={2}>2 Weeks</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={4}>5 Weeks (1 Month)</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={9}>9 Weeks (2 Months)</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={26}>26 Weeks (6 Months)</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={52}>52 Weeks (1 Year)</MenuItem>
		</Select>;
	}
	displayActions(rowData) {
		return rowData.approvalLinkText.length > 0 ? <Link data-rowid={rowData.rowId} data-source="pendingApprovalInvoices" data-mode="Approval" href="#" onClick={this.invoiceDocument_clickHandler}>{rowData.approvalLinkText}</Link> : <></>;
	}
	displayVendorName(rowData) {
		return stringIsNullOrEmpty(rowData.vendorName) ? rowData.vendorCode : rowData.vendorName;
	}
	getRoutedToInfoColumn(rowData) {
		var returnValue = <div></div>;
		if (rowData.routedTo === "Not Routed") {
			if (rowData.suggestionFlag === "H") {
				returnValue = <Link href="#"><Tooltip title="route to IO/PO resources" ><EmojiObjectsIcon style={{ color: green[500] }} data-invoicedocumentid={rowData.invoiceDocumentId} onClick={this.suggestionFlag_clickHandler} /></Tooltip></Link>;
			}
			else if (rowData.suggestionFlag === "L") {
				returnValue = <Link href="#"><Tooltip title="route to any resources"><EmojiObjectsOutlinedIcon style={{ color: orange[500] }} data-invoicedocumentid={rowData.invoiceDocumentId} onClick={this.suggestionFlag_clickHandler} /></Tooltip></Link>;
			}
		}
		else {
			if (rowData.suggestionFlag === "N") {
				if (rowData.createId === "system") {
					returnValue = <Tooltip title="routed by system" ><DirectionsIcon color="primary" /></Tooltip>;
				}
				else {
					returnValue = <Tooltip title={rowData.comments} ><MessageIcon color="primary" /></Tooltip>;
				}
			}
		}
		return returnValue;
	}
	getDistinctRoutedToResourceList(unmatchedInvoices) {
		var distinctRoutedtoResources = [];
		unmatchedInvoices.map(function (unmatchedInvoice, index) {
			if (unmatchedInvoice.routedToCsv && unmatchedInvoice.routedToCsv.split('|$|').length > 1) {
				var routedTo = unmatchedInvoice.routedToCsv.split('|$|');
				for (var i = 0; i < routedTo.length; i++) {
					if (routedTo[i]) {
						var resName = defaultStringIfNullOrEmpty(routedTo[i].split('|')[1], "Not Routed");
						var resId = defaultStringIfNullOrEmpty(routedTo[i].split('|')[0], "Not Routed") 
						var routedToResource = {
							routedToResourceId: resId,
							routedTo: resName
						};
						if (!distinctRoutedtoResources.some(r => r.routedToResourceId === resId)) {
							distinctRoutedtoResources.push(routedToResource);
						};
					}
				}
			}
			else {
				var routedToResource = {
					routedToResourceId: defaultStringIfNullOrEmpty(unmatchedInvoice.routedToResourceId, "Not Routed"),
					routedTo: unmatchedInvoice.routedTo
				};
				if (!distinctRoutedtoResources.some(r => r.routedToResourceId === defaultStringIfNullOrEmpty(unmatchedInvoice.routedToResourceId, "Not Routed"))) {
					distinctRoutedtoResources.push(routedToResource);
				};
			}
		});
		return distinctRoutedtoResources.sort((a, b) => (a.routedToResourceId > b.routedToResourceId ? 0 : -1));
	}
	getFilteredUnmatchedInvoices(unmatchedInvoices, routedToResourceId) {
		var filteredUnmatchedInvoices = [];
		filteredUnmatchedInvoices = routedToResourceId === "-1" ? unmatchedInvoices : unmatchedInvoices.filter(unmatchedInvoice => defaultStringIfNullOrEmpty(unmatchedInvoice.routedToResourceId, "Not Routed").includes(routedToResourceId));
		return filteredUnmatchedInvoices;
	}
	displayUploadStatus(uploadStatus) {
		switch (uploadStatus) {
			case 1:
				return <div>Not Processed</div>;
			case 2:
				return <div>Scan in Progress</div>;
			default:
				return <div>Unknown</div>;
		}
	}

	//#endregion
	//#region Events
	weeksToFilter_changeHandler(event) {
		var gadgetNumber = parseInt(event.currentTarget.dataset.gadgetnumber);
		var weeksToFilter = parseInt(event.target.value, 10);
		this.fetchDashboard(gadgetNumber, weeksToFilter);
	}
	routedToResourceList_changeHandler(event) {
		var filteredUnmatchedInvoices = this.getFilteredUnmatchedInvoices(this.state.unmatchedInvoices, event.target.value);
		this.setState({ selectedRoutedToResourceId: event.target.value, filteredUnmatchedInvoices: filteredUnmatchedInvoices });
	}
	bulkRouteToEmployee_clickHandler(event) {
		if (this.selectedRows.length > 0) {
			var isProduction = this.props.modelDefId === 2;
			this.setState({ openPOHeaderResourceDialog: !isProduction, openLevel2ResourceDialog: isProduction });
		}
	}
	poHeaderResourceDialog_closeHandler(event, poHeaderResources) {
		if (poHeaderResources.length > 0) {
			var that = this;
			var routedToResources = [];
			if (this.selectedRows.length > 0) {
				this.selectedRows.map(function (selectedRow, selectedRowIndex) {
					routedToResources = routedToResources.concat(that.getRoutedToResources(poHeaderResources, selectedRow.invoiceDocumentId));
				});
			}
			else {
				routedToResources = this.getRoutedToResources(poHeaderResources, this.state.invoiceDocumentId);
			}
			this.props.inProgress(true);
			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					this.props.inProgress(false);
					this.selectedRows = [];
					this.fetchDashboard(2, this.state["weeksToFilter" + 2]);
					toast.success('Routed to Employee Successfully');
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}
		this.setState({ openPOHeaderResourceDialog: false, invoiceDocumentId: null });
	}
	level2ResourceDialog_closeHandler(event, level2Resources) {
		if (level2Resources.length > 0) {
			var that = this;
			var routedToResources = [];
			if (this.selectedRows.length > 0) {
				this.selectedRows.map(function (selectedRow, selectedRowIndex) {
					routedToResources = routedToResources.concat(that.getRoutedToResources(level2Resources, selectedRow.invoiceDocumentId));
				});
			}
			else {
				routedToResources = this.getRoutedToResources(level2Resources, this.state.invoiceDocumentId);
			}
			this.props.inProgress(true);
			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					this.props.inProgress(false);
					this.selectedRows = [];
					this.fetchDashboard(2, this.state["weeksToFilter" + 2]);
					toast.success('Routed to Employee Successfully');
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}
		this.setState({ openLevel2ResourceDialog: false, invoiceDocumentId: null });
	}
	refresh_clickHandler(event) {
		var gadgetNumber = parseInt(event.currentTarget.dataset.gadgetnumber);
		this.fetchDashboard(gadgetNumber, this.state["weeksToFilter" + gadgetNumber]);
	}
	fileUpload_changeHandler(event) {
		fileUpload(this, event, this.state.modelDefId, toast, httpPost,
			() => { this.props.inProgress(true); },
			() => { this.props.inProgress(false); },
			ProgressEvent => { },
			() => {
				this.fetchDashboard(1, this.state["weeksToFilter" + 1]);
			});
	}
	fileUpload_clickHandler(event) {
		document.getElementById("contained-button-file").click();
	}
	viewLink_clickHandler(event) {
		event.preventDefault();
		var rowId = parseInt(event.target.dataset.rowid, 10);
		var gadgetName = event.target.dataset.gadgetname;
		var rowData = this.state[gadgetName].find(d => d.rowId === rowId);
		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.invoiceDocumentFileLocation });
	}
	scan_clickHandler(event) {
		event.preventDefault();
		this.props.inProgress(true);
		httpPut("/api/nonmappedinvoices/manuallyuploadedinvoices/" + event.currentTarget.dataset.documentmanualuploadrecordid)
			.then(res => {
				this.props.inProgress(false);
				toast.success('File Scan in Progress');
				this.fetchDashboard(0, 0);
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Couldn\'t start scanning');
			});
	}
	scanAll_clickHandler(event) {
		event.preventDefault();
		this.props.inProgress(true);
		if (this.scanSelectedRows.length > 0) {
			httpPost('/api/NonMappedInvoices/scanAll', this.scanSelectedRows)
				.then((response) => {
					this.props.inProgress(false);
					this.scanSelectedRows = [];
					toast.success('File(s) Scan in Progress');
					this.invoiceQueueGridRef.current.setRowSelectionModel([]);
					this.fetchDashboard(0, 0);
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error('Couldn\'t start scanning');
				});
		}
		else {
			this.props.inProgress(false);
			toast.error('No invoice(s) selected for scan');
		}
	}
	invoiceDocumentRescan_clickHandler(event) {
		event.preventDefault();
		var rowData = this.state.queuedInvoices.find(d => d.documentManualUploadRecordId === parseInt(event.target.dataset.rowid, 10));
		this.props.inProgress(true);
		httpPost("/api/invoicedocument/scaninvoice/", rowData)
			.then(res => {
				this.props.inProgress(false);
				toast.success('Invoice scan successfully');
				this.fetchDashboard(0, 0);
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error(<div><div>Invoice Scan Failed</div>Please contact Nexelus support for further assistance<div></div></div>,
					{ allowHtml: true });
			});
	}
	invoiceDocument_clickHandler(event) {
		event.preventDefault();
		var rowId = parseInt(event.target.dataset.rowid, 10);
		var datasource = this.state[event.target.dataset.source];
		var rowData = datasource.find(d => d.rowId === rowId);
		this.setState({ openInvoiceDocumentEditorDialog: true, mode: event.target.dataset.mode, rowData: rowData, isViaQueryString: false });
	}
	suggestionFlag_clickHandler(event) {
		event.preventDefault();
		var invoiceDocumentId = parseInt(event.currentTarget.dataset.invoicedocumentid, 10);
		var isProduction = this.props.modelDefId === 2;
		this.setState({ openPOHeaderResourceDialog: !isProduction, openLevel2ResourceDialog: isProduction, invoiceDocumentId: invoiceDocumentId });
	}
	closeDialog(event) {
		this.setState({ openDelete: false, openInvoiceViewer: false });
	}
	invoiceDocumentEditor_closeHandler(event) {
		this.setState({ openInvoiceDocumentEditorDialog: false, rowData: null });
		this.fetchDashboard(0, 0);
	}
	//#endregion
	
	
	render() {
		const info = {
			modelDefId: this.props.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			inProgress: this.props.inProgress,
			onClose: this.closeDialog
		}
		const InvoiceDocumentEditorInfo = {
			rowData: this.state.rowData,
			mode: this.state.mode,
			modelDefId: this.state.modelDefId,
			isViaQueryString: this.state.isViaQueryString,
			inProgress: this.props.inProgress
		}
		const poHeaderResourceInfo = {
			inProgress: this.props.inProgress,
			openDialog: this.state.openPOHeaderResourceDialog,
			vendorCode: null,
			siteId: null,
			invoiceDocumentId: this.state.invoiceDocumentId
		};
		const level2ResourceInfo = {
			inProgress: this.props.inProgress,
			openDialog: this.state.openLevel2ResourceDialog,
			vendorCode: null,
			siteId: null,
			invoiceDocumentId: this.state.invoiceDocumentId
		};
		const spacing = 1;
		const fontSize = 12;
		const cellStyle = { fontSize: fontSize, padding: 8 };
		const headerStyle = { fontSize: fontSize, padding: 8 };
		const fontStyle = { fontSize: fontSize, padding: 8 };
		const gridStyle = { flexGrow: 1 };
		const tableOptionsTopLeft = {
			minBodyHeight: 230,
			maxBodyHeight: 230,
			headerStyle: { fontSize: fontSize },
			searchFieldStyle: fontStyle,
			actionsColumnIndex: -1,
			selection: true,
			showTextRowsSelected: false,
			selectionProps: (rowData) => {
				const checked = rowData.invoiceDocumentScanStatusId === 30 ? { checked: false } : {};
				return {
					color: 'default',
					disabled: rowData.invoiceDocumentScanStatusId === 30,
					...checked
				}
			}
		};
		const tableOptionsTopRight = {
			minBodyHeight: 230,
			maxBodyHeight: 230,
			headerStyle: fontStyle,
			searchFieldStyle: fontStyle,
			showTextRowsSelected: false,
			selection: true,
			showSelectAllCheckbox: false,
			selectionProps: rowData => ({
				color: 'default'
			})
		};
		const tableOptionsBottom = {
			minBodyHeight: 320,
			maxBodyHeight: 320,
			headerStyle: fontStyle,
			searchFieldStyle: fontStyle
		};
		
		return (
			<div>
				<h1>{this.props.modelDefId === 1 ? 'Media' : 'Production'} Invoices Dashboard</h1>
				<Grid container style={gridStyle} spacing={spacing}>
					<Grid item xs={12}>
						<Grid container justify="center" spacing={spacing}>
							<Grid key={1} item xs={6} onClick={(el) => this.showFullScreen(el,1)}>
								<div style={{ height: 390, width: '100%' }}>
									<EnhancedDataGrid rows={this.state.queuedInvoices}
										disableColumnMenu
										pagination
										title="Invoice Queue (waiting to be scanned)"
										gridRef={this.invoiceQueueGridRef}
										pageSize={5}
										rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
										isRowSelectable={(params) => params.row.invoiceDocumentScanStatusId !== 30}
										initialState={{
											sorting: {
												sortModel: [{ field: 'documentManualUploadFileUpdateDate', sort: 'desc' }],
											},
										}}
										columns={[
											{
												headerName: 'File Name',
												field: 'fileName',
												flex: 1,
												cellStyle: cellStyle,
												headerStyle: headerStyle
											},
											{
												headerName: 'Received Date',
												field: 'documentManualUploadFileUpdateDate',
												//type: 'datetime',
												type: 'date',
												//defaultSort: 'desc',
												valueGetter: ({ value }) => value && new Date(value),
												cellStyle: cellStyle,
												headerStyle: headerStyle,
												flex: 1
											},
											{
												headerName: 'Status',
												field: 'uploadStatus',
												cellStyle: cellStyle,
												headerStyle: headerStyle,
												flex: 1,
												renderCell: rowData => this.displayUploadStatus(rowData.value)
											},
											{
												headerName: 'View',
												field: 'view',
												cellStyle: cellStyle,
												headerStyle: headerStyle, resizable: false, sortable: false,
												width: 150,
												renderCell: rowData => (
													<Link data-rowid={rowData.row.rowId} data-gadgetname="queuedInvoices" href="#" onClick={this.viewLink_clickHandler}>View</Link>
												)
											},
											{
												headerName: 'Scan Now',
												field: 'scanNow',
												cellStyle: cellStyle,
												headerStyle: headerStyle,
												width: 150,
												renderCell: rowData => {
													var scanDate = rowData.row.scanDate;
													var Minutes = Math.abs(new Date() - new Date(scanDate)) / 60000;
													return (<div>
														{rowData.row.uploadStatus === 1 && <Link data-documentmanualuploadrecordid={rowData.row.documentManualUploadRecordId} href="#" onClick={this.scan_clickHandler}>Scan</Link>}
														{(scanDate && rowData.row.uploadStatus === 2 && Minutes > 30) && <Link data-rowid={rowData.row.documentManualUploadRecordId} data-mode="Rescan" href="#" onClick={this.invoiceDocumentRescan_clickHandler}>Scan Now</Link>}
													</div>)
												}
												
											}
										]}
										customcomponent={
											<div>
												{this.getRefreshButton(1)}
												<input
													type="file"
													multiple
													accept="application/pdf,image/jpg,image/jpeg,image/png"
													id="contained-button-file"
													style={{ display: "none" }}
													onChange={this.fileUpload_changeHandler} />
												{this.getScanButton(5)}
												{this.getUploadButton(1)}
												{this.getWeeksToFilterDropDown(1)}
											</div>
										}
										options={tableOptionsTopLeft}
										onSelectionChange={(rows) => {
											this.scanSelectedRows = rows
										}}
										getRowId={(row) => row.rowId}
										disableRowSelectionOnClick
									/>
								</div>
							</Grid>
							<Grid key={2} item xs={6} onClick={(el) => this.showFullScreen(el, 2)}>
								<div style={{ height: 390, width: '100%' }}>
									<EnhancedDataGrid rows={this.state.filteredUnmatchedInvoices}
										disableColumnMenu
										pagination
										title="Unmatched/Discrepant Invoices"
										pageSize={5}
										rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
										columns={[
											{
												headerName: 'Vendor Name',
												field: 'vendorName',
												align: 'left',
												headerAlign: 'left',
												cellStyle: cellStyle,
												width: 130,
												renderCell: rowData => { return this.displayVendorName(rowData.row); }
											},
											{
												headerName: 'Status',
												field: 'status',
												cellStyle: cellStyle,
												width: 100,
												renderCell: rowData => rowData.row.status + (rowData.row.duplicateInvoiceFlag === 'Y' ? " (Duplicate Invoice!)" : "")
											},
											{
												headerName: 'File Name',
												field: 'fileName',
												width: 300,
												cellStyle: cellStyle
											},
											{
												headerName: 'Routed To',
												field: 'routedTo',
												cellStyle: cellStyle,
												hide: true /*!this.isAdmin*/,
												width: 100,
												renderCell: rowData => {
													if (rowData.row.routedToCsv && rowData.row.routedToCsv.split('|$|').length > 1) {
														var routedTo = rowData.row.routedToCsv.split('|$|');
														var emplList = "";
														for (var i = 0; i < routedTo.length; i++) {
															if (routedTo[i]) {
																emplList += routedTo[i].split('|')[1] + "\n";
															}
														}
														return <div>
															<Tooltip
																title={
																	<span style={{ whiteSpace: 'pre-line' }}>{emplList}</span>
																}>
																<Typography variant="inherit">{rowData.row.routedTo} ...</Typography>
															</Tooltip>
														</div>;

													}
													return rowData.row.routedTo;
												}
											},
											{
												headerName: ' ',
												field: 'comments',
												cellStyle: cellStyle,
												width: 20,
												resizable: false,
												sortable: false,
												renderCell: rowData => { return this.getRoutedToInfoColumn(rowData.row); }
											},
											{
												headerName: 'Date Scanned',
												field: 'invoiceDocumentScanUpdateDate',
												//type: 'datetime',
												type: 'date',
												defaultSort: 'desc',
												valueFormatter: ({ value }) => value && formatDate(new Date(value)),
												valueGetter: ({ value }) => value && new Date(value),
												width: 100,
												cellStyle: cellStyle
											},
											{
												headerName: 'Action',
												field: 'action',
												cellStyle: cellStyle,
												resizable: false, sortable: false,
												width: 100,
												renderCell: rowData => (
													<Link data-rowid={rowData.row.rowId} data-source="unmatchedInvoices" data-mode="MakeCorrections" href="#" onClick={this.invoiceDocument_clickHandler}>Make Corrections</Link>
												)
											}
										]}
										customcomponent={
											<>
												{this.getRefreshButton(2)}
												<IconButton
													variant="contained"
													onClick={this.bulkRouteToEmployee_clickHandler}
													size="small"
													style={{ float: "right", zIndex: 1, marginTop: 10 }}
												>
													<Tooltip key={1} title={'Route to employees'}>
														<RouteToEmployeeIcon key={2} />
													</Tooltip>
												</IconButton>
												{this.getRoutedToFilterDropDown()}
												{this.getWeeksToFilterDropDown(2)}
											</>
										}
										options={tableOptionsTopRight}
										onSelectionChange={(rows) => { this.selectedRows = rows }}
										getRowId={(row) => row.rowId}
										disableRowSelectionOnClick
									/>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify="center" spacing={spacing}>
							<Grid key={1} item xs={6} onClick={(el) => this.showFullScreen(el, 3)}>
								<div style={{ height: 390, width: '100%' }}>
									<EnhancedDataGrid rows={this.state.pendingApprovalInvoices}
										disableColumnMenu
										pagination
										title="Pending Approval"
										pageSize={5}
										rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
										columns={[
											{
												headerName: 'Vendor Name',
												field: 'vendorName',
												cellStyle: cellStyle,
												flex: 1,
												renderCell: rowData => this.displayVendorName(rowData.row)
											},
											{
												headerName: 'Invoice Number',
												field: 'invoiceNumber',
												flex: 1,
												cellStyle: cellStyle
											},
											{
												headerName: 'Total Invoice Amt',
												field: 'totalAmount',
												type: 'currency',
												flex: 1,
												align: 'right',
												headerAlign: 'right',
												headerStyle: {
													textAlign: 'right',
												},
												cellStyle: {
													paddingRight: 8,
													fontSize: 12
												},
												renderCell: rowData => formatNumber(rowData.row.totalAmount) + " " + rowData.row.currencyCode
											},
											{
												headerName: 'Invoice Date',
												field: 'invoiceDate',
												flex: 1,
												type: 'date',
												valueGetter: ({ value }) => value && new Date(value),
												cellStyle: cellStyle
											},
											{
												headerName: 'Assigned To',
												field: 'assignedTo',
												flex: 1,
												cellStyle: cellStyle,
												renderCell: rowData => {
													if (rowData.row.assignedTo && rowData.row.assignedTo.split('|').length > 1)
														return <div>
															<Tooltip
																title={
																	<span style={{ whiteSpace: 'pre-line' }}>{rowData.row.assignedTo.split('|').join("\n")}</span>
																}>
																<Link>{rowData.row.assignedTo.split('|')[0]} ...</Link>
															</Tooltip>
														</div>;
													return rowData.row.assignedTo;
												}
											},
											{
												headerName: 'Previously Approved',
												field: 'approvedBy',
												flex: 1,
												cellStyle: cellStyle
											},
											{
												headerName: 'Status',
												field: 'status',
												flex: 1,
												cellStyle: cellStyle
											},
											{
												headerName: 'Review Invoice',
												field: 'reviewInvoice',
												cellStyle: cellStyle,
												flex: 1, resizable: false, sortable: false,
												renderCell: rowData => this.displayActions(rowData.row)
											}
										]}
										customcomponent={<>{this.getRefreshButton(3)}
											{this.getWeeksToFilterDropDown(3)}</>}
										options={tableOptionsBottom}
										getRowId={(row) => row.rowId}
										disableRowSelectionOnClick
									/>
								</div>
							</Grid>
							<Grid key={2} item xs={6} onClick={(el) => this.showFullScreen(el, 4)}>
								<div style={{ height: 390, width: '100%' }}>
									<EnhancedDataGrid rows={this.state.approvedInvoices}
										disableColumnMenu
										pagination
										title="Approved Invoices"
										pageSize={5}
										rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
										columns={[
											{
												headerName: 'Vendor Name',
												field: 'vendorName',
												cellStyle: cellStyle,
												flex: 1,
												renderCell: rowData => { return this.displayVendorName(rowData.row); }
											},
											{
												headerName: 'Invoice Number',
												field: 'invoiceNumber',
												width: 120,
												cellStyle: cellStyle
											},
											{
												headerName: 'Total Invoice Amt',
												field: 'totalAmount',
												width: 120,
												type: 'currency',
												align: 'right',
												headerAlign: 'right',
												headerStyle: {
													textAlign: 'right',
												},
												cellStyle: {
													paddingRight: 8,
													fontSize: 12
												},
												renderCell: rowData => formatNumber(rowData.row.totalAmount) + " " + rowData.row.currencyCode
											},
											{
												headerName: 'Date Approved',
												field: 'approvalRejectionUpdateDate',
												//type: 'datetime',
												type: 'date',
												valueFormatter: ({ value }) => value && formatDate(new Date(value)),
												valueGetter: ({ value }) => value && new Date(value),
												flex: 1,
												cellStyle: cellStyle
											},
											{
												headerName: 'Last Approved By',
												field: 'approvedBy',
												flex: 1,
												cellStyle: cellStyle
											},
											{
												headerName: 'Status',
												field: 'pendingStatus',
												cellStyle: cellStyle,
												flex: 1,
												renderCell: rowData =>
													<div>
														{rowData.row.pendingStatus}
														<Tooltip title={rowData.row.invoiceDocumentPostingErrorMessage ? rowData.row.invoiceDocumentPostingErrorMessage : ""}>
															<ErrorIcon style={{ float: "right", display: rowData.row.invoiceDocumentStatusId === 180 ? "" : "none" }} color="secondary" />
														</Tooltip>
													</div>
											},
											{
												headerName: 'View',
												field: 'view',
												cellStyle: cellStyle,
												width: 100, resizable: false, sortable: false,
												renderCell: rowData => (
													<Link data-rowid={rowData.row.rowId} data-source="approvedInvoices" data-mode="View" href="#" onClick={this.invoiceDocument_clickHandler}>View</Link>
												)
											}
										]}
										customcomponent={<>
											{this.getRefreshButton(4)}
											{this.getWeeksToFilterDropDown(4)}
										</>}
										options={tableOptionsBottom}
										getRowId={(row) => row.rowId}
										disableRowSelectionOnClick
									/>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openInvoiceDocumentEditorDialog} TransitionComponent={Transition} fullScreen>
					<InvoiceDocumentEditor info={InvoiceDocumentEditorInfo} onClose={this.invoiceDocumentEditor_closeHandler} />
				</Dialog>
				<POHeaderResourceLookup info={poHeaderResourceInfo} onClose={this.poHeaderResourceDialog_closeHandler} />
				<Level2ResourceLookup info={level2ResourceInfo} onClose={this.level2ResourceDialog_closeHandler} />
				
			</div >
		);
	}
}