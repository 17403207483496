const userRoleModel = function getUserRolesModel() {
    let m = {
        allowInvoiceAutoRouting_1: false,
        allowInvoiceAutoRouting_2: false,
        allowInvoiceCorrection_1: false,
        allowInvoiceCorrection_2: false,
        allowInvoiceDocumentManagement_1: false,
        allowInvoiceDocumentManagement_2 : false,
        allowInvoiceLevel1Approval_1 : false,
        allowInvoiceLevel1Approval_2 : false,
        allowInvoiceLevel2Approval_1 :false,
        allowInvoiceLevel2Approval_2 :false,
        allowInvoiceLevel3Approval_1 :false,
        allowInvoiceLevel3Approval_2:false,
        allowInvoiceLevel4Approval_1:false,
        allowInvoiceLevel4Approval_2:false,
        allowInvoiceLevel5Approval_1: false,
        allowInvoiceLevel5Approval_2 :false,
        allowInvoiceManualProcessing_1:false,
        allowInvoiceManualProcessing_2 : false,
        allowInvoiceModelMapping_1:false,
        allowInvoiceModelMapping_2:false,
        allowNonMappedInvoicesRequestProcessing_1:false,
        allowNonMappedInvoicesRequestProcessing_2:false,
        allowRoutingSetup:false,
        apadUserRolePositionCategories: [],
        apadUserRolesResource :[],
        companyCode: 0,
        createDate:null,
        createId:null,
        isAdmin:false,
        isAdminRole:false,
        isAllowRoutingSetup:false,
        isNew:true,
        modifyDate:null,
        modifyId:null,
        rowId:0,
        userRoleId:0,
        userRoleName:"",
        userRolesInvoiceRightsList :[]
    }
    return m;
};

const invoiceEditorDetailModel = function getInvoiceEditorDetailModel() {
    let m = {
        apadInvoiceDocument: null,
        campaignName: null,
        companyCode: 0,
        consumedAmount: 0,
        costType: null,
        deliveryAmount: null,
        invoiceDocumentDetailTaxes: null,
        invoiceDocumentEditor: null,
        invoiceDocumentId: 0,
        ioDescription: null,
        ioInvoiceAmount: 0,
        ioNumber: "",
        isCostCodesValid: "",
        isIONumberValid: "",
        isLevel2KeyValid: "",
        isLevel3KeyValid: "",
        level2Description: null,
        level2Key: null,
        level3Key: null,
        matchToDateNet: null,
        mediaPlanName: null,
        netCost: null,
        poAmount: 0,
        poCode: null,
        poHeader: null,
        poRequiredFlag: null,
        quantity: null,
        rTypeName: null,
        remainingAmount: 0,
        resType: null,
        taxAmount: 0,
        taxCode: "",
        tolerancePOAmount: 0,
        tolerancePOFlag: null,
        isDirty: false
    }
    return m;
};


exports.userRoleModel = userRoleModel;
exports.invoiceEditorDetailModel = invoiceEditorDetailModel;