import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/MoreHoriz';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { httpGet, httpPut } from '../../HttpRequestHandling/httpRequestHandler';
import { InputCheckbox } from '../FormFieldControls/InputCheckbox';
import { GeneralInfo } from './GeneralInfo'
import { CompanyRoutingSetup } from './CompanyRoutingSetup'
import { APInvoiceLandingSetup } from './APInvoiceLandingSetup'
import { EmailReminderSetup } from './EmailReminderSetup'
import { ApprovalStampConfiguration } from '../InvoiceModels/InvoiceModelSetup/ApprovalStampConfiguration';

export default class CompanyConfiguration extends React.Component {
	constructor() {
		super();

		this.dataLoaded = false;

		this.state = { company: null, isDirty: false, openResetConfirmation: false };

		this.collapseExpandHandler = this.collapseExpandHandler.bind(this);
		this.resetClickHandler = this.resetClickHandler.bind(this);
		this.resetYesClickHandler = this.resetYesClickHandler.bind(this);
		this.resetNoClickHandler = this.resetNoClickHandler.bind(this);
		this.updateClickHandler = this.updateClickHandler.bind(this);
		this.settings_clickHandler = this.settings_clickHandler.bind(this);
		this.popover_closeHandler = this.popover_closeHandler.bind(this);

		this.changeHandler = this.changeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchCompany();
	}

	fetchCompany() {
		var that = this;

		const userInfo = JSON.parse(localStorage.userInfo);

		this.props.inProgress(true);

		httpGet('/api/company/' + userInfo.companyCode)
			.then((response) => {
				var data = response.data;
				this.dataLoaded = true;

				that.setState({ company: data, isDirty: false });

				this.props.inProgress(false);
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the company record');
			});
	}

	collapseExpandHandler(event) {
		this.setState({
			[event.target.id]: event.target.value
		})
		$('.' + event.currentTarget.id).slideToggle('slow', function () {

		});
	}

	resetClickHandler(event) {
		this.setState({ openResetConfirmation: true });
	}

	resetYesClickHandler(event) {
		event.preventDefault();

		this.fetchCompany();

		toast.success('Company Reset Successfully');
		this.setState({ openResetConfirmation: false });
	}

	resetNoClickHandler(event) {
		this.setState({ openResetConfirmation: false });
	}

	updateClickHandler(event) {
		var that = this;

		this.props.inProgress(true);

		httpPut('/api/company/', this.state.company)
			.then((response) => {
				this.props.inProgress(false);
				that.setState({ isDirty: false });
				toast.success('Company updated successfully');
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error(<div>Company Update Failed<br />{error.response.data.detail}</div>);				
			});
	}

	changeHandler(event, modelDefId, childListName) {
		var comp = this.state.company;

		if (arguments.length === 1) {
			comp[event.target.name] = typeof (comp[event.target.name]) === "number" ? parseInt(event.target.value, 10) : event.target.value
		}
		else {
			var childListObject = comp[childListName].find(c => c.modelDefId === modelDefId);
			var propertyName = event.target.dataset.name !== undefined ? event.target.dataset.name : event.target.name;

			childListObject[propertyName] = typeof (childListObject[propertyName]) === "number" ? parseInt(event.target.value, 10) : event.target.value;
		}

		this.setState({
			company: comp,
			isDirty: true
		})
	}

	settings_clickHandler(event) {

		this.setState({ anchorEl: event.currentTarget });
	}

	popover_closeHandler(event) {
		this.setState({ anchorEl: null });
	}

	render() {
		if (!this.dataLoaded) { return (<div></div>); }

		var companyApprovalConfigurationList = [];
		var companyInvoiceConfigurationList = [];

		this.state.company.companyApprovalConfigurations.map(function (companyApprovalConfiguration, index) {
			companyApprovalConfigurationList.push(<CompanyRoutingSetup key={index} companyApprovalConfiguration={companyApprovalConfiguration} changeHandler={this.changeHandler} />);
		}, this);

		this.state.company.companyInvoiceConfigurations.map(function (companyInvoiceConfiguration, index) {
			companyInvoiceConfigurationList.push(<EmailReminderSetup key={index} companyInvoiceConfiguration={companyInvoiceConfiguration} changeHandler={this.changeHandler} />);
		}, this);

		const poFilterByDateInfo = {
			checkboxId: "chkPoFilterByDate",
			checkboxName: "poFilterByDate",
			checkboxValue: this.state.company.poFilterByDate,

			labelId: "lblPoFilterByDate",
			labelText: "PO Filter By Date"
		}

		return (
			<div>
				<h1>Company Configuration</h1>
				<div className="todo-container marginBottom10">
					<div className="row">
						<div className="card ">
							<div className="header">
								<h4 className="title">General Info</h4>
								<span className="gadgeticons">
									<ul>
										<li onClick={this.collapseExpandHandler} id="GeneralInfoGadget">
											<i className="fa fa-minus" aria-hidden="true"></i>
										</li>
									</ul>
								</span>
							</div>
							<div className="content marginTop10 GeneralInfoGadget">
								<GeneralInfo company={this.state.company} />
							</div>
						</div>
					</div>
				</div>
				<div className="todo-container marginBottom10">
					<div className="row">
						<div className="card ">
							<div className="header">
								<h4 className="title">Invoice Routing Setup</h4>
								<span className="gadgeticons">
									<ul>
										<li onClick={this.collapseExpandHandler} id="CompanyRoutingSetupGadget">
											<i className="fa fa-minus" aria-hidden="true"></i>
										</li>
									</ul>
								</span>
							</div>
							<div className="col-md-12 form-group CompanyRoutingSetupGadget" style={{ marginTop: 10 }} >
								<div className="form-group">
									<div className="labeldiv col-md-3">
										<label>Approval Stamp Settings:</label>
									</div>
									<div className="fielddiv col-md-3">
										<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.settings_clickHandler}>
											<SettingsIcon />
										</IconButton>
									</div>
								</div>
								<div className="form-group">
									<div className="labeldiv col-md-3">
										<label>PO Filter By Date:</label>
									</div>
									<div className="labeldiv col-md-3">
										<InputCheckbox checkboxInfo={poFilterByDateInfo} changeHandler={this.changeHandler} />
									</div>
								</div>
								{companyApprovalConfigurationList}
							</div>
						</div>
					</div>
				</div>
				<div className="todo-container marginBottom10">
					<div className="row">
						<div className="card ">
							<div className="header">
								<h4 className="title">Invoice Landing Setup</h4>
								<span className="gadgeticons">
									<ul>
										<li onClick={this.collapseExpandHandler} id="APInvoiceLandingSetupGadget">
											<i className="fa fa-minus" aria-hidden="true"></i>
										</li>
									</ul>
								</span>
							</div>
							<div className="content marginTop10 APInvoiceLandingSetupGadget">
								<APInvoiceLandingSetup company={this.state.company} changeHandler={this.changeHandler} />
							</div>
						</div>
					</div>
				</div>
				<div className="todo-container marginBottom10">
					<div className="row">
						<div className="card ">
							<div className="header">
								<h4 className="title">Email Reminder Setup</h4>
								<span className="gadgeticons">
									<ul>
										<li onClick={this.collapseExpandHandler} id="EmailReminderSetupGadget">
											<i className="fa fa-minus" aria-hidden="true"></i>
										</li>
									</ul>
								</span>
							</div>
							<div className="content marginTop10 EmailReminderSetupGadget">
								{companyInvoiceConfigurationList}
							</div>
						</div>
					</div>
					<div style={{ height: 50 }}>
						&nbsp;
					</div>
				</div>

				<div className="floatingButtons">
					<button id="resetBtnMain" className="btn-grey" style={{ marginRight: 15 }} disabled={!this.state.isDirty} onClick={this.resetClickHandler}>
						Reset</button>
					<button id="saveBtnMain" className="btn-blue" disabled={!this.state.isDirty} onClick={this.updateClickHandler}>
						Update</button>
				</div>
				<Dialog open={this.state.openResetConfirmation} aria-labelledby="draggable-dialog-title">
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Reset changes
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							This action will discard changes on this screen and refresh the page. Do you want to continue?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.resetYesClickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={this.resetNoClickHandler} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>

				<ApprovalStampConfiguration
					anchorEl={this.state.anchorEl}
					readOnly={this.state.readOnly}
					approvalStampConfiguration={this.state.company.approvalStampConfiguration}
					onChange={this.changeHandler}
					onClose={this.popover_closeHandler} />
			</div>
		);
	}
}
