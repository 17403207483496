import * as React from 'react';
import { toast } from 'react-toastify';
import { checkForUnAuthorizedException, stringIsNullOrEmpty, formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

export default class PaymentDashboard extends React.Component {
	constructor() {
		super();
		const userInfo = JSON.parse(localStorage.userInfo);
		this.isAdmin = userInfo.selectedUserRoles.isAdmin;

		this.state = {
			paymentDashboard: []
		};
	}

	componentDidMount() {
		this.fetchPaymentDashboard();
	}

	fetchPaymentDashboard() {
		const that = this;
		var modelDefId = this.props.modelDefId;

		this.props.inProgress(true);

		httpGet("/api/dashboard/appayments/" + modelDefId)
			.then((response) => {
				var data = response.data;

				var paymentDashboard = data;

				this.setRowId(paymentDashboard);

				this.setState({ paymentDashboard: paymentDashboard });

				this.props.inProgress(false);
			}).catch((error) => {
				checkForUnAuthorizedException(that, error);
				this.props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	setRowId(data) {
		var rowId = 1;
		data.map(row => row.rowId = rowId++);
	}
	displayVendorName(rowData) {
		return stringIsNullOrEmpty(rowData.vendorName) ? rowData.vendorCode : rowData.vendorName;
	}
	render() {
		const fontSize = 12;
		const cellStyle = { fontSize: fontSize, padding: 8 };
		const fontStyle = { fontSize: fontSize, padding: 8 };

		const tableOptions = {
			pageSize: 10,
			pageSizeOptions: [10, 20, 30, 40, 50],
			minBodyHeight: 320,
			maxBodyHeight: 670,
			headerStyle: fontStyle,
			searchFieldStyle: fontStyle
		};

		return (
			<div>
				<h1>{this.props.modelDefId === 1 ? 'Media' : 'Production'} Payment Dashboard</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.paymentDashboard}
							disableColumnMenu
							pagination
							pageSize={10}					
							columnVisibilityModel={{
								//'mediaPlanName': this.props.info.modelDefId === 1,//hide: this.props.info.modelDefId === 2,	
								//'deliveryAmount': this.props.info.modelDefId === 1,//hide: this.props.info.modelDefId === 2,
								'jobCode':this.props.modelDefId === 2,//hide: (this.props.modelDefId !== 2),
								'activityCode': this.props.modelDefId === 2,//hide: (this.props.modelDefId !== 2),
								'campaignName': this.props.modelDefId !== 2,//hide: (this.props.modelDefId === 2),
								'mediaPlanName': this.props.modelDefId !== 2,//hide: (this.props.modelDefId === 2),
							}}
							columns={[
								{
									headerName: 'Vendor Name',
									field: 'vendorName',
									renderCell: rowData => { return this.displayVendorName(rowData.row); },
									width:150
								},
								{
									headerName: 'Vendor Invoice Number',
									field: 'invoiceNumber',
									width: 150
								},
								{
									headerName: 'Vendor Invoice Date',
									field: 'invoiceDate',
									type: 'date',
									valueGetter: ({value})=>value && new Date(value),
									width: 150
								},
								{
									headerName: 'Last Approved By',
									field: 'approvedBy',
									width: 150
								},
								{
									headerName: 'Campaign Name',
									field: 'campaignName',
									//hide: (this.props.modelDefId === 2),
									width: 150
								},
								{
									headerName: 'Media Plan',
									field: 'mediaPlanName',
									//hide: (this.props.modelDefId === 2),
									width: 150
								},
								{
									headerName: 'Job',
									field: 'jobCode',
									//hide: (this.props.modelDefId !== 2),
									width: 100
								},
								{
									headerName: 'Activity',
									field: 'activityCode',
									//hide: (this.props.modelDefId !== 2),
									width: 150
								},
								{
									headerName: (this.props.modelDefId === 2 ? 'PO' : 'IO'),
									field: 'ioNumber',
									width: 100
								},
								{
									headerName: 'Amount',
									field: 'amount',
									type: 'currency',
									headerAlign: 'right',
									align: 'right',
									renderCell: rowData => formatNumber(rowData.row.amount) + " " + rowData.row.currencyCode,
									width: 100
								},
								{
									headerName: 'Posted Date',
									field: 'postedDate',
									type: 'date',
									
									valueGetter: ({value})=>value && new Date(value),
									width: 150
								},
								{
									headerName: 'Payment Status',
									field: 'paymentStatus',
									cellStyle: cellStyle,
									width: 100
								},
								{
									headerName: 'Payment Amount',
									field: 'paymentAmount',
									type: 'currency',
									headerAlign: 'right',
									align: 'right',
									renderCell: rowData => formatNumber(rowData.row.paymentAmount) + " " + rowData.row.currencyCode,
									width: 150
								},
								{
									headerName: 'Payment Info',
									field: 'paymentInfo',
									width: 150
								}
							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
					
				</div>
			</div >
		);
	}
}
