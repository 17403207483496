import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';

export class PositionCategoryCodes extends React.Component {
	constructor() {
		super();

		this.state = {
			positionCategoryList: []
		};

		this.popover_handleClickOpen = this.popover_handleClickOpen.bind(this);
		this.popover_handleClose = this.popover_handleClose.bind(this);
		this.positionCategory_changeHandler = this.positionCategory_changeHandler.bind(this);
	}

	componentDidMount() {
		var positionCategoryList = this.props.info.positionCategoryList;

		positionCategoryList.map((positionCategory, index) => (
			positionCategory.checked = this.props.info.apadUserRolePositionCategories.findIndex(urpc => urpc.positionCategoryCode === positionCategory.positionCategoryCode) > -1));

		this.setState({ positionCategoryList: positionCategoryList });
	}

	positionCategory_changeHandler(event) {
		var positionCategoryList = this.state.positionCategoryList;
		positionCategoryList.find(pc => pc.positionCategoryCode === parseInt(event.target.value)).checked = event.target.checked;

		this.setState({ positionCategoryList: positionCategoryList });
	}

	popover_handleClickOpen(event) {
		this.setState({ anchorEl: event.currentTarget });
	}

	popover_handleClose(event) {
		this.setState({ anchorEl: null });

		if (this.props.onClose != null) {
			this.props.onClose(this.state.positionCategoryList.filter(pc => pc.checked));
		}
	}

	render() {
		var that = this;

		return (
			<div>
				<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.popover_handleClickOpen}>
					<SettingsIcon />
				</IconButton>
				<Popover
					open={this.state.anchorEl != null}
					anchorEl={this.state.anchorEl}
					onClose={this.popover_handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					{
						this.state.positionCategoryList.map((positionCategory, index) => (
							<MenuItem key={index}>
								<Checkbox key={index+1} color="default" checked={positionCategory.checked} value={positionCategory.positionCategoryCode} onChange={that.positionCategory_changeHandler} />
								<ListItemText key={index+2} primary={positionCategory.positionCategoryName} />
							</MenuItem>
						))
					}
				</Popover>
			</div>
		);
	}
}
