import React from 'react'
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { httpDelete, httpGet, httpPost, httpPut } from '../../HttpRequestHandling/httpRequestHandler';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import { fileUpload } from '../NonMappedInvoices/ManualUploadApi';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { formatDate } from '../Common/Utilities';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class ManualUpload extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isDirty: false,
			selectedFiles: null,
			openDelete: false,
			openInvoiceViewer: false
		};

		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.fileUpload_changeHandler = this.fileUpload_changeHandler.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.scan_clickHandler = this.scan_clickHandler.bind(this);
		this.deleteYes_clickHandler = this.deleteYes_clickHandler.bind(this);
		this.openDeleteDialog = this.openDeleteDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.invoiceDocumentRescan_clickHandler = this.invoiceDocumentRescan_clickHandler.bind(this);
		this.fetchManuallyUploadedInvoices();
	}

	fetchManuallyUploadedInvoices() {
		var that = this;

		this.props.info.inProgress(true, false, 0);

		httpGet('/api/nonmappedinvoices/manuallyuploadedinvoices/' + this.props.info.modelDefId)
			.then((response) => {
				//var data = response.data;
				var data = response.data.map((e, i) => { let d = { ...e, rowId: i }; return d; });
				this.props.info.inProgress(false, false, 0);
				that.setState({...this.state, data: data, isDirty: false });
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('An error occurred while fetching the records');
			});
	}

	refresh_clickHandler(event) {
		this.fetchManuallyUploadedInvoices();
	}

	fileUpload_changeHandler(event) {
		fileUpload(this, event, this.props.info.modelDefId, toast, httpPost,
			() => { this.props.info.inProgress(true, false, 0);},
			() => { this.props.info.inProgress(false, false, 0);},
			ProgressEvent => {
				this.props.info.inProgress(true, true, (ProgressEvent.loaded / ProgressEvent.total * 100));},
			() => this.fetchManuallyUploadedInvoices());
	}

	viewLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.state.data.find(d => d.documentManualUploadRecordId === parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.documentManualUploadFileLocation });
	}

	scan_clickHandler(event) {
		this.props.info.inProgress(true, false, 0);

		httpPut("/api/nonmappedinvoices/manuallyuploadedinvoices/" + event.value, null)
			.then(res => {
				this.props.info.inProgress(false, false, 0);

				toast.success('File Scan in Progress');
				this.fetchManuallyUploadedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('Couldn\'t start scanning');
			});
	}

	openDeleteDialog(event) {
		this.setState({...this.state, openDelete: true, documentManualUploadRecordId: event.value });
	}

	closeDialog(event) {
		this.setState({ openDelete: false, openInvoiceViewer: false });
	}

	deleteYes_clickHandler(event) {
		this.props.info.inProgress(true, false, 0);
		httpDelete("/api/nonmappedinvoices/manuallyuploadedinvoices/" + this.state.documentManualUploadRecordId)
			.then(res => {
				this.props.info.inProgress(false, false, 0);

				toast.success('Deleted Successfully');
				this.fetchManuallyUploadedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error(<div>Delete Failed<br />{error.response.data}</div>);
			});

		this.closeDialog(event);
	}

	displayUploadStatus(uploadStatus) {
		switch (uploadStatus) {
			case 1:
				return <div>Not Processed</div>;
			case 2:
				return <div>Scan in Progress</div>;
			case 3:
				return <div>Scanned, Invoice could not be mapped. Open in <a name="tabScannedSuccessful" onClick={() => { this.props.info.handleTabChange("tabScannedSuccessful") }}>Scanned Successfully</a> tab</div >;
			case 4:
				return <div>Scanned and partially mapped. Open in <a name="tabScannedSuccessful" onClick={() => { this.props.info.handleTabChange("tabScannedSuccessful") }}>Scanned Successfully</a> tab</div >;
			case 5:
				return <div>Scanned and mapped successfully. Open in <a href={this.props.info.basePath + "/manageinvoicedocuments"}>Manage Invoice Documents</a> screen</div >;
			case 6:
				return <div>Scan failed. Open in Scanner Failed tab</div>;
			case 7:
				return <div>Mapping and/or Vendor Assignment requested</div>;
			case 8:
				return <div>Routed for manual processing</div>;
			default:
				return <div>Unknown</div>;
		}
	}
	
	invoiceDocumentRescan_clickHandler(event) {
		event.preventDefault();
		
		var rowData = this.state.data.find(d => d.documentManualUploadRecordId === parseInt(event.target.dataset.rowid, 10));

		this.props.info.inProgress(true, false, 0);

		httpPost("/api/invoicedocument/scaninvoice/", rowData)
			.then(res => {
				this.props.info.inProgress(false, false, 0);
				toast.success('Invoice scan successfully');
				this.fetchManuallyUploadedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error(<div><div>Invoice Scan Failed</div>Please contact Nexelus support for further assistance<div></div></div>,
					{ allowHtml: true });
			});
	}
	render() {
		const info = {
			modelDefId: this.props.info.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			inProgress: this.props.info.inProgress,
			onClose: this.closeDialog
		}

		return (
			<div>
				<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
					<EnhancedDataGrid rows={this.state.data}
						disableColumnMenu
						pagination
						pageSize={10}
						columns={[
							{
								headerName: 'Uploaded File Name',
								field: 'fileName',
								width: 500,
								flex: 1
							},
							{
								headerName: 'Timestamp',
								field: 'documentManualUploadFileUpdateDate',
								type: 'datetime',
								valueFormatter: ({ value }) => value && formatDate(value),
								valueGetter: ({ value }) => value && new Date(value),
								
								flex: 0.4
							},
							{
								headerName: 'View',
								field: 'view', headerAlign: 'center',
								align: 'center',
								flex: 0.2, resizable: false, sortable: false,
								renderCell: rowData => (
									<Link data-rowid={rowData.row.documentManualUploadRecordId} href="#" onClick={this.viewLink_clickHandler}>View</Link>
								)
							},
							{
								headerName: 'Action',
								field:'action',
								headerAlign: 'center',
								align:'center',
								 flex: 0.5, resizable: false, sortable: false,
								renderCell: rowData => {
									var scanDate = rowData.row.scanDate;
									var Minutes = Math.abs(new Date() - new Date(scanDate)) / 60000;
									return <div>
										<div style={{ marginTop: 15, display: rowData.row.uploadStatus === 1 ? "" : "none" }}>
											<Link value={rowData.row.documentManualUploadRecordId}
												onClick={(event) => { this.scan_clickHandler({ ...event, value: rowData.row.documentManualUploadRecordId }) }}>Scan</Link>  |  <Link value={rowData.row.documentManualUploadRecordId} onClick={(event) => { this.openDeleteDialog({ ...event, value: rowData.row.documentManualUploadRecordId }) }}>Remove</Link>
										</div>

										<Link style={{ display: (scanDate && rowData.row.uploadStatus == 2 && Minutes > 30) ? "" : "none" }} data-rowid={rowData.row.documentManualUploadRecordId} data-mode="Rescan" href="#" onClick={this.invoiceDocumentRescan_clickHandler}>Scan Now</Link> </div>
								}
							},
							{
								headerName: 'Status',
								field: 'uploadStatus',
								width: 500,
								renderCell: rowData => this.displayUploadStatus(rowData.row.uploadStatus),
								flex:1
							}

						]}
						customcomponent={
							<div>
								{<Button
									variant="contained"
									color="inherit"
									onClick={this.refresh_clickHandler}
									size="medium"
									style={{ float: "right", zIndex: 1, marginRight: 10, marginTop: -2 }}
									startIcon={<RefreshIcon />}>Refresh</Button>}
								<input
									type="file"
									multiple
									accept="application/pdf,image/jpg,image/jpeg,image/png"
									id="contained-button-file"
									style={{ display: "none" }}
									onChange={this.fileUpload_changeHandler} />
								{<Button variant="contained" color="inherit" size="medium"
									style={{ float: "right", zIndex: 1, marginRight: 10, marginTop: -2 }}
									onClick={(event) => { document.getElementById('contained-button-file')?.click() }}
									startIcon={<CloudUploadIcon />}>Upload</Button>}
										
											</div>

							 }
						getRowId={(row) => row.rowId}
						disableSelectionOnClick
					/>
				</div>				
				<Dialog
					open={this.state.openDelete}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Delete
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this file?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.deleteYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
