import * as React from 'react';
import Button from '@mui/material/Button';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { toast } from 'react-toastify';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; 
import { GridRowModes, useGridApiRef } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { flushSync } from 'react-dom';



export default function TaxMaps(props) {

	const [state, setState] = React.useState({
		data: [],
		selectedRows: [],
		isDirty: false,
		isRowInProcessMode: false,
		processedRowId: null
	});
	const [isCommited, setIsCommited] = React.useState(false);
	const apiGridRef = useGridApiRef();
	const [trigger, setTrigger] = React.useState(false);
	const [key, setKey] = React.useState(false);
	React.useEffect(() => {
		fetchTaxMaps();
	}, []);
	React.useEffect(() => {
		if (state.isRowInProcessMode) {
			setState({ ...state, data: state.data.filter((e, i) => (e.apamInvoiceTaxTypeCode || '').trim()), isRowInProcessMode: false })
			setIsCommited(false);
		}
		if (state.isDirty) setTrigger(true);
	}, [isCommited]);

	React.useEffect(() => {
		if (state.isDirty && key && !state.isRowInProcessMode) {
			taxMaps_saveHandler();
			setState((...prev) => {
				let _p = prev[0];
				return { ..._p, isDirty: false }
			});
			setKey(false);
		}
		setTrigger(false);

	}, [trigger, key]);

	const saveChanges = React.useCallback(() => {
		flushSync(() => {
			if (state.processedRowId) {
				apiGridRef.current.stopRowEditMode({ id: state.processedRowId });
			}
			setKey(true);
		})


	}, [state.processedRowId, state.data]);

	const taxMaps_cancelHandler = React.useCallback((event) => {
		if (props.onClose) {
			props.onClose(event);
		}
	}, []);


	const taxMaps_saveHandler = React.useCallback((event) => {
		var data = {
			taxTypeCode: props.info.taxTypeCode,
			taxMaps: state.data
		};

		props.info.inProgress(true);

		httpPost("/api/setup/savetaxmaps/", data)
			.then((response) => {
				toast.success('Updated Successfully');
				props.info.inProgress(false);

				taxMaps_cancelHandler(null);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error(<div>Taxes Update Failed<br />{error.response.data.detail}</div>);
			});
	}, [state.data])

	const fetchTaxMaps = React.useCallback(() => {

		props.info.inProgress(true);

		httpGet('/api/setup/taxmaps/' + props.info.taxTypeCode)
			.then((response) => {
				var data = response.data;
				setState((prev) => ({ ...prev, data: data.map((e, i) => { let d = { ...e, rowId: i }; return d; }) }));

				props.info.inProgress(false);
			})
			.catch((error) => {
				props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}, [])

	/////////////////////////////////////////////////////////

	const getAddButton = React.useCallback(() => {
		return <IconButton
			variant="contained"
			title={'add'}
			onClick={handleClick}
			size="small"
			disabled={state.isRowInProcessMode }
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
		>
			<AddBoxIcon />
		</IconButton>;
	}, [state.data, state.isRowInProcessMode])
	const getDeleteButton = React.useCallback(() => {
		return <IconButton
			variant="contained"
			title={'delete'}
			onClick={handleRowDelete}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			disabled={state.selectedRows.length === 0 || state.isRowInProcessMode}
		>
			<DeleteIcon />
		</IconButton>;
	}, [state.selectedRows, state.isRowInProcessMode])


	const handleClick = React.useCallback(() => {
		const id = ([...state.data]?.sort((a, b) => b.rowId - a.rowId)[0]?.rowId + 1) || 0;
		setState((prev) => ({
			...prev, isDirty: true,
			isRowInProcessMode: true,
			processedRowId: id,
			data: [{
				rowId: id,
				apamInvoiceTaxTypeCode: "",
				isNew: true,
				taxTypeCode: props.info.taxTypeCode,
				taxTypeCodeNavigation: null
			}, ...prev.data]
		}));
		apiGridRef.current.startRowEditMode({ id: id, fieldToFocus: 'apamInvoiceTaxTypeCode' });
	}, [state.data]);

	const processRowUpdate = (newrow, oldrow) => { 
		let index = state.data.findIndex((e, i) => e.rowId == newrow.rowId);
		Object.assign(state.data[index], newrow);
		setState((...prev) => { let _p = prev[0]; return { ..._p, isDirty: true, processedRowId: null } })
		setIsCommited(true);
		return newrow;
	}

	const handleRowClick = React.useCallback((params, event) => {
		if (!params.isEditable) {
			return;
		}
		if (!event.currentTarget.contains(event.target)) {
			return;
		}
		if (params.cellMode == GridRowModes.View) //GridRowModes.Edit)
		{
			setState({ ...state, isRowInProcessMode: true, isDirty:true });
			apiGridRef.current.startRowEditMode({ id: params.id });
		}
	}, [state])
	const handleRowSelection = React.useCallback((rows) => {
		let selectedRows = rows.map((e, i) => e.rowId);
		setState((...prev) => {
			let _p = prev[0];
			return { ..._p, selectedRows: selectedRows }
		});
	}, [state])

	const handleRowDelete = React.useCallback(() => {
		setState((...prev) => {
			return { ...prev, isDirty: true, selectedRows: [], data: state.data.filter((e, i) => (state.selectedRows.indexOf(e.rowId) === -1)) }
		});
	}, [state])


	//////////////////////////////////////////////////////////
		return (
			<div>
				<h1>Tax Mappings  {props.info.taxTypeCode && <span>({props.info.taxTypeCode})</span>}</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={state.data}
							disableColumnMenu
							pagination
							pageSize={10}

							columns={[
								{
									headerName: 'Invoice Tax Type',
									field: 'apamInvoiceTaxTypeCode',
									flex: 1,
									editable:true
								}
							]}
							getRowId={(row) => { return row.rowId }}
							customcomponent={
								(<>
									{getAddButton()}
									{getDeleteButton()}
								</>)
							}
							apiRef={apiGridRef}
							editMode="row"
							onCellClick={handleRowClick}
							onSelectionChange={handleRowSelection}
							processRowUpdate={processRowUpdate}
							disableSelectionOnClick
							options={{
								actionsColumnIndex: -1,
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670
							}}
							editable={
								{
									onRowAdd: newData =>
										new Promise((resolve, reject) => {
											const taxMaps = state.data;

											newData.taxTypeCode = props.info.taxTypeCode;
											taxMaps.push(newData);

											setState({ data: taxMaps, isDirty: true }, () => resolve());
										}),
									onRowUpdate: (newData, oldData) =>
										new Promise((resolve, reject) => {
											const taxMaps = state.data;
											const index = taxMaps.indexOf(oldData);
											taxMaps[index] = newData;

											setState({ data: taxMaps, isDirty: true }, () => resolve());
										}),
									onRowDelete: oldData =>
										new Promise((resolve, reject) => {
											const taxMaps = state.data;
											const index = taxMaps.indexOf(oldData);
											taxMaps.splice(index, 1);

											setState({ data: taxMaps, isDirty: true }, () => resolve());
										}),
								}}
						/>
					</div>
				</div>
				<Button color="primary" onClick={taxMaps_cancelHandler} style={{ float: "right" }}>
					Cancel
				</Button>
				<Button autoFocus color="primary" disabled={!state.isDirty} onClick={saveChanges} style={{ float: "right" }}>
					Save
				</Button>
			</div>
		);
}