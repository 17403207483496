import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { InvoiceModelSetup } from './InvoiceModelSetup/InvoiceModelSetup';
import { VendorLookup } from '../Lookups/VendorLookup';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export default class ManageInvoiceModel extends React.Component {
	constructor() {
		super();

		this.callsInProgress = 0;

		this.state = {
			data: [],
			openInvoiceViewer: false,
			openInvoiceModelSetupDialog: false,
			openVendorLookupDialog: false,
			rowData: null,
			invoiceDocumentSampleFileLocation: ""
		};

		this.inProgress = this.inProgress.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.invoiceModelSetupLink_clickHandler = this.invoiceModelSetupLink_clickHandler.bind(this);
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.newButton_clickHandler = this.newButton_clickHandler.bind(this);
		this.invoiceModelSetup_closeHandler = this.invoiceModelSetup_closeHandler.bind(this);
		this.vendorLookupLink_clickHandler = this.vendorLookupLink_clickHandler.bind(this);
		this.vendorDialog_closeHandler = this.vendorDialog_closeHandler.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
	}

	componentDidMount() {
		this.fetchInvoiceModels();
	}

	inProgress(value, uploading, loaded, invoiceDocumentSampleFileLocation) {
		if (!uploading) {
			if (value === true) {
				this.callsInProgress++;
			}
			else if (value === false) {
				if (this.callsInProgress > 0) {
					this.callsInProgress--;
				}

				if (this.callsInProgress === 0) {
					if (invoiceDocumentSampleFileLocation) {
						this.setState({ invoiceDocumentSampleFileLocation: invoiceDocumentSampleFileLocation });
					}
				}
			}
		}

		return this.props.inProgress(value, uploading, loaded);
	}

	fetchInvoiceModels() {
		var that = this;
		var modelDefId = this.props.modelDefId;

		this.props.inProgress(true);

		httpGet('/api/invoicemodels/' + modelDefId)
			.then((response) => {
				var data = response.data;

				var rowId = 1;
				data.map(row => row.rowId = rowId++);

				this.props.inProgress(false);

				that.setState({data: data });
			})
			.catch((error) => {
				this.props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	newButton_clickHandler(event) {
		this.setState({ openInvoiceModelSetupDialog: true, mode: "NewSample", rowData: null });
	}

	checkbox_clickHandler(event) {
		var rowData = this.getRowData(parseInt(event.target.value, 10));
		rowData.documentModelIsActive = event.target.checked ? 'Y' : 'N';

		this.props.inProgress(true);

		httpPost("/api/invoicemodels/", rowData)
			.then((response) => {
				this.props.inProgress(false);

				toast.success('Updated Successfully');

				this.fetchInvoiceModels();
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error(<div>Invoices Update Failed<br />{error.response.data.detail}</div>);
			});
	}

	viewLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.getRowData(parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.invoiceDocumentFileLocation });
	}

	invoiceModelSetupLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.getRowData(parseInt(event.target.dataset.rowid, 10));

		this.setState({  openInvoiceModelSetupDialog: true, mode: event.target.dataset.mode, rowData: rowData });
	}

	invoiceModelSetup_closeHandler(event) {
		this.setState({  openInvoiceModelSetupDialog: false });

		this.fetchInvoiceModels();
	}

	vendorLookupLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.getRowData(parseInt(event.target.dataset.rowid, 10));

		this.setState({ openVendorLookupDialog: true, mode: event.target.dataset.mode, rowData: rowData });
	}

	vendorDialog_closeHandler(event, vendor) {
		var rowData = this.state.rowData;
		var saveVendor = vendor && (rowData.vendorCode !== vendor.vendorCode || rowData.siteId !== vendor.siteId);

		this.setState({  openVendorLookupDialog: false });

		if (saveVendor) {
			rowData.vendorCode = vendor.vendorCode;
			rowData.siteId = vendor.siteId;

			this.props.inProgress(true);

			httpPost("/api/invoicemodels/", rowData)
				.then((response) => {
					this.props.inProgress(false);

					toast.success('Updated Successfully');

					this.fetchInvoiceModels();
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error(<div>Invoices Update Failed<br />{error.response.data.detail}</div>);
				});
		}
		else {
			this.props.inProgress(false);
		}
	}

	closeDialog(event) {
		this.setState({  openInvoiceViewer: false });
	}

	getRowData(value) {
		var rowData = this.state.data;
		return rowData[rowData.findIndex(d => d.rowId === value)];
	}

	displayActions(rowData) {
		switch (rowData.action) {
			case 1:
				return <div>
					<Link data-rowid={rowData.rowId} href="#" data-mode="ViewInvoice" onClick={this.viewLink_clickHandler}>View Invoice</Link> / <Link data-rowid={rowData.rowId} data-mode="New" href="#" onClick={this.invoiceModelSetupLink_clickHandler}>New</Link>
				</div>;
			case 2:
				return <div>
					<Link data-rowid={rowData.rowId} href="#" data-mode="ViewInvoice" onClick={this.viewLink_clickHandler}>View Invoice</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="AssignVendor" onClick={this.invoiceModelSetupLink_clickHandler}>Assign Vendor</Link>
				</div>;
			case 3:
				return <div>
					<Link data-rowid={rowData.rowId} href="#" data-mode="ViewInvoice" onClick={this.viewLink_clickHandler}>View Invoice</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="Edit" onClick={this.invoiceModelSetupLink_clickHandler}>Edit</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="Copy" onClick={this.invoiceModelSetupLink_clickHandler}>Copy</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="VendorLookup" onClick={this.vendorLookupLink_clickHandler}>Assign Vendor</Link>
				</div>;
			case 4:
				return <div>
					<Link data-rowid={rowData.rowId} href="#" data-mode="ViewInvoice" onClick={this.viewLink_clickHandler}>View Invoice</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="Edit" onClick={this.invoiceModelSetupLink_clickHandler}>Edit</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="Copy" onClick={this.invoiceModelSetupLink_clickHandler}>Copy</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="VendorLookup" onClick={this.vendorLookupLink_clickHandler}>Reassign Vendor</Link>
				</div>;
			case 5:
				return <div>
					<Link data-rowid={rowData.rowId} href="#" data-mode="ViewInvoice" onClick={this.viewLink_clickHandler}>View Invoice</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="View" onClick={this.invoiceModelSetupLink_clickHandler}>View</Link> / <Link data-rowid={rowData.rowId} href="#" data-mode="Copy" onClick={this.invoiceModelSetupLink_clickHandler}>Copy</Link>
				</div>;
			default:
				return <div>Unknown</div>;
		}
	}

	render() {
		const info = {
			rowData: this.state.rowData,
			mode: this.state.mode,
			inProgress: this.inProgress,
			modelDefId: this.props.modelDefId,
			invoiceDocumentSampleFileLocation: this.state.invoiceDocumentSampleFileLocation
		}

		const vendorInfo = {
			openDialog: this.state.openVendorLookupDialog,
			invoiceModel: {
				vendorCode: this.state.rowData ? this.state.rowData.vendorCode : null,
				siteId: this.state.rowData ? this.state.rowData.siteId : null
			},
			inProgress: this.props.inProgress,
			mode: this.state.mode
		}

		const invoiceViewerInfo = {
			inProgress: this.props.inProgress,
			modelDefId: this.props.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			onClose: this.closeDialog
		}

		return (
			<div>
				<h1>Manage {this.props.modelDefId === 1 ? 'Media' : 'Production'} Invoice Models</h1>
				<div className="form-group">
					
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							
							columns={[
								{
									headerName: 'ID',
									field: 'id',
									width: 100
								},
								{
									headerName: 'Model Name',
									field: 'documentModelName',
									width: 140,
									flex:0.8
								},
								{
									headerName: 'Model Tag',
									field: 'documentModelNameTag',
									flex:1
								},
								{
									headerName: 'Model Status',
									field: 'modelStatus',
									flex:1
								},
								{
									headerName: 'Active',
									field: 'documentModelIsActive',
									headerStyle: {
										textAlign: 'left',
									},
									width: 100,
									renderCell: rowData =>
										<Checkbox
											color="default"
											checked={rowData.row.documentModelIsActive === 'Y'}
											name={"documentModelIsActive" + rowData.row.id}
											disabled={rowData.row.vendor.length === 0}
											value={rowData.row.rowId}
											style={{ display: rowData.row.action === 1 ? "none" : "" }}
											onClick={this.checkbox_clickHandler}
										/>
								},
								{
									headerName: 'ERP Vendor',
									field: 'vendor',
									flex:1
								},
								{
									headerName: 'ERP Vendor Site',
									field: 'siteId',
									width: 140,
									flex:1
								},
								{
									headerName: 'Actions',
									field: 'actions',
									align: 'center',
									headerAlign:'center',
									width: 230,
									headerStyle: {
										textAlign: 'center',
									},
									cellStyle: {
										textAlign: 'center'
									},
									flex:1,
									renderCell: rowData => this.displayActions(rowData.row)
								}

							]}
							customcomponent={<Button
								variant="contained"
								color="inherit"
								onClick={this.newButton_clickHandler}
								size="medium"
								style={{ float: "right", zIndex: 1, marginRight: 10, marginTop:-2 }}
								startIcon={<PostAddIcon />}>New Model</Button> }
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
					
				</div>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={invoiceViewerInfo} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog fullScreen open={this.state.openInvoiceModelSetupDialog} TransitionComponent={Transition}>
					<InvoiceModelSetup info={info} onClose={this.invoiceModelSetup_closeHandler} />
				</Dialog>
				<VendorLookup info={vendorInfo} onClose={this.vendorDialog_closeHandler} />
			</div>
		);
	}
}
