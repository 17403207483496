import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { formatNumber, isNullOrUndefined, stringIsNullOrEmpty } from '../Common/Utilities';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';


import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class POHeaderLookup extends React.Component {
	constructor(props) {
		super(props);

		this.vendorCode = null;
		this.siteId = null;
		this.serviceTermStartDate = null;
		this.serviceTermEndDate = null;
		this.invoiceDocumentId = 0;
		this.searchText = "";
		this.inProgress = false;
		this.doFetchRecords = true;

		this.state = {
			poCode: null,
			data: [],
			rowData: null
		};

		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.gridRef = React.createRef();
	}

	componentDidUpdate() {
		var that = this;

		if (this.props.info.vendorCode !== this.vendorCode ||
			this.props.info.siteId !== this.siteId ||
			this.props.info.serviceTermStartDate !== this.serviceTermStartDate ||
			this.props.info.serviceTermEndDate !== this.serviceTermEndDate ||
			this.props.info.invoiceDocumentId !== this.invoiceDocumentId) {
			this.vendorCode = this.props.info.vendorCode;
			this.siteId = this.props.info.siteId;
			this.serviceTermStartDate = this.props.info.serviceTermStartDate;
			this.serviceTermEndDate = this.props.info.serviceTermEndDate;
			this.invoiceDocumentId = this.props.info.invoiceDocumentId;

			this.doFetchRecords = true;
			this.inProgress = false;

			this.setState({ data: [], rowData: null });
		}

		if (this.props.info.openDialog && this.state.data.length === 0 && this.doFetchRecords && !this.inProgress) {
			this.inProgress = true;
			this.fetchRecords();
		}
		else if (this.props.info.poCode !== this.state.poCode) {
			var rowData = this.getRowData(this.props.info.poCode);
			this.setState({ poCode: this.props.info.poCode, rowData: rowData });
		}
	}

	checkbox_clickHandler(event) {
		var rowData = this.getRowData(event.target.value);
		this.setState({ rowData: rowData });
	}

	close_clickHandler(event) {
		this.searchText = "";

		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}
	handleClose = (event) => {
		this.props.onClose(event, this.state.rowData);
	};
	refresh_clickHandler(event) {
		if (!stringIsNullOrEmpty(this.gridRef?.current?.filterText)) {
			this.props.info.inProgress(true);

			httpGet("/api/invoicedocument/refetchpoinfo/" + this.props.info.modelDefId + '/' + this.gridRef?.current?.filterText)
				.then((response) => {
					var data = response.data;
					this.doFetchRecords = true;
					this.props.info.inProgress(false);
					let poexist = this.state.data.some((e, i) => e.poCode === data.poCode);
					if (!poexist) {
						data.rowId = (this.state?.data?.length || 0) + 5000 + 1;
					}
					//this.setState({ data: [...this.state.data,data] });
					this.setState({ data: [] });

				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error('Error fetching IO/PO Number Lookup');
				});
		}
	}

	fetchRecords() {
		if (this.state.data.length === 0) {
			var url = "/api/lookup/poheaderlistbyvendor/" + encodeURIComponent(this.props.info.vendorCode) + "/" + encodeURIComponent(this.props.info.siteId) + "/" + this.props.info.modelDefId;
			url += "/" + (isNullOrUndefined(this.props.info.invoiceDocumentId) ? 0 : this.props.info.invoiceDocumentId);

			if (!stringIsNullOrEmpty(this.props.info.serviceTermStartDate) && !stringIsNullOrEmpty(this.props.info.serviceTermEndDate)) {
				url += "/" + this.props.info.serviceTermStartDate + "/" + this.props.info.serviceTermEndDate;
			}

			this.props.info.inProgress(true);

			httpGet(url)
				.then((response) => {
					var data = response.data.map((e, i) => ({...e,rowId:i}));
					var rowData = data[data.findIndex(d => d.poCode === this.props.info.poCode)];

					this.inProgress = false;
					this.doFetchRecords = false;
					this.setState({ vendorCode: this.props.info.vendorCode, siteId: this.props.info.siteId, poCode: this.props.info.poCode, data: data, rowData: rowData });
					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error('Error fetching IO/PO Number Lookup');

					this.close_clickHandler();
				});
		}
	}

	getRowData(value) {
		var data = this.state.data;

		return data[data.findIndex(d => d.poCode === value)];
	}

	getRefreshButton() {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}>
			<RefreshIcon />
		</IconButton>;
	}

	render() {
		const cellStyle = { paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Select IO/PO Number
					<IconButton
						aria-label="close"
						onClick={this.handleClose}
						style={{
							position: "absolute",
							right: 8,
							float:"right",
							top: 8,
							color: (theme) => theme.palette.grey[500]
						}}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				
				<DialogContent>
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							gridRef={this.gridRef}
							columnVisibilityModel={{
								'mediaPlanName': this.props.info.modelDefId === 1,//hide: this.props.info.modelDefId === 2,	
								'deliveryAmount': this.props.info.modelDefId === 1,//hide: this.props.info.modelDefId === 2,
							}}
							columns={[
								{
									headerName: ' ',
									field: 'chkPOCode',
									width: 40,
									resizable: false, sortable: false,
									renderCell: rowData =>
										<Checkbox
											color="default"
											name="chk"
											checked={this.state.rowData != null && this.state.rowData !== undefined && rowData.row.poCode === this.state.rowData.poCode}
											value={rowData.row.poCode}
											onClick={this.checkbox_clickHandler}
										/>
								},
								{
									headerName: (this.props.info.modelDefId === 2 ? 'PO Number' : 'IO/PO Number'),
									field: 'poCode',									
									flex: 0.6
								},
								{
									headerName: 'IO/PO Amount',
									field: 'poAmount',
									type: 'currency',
									align: 'right',
									headerAlign: 'right',
									renderCell: rowData => formatNumber(rowData.row.poAmount),
									flex: 0.5
								},
								{
									headerName: 'Consumed Amount',
									field: 'consumedAmount',
									type: 'currency',
									align: 'center',
									headerAlign: 'center',
									renderCell: rowData => formatNumber(rowData.row.consumedAmount),
									flex: 0.5
								},
								{
									headerName: 'Remaining Amount',
									type: 'currency',
									field:'remainingAmount',
									align: 'center',
									headerAlign: 'center',
									renderCell: rowData => formatNumber(rowData.row.poAmount - rowData.row.consumedAmount),
									flex: 0.5
								},
								{
									headerName: 'Delivery Amount',
									field: 'deliveryAmount',
									type: 'currency',
									align: 'center',
									headerAlign: 'center',
									
									renderCell: rowData => formatNumber(rowData.row.deliveryAmount),
									flex: 0.5
								},
								{
									headerName: 'Currency',
									field: 'currencyCode',
									flex: 0.4
								},
								{
									headerName: 'Client Name',
									field: 'clientName',
									flex: 0.5
								},
								{
									headerName: 'Job Code',
									field: 'jobCode',
									flex: 0.5
								},
								{
									headerName: (this.props.info.modelDefId === 2 ? 'Job Name' : 'Campaign Name'),
									field: 'campaignName',
									flex: 1
								},
								{
									headerName: 'Media Plan Name',
									field: 'mediaPlanName',									
									flex: 1

								}

							]}
							customcomponent={this.getRefreshButton() }
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Select
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
