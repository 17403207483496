import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    //padding: theme.spacing(0, 2),
    //height: '90%',
    marginTop:'5px',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    
    '& .MuiInputBase-input': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
        //padding: theme.spacing(1, 1, 1, 0),        
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '25ch',
        },
    },
}));
export class InputSearch extends React.Component {
    constructor() {
        super();

        this.state = {
            readOnly: true,
            value: ""
        }
    }
    render() {
       
        return (<>
            <Search style={this.props.wraperStyle}>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase value={this.props.value}
                    style={this.props.inputStyle}
                    placeholder={this.props.placeholder}
                    autoFocus={this.props.autoFocus}
                    onChange={this.props.onChange}
                    inputProps={{ 'aria-label': 'search' }}
                    readOnly={this.props.readOnly}
                />               
            </Search>
        </>);
    }
}


