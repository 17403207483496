import React from 'react'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import Zoom from '@mui/material/Zoom';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { formatDate } from '../Common/Utilities';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class ScannedSuccessful extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isDirty: false,
			openInvoiceViewer: false
		};

		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.submit_clickHandler = this.submit_clickHandler.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
	}

	componentDidMount() {
		this.fetchScannedInvoices();
	}

	fetchScannedInvoices() {
		var that = this;

		this.props.info.inProgress(true, false, 0);

		httpGet('/api/nonmappedinvoices/scannedinvoices/10/' + this.props.info.modelDefId)
			.then((response) => {
				//var data = response.data;
				var data = (response.data || []).map((e, i) => { let d = { ...e, rowId: i }; return d });
				this.props.info.inProgress(false, false, 0);
				that.setState({ data: data, isDirty: false });
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	refresh_clickHandler(event) {
		this.fetchScannedInvoices();
	}

	viewLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.state.data.find(d => d.scannerMonitorRecordId === parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.invoiceDocumentFileLocation });
	}

	checkbox_clickHandler(event) {
		var rows = this.state.data;
		var checkbox = event.target;
		var scannerMonitorRecordId = parseInt(checkbox.name.substr("scannedSuccessful".length, checkbox.name.length), 10);
		var index = rows.findIndex(d => d.scannerMonitorRecordId === scannerMonitorRecordId);

		rows[index].requestToMap = false;
		rows[index].manualProcessing = false;
		rows[index].remove = false;

		rows[index][checkbox.value] = checkbox.checked;

		this.setState({ data: rows, isDirty: true });
	}

	submit_clickHandler(event) {
		this.props.info.inProgress(true, false, 0);

		httpPost("/api/nonmappedinvoices/scannedinvoices/", this.state.data)
			.then((response) => {
				this.props.info.inProgress(false, false, 0);

				toast.success("Records saved successfully");
				this.fetchScannedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('An error occurred while updating the records');
			});
	}

	closeDialog(event) {
		this.setState({ openInvoiceViewer: false });
	}

	render() {
		const info = {
			modelDefId: this.props.info.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			inProgress: this.props.info.inProgress,
			onClose: this.closeDialog
		}

		return (
			<div>
				
				<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
					<EnhancedDataGrid rows={this.state.data}
						disableColumnMenu
						pagination
						pageSize={10}
						
						columns={[
							{
								headerName: 'File Name',
								field: 'fileName',
								width: 500,
								flex: 1
							},
							{
								headerName: 'Timestamp',
								field: 'invoiceDocumentScanUpdateDate',
								type: 'datetime',
								valueFormatter: ({ value }) => value && formatDate(value),
								valueGetter: ({ value }) => value && new Date(value),
								flex: 0.4
							},
							{
								headerName: 'View',
								field: 'view', headerAlign: 'center',
								align: 'center',
								flex: 0.2, resizable: false, sortable: false,
								renderCell: rowData => (
									<Link data-rowid={rowData.row.scannerMonitorRecordId} href="#" onClick={this.viewLink_clickHandler}>View</Link>
								)
							},
							{
								headerName: 'Request for Mapping and/or Vendor Assignment',
								type: 'boolean',
								field: 'requestForMapping',
								headerAlign: 'center',
								align: 'center',
								flex: 0.5, resizable: false, sortable: false,
								renderCell: rowData =>
									<Checkbox
										color="default"
										checked={rowData.row.requestToMap}
										name={"scannedSuccessful" + rowData.row.scannerMonitorRecordId}
										value="requestToMap"
										onClick={this.checkbox_clickHandler}
									/>
							},
							{
								headerName: 'Route for Manual Processing',
								type: 'boolean',
								field: 'requestForManualProcessing',
								headerAlign: 'center',
								align: 'center',
								flex: 0.5, resizable: false, sortable: false,
								renderCell: rowData =>
									<Checkbox
										color="default"
										checked={rowData.row.manualProcessing}
										name={"scannedSuccessful" + rowData.row.scannerMonitorRecordId}
										value="manualProcessing"
										onClick={this.checkbox_clickHandler}
									/>
							},
							{
								headerName: 'Remove',
								type: 'boolean',
								field: 'remove',
								headerAlign: 'center',
								align: 'center',
								flex: 0.2, resizable: false, sortable: false,
								renderCell: rowData =>
									<Checkbox
										color="default"
										checked={rowData.row.remove}
										name={"scannedSuccessful" + rowData.row.scannerMonitorRecordId}
										value="remove"
										onClick={this.checkbox_clickHandler}
									/>
							}


						]}
						customcomponent={<Button
							variant="contained"
							color="inherit"
							onClick={this.refresh_clickHandler}
							size="medium"
							style={{ float: "right", zIndex: 1, marginRight: 10,marginTop:-2 }}
							startIcon={<RefreshIcon />}>Refresh</Button> }
						getRowId={(row) => row.rowId}
						disableSelectionOnClick
					/>
				</div>

				<div align="right" style={{ paddingTop: 10 }}>
					<Button variant="contained" size="large" onClick={this.submit_clickHandler} disabled={!this.state.isDirty}>Submit</Button>
				</div>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
