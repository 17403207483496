import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Link from '@mui/material/Link';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { formatNumber, defaultStringIfNullOrEmpty, formatDate } from '../Common/Utilities';
import { httpGet, httpPost, httpDelete } from '../../HttpRequestHandling/httpRequestHandler';
import { InvoiceDocumentRoutingHistory } from './InvoiceDocumentRoutingHistory';
import { InvoiceDocumentEditor } from './InvoiceDocumentEditor';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class InvoiceDocument extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			isDirty: false,
			openInvoiceDocumentEditorDialog: false,
			openInvoiceDocumentRoutingHistoryDialog: false,
			showBulkPostToERP: false,
			openDeleteConfirmation: false
		};

		this.newInvoice_clickHandler = this.newInvoice_clickHandler.bind(this);
		this.invoiceDocument_clickHandler = this.invoiceDocument_clickHandler.bind(this);
		this.invoiceDocumentRescan_clickHandler = this.invoiceDocumentRescan_clickHandler.bind(this);
		this.invoiceDocumentPostToERP_clickHandler = this.invoiceDocumentPostToERP_clickHandler.bind(this);
		this.bulkPostToERP_clickHandler = this.bulkPostToERP_clickHandler.bind(this);
		this.invoiceDocumentRoutingHistory_clickHandler = this.invoiceDocumentRoutingHistory_clickHandler.bind(this);
		this.invoiceDocumentEditor_closeHandler = this.invoiceDocumentEditor_closeHandler.bind(this);
		this.invoiceDocumentRoutingHistoryDialog_closeHandler = this.invoiceDocumentRoutingHistoryDialog_closeHandler.bind(this);
		this.invoiceDocumentDelete_clickHandler = this.invoiceDocumentDelete_clickHandler.bind(this);
		this.deleteYes_clickHandler = this.deleteYes_clickHandler.bind(this);
		this.deleteNo_clickHandler = this.deleteNo_clickHandler.bind(this);
	}

	componentDidMount() {
		this.fetchInvoiceDocuments();
	}

	fetchInvoiceDocuments() {
		var that = this;
		var modelDefId = this.props.modelDefId;

		this.props.inProgress(true);

		httpGet('/api/invoicedocument/' + modelDefId)
			.then((response) => {
				//var data = response.data;
				let data = (response.data || []).map((e, i) => { let d = { ...e, rowId: i }; return d; });
				that.setState({ data: data, isDirty: false });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	newInvoice_clickHandler(event) {
		this.setState({ openInvoiceDocumentEditorDialog: true, mode: "UserEntry", rowData: null });
	}

	invoiceDocument_clickHandler(event) {
		event.preventDefault();

		var rowData = this.getRowData(parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceDocumentEditorDialog: true, mode: event.target.dataset.mode, rowData: rowData });
	}

	invoiceDocumentRescan_clickHandler(event) {
		event.preventDefault();

		var rowData = this.getRowData(parseInt(event.target.dataset.rowid, 10));

		this.props.inProgress(true);

		httpPost("/api/invoicedocument/rescan/", rowData)
			.then(res => {
				this.props.inProgress(false);

				toast.success('Invoice rescanned successfully');
				this.fetchInvoiceDocuments();
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Invoice rescanning failed');
			});
	}

	invoiceDocumentPostToERP_clickHandler(event) {
		event.preventDefault();

		var data = [parseInt(event.target.dataset.rowid, 10)];

		this.postToERP(data);
	}

	bulkPostToERP_clickHandler(event) {
		var selectedRows = this.state.selectedRows;
		var data = [];

		selectedRows.map(function (row, index) {
			data.push(row.invoiceDocumentId);
		});

		this.postToERP(data);
	}

	invoiceDocumentRoutingHistory_clickHandler(event) {
		event.preventDefault();

		var rowData = this.getRowData(parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceDocumentRoutingHistoryDialog: true, mode: event.target.dataset.mode, rowData: rowData });
	}

	invoiceDocumentEditor_closeHandler(event) {
		this.setState({ openInvoiceDocumentEditorDialog: false, rowData: null });

		this.fetchInvoiceDocuments();
	}

	invoiceDocumentRoutingHistoryDialog_closeHandler(event) {
		this.setState({ openInvoiceDocumentRoutingHistoryDialog: false });
	}

	invoiceDocumentDelete_clickHandler(event) {
		event.preventDefault();

		this.setState({ openDeleteConfirmation: true, invoiceDocumentId: event.target.dataset.rowid });
	}

	deleteYes_clickHandler(event) {
		event.preventDefault();

		this.props.inProgress(true);

		httpDelete("/api/invoicedocument/" + this.state.invoiceDocumentId)
			.then(res => {
				this.props.inProgress(false);

				toast.success('Invoice deleted successfully');
				this.fetchInvoiceDocuments();
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error(<div>Invoice delete failed<br />{error.response.data}</div>);				
			});

		this.setState({ openDeleteConfirmation: false, invoiceDocumentId: null });
	}

	deleteNo_clickHandler(event) {
		this.setState({ openDeleteConfirmation: false, invoiceDocumentId: null });
	}

	postToERP(data) {
		this.props.inProgress(true);

		httpPost("/api/adapter/postinvoices/", data)
			.then(res => {
				this.props.inProgress(false);

				toast.success('Invoice(s) post request sent');
				this.fetchInvoiceDocuments();
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Invoice(s) posting failed');
			});
	}

	getRowData(value) {
		var rowData = this.state.data;
		return rowData[rowData.findIndex(d => d.invoiceDocumentId === value)];
	}

	getViewLink(rowData) {
		return <Link data-rowid={rowData.invoiceDocumentId} data-mode="View" href="#" onClick={this.invoiceDocument_clickHandler}>View</Link>
	}

	getMakeCorrectionsLink(rowData) {
		return <Link data-rowid={rowData.invoiceDocumentId} data-mode={rowData.action === 2 && rowData.invoiceDocumentStatusAttribute !== "C" ? "ManualEntry" : "MakeCorrections"} href="#" onClick={this.invoiceDocument_clickHandler}>{rowData.action === 2 && rowData.invoiceDocumentStatusAttribute !== "C" ? "Manual Entry" : "Make Corrections"}</Link>
	}

	getApprovalLink(rowData) {
		return rowData.approvalLinkText.length > 0 ? <Link data-rowid={rowData.invoiceDocumentId} data-mode="Approval" href="#" onClick={rowData.approvalLinkText === "Post to ERP System" || rowData.approvalLinkText === "Repost to ERP System" ? this.invoiceDocumentPostToERP_clickHandler : this.invoiceDocument_clickHandler}> {rowData.approvalLinkText}</Link > : <></>;
	}

	getApprovalHistoryLink(rowData) {
		return <Link data-rowid={rowData.invoiceDocumentId} data-mode="ViewRoutingHistory" href="#" onClick={this.invoiceDocumentRoutingHistory_clickHandler}>View Approval History</Link>
	}

	getDeleteLink(rowData) {
		return <Link data-rowid={rowData.invoiceDocumentId} data-mode="Delete" href="#" onClick={this.invoiceDocumentDelete_clickHandler}>Delete</Link>
	}

	displayActions(rowData) {
		var viewLink = this.getViewLink(rowData);
		var makeCorrectionsLink = this.getMakeCorrectionsLink(rowData);
		var approvalLink = this.getApprovalLink(rowData);
		var approvalHistoryLink = this.getApprovalHistoryLink(rowData);
		var deleteLink = this.getDeleteLink(rowData);

		var slashCharacter = rowData.approvalLinkText.length > 0 ? "/" : "";

		switch (rowData.action) {
			case 0:
			case 1:
				return <div>
					{viewLink} {slashCharacter} {approvalLink} / {deleteLink}
				</div>;
			case 2:
				return <div>
					{viewLink} / {makeCorrectionsLink} {slashCharacter} {approvalLink} / {deleteLink}
				</div>;
			case 3:
				var dataMode = "ManualEntry";
				var linkText = "Manual Entry";
				var clickHandler = this.invoiceDocument_clickHandler;

				if (rowData.invoiceDocumentStatusId === 30) {
					dataMode = "Rescan";
					linkText = "Rescan";
					clickHandler = this.invoiceDocumentRescan_clickHandler;
				}

				return <div>
					{viewLink} / <Link data-rowid={rowData.invoiceDocumentId} data-mode={dataMode} href="#" onClick={clickHandler}>{linkText}</Link> / {deleteLink}
				</div>;
			case 4:
			case 5:
				return <div>
					{viewLink} / {makeCorrectionsLink} / {deleteLink}
				</div>;
			case 6:
				//if(rowData.approvalLinkText !== postToERPSystem && rowData.approvalLinkText !== repostToERPSystem && rowData.approvalLinkText !== readyToPost)
				if (rowData.pendingStatus == "Ready to Post")
					return <div>
						{viewLink} / {approvalHistoryLink} / {approvalLink}
					</div>;
				return <div>
					{viewLink} / {approvalHistoryLink} / {deleteLink} {slashCharacter} {approvalLink}
				</div>;
			case 7:
				return <div>
					{viewLink} / {approvalHistoryLink} / {makeCorrectionsLink} / {deleteLink}
				</div>;
			case 8:
				return <div>
					{viewLink} / {approvalHistoryLink} {slashCharacter} {approvalLink}
				</div>;
			case 9:
				if (rowData.invoiceDocumentPostingErrorMessage && rowData.invoiceDocumentPostingErrorMessage == "This invoice already exists in ERP")
					return <div>
						{viewLink} / {approvalHistoryLink} /  {approvalLink}
					</div>;
				return <div>
					{viewLink} / {approvalHistoryLink} / {makeCorrectionsLink} / {approvalLink} / {deleteLink}
				</div>;
			default:
				return <div>Unknown</div>;
		}
	}

	render() {
		const info = {
			rowData: this.state.rowData,
			mode: this.state.mode,
			inProgress: this.props.inProgress,
			modelDefId: this.props.modelDefId
		}

		const invoiceDocumentRoutingHistoryInfo = {
			rowData: this.state.rowData,
			mode: this.state.mode,
			inProgress: this.props.inProgress,
			modelDefId: this.props.modelDefId,
			openDialog: this.state.openInvoiceDocumentRoutingHistoryDialog
		};

		return (
			<div>
				<h1>Manage {this.props.modelDefId === 1 ? 'Media' : 'Production'} Invoice Documents</h1>
				<div className="form-group">
					
					
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<Button
							variant="contained"
							color="inherit"
							disabled={!this.state.showBulkPostToERP}
							onClick={this.bulkPostToERP_clickHandler}
							size="medium"
							style={{ position: 'absolute', zIndex: 1, margin: 10 }}
							startIcon={<SendIcon />}>Bulk Post to ERP System</Button>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							title=""
							pageSize={10}							
							columns={[
								{
									headerName: 'File Name',
									field: 'fileName',
									flex: 1
								},
								{
									headerName: 'Timestamp',
									field: 'dateUpdated',
									type: 'datetime',
									valueFormatter: ({ value }) => value && formatDate(value),
									valueGetter: ({ value }) => value && new Date(value),
									flex: 1
								},
								{
									headerName: 'Client',
									field: 'clientCode',
									flex: 1,
									renderCell: rowData => {
										
										if (rowData.row.clientCode && rowData.row.clientCode.split('|').length > 1) {
											var clients = rowData.row.clientCode.split('|');
											var clientFullName = "";
											for (var i = 0; i < clients.length; i++) {
												if (clients[i]) {
													clientFullName += clients[i] + " - " + rowData.row.clientName.split('|')[i] + "|";
												}
											}
											return <div>
												<Tooltip
													title={
														<span style={{ whiteSpace: 'pre-line' }}>{clientFullName.split('|').join("\n")}</span>
													}>
													<Link>{clientFullName.split('|')[0]}...</Link>
												</Tooltip>
											</div>;
										}
										return rowData.row.client;
									}
								},
								{
									headerName: 'ERP Vendor/Site',
									field: 'vendor',
									flex: 1
								},
								{
									headerName: 'Status',
									field: 'status',
									renderCell: rowData =>
										<div>
											{rowData.row.status}
											<Tooltip key={rowData.row.rowId} title={rowData.row.invoiceDocumentPostingErrorMessage ? rowData.row.invoiceDocumentPostingErrorMessage : ""}>
												<ErrorIcon key={rowData.row.rowId+'_i'} style={{ float: "right", display: rowData.row.invoiceDocumentStatusId === 180 ? "" : "none" }} color="secondary" />
											</Tooltip>
										</div>,
									flex: 1
								},
								{
									headerName: 'Invoice #',
									field: 'invoiceNumber',
									flex: 1
								},
								{
									headerName: 'Total Invoice Amount',
									field: 'totalAmount',
									type: 'currency',
									align: 'right',
									headerAlign: 'right',
									renderCell: rowData => formatNumber(rowData.row.totalAmount) + " " + defaultStringIfNullOrEmpty(rowData.row.currencyCode, ""),
									flex: 1
								},
								{
									headerName: 'Due Date',
									field: 'dueDate',
									type: 'date',
									//valueFormatter: ({ value }) => value && formatDate(value),
									valueGetter: ({ value }) => value && new Date(value),
									flex: 1
								},
								{
									headerName: 'Transaction Id',
									field: 'transactionId',
									flex: 1
								},
								{
									headerName: 'Actions',
									field:'actions',
									align: 'center',
									headerAlign: 'center',
									renderCell: rowData => this.displayActions(rowData.row),
									flex:1
								}

							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
							options={{
								showTextRowsSelected: false,
								selection: true,
								showSelectAllCheckbox: false,
								selectionProps: rowData => ({
									disabled: rowData.approvalLinkText !== "Post to ERP System",
									color: 'default'
								}),
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670
							}}
							customcomponent={<Button
								key={1 }
								variant="contained"
								color="inherit"
								onClick={this.newInvoice_clickHandler}
								size="medium"
								style={{ float: "right", zIndex: 1, marginLeft: 10,marginTop:-2 }}
								startIcon={<PostAddIcon />}>Create New Invoice</Button> }
							onSelectionChange={(rows) => {
								const postToERPSystem = "Post to ERP System";
								const repostToERPSystem = "Repost to ERP System";
								const readyToPost = "Ready to Post";

								var d = rows.filter(x => x.approvalLinkText !== postToERPSystem && x.approvalLinkText !== repostToERPSystem && x.approvalLinkText !== readyToPost);
								this.setState({ showBulkPostToERP: rows.length > 0 && d.length==0, selectedRows: rows })
							}}
							data={this.state.data}
						/>
					</div>
				</div>
				<Dialog
					open={this.state.openDeleteConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Delete Invoice
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this invoice ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.deleteYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={this.deleteNo_clickHandler} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openInvoiceDocumentEditorDialog} TransitionComponent={Transition} fullScreen>
					<InvoiceDocumentEditor info={info} onClose={this.invoiceDocumentEditor_closeHandler} />
				</Dialog>
				<InvoiceDocumentRoutingHistory info={invoiceDocumentRoutingHistoryInfo} onClose={this.invoiceDocumentRoutingHistoryDialog_closeHandler} />
			</div>
		);
	}
}
