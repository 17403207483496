import React, { Component } from 'react';
//import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { logoutUser } from '../../store/action/loginAction';
import { stringIsNullOrEmpty } from '../Common/Utilities';
import FavoriteMenu from './favoriteMenu';
import { httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import Check from '@mui/icons-material/CheckCircle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { Menu, MenuItem, ListItemIcon, Button } from "@mui/material";
import { NestedMenuItem } from 'mui-nested-menu';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import './TopMenu.css';
import Divider from '@mui/material/Divider';
class TopMenu extends Component {

	constructor() {
		super();
		this.state = {
			menuPosition: null,
			inProgress: false,
			anchorEl:null
		}
		this.logoutHandler = this.logoutHandler.bind(this);
	}
	

	rowClick = () => {
		this.setState({ currClass: "current" });
	}
	initialCap(data) {
		return data[0].toUpperCase() + data.slice(1);
	}
	getName() {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return this.initialCap(userInfo.lastName) + ', ' + this.initialCap(userInfo.firstName);
		} catch { }
	}
	getCompany() {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return this.initialCap(userInfo.coName);
		} catch { }
	}
	getRole() {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return this.initialCap(userInfo.selectedUserRoles.userRoleName);
		} catch { }
	}
	
	getUserCompanyList() {
		try {
			var userInfo = JSON.parse(localStorage.authenticatedUser);
			return userInfo.authenticatedUserInfoList;
		} catch { }
	}
	getUserRolesList() {
		try {
			var userInfo = JSON.parse(localStorage.authenticatedUser);
			return userInfo.selectedUserInfo.userRolesList;
		} catch { }
	}
	
	logoutHandler() {

		//event.preventDefault()

		const ssoType = localStorage.ssoType;
		const token = localStorage.token;

		localStorage.removeItem("ssoType");
		localStorage.removeItem("token");
		localStorage.removeItem("tokenExpiry");
		localStorage.removeItem("authenticatedUser");
		localStorage.removeItem("userInfo");

		if (stringIsNullOrEmpty(ssoType)) {
			this.props.logoutUser({});
			this.props.tokenHandler(token);

			window.location = "/login";
		}
		else {
			window.location = "/ssoauthentication/logout";
		}
	}
	switchuser(companyCode, userRoleId) {
		var that = this;
		var username = null;
		var email = null;
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			username = userInfo.username;
			email = userInfo.email;
		} catch { }

		var formData = {
			Username: username,
			Password: null,
			email: email,
			companyCode: companyCode,
			userRoleId: userRoleId
		}

		this.setState({ inProgress: true, anchorEl: null });
		httpPost("api/auth/switchuser", formData)
			.then((response) => {
				var pathName = "/UserRoles";

				that.setState({ inProgress: false });
				if (response.message) {
					// Here you should have logic to handle invalid login credentials.
					// This assumes your Rails API will return a JSON object with a key of
					// 'message' if there is an error
				}
				else {
					var data = response.data;
					let searchParams = new URLSearchParams(window.location.search);
					searchParams.delete("ssotoken");
					searchParams.delete("email");
					searchParams.delete("loginId");
					searchParams.delete("companyCode");
					searchParams.delete("userRoleId");
					searchParams.delete("userRoleName");

					if (data.token && data.token.length > 0) {
						localStorage.setItem("token", data.token);
						localStorage.setItem("tokenExpiry", data.tokenExpiry);
						localStorage.setItem("userInfo", JSON.stringify(data.selectedUserInfo));
						localStorage.setItem("authenticatedUser", JSON.stringify(data));
						if (!stringIsNullOrEmpty(data.selectedUserInfo.invoiceType)) {
							if (data.selectedUserInfo.invoiceType.includes('1')) {
								pathName = "/Media/Dashboard";
							}
							else if (data.selectedUserInfo.invoiceType.includes('2')) {
								pathName = "/Production/Dashboard";
							}
						}
					}


					if (!stringIsNullOrEmpty(localStorage.token)) {
						var sParam = searchParams.toString();
						//that.props.history.push({
						//	pathname: pathName,
						//	search: sParam
						//});

						if (!stringIsNullOrEmpty(sParam)) {
							window.location.search = sParam;
						}
						window.location.pathname = pathName;
					}
				}
			}).catch((error) => {
				that.setState({ inProgress: false });

				if (error && error.response && error.response.data) {
					toast.error(error.response.data);
					console.log("login error", error.response);
				}
				else {
					console.log("login error");
				}
			});
	}
	getUserImage() {

		return "/images/avatar.png";
	}
	//handleMenuClick = (event) => {
	//	if (this.state.menuPosition) {
	//		return;
	//	}
	//	event.preventDefault();
	//	this.setState({
	//		anchorEl: event.currentTarget
	//	});
	//	//var box = document.getElementById("topmenu").getBoundingClientRect();
	//	//this.setState({
	//	//	menuPosition: {
	//	//		top: box.bottom + box.top,
	//	//		left: box.left+40
	//	//	}
	//	//});
	//};
	
	render() {
		const open = Boolean(this.state.anchorEl);	
		var currentCompanyName = this.getCompany();
		var currentRoleName = this.getRole();
		const listCompanyItems = this.getUserCompanyList()?.map(function (item) {
			{
				var coName = this.initialCap(item.coName);
				return <MenuItem key={item.companyCode}
					onClick={() => {
						console.log("Switch company clicked" + item);
						if (coName != currentCompanyName) {
							this.switchuser(item.companyCode, item.userRolesList[0].userRoleId);
						}
					}}>
					{coName}{
						coName == currentCompanyName ? <ListItemIcon><Check /></ListItemIcon> : <ListItemIcon></ListItemIcon>
					}
				</MenuItem>
			}

		}, this);

		const listGroupItems = this.getUserRolesList()?.map(function (item) {
			{
				var rName = this.initialCap(item.userRoleName);
				return <MenuItem key={item.userRoleId}
					
					onClick={() => {
						console.log("Switch group clicked" + item);
						if (rName != currentRoleName) {
							this.switchuser(item.companyCode, item.userRoleId);
							
						}
					}}>
					{rName}{
						rName == currentRoleName ? <ListItemIcon><Check /></ListItemIcon> : <ListItemIcon></ListItemIcon>
					}
				</MenuItem>
			}

		}, this);

		return (
			<div className="top-menu">
				<ul className="nav navbar-nav pull-right">
					<FavoriteMenu RenderHeader={this.props.RenderHeader} />
					<li className="droddown dropdown-separator">
						<span className="separator"></span>
					</li>
					<li className="dropdown dropdown-user dropdown-dark" id="topmenu">
						<>
							<Button onClick={(event) => {
								this.setState({ anchorEl: event.currentTarget });
							}}
>
								<div className="dropdown-toggle inlineBlock" data-hover="dropdown" data-close-others="true">
									<label className="userProfilePicture">
										<img id="profile-picture" alt="" className="img-circle" src={this.getUserImage()} />
									</label>
									<label className="inlineBlock user-info">
										<label>
											<span className="lblUserName inlineBlock">{this.getName()}</span>
										</label>

										<label className="company-info">
											<i className="fa fa-user fa-6" aria-hidden="true"></i>
											<span className="username username-hide-mobile">
												{currentRoleName}
											</span>
											<i className="material-icons"></i>
											<span className="username username-hide-mobile">{currentCompanyName}</span>
										</label>
									</label>

								</div>
							</Button>
							<div className="dropdown-menu">
								<Menu
									anchorEl={this.state.anchorEl} open={open} onClose={(event) => {
										this.setState({ anchorEl: null });
									}}
									
									disableScrollLock={true}								
								>
									<NestedMenuItem
										label="Company"
										parentMenuOpen={open} rightIcon={<ArrowRightIcon />}
									>
										{listCompanyItems}
									</NestedMenuItem>
									<Divider key="i2" />
									<NestedMenuItem
										label="User Group" rightIcon={<ArrowRightIcon />}
										parentMenuOpen={open}																
									>
										{listGroupItems}
									</NestedMenuItem>
									<Divider key="i3"/>
									<MenuItem onClick={this.logoutHandler}>LogOut</MenuItem>
								</Menu>
							</div>
						</>
					</li>
					<li className="droddown dropdown-separator">
						<span className="separator"></span>
					</li>

					<li className="dropdown dropdown-extended dropdown-tasks dropdown-dark marginTop10">
						<span className="dropdown-toggle non-auth" >
							<i className="fa fa-sign-out" title="Logout" onClick={this.logoutHandler}></i>
						</span>
					</li>
				</ul >
				<Backdrop style={{ zIndex: 2000, color: '#fff' }} open={this.state.inProgress}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return ({
		users: state.rootReducer.currentUser
	})
}
const mapDispatchToProps = dispatch => ({
	logoutUser: (updatedValue) => {
		dispatch(logoutUser(updatedValue));
	}
})

//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles,withTheme)(TopMenu)));
//export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu));
export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);