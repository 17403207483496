import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class VendorLookup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			rowData: null,
			inProgress: false,
			currencyCode: ""
		};
		this.fetchTries = 0;
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getvendors";
		let messageName = "Vendors";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidUpdate() {
		var that = this;
		var loaded = false;
		if (this.props.info.openDialog && !this.state.inProgress) {
			if ((that.props.info.invoiceModel.currencyCode || '') != this.state.currencyCode) {
				loaded = true;
			}			
			else if (this.state.data.length == 0) {
				if (that.fetchTries < 3) {
					loaded = true;
				}
			}
		}
		if (loaded) {
			this.state.inProgress = true;
			this.fetchVendors();
			that.props.info.inProgress(true);
		}
	}

	fetchVendors() {
		var that = this;
		        that.fetchTries += 1;
		httpGet('/api/lookup/vendor')
			.then((response) => {
				var data = response.data;
				var currencyCode = "";
				if (that.props.info.invoiceModel.currencyCode) {
					currencyCode = that.props.info.invoiceModel.currencyCode;
					var vendors = data.filter(z => z.currencyCode == currencyCode);
					if (vendors.length > 0) {
						data = vendors;
					}
				}
				var rowId = 1;
				data.map(row => row.rowId = rowId++);				
				var rowData = data.find(i => i.vendorCode === that.props.info.invoiceModel.vendorCode && i.siteId === that.props.info.invoiceModel.siteId);

				that.setState({ data: data, rowData: rowData, inProgress: false, currencyCode: currencyCode });
				this.props.info.inProgress(false);
			})
			.catch((error) => {
				that.props.info.setInProgess(false);
				that.setState({ inProgress: false });
				toast.error('An error occurred while fetching the records');
			});
	}
	checkbox_clickHandler(event) {
		var rowData = this.getRowData(parseInt(event.target.value, 10));
		this.setState({ rowData: rowData });
	}

	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}

	getRowData(value) {
		var rowData = this.state.data;
		return rowData[rowData.findIndex(d => d.rowId === value)];
	}

	render() {
		let openDialog = false;
		if (this.props.info.openDialog)
			openDialog = this.props.info.openDialog;

		return (
			<Dialog open={openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Select Vendor
				</DialogTitle>
				<DialogContent>
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: ' ',
									field: 'chkVendor',
									width: 40, resizable: false, sortable: false,
									renderCell: rowData =>
										<Checkbox
											color="default"
											name="chk"
											key={1 }
											checked={this.state.rowData != null && this.state.rowData !== undefined && rowData.row.rowId === this.state.rowData.rowId}
											value={rowData.row.rowId}
											onClick={this.checkbox_clickHandler}
										/>,
									
								},
								{
									headerName: 'Site ID',
									field: 'siteId',
									flex: 0.3
								},
								{
									headerName: 'Vendor Code',
									field: 'vendorCode',
									flex: 0.4
								},
								{
									headerName: 'Vendor Name',
									field: 'vendorName',
									width: 300,
									flex: 1
								},
								{
									headerName: 'Vendor Address',
									field: 'vendorAddress',
									width: 500,
									flex: 1
								},
								{
									headerName: 'Currency',
									field: 'currencyCode',
									width: 100,
									flex: 0.3
								}

							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}