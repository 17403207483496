import * as React from 'react';
import reactCSS from 'reactcss';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import Popover from '@mui/material/Popover';
import ToggleButton from '@mui/lab/ToggleButton';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import { SketchPicker } from 'react-color'
import { isNullOrUndefined, stringIsNullOrEmpty } from '../../Common/Utilities';

export class ApprovalStampConfiguration extends React.Component {
	constructor(props) {
		super(props);

		this.arrFontFamily = ["Arial", "Courier NewCourier", "Helvetica", "Times New Roman", "Times", "Palatino", "Garamond", "Bookman", "Avant Garde", "Verdana", "Georgia", "Comic Sans MS", "Trebuchet MS", "Arial Black", "Impact"];
		this.arrFontFamily = this.arrFontFamily.sort();

		this.state = {
			displayColorPicker: false,
			color: {
				hex: '#ff0000',
				rgb: {
					r: 255,
					g: 0,
					b: 0,
					a: 1,
				}
			}
		};

		this.popover_closeHandler = this.popover_closeHandler.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.input_changeHandler = this.input_changeHandler.bind(this);
		this.fontFamily_changeHandler = this.fontFamily_changeHandler.bind(this);
		this.fontSize_changeHandler = this.fontSize_changeHandler.bind(this);
		this.sketchPicker_clickHandler = this.sketchPicker_clickHandler.bind(this);
		this.sketchPicker_closeHandler = this.sketchPicker_closeHandler.bind(this);
		this.sketchPicker_changeHandler = this.sketchPicker_changeHandler.bind(this);
		this.sketchPicker_changeCompleteHandler = this.sketchPicker_changeCompleteHandler.bind(this);
		this.fontStyle_changeHandler = this.fontStyle_changeHandler.bind(this);
	}

	componentDidMount() {
		var color = this.state.color;
		var textFormat = this.props.approvalStampConfiguration.textFormat;

		if (stringIsNullOrEmpty(textFormat.fontColor)) {
			textFormat.fontColor = color.hex;
			textFormat.opacity = color.rgb.a;
		}
		else {
			color.hex = textFormat.fontColor;
			color.rgb = this.hexToRgb(textFormat.fontColor);
			color.rgb.a = textFormat.opacity;
		}

		this.setState({ color: color });
	}

	componentDidUpdate() {
		if (!isNullOrUndefined(this.props.anchorEl) && isNullOrUndefined(this.state.anchorEl)) {
			this.setState({ anchorEl: this.props.anchorEl });
		}
	}

	popover_closeHandler(event) {
		this.setState({ anchorEl: null });

		if (this.props.onClose) {
			this.props.onClose(event);
		}
	}

	changeHandler(key, value) {
		var target = { name: key, value: value };
		var event = { target: target, currentTarget: target };

		if (this.props.onChange) {
			this.props.onChange(event);
		}
	}

	input_changeHandler(event) {
		var approvalStampConfiguration = this.props.approvalStampConfiguration;
		var inputValue = typeof (approvalStampConfiguration[event.target.name]) === "number" ? parseInt(event.target.value, 10) : event.target.value;

		if (inputValue >= 0) {
			approvalStampConfiguration[event.target.name] = inputValue;

			this.changeHandler("approvalStampConfiguration", approvalStampConfiguration);
		}
	}

	fontFamily_changeHandler(event) {
		var approvalStampConfiguration = this.props.approvalStampConfiguration;
		var textFormat = approvalStampConfiguration.textFormat;
		textFormat.fontFamily = event.target.value;

		approvalStampConfiguration.textFormatSettings = JSON.stringify(textFormat);

		this.changeHandler("approvalStampConfiguration", approvalStampConfiguration);
	}

	fontSize_changeHandler(event) {
		var approvalStampConfiguration = this.props.approvalStampConfiguration;
		var textFormat = approvalStampConfiguration.textFormat;
		var inputValue = parseFloat(event.target.value);

		if (inputValue >= 6 && inputValue <= 48) {
			textFormat.fontSize = inputValue;

			approvalStampConfiguration.textFormatSettings = JSON.stringify(textFormat);

			this.changeHandler("approvalStampConfiguration", approvalStampConfiguration);
		}
	}

	fontStyle_changeHandler(event) {
		var approvalStampConfiguration = this.props.approvalStampConfiguration;
		var textFormat = approvalStampConfiguration.textFormat;

		if (event.currentTarget.value === "bold") { textFormat.bold = !textFormat.bold; };
		if (event.currentTarget.value === "italic") { textFormat.italic = !textFormat.italic; };
		if (event.currentTarget.value === "underlined") { textFormat.underlined = !textFormat.underlined; };

		approvalStampConfiguration.textFormatSettings = JSON.stringify(textFormat);

		this.changeHandler("approvalStampConfiguration", approvalStampConfiguration);
	}

	sketchPicker_clickHandler() {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	};

	sketchPicker_closeHandler() {
		this.setState({ displayColorPicker: false });
	};

	sketchPicker_changeHandler(color) {
		this.setState({ color: color });
	};

	sketchPicker_changeCompleteHandler(color, event) {
		var approvalStampConfiguration = this.props.approvalStampConfiguration;
		var textFormat = approvalStampConfiguration.textFormat;
		textFormat.fontColor = color.hex;
		textFormat.opacity = color.rgb.a;

		approvalStampConfiguration.textFormatSettings = JSON.stringify(textFormat);

		this.changeHandler("approvalStampConfiguration", approvalStampConfiguration);
	}

	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	render() {
		var textFormat = this.props.approvalStampConfiguration.textFormat;

		const styles = reactCSS(
			{
				'default': {
					color: {
						width: '36px',
						height: '14px',
						borderRadius: '2px',
						background: `${textFormat.fontColor}`,
						opacity: textFormat.opacity
					},
					swatch: {
						padding: '5px',
						marginLeft: '10px',
						background: '#fff',
						borderRadius: '1px',
						boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
						display: 'inline-block',
						cursor: 'pointer',
					},
					popover: {
						position: 'absolute',
						zIndex: '1400',
						top: '20px',
						left: '200px'
					},
					cover: {
						position: 'fixed',
						top: '0px',
						right: '0px',
						bottom: '0px',
						left: '0px',
					},
				},
			});

		const fontStyle = [];

		if (textFormat.bold) { fontStyle.push("bold") };
		if (textFormat.italic) { fontStyle.push("italic") };
		if (textFormat.underlined) { fontStyle.push("underlined") };

		const disabled = this.props.readOnly ? "disabled" : "";

		return (
			<Popover
				open={this.state.anchorEl != null}
				anchorEl={this.state.anchorEl}
				onClose={this.popover_closeHandler}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}} height={200}>
				<div className="col-invoice-view">
					<div className="mainTable" style={{ maxWidth: "none" }}>
						<div className="row-form">
							<div className="mf-12 field_full-7">
								<label>Font Family:</label>
								<select id="fontFamily" name="fontFamily" disabled={this.props.readOnly} value={textFormat.fontFamily} onChange={this.fontFamily_changeHandler}>
									{
										this.arrFontFamily.map(function (item, index) {
											return <option key={index} value={item} style={{ fontFamily: item }}>{item}</option>
										})
									}
								</select>
							</div>
						</div>
						<div className="row-form">
							<div className="mf-12 field_full-7">
								<label>Font Size:</label>
								<input type="number" name="fontSize" readOnly={this.props.readOnly} value={textFormat.fontSize} min="6" max="48" onChange={this.fontSize_changeHandler} />
							</div>
						</div>
						<div className="row-form">
							<div className="mf-12 field_full-7">
								<label>Font Color:</label>
								<div style={styles.swatch} onClick={this.props.readOnly ? null : this.sketchPicker_clickHandler}>
									<div style={styles.color} />
								</div>
								<div>
									{this.state.displayColorPicker ? <div style={styles.popover}>
										<div style={styles.cover} onClick={this.sketchPicker_closeHandler} />
										<SketchPicker presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']} color={this.state.color.rgb} onChange={this.sketchPicker_changeHandler} onChangeComplete={this.sketchPicker_changeCompleteHandler} />
									</div> : null}
								</div>
							</div>
						</div>
						<div className="row-form">
							<div className="mf-12 field_full-7">
								<label>Font Style:</label>
								<ToggleButtonGroup
									size="small"
									value={fontStyle}
									onChange={this.fontStyle_changeHandler}
									aria-label="font style"
									style={{ margin: 2, marginLeft: 10 }}
									name={fontStyle}
								>
									<ToggleButton value={"bold"} aria-label="bold" disabled={disabled}>
										<FormatBoldIcon />
									</ToggleButton>
									<ToggleButton value={"italic"} aria-label="italic" disabled={disabled}>
										<FormatItalicIcon />
									</ToggleButton>
									<ToggleButton value={"underlined"} aria-label="underlined" disabled={disabled}>
										<FormatUnderlinedIcon />
									</ToggleButton>
								</ToggleButtonGroup>
							</div>
						</div>
						<div className="row-form">
							<div className="mf-12 field_full-7">
								<label>Top:</label>
								<input type="number" name="top" readOnly={this.props.readOnly} min="0" value={this.props.approvalStampConfiguration.top} onChange={this.input_changeHandler} />
							</div>
							<div className="mf-12 field_full-7">
								<label>Left:</label>
								<input type="number" name="left" readOnly={this.props.readOnly} min="0" value={this.props.approvalStampConfiguration.left} onChange={this.input_changeHandler} />
							</div>
						</div>
						<div className="row-form">
							<div className="mf-12 field_full-7">
								<label>Width:</label>
								<input type="number" name="width" readOnly={this.props.readOnly} min="0" value={this.props.approvalStampConfiguration.width} onChange={this.input_changeHandler} />
							</div>
							<div className="mf-12 field_full-7">
								<label>Height:</label>
								<input type="number" name="height" readOnly={this.props.readOnly} min="0" value={this.props.approvalStampConfiguration.height} onChange={this.input_changeHandler} />
							</div>
						</div>
					</div>
				</div>
			</Popover>
		);
	}
}
