import * as React from 'react';
import { MappingInputField } from './MappingInputField';
import './css/style.css';

export class LabelValueContainer extends React.Component {
	constructor(props) {
		super(props);

	}

	render() {
		return (
			<div className="row-form row-ov-form">
				<div className="mf-12 field_full-7">
					<label>{this.props.label}</label>
					<div className="textBoxTwoField">
						<MappingInputField
							name={this.props.name + "Label"}
							info={this.props.info}
							showAlignment={this.props.showLabelTextAlignment} />
						<MappingInputField
							name={this.props.name + "Value"}
							info={this.props.info}
							showAlignment={this.props.showValueTextAlignment}
							showStringFormat={this.props.showStringFormat}
							showDateFormat={this.props.showDateFormat}
							showDateRange={this.props.showDateRange}
							showCurrencyFormat={this.props.showCurrencyFormat}
							onDefaultValueClick={this.props.onDefaultValueClick} />
					</div>
				</div>
			</div>
		);
	}
}
