import * as React from 'react';
export class LabelText extends React.Component {
    constructor() {
        super();
        this.state = { readOnly: true }
        this._click = this._click.bind(this);
    }
    _click() {

    }
    render() {
        return (
            <label id={this.props.labelInfo.labelId}>{this.props.labelInfo.labelText}</label>
        );
    }
}
