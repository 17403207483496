import * as React from 'react';
import Button from '@mui/material/Button';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { toast } from 'react-toastify';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { GridRowModes, useGridApiRef } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { flushSync } from 'react-dom';
export default function PaymentTermsMaps(props) {
	const [state, setState] = React.useState({
		data: [],
		selectedRows: [],
		isDirty: false,
		isRowInProcessMode: false,
		processedRowId:null
	});
	const [isCommited, setIsCommited] = React.useState(false);
	const [trigger, setTrigger] = React.useState(false);
	const [key, setKey] = React.useState(false);
	const apiGridRef = useGridApiRef();
	React.useEffect(() => {
		fetchPaymentTermsMaps();
	}, [])
	React.useEffect(() => {
		if (state.isRowInProcessMode) {
			setState({ ...state, data: state.data.filter((e, i) => (e.apamInvoiceTermsCode || '').trim()), isRowInProcessMode: false })
			setIsCommited(false);
		}
		if (state.isDirty) setTrigger(true);
	}, [isCommited]);

	React.useEffect(() => {
		if (state.isDirty && key && !state.isRowInProcessMode) {
			paymentTermsMaps_saveHandler();
			setState((...prev) => {
				let _p = prev[0];
				return { ..._p, isDirty: false }
			});
			setKey(false);
		}
		setTrigger(false);

	}, [trigger, key]);

	const saveChanges = React.useCallback(() => {
		flushSync(() => {
			if (state.processedRowId) {
				apiGridRef.current.stopRowEditMode({ id: state.processedRowId });
			}
			setKey(true);
		})


	}, [state.processedRowId, state.data])

	const paymentTermsMaps_cancelHandler = React.useCallback((event) =>{
		if (props.onClose) {
			props.onClose(event);
		}
	}, [])

	const paymentTermsMaps_saveHandler = React.useCallback((event) => {
		var data = {
			termsCode: props.info.termsCode,
			paymentTermsMaps: state.data
		};

		props.info.inProgress(true);
		httpPost("/api/setup/savepaymenttermsmaps/", data)
			.then((response) => {
				toast.success('Updated Successfully');
				props.info.inProgress(false);
				paymentTermsMaps_cancelHandler(null);
			}).catch((error) => {
				props.info.inProgress(false);
				toast.error(<div>Payment Terms Update Failed<br />{error.response.data.detail}</div>);
			});
	}, [state.data])

	const fetchPaymentTermsMaps = React.useCallback(() => {

		props.info.inProgress(true);

		httpGet('/api/setup/paymenttermsmaps/' + props.info.termsCode)
			.then((response) => {
				var data = response.data;
				setState((prev)=>({...prev, data: (data || []).map((e, i) => { let d = { ...e, rowId: i }; return d; }) }));

				props.info.inProgress(false);
			})
			.catch((error) => {
				props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}, [])



	//////////////////////////////

	const getAddButton = React.useCallback(() => {
		return <IconButton
			variant="contained"
			title={'add'}
			onClick={handleClick}
			size="small"
			disabled={state.isRowInProcessMode }
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
		>
			<AddBoxIcon />
		</IconButton>;
	}, [state.data, state.isRowInProcessMode])
	const getDeleteButton = React.useCallback(() => {
		return <IconButton
			variant="contained"
			title={'delete' }
			onClick={handleRowDelete}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
			disabled={state.selectedRows.length === 0 || state.isRowInProcessMode }
		>
			<DeleteIcon />
		</IconButton>;
	}, [state.selectedRows, state.isRowInProcessMode])


	const handleClick = React.useCallback(() => {
		const id = ([...state.data]?.sort((a, b) => b.rowId - a.rowId)[0]?.rowId + 1) || 0;
		setState((prev) => ({
			...prev, isDirty: true,
			isRowInProcessMode: true,
			processedRowId:id,
			data: [{
				rowId: id,
				apamInvoiceTermsCode: "",
				isNew: true,
				termsCode: props.info.termsCode,
				termsCodeNavigation: null
			}, ...prev.data]
		}));
		apiGridRef.current.startRowEditMode({ id: id, fieldToFocus: 'apamInvoiceTermsCode' });
	}, [state.data]);

	const processRowUpdate = (newrow, oldrow) => {

		let index = state.data.findIndex((e, i) => e.rowId == newrow.rowId);
		Object.assign(state.data[index], newrow);
		setState((...prev) => { let _p = prev[0]; return { ..._p, isDirty: true, processedRowId:null } })
		setIsCommited(true);
		return newrow;
	}

	const handleRowClick = React.useCallback((params, event) => {
		if (!params.isEditable) {
			return;
		}
		if (!event.currentTarget.contains(event.target)) {
			return;
		}
		if (params.cellMode == GridRowModes.View) //GridRowModes.Edit)
		{
			setState({ ...state, isRowInProcessMode: true, isDirty: true });
			apiGridRef.current.startRowEditMode({ id: params.id });
		}
	}, [state])
	const handleRowSelection = React.useCallback((rows) => {
		let selectedRows = rows.map((e, i) => e.rowId);
		setState((...prev) => {
			let _p = prev[0];
			return { ..._p, selectedRows: selectedRows }
		});
	}, [state])
	const handleRowDelete = React.useCallback(() => {
		setState((...prev) => {
			return { ...prev, isDirty: true, selectedRows: [], data: state.data.filter((e, i) => (state.selectedRows.indexOf(e.rowId) === -1)) }
		});
	}, [state])



	/////////////////////////////

		return (
			<div>
				<h1>Payment Terms Mappings {props.info.termsCode && <span>({props.info.termsCode})</span>}</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							columns={[
								{
									headerName: 'Invoice Terms Code',
									field: 'apamInvoiceTermsCode',
									flex: 1,
									editable:true
								}
							]}
							getRowId={(row) => { return row.rowId }}
							disableSelectionOnClick
							customcomponent={
								(<>
									{getAddButton()}
									{getDeleteButton()}
								</>)
							}
							apiRef={apiGridRef}
							editMode="row"
							onCellClick={handleRowClick}
							onSelectionChange={handleRowSelection}
							processRowUpdate={processRowUpdate}
							editable={
								{
									onRowAdd: newData =>
										new Promise((resolve, reject) => {
											const paymentTermsMaps = state.data;

											newData.termsCode = props.info.termsCode;
											paymentTermsMaps.push(newData);

											setState({ data: paymentTermsMaps, isDirty: true }, () => resolve());
										}),
									onRowUpdate: (newData, oldData) =>
										new Promise((resolve, reject) => {
											const paymentTermsMaps = state.data;
											const index = paymentTermsMaps.indexOf(oldData);
											paymentTermsMaps[index] = newData;

											setState({ data: paymentTermsMaps, isDirty: true }, () => resolve());
										}),
									onRowDelete: oldData =>
										new Promise((resolve, reject) => {
											const paymentTermsMaps = state.data;
											const index = paymentTermsMaps.indexOf(oldData);
											paymentTermsMaps.splice(index, 1);

											setState({ data: paymentTermsMaps, isDirty: true }, () => resolve());
										}),
								}}
						/>
					</div>
				</div>
				<Button color="primary" onClick={paymentTermsMaps_cancelHandler} style={{ float: "right" }}>
					Cancel
				</Button>
				<Button autoFocus color="primary" disabled={!state.isDirty} onClick={saveChanges} style={{ float: "right" }}>
					Save
				</Button>
			</div>
		);
}