import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { formatNumber, stringIsNullOrEmpty } from '../Common/Utilities';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
export default class VendorInvoicesReport extends React.Component {
	constructor() {
		super();
		var userInfo = JSON.parse(localStorage["userInfo"]);
		var modelDefId = this.getDefaultModelDefId(userInfo);
		
		this.state = {
			data: [],
			modelDefId: modelDefId,
			userInfo: userInfo
		};
		this.modelDefId_changeHandler = this.modelDefId_changeHandler.bind(this);
		this.getDefaultModelDefId = this.getDefaultModelDefId.bind(this);
		this.getInvoiceTypeMenuItem = this.getInvoiceTypeMenuItem.bind(this);
	}
	getDefaultModelDefId(userInfo) {
		if (!stringIsNullOrEmpty(userInfo.invoiceType)) {
			if (userInfo.invoiceType.includes('1')) {
				return 1;
			}
			else if (userInfo.invoiceType.includes('2')) {
				return 2;
			}
		}
	}
	componentDidMount() {
		this.fetchVendorInvoices(this.state.modelDefId);
	}
	modelDefId_changeHandler(event) {
		var modelDefId = parseInt(event.target.value, 10);
		this.setState({ data: [], modelDefId: modelDefId });
		this.fetchVendorInvoices(modelDefId);
	}
	fetchVendorInvoices(modelDefId) {
		var that = this;
		this.props.inProgress(true);
		//const lookup =
		//{
		//	10: 'Unrecognized',
		//	20: 'Routed for Manual Processing',
		//	30: 'Mapping Requested',
		//	35: 'Missing Vendor Assignment',
		//	40: 'Missing Info',
		//	50: 'Verified Successfully',
		//	60: 'Verified with Errors',
		//	//70: 'First Level Approved',
		//	//80: 'First Level Rejected',
		//	//90: 'Second Level Approved',
		//	//100: 'Second Level Rejected',
		//	//110: 'Third Level Approved',
		//	//120: 'Third Level Rejected',
		//	//130: 'Fourth Level Approved',
		//	//140: 'Fourth Level Rejected',
		//	//150: 'Fifth Level Approved',
		//	//160: 'Fifth Level Rejected',
		//	70: 'Pending Approval',
		//	170: 'Posted',
		//	180: 'Post Failed'
		//};
		httpGet('/api/reports/vendorinvoices/' + modelDefId)
			.then((response) => {
				//var data = response.data;
				let data = (response.data || []).map((e, i) => {
					//let invoiceDocumentStatus = "";
					//if (e.invoiceDocumentStatusId) {
					//	if (e.invoiceDocumentStatusId >= 70 && e.invoiceDocumentStatusId <= 160) {
					//		invoiceDocumentStatus = 'Pending Approval';
					//	}
					//	else
					//		invoiceDocumentStatus = lookup[e.invoiceDocumentStatusId];
					//}
					let d = {
						...e, rowId: i,
						//invoiceDocumentStatus: invoiceDocumentStatus
					}; return d;
				});
				that.setState({ data: data });
				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}
	getInvoiceTypeMenuItem(modelDefId, displayValue) {
		if (this.state.userInfo.invoiceType.includes('' + modelDefId)) {
			return <MenuItem value={modelDefId}>{displayValue}</MenuItem>
		}
	}
	render() {
		
		//const lookupFilter = [
		//	'Unrecognized',
		//	'Routed for Manual Processing',
		//	'Mapping Requested',
		//	'Missing Vendor Assignment',
		//	'Missing Info',
		//	'Verified Successfully',
		//	'Verified with Errors',
		//	'Pending Approval',
		//	'Posted',
		//	'Post Failed'];
		//const lookup =
		//{
		//	10: 'Unrecognized',
		//	20: 'Routed for Manual Processing',
		//	30: 'Mapping Requested',
		//	35: 'Missing Vendor Assignment',
		//	40: 'Missing Info',
		//	50: 'Verified Successfully',
		//	60: 'Verified with Errors',
		//	70: 'First Level Approved',
		//	80: 'First Level Rejected',
		//	90: 'Second Level Approved',
		//	100: 'Second Level Rejected',
		//	110: 'Third Level Approved',
		//	120: 'Third Level Rejected',
		//	130: 'Fourth Level Approved',
		//	140: 'Fourth Level Rejected',
		//	150: 'Fifth Level Approved',
		//	160: 'Fifth Level Rejected',
		//	170: 'Posted',
		//	180: 'Post Failed'
		//};
		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };
		const columns = [
			{
				headerName: 'Vendor Code',
				field: 'vendorCode',
				width: 250, hideable: false
			},
			{
				headerName: 'Vendor Name',
				field: 'vendorName',
				width: 250, hideable: false
			},
			{
				headerName: 'Site Id',
				field: 'siteId',
				width: 250
			},
			{
				headerName: 'Invoice Number',
				field: 'invoiceNumber',
				width: 250
			}];
		if (this.state.modelDefId === 2)
		{
			columns.push(
				{
					headerName: 'PO Code',
					field: 'poCode',					
					width: 250
				});
		}
		columns.push(
			//{
			//	headerName: 'Status',
			//	field: 'invoiceDocumentStatus',
			//	filterCellStyle: { width: 40, maxWidth: 40 },
			//	width: 250,
			//	type: 'singleSelect',
			//	valueOptions: lookupFilter,
			//	valueFormatter: ({ value }) => value
			//},
			{
				headerName: 'Status',
				field: 'invoiceDocumentStatus',
				//valueFormatter: ({ value }) => value && lookup[value],
				filterCellStyle: { width: 40, maxWidth: 40 },
				//lookup: lookup,
				width: 250
			},
			{
				headerName: 'Assigned To',
				field: 'assignedTo',
				filterCellStyle: { width: 40, maxWidth: 40 },
				renderCell: rowData => {
					if (rowData.row.assignedTo && rowData.row.assignedTo.split('|').length > 1)
						return <div>
							<Tooltip
								title={
									<span style={{ whiteSpace: 'pre-line' }}>{rowData.row.assignedTo.split('|').join("\n")}</span>
								}>
								<Link>{rowData.row.assignedTo.split('|')[0]} ...</Link>
							</Tooltip>
						</div>;
					return rowData.row.assignedTo;
				},
				width: 250
			},
			{
				headerName: 'Invoice Date',
				field: 'invoiceDate',
				type: 'date',
				valueGetter: ({ value }) => value && new Date(value),
				width: 250
			},
			{
				headerName: 'Due Date',
				field: 'dueDate',
				type: 'date',
				valueGetter: ({ value }) => value && new Date(value),
				width: 250
			},
			{
				headerName: 'Payment Term',
				field: 'paymentTermCode',
				width: 250
			});
		if (this.state.modelDefId !== 2) {
			columns.push(
				{
					headerName: 'Service Term From',
					field: 'serviceTermStartDate',
					type: 'date',
					valueGetter: ({ value }) => value && new Date(value),					
					width: 250
				},
				{
					headerName: 'Service Term To',
					field: 'serviceTermEndDate',
					type: 'date',
					valueGetter: ({ value }) => value && new Date(value),					
					width: 250
				});
		}
		if (this.state.modelDefId === 2)
		{
			columns.push({
				headerName: 'Customer Code',
				field: 'clientCode',				
				width: 250
			},
			{
				headerName: 'Customer Name',
				field: 'clientName',				
				width: 250
			});
		}
		columns.push(
			{
				headerName: 'Currency',
				field: 'currencyCode',
				width: 250
			});
		if (this.state.modelDefId !== 2) {
			columns.push({
				headerName: 'Client Code',
				field: 'clientCode',				
				width: 250
			},
			{
				headerName: 'Client Name',
				field: 'clientName',				
				width: 250
			},
			{
				headerName: 'IO/PO Number',
				field: 'ioNumber',				
				width: 250
			},
			{
				headerName: 'IO/PO Description',
				field: 'ioDescription',
				width: 250
			});
		}
		if (this.state.modelDefId === 2)
		{
			columns.push(
				{
					headerName: 'Project',
					field: 'level2Key',					
					width: 250
				},
				{
					headerName: 'Activity',
					field: 'level3Key',					
					width: 250
				},
				{
					headerName: 'Expense Type',
					field: 'rTypeName',					
					width: 250
				},
				{
					headerName: 'Quantity',
					field: 'quantity',
					type: 'numeric',
					valueGetter: ({ value }) => (value && Number(value)) || 0,					
					width: 250,
					align: 'right',
					headerAlign: 'right'
				});
		}
		columns.push(
			{
				headerName: this.state.modelDefId === 2 ? 'Amount' : 'Invoice Net Amount',
				field: 'ioInvoiceAmount',
				type: 'currency',
				cellStyle: cellStyle,
				filterCellStyle: filterCellStyle,
				headerStyle: headerStyle,
				valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
				//renderCell: rowData => formatNumber(rowData.row.ioInvoiceAmount),
				width: 250,
				align: 'right',
				headerAlign: 'right'
			});
		if (this.state.modelDefId === 2)
		{
			columns.push(
				{
					headerName: 'Net Cost',
					field: 'netCost',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
					//renderCell: rowData => formatNumber(rowData.row.netCost),
					width: 250,
					align: 'right',
					headerAlign: 'right'
				},
				{
					headerName: 'Consumed Amount',
					field: 'matchToDateNet',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
					//renderCell: rowData => formatNumber(rowData.row.matchToDateNet),
					width: 250,
					align: 'right',
					headerAlign: 'right'
				},
				{
					headerName: 'Remaining Amount',
					field: 'remainingAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
					//renderCell: rowData => formatNumber(rowData.row.remainingAmount),
					width: 250,
					align: 'right',
					headerAlign: 'right'
				});
		}
		if (this.state.modelDefId !== 2) {
			columns.push(
				{
					headerName: 'IO/PO Amount',
					field: 'poAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
					//renderCell: rowData => formatNumber(rowData.row.poAmount),
					width: 250,
					align: 'right',
					headerAlign: 'right'
				},
				{
					headerName: 'Consumed Amount',
					field: 'consumedAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
					//renderCell: rowData => formatNumber(rowData.row.consumedAmount),
					width: 250,
					align: 'right',
					headerAlign: 'right'
				},
				{
					headerName: 'Delivery Amount',
					field: 'deliveryAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					valueGetter: ({ value }) => (value && formatNumber(value)) || "0.00",
					//renderCell: rowData => formatNumber(rowData.row.deliveryAmount),
					width: 250,
					align: 'right',
					headerAlign:'right'
				});
		}
		columns.push(
			{
				headerName: 'Transaction Id',
				field: 'transactionId',
				width: 250
			});
		return (
			<div>
				<h1>Vendor Invoices</h1>
				<div className="form-group">
					
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}							
							columns={columns}
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670,
								filtering: true,
								columnsButton: true,
								exportButton: true,
								exportAllData: true,
								exportFileName: "VendorInvoices"
							}}
							customcomponent={<Select
								variant='standard'
								style={{ float: "right", zIndex: 1, marginTop: 2, marginRight: 10 }}
								value={this.state.modelDefId}
								onChange={this.modelDefId_changeHandler}
							>
								{this.getInvoiceTypeMenuItem(1, 'Media')}
								{this.getInvoiceTypeMenuItem(2, 'Production')}
							</Select> }
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
			</div>
		);
	}
}
