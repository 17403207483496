import * as React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
export class InputSelect extends React.Component {
    constructor() {
        super();

        this.state = {
            readOnly: true,
            value: ""
        }
    }   
    render() {
        return (
            <div style={this.props.wraperStyle}>
                <Select
                    variant="standard"
                    labelId="select-label"
                    style={this.props.inputStyle}
                    value={this.props.value}
                    onChange={this.props.onChange}                    
                    readOnly={this.props.readOnly}                    
                >
                    {this.props.children}
                </Select>
                {this.props.inputLabelText ?
                    <InputLabel id="select-label" style={this.props.labelStyle}>{this.props.inputLabelText}</InputLabel>
                    : null
                }
            </div>	
        );
    }
}
