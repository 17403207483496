import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class Level2Lookup extends React.Component {
	constructor(props) {
		super(props);

		this.poCode = null;
		this.noPoFlag = null;

		this.inProgress = false;

		this.state = {
			level2Key: null,
			data: [],
			rowData: null
		};
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getlevel2";
		let messageName = "Level 2";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidUpdate() {
		//var that = this;

		if (this.props.info.poCode !== this.poCode || this.props.info.noPoFlag !== this.noPoFlag) {
			this.poCode = this.props.info.poCode;
			this.noPoFlag = this.props.info.noPoFlag;

			this.inProgress = false;
			this.setState({ data: [], rowData: null });
		}

		if (this.props.info.openDialog && this.state.data.length === 0 && !this.inProgress) {
			this.inProgress = true;
			this.fetchRecords();
		}
		else if (this.props.info.level2Key !== this.state.level2Key) {
			var rowData = this.getRowData(this.props.info.level2Key);

			this.setState({ level2Key: this.props.info.level2Key, rowData: rowData });
		}
	}

	checkbox_clickHandler(event) {
		var rowData = this.getRowData(event.target.value);

		this.setState({ rowData: rowData });
	}

	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}

	fetchRecords() {
		if (this.state.data.length === 0) {
			this.props.info.inProgress(true);

			httpGet("/api/lookup/level2list/" + this.props.info.noPoFlag + "/" + this.props.info.poCode)
				.then((response) => {
					//var data = response.data;
					var data = response.data.map((e, i) => { let d = { ...e, rowId: i }; return d; })
					var rowData = data[data.findIndex(d => d.level2Key === this.props.info.level2Key)];
					this.setState({ data: data, rowData: rowData });
					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error('Error fetching Project Lookup');

					this.close_clickHandler();
				});
		}
	}

	getRowData(value) {
		var data = this.state.data;

		return data[data.findIndex(d => d.level2Key === value)];
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 28 };
		const headerStyle = { textAlign: 'right' };

		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Select Project
						</DialogTitle>
				<DialogContent>
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: ' ',
									field:'chkLevel2',
									
									renderCell: rowData =>
										<Checkbox
											color="default"
											name="chk"
											checked={this.state.rowData != null && this.state.rowData !== undefined && rowData.row.level2Key === this.state.rowData.level2Key}
											value={rowData.row.level2Key}
											onClick={this.checkbox_clickHandler}
										/>,
									width: 40
								},
								{
									headerName: 'Project',
									field: 'level2Key',
									width: 50,
									flex: 1
								},
								{
									headerName: 'Description',
									field: 'level2Description',
									flex: 1
								},
								{
									headerName: 'Open Date',
									field: 'openDate',
									type: 'date',
									valueGetter: ({ value }) => (value && new Date(value)),
									flex: 1
								},
								{
									headerName: 'Customer Code',
									field: 'customerCode',
									width: 50,
									flex: 1
								},
								{
									headerName: 'Customer Name',
									field: 'customerName',
									width: 50,
									flex: 1
								},
								{
									headerName: 'Customer PO Number',
									field: 'customerPONumber',
									width: 50,
									flex: 1
								}
							

							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
