import * as React from 'react';

import { Header } from './Header';
import BodyContainer from './bodyContainer';

import Home from './Home';


export default (props: { tokenHandler: any; }) => (
    <React.Fragment>
        <div className="loader" id="loading" style={{ display: "none" }}>
            <img src="/images/loading.gif" alt="LOADING" />
        </div>
        <Header tokenHandler={props.tokenHandler} />
        <BodyContainer />
    </React.Fragment>
);
