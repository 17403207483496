import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { httpGet, httpPost, httpPut, httpDelete } from '../../HttpRequestHandling/httpRequestHandler';
import { UserRolesResources } from './UserRolesResources';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import { GridRowModes, useGridApiRef, GridActionsCellItem,  } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import { Check } from '@mui/icons-material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RemoveIcon from '@mui/icons-material/Remove';

import RefreshIcon from '@mui/icons-material/Refresh';
var currentEditRow = null;
export default function Resources(props) {

	const companyCode = JSON.parse(localStorage.userInfo).companyCode;
	const apiGridRef = useGridApiRef();
	const [state,setState] =React.useState({
		data: [],
		companyCode: companyCode,
		userRolesList: [],
		openPasswordResetConfirmation: false,
		resetPasswordRowData: null,
		openDeleteConfirmation: false,
		deleteRowData: null,
		selectedRows: [],
		edit:null
	});
	const [currentEditRowId, setCurrentEditRowId] = React.useState(null);
	const [pageSize, setPageSize] = React.useState(10);
	const [rowModesModel, setRowModesModel] = React.useState({});

	React.useEffect(() => {
		fetchResources().then((response) => {
			props.inProgress(true);

			httpGet("/api/userroles/")
				.then((response) => {
					var userRolesList = response.data;

					props.inProgress(false);

					setState((prev)=>({...prev, userRolesList: userRolesList }));
				})
		}).catch((error) => {
			props.inProgress(false);

			toast.error('An error occurred while fetching the records');
			console.error('There was an error!', error);
		});
	}, [])
	const getRefreshButton = React.useCallback((event) => { 
		return <IconButton
			variant="contained"
			onClick={refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}, [state.data, currentEditRowId])

	const refresh_clickHandler = React.useCallback((event) => {
		props.inProgress(true);
		let functionName = "getresources";
		let messageName = "Resources";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}, [])
	const fetchResources = React.useCallback(() => {
		props.inProgress(true);
		var promise = httpGet("/api/resources/")
			.then((response) => {
				var data = response.data;
				var rowId = 1;

				data.map(row => row.rowId = rowId++);

				props.inProgress(false);

				setState((prev) =>({ ...prev, data: data }));
			}).catch((error) => {
				props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});

		return promise;
	},[])

	const saveResources = React.useCallback((newdata, oldData) => {
		let rowdata = structuredClone(newdata);
		if (newdata.isNew) {
			rowdata.rowId = 0;
		}
		rowdata.companyCode = parseInt(state.companyCode);
		var httpMethod = rowdata.rowId === 0 ? httpPost : httpPut;

		props.inProgress(true);

		httpMethod("/api/resources/", rowdata)
			.then((response) => {
				props.inProgress(false);
				toast.success('Updated Successfully');
				//resolve();
				currentEditRow = null;
				fetchResources();
			}).catch((error) => {
				//reject();
				if (newdata?.isNew) {
					setState((prev) => ({ ...prev, data: state.data.filter(e => e.rowId !== newdata.rowId) }))
				} else {
					apiGridRef.current.updateRows([{ id: newdata.rowId, ...oldData }])
				}
				props.inProgress(false);
				toast.error(<div>Resources Update Failed<br />{error.response.data.detail}</div>);
			});
	}, [state.companyCode,state.data])

	const deleteResources = React.useCallback(() => {
		props.inProgress(true);
		let data = state.deleteRowData;
		setState((prev) => ({ ...prev, openDeleteConfirmation: false, deleteRowData: null }));
		httpDelete("/api/resources/" + data.resourceId)
			.then((response) => {
				props.inProgress(false);
				toast.success('Deleted Successfully');
				fetchResources();
			}).catch((error) => {
				props.inProgress(false);

				if (error.response.data.includes("Cannot delete, record being referenced by other entities.")) {
					toast.error("Cannot delete, record being referenced by other entities.");
				}
				else {
					toast.error(<div>Delete Failed<br />{error.response.data}</div>);
				}
			});
	}, [state.deleteRowData])

	const isRecordValid = React.useCallback((data) => {
		var returnValue = true;
		var message = "";

		const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (data.resourceId === null || data.resourceId === undefined || data.resourceId.trim().length === 0) {
			message += "Resource Id, ";
		}
		if (data.nameFirst === null || data.nameFirst === undefined || data.nameFirst.trim().length === 0) {
			message += "First Name, ";
		}
		if (data.nameLast === null || data.nameLast === undefined || data.nameLast.trim().length === 0) {
			message += "Last Name, ";
		}
		if (data.email && data.email.trim().length > 0 && !regex.test(data.email.toLowerCase())) {
			message += "valid Email, ";
		}
		if (data.loginId === null || data.loginId === undefined || data.loginId.trim().length === 0) {
			message += "Login Id, ";
		}

		if (message.length > 0) {
			message = message.substr(0, message.length - 2);

			if (message.indexOf(", ") !== -1) {
				var index = message.lastIndexOf(",");
				message = message.substring(0, index) + " and" + message.substring(index + 1);
			}

			toast.error("Please Provide " + message);

			returnValue = false;
		}

		return returnValue;
	}, [])

	const getUserRoleName = React.useCallback((apadUserRolesResource) => {
		var assignedUserRolesResources = "";

		if (state.userRolesList.length > 0 && apadUserRolesResource && apadUserRolesResource.length > 0) {
			assignedUserRolesResources = state.userRolesList.find(ur => ur.userRoleId === apadUserRolesResource[0].userRoleId).userRoleName + (apadUserRolesResource.length > 1 ? "..." : "");
		}

		return assignedUserRolesResources;
	}, [state,state.edit])

	const getUserRoleNameCSV = React.useCallback( (apadUserRolesResource) => {
		var assignedUserRolesResources = [];
		if (state.userRolesList.length > 0 && apadUserRolesResource && apadUserRolesResource.length > 0) {
			apadUserRolesResource.map((userRolesResource, index) => (
				assignedUserRolesResources.push(state.userRolesList.find(ur => ur.userRoleId === userRolesResource.userRoleId).userRoleName)
			))
		}

		return assignedUserRolesResources.join(", ");
	}, [state, state.edit])

	const getUserRoleNameList = React.useCallback((apadUserRolesResource) => {
		return (<ul key={2} style={{ margin: 0, pading: 0, listStyle: 'none' }}> {(apadUserRolesResource)
			.map((e, ei) => {
				let rolename = state.userRolesList.find(ur => ur.userRoleId === e.userRoleId)?.userRoleName;
				return (rolename && <li key={ei}>{rolename}</li>) || <></>
			})}</ul>)
	}, [state, state.edit])

	const selectedUserRoles_onClose = React.useCallback((selectedUserRoles) => {
		if (selectedUserRoles) {
			var apadUserRolesResource = [];

			selectedUserRoles.map((userRolesResource, index) => (
				apadUserRolesResource.push({ companyCode: userRolesResource.companyCode, resourceId: currentEditRow.resourceId, userRoleId: parseInt(userRolesResource.userRoleId, 10) })
			));

			currentEditRow.apadUserRolesResource = apadUserRolesResource;

			setState((prev) => ({ ...prev, dummy: null, edit: { ...prev.edit, apadUserRolesResource: apadUserRolesResource } }));
		}
	}, [state])

	const resources_resetPasswordClickHandler = React.useCallback((rowData) => {
		setState((prev) => ({ ...prev, openPasswordResetConfirmation: true, resetPasswordRowData: rowData }));
	}, [])


	const resources_DeleteClickHandler = React.useCallback((rowData) => {
		setState((prev) => ({ ...prev, openDeleteConfirmation: true, deleteRowData: rowData }));
	}, [])

	const closeDialog = React.useCallback( (event) => {
		setState((prev) => ({ ...prev, openPasswordResetConfirmation: false, resetPasswordRowData: null }));
	}, [])


	const closeDeleteDialog = React.useCallback((event) => {
		setState((prev) => ({ ...prev, openDeleteConfirmation: false, deleteRowData: null }));
	}, [])


	const resetPasswordYes_clickHandler = React.useCallback((event) => {
		var url = "/api/resources/resetpassword/";
		var rowData = state.resetPasswordRowData;

		setState((prev) => ({ ...prev, openPasswordResetConfirmation: false, resetPasswordRowData: null }));

		props.inProgress(true);

		httpPost(url, rowData)
			.then((response) => {
				props.inProgress(false);

				toast.success('Password reset Successfully');
			}).catch((error) => {
				props.inProgress(false);

				toast.error('Password reset Failed');
			});
	}, [state])



	/////////////////////////////////////////////////////////

	const getAddButton = React.useCallback(() => {
		return <IconButton
			key={1 }
			disabled={!!currentEditRowId }
			variant="contained"
			onClick={handleAddClick}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10 }}
		>
			<Tooltip key={1} title={'add'}><AddBoxIcon key={2} /></Tooltip>
		</IconButton>;
	}, [state.data, currentEditRowId])


	const handleAddClick = React.useCallback(() => {
		const id = ([...state.data]?.sort((a,b)=>b.rowId-a.rowId)[0]?.rowId + 1) || 1;
		//apiGridRef.current.setPage(Math.ceil((state.data.length+1) / pageSize));
		let model = {};
		model.rowId = id;
		model.isNew = true;
		let field = 'resourceId';
		setCurrentEditRowId(id);
		setState((prev) => ({
			...prev, isDirty: true, data: [ model,...prev.data]
		}));
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: field } })
		//apiGridRef.current.startRowEditMode({ id: id, fieldToFocus: field });
		
	}, [state.data]);

	const processRowUpdate = React.useCallback((newrow, oldrow) => {
		let _newrow = structuredClone(newrow);
		if (!isRecordValid(_newrow)) {
			setRowModesModel({ ...rowModesModel, [_newrow.rowId]: { mode: GridRowModes.Edit } })
			throw new Error('')
		}
		setCurrentEditRowId(null);
		//if (_newrow?.isNew) apiGridRef.current.setPage(0);
		_newrow.apadUserRolesResource = state.edit?.apadUserRolesResource
			|| currentEditRow.apadUserRolesResource;
		setState((prev) => ({ ...prev, edit: null }))
		saveResources(_newrow, oldrow);
		return newrow;
	}, [state, state.edit])

	const handleRowClick = React.useCallback((params, event) => {
		if (!params.isEditable) {
			return;
		}
		if (!event.currentTarget.contains(event.target)) {
			return;
		}
		if (params.cellMode == GridRowModes.View) 
		{
			apiGridRef.current.startRowEditMode({ id: params.id });
		}
	}, [state])

	const handlePageSizeChange = React.useCallback((newpagesize) => {
		setPageSize((prev) => (newpagesize));
	},[state])




	const handleEditClick = (id) => {
		setCurrentEditRowId(id);
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (newData) => {
		setRowModesModel({ ...rowModesModel, [newData.rowId]: { mode: GridRowModes.View } });
	};

	const handleCancelClick = (id) => {
		setCurrentEditRowId(null);
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});
		const editedRow = state.data.find((row) => row.rowId === id);
		//if (editedRow?.isNew) {
		//	apiGridRef.current.setPage(0);

		//}
		setState((prev) => { 
			return (editedRow?.isNew)? { ...prev, edit: null, data: state.data.filter((row) => row.rowId !== id) }
			:{ ...prev, edit: null }
		});
	};


	//////////////////////////////////////////////////////////

		return (
			<div>
				<h1>User Management</h1>
				<div className="form-group">
					<div style={{ display: 'block',overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							editMode="row"
							onCellClick={handleRowClick}
							processRowUpdate={processRowUpdate}
							onProcessRowUpdateError={(err) => { }}
							disableSelectionOnClick
							apiRef={apiGridRef}
							customcomponent={
								(<>
									{getRefreshButton()}
									{getAddButton()}									
								</>)
							}
							rowModesModel={rowModesModel}
							columns={[
								{
									headerName: 'User Group',
									field: 'apadUserRolesResource',
									width:200,
									renderCell: rowData => {
										return (<div key={1 }>
											<Tooltip key={2} title={getUserRoleNameList(rowData?.row?.apadUserRolesResource || [])}>
												<Typography variant="inherit">{getUserRoleName(rowData.row.apadUserRolesResource)}</Typography>
											</Tooltip>
										</div>)
									},
									renderEditCell: props => {
										currentEditRow = props.row;

										const info = {
											userRolesList: state.userRolesList,
											apadUserRolesResource: props.row.apadUserRolesResource ? props.row.apadUserRolesResource : []
										};

										return <Box  
											key={1}
											sx={{
												display: 'flex',
												flexDirection: 'row'
											}}>
											<Tooltip key={2} title={getUserRoleNameList((state.edit?.apadUserRolesResource || currentEditRow.apadUserRolesResource) || [])}>
												<Typography key={3} sx={{
													pt:'10px'
												}}
													variant="inherit">{getUserRoleName(state.edit?.apadUserRolesResource || currentEditRow.apadUserRolesResource)}</Typography>
											</Tooltip>
											<UserRolesResources  info={info} onClose={selectedUserRoles_onClose} />
										</Box>
									},
									editable: true
									
								},
								{
									headerName: 'Resource Id',
									field: 'resourceId',
									cellClassName: "MuiInputBase-input MuiInput-input",
									renderCell: (props) => {
										return <span>{props.row.resourceId}</span>
									},
									renderEditCell: (props) => {
										let row = props.row;
										if (!row.isNew) {
											return <span>{row.resourceId}</span>
										} else {
											return <input
												type="text"
												aria-invalid="false"
												placeholder=""
												value={row.resourceId || ''}
												className="MuiInputBase-input MuiInput-input"
												style={{ width: '100%' }}
												onChange={(event) => {
													const newValue = event.target.value;
													const { id, api, field, value } = props;// The new value entered by the user
													api.setEditCellValue({ id, field, value: newValue })
												}
											}
											/>
										}
									},
									width: 100,
									editable: true,
								},
								{
									headerName: 'First Name',
									field: 'nameFirst',
									editable: true,
									width: 200
								},
								{
									headerName: 'Last Name',
									field: 'nameLast',
									editable: true,
									width: 200
								},
								{
									headerName: 'Name Initial',
									field: 'nameInit',
									editable: true,
									width: 100
								},
								{
									headerName: 'Title',
									field: 'title',
									editable: true,
									flex:1
								},
								{
									headerName: 'Email',
									field: 'email',
									editable: true,
									width: 200
								},
								{
									headerName: 'Login',
									field: 'loginId',
									editable: true,
									width: 200
								},
								{
									headerName: 'Active',
									field: 'activeFlag',
									type: 'boolean',
									width: 80,
									headerAlign: 'center',
									align: 'center',
									renderCell: (props) => {
										return (
											(props?.row?.activeFlag) ?
												<Check key={props?.row?.rowId} color={'action'} /> :
												<RemoveIcon key={props?.row?.rowId} color={'action'} />
												
										)
									},
									renderEditCell: props => (
										<Checkbox
											key={props.row.rowId }
											color="default"
											checked={props.value}
											onChange={e => {
												const newValue = e.target.checked;
												const { id, api, field, value } = props;// The new value entered by the user
												api.setEditCellValue({ id, field, value: newValue })

											} 
										}
										/>
									),
									editable: true,
									
								},
								{
									field: 'actions',
									type: 'actions',
									headerName: 'Actions',
									width: 120,
									cellClassName: 'actions',
									getActions: (rowData) => {
										const isInEditMode = rowModesModel[rowData.id]?.mode === GridRowModes.Edit;

										if (isInEditMode) {
											return [
												<GridActionsCellItem
													key={rowData.id+1}
													icon={<Tooltip key={rowData.id + 2} title={'Save'}>
														<DoneIcon key={rowData.id + 1} />
														</Tooltip>}
													label="Save"
													color="inherit"
													onClick={() => { handleSaveClick(rowData.row) }}
													
												/>,
												<GridActionsCellItem
													key={rowData.id + 2}
													icon={<Tooltip key={rowData.id + 2}  title={'Cancel'}>
														<CancelIcon key={rowData.id + 1} />
														</Tooltip>}
													label="Cancel"
													className="textPrimary"
													onClick={() => { handleCancelClick(rowData.id) }}
													color="inherit"
												/>,
											];
										}

										return [
											<GridActionsCellItem
												key={rowData.id + 1}
												icon={<Tooltip key={rowData.id + 2} title={'Reset Password'}>
													<VpnKeyIcon key={rowData.id + 1} />
														</Tooltip>}
												label="Reset Password"
												className="textPrimary"
												onClick={() => { resources_resetPasswordClickHandler(rowData.row) }}
												color="inherit"
												disabled={currentEditRowId && currentEditRowId !== rowData.id}
											/>,
											<GridActionsCellItem
												key={rowData.id + 2}
												icon={<Tooltip key={rowData.id + 2} title={'Edit'}>
													<EditIcon key={rowData.id + 1} />
														</Tooltip>}
												label="Edit"
												className="textPrimary"
												onClick={() => { handleEditClick(rowData.id) }}
												color="inherit"
												disabled={currentEditRowId && currentEditRowId !== rowData.id}
											/>,
											<GridActionsCellItem
												key={rowData.id + 3}
												icon={<Tooltip key={rowData.id + 2} title={'Delete'}>
													<DeleteIcon key={rowData.id + 1} />
														</Tooltip>}
												label="Delete"
												onClick={() => { resources_DeleteClickHandler(rowData.row) }}
												color="inherit"
												disabled={currentEditRowId && currentEditRowId !== rowData.id}
											/>,
										];
									},
								},

							]}
							onPageSizeChange={handlePageSizeChange }
							getRowId={(row) => row.rowId}
							editable={
								{
									onRowAdd: newData =>
										new Promise((resolve, reject) => {
											newData.rowId = 0;
											newData.companyCode = parseInt(state.companyCode);

											if (isRecordValid(newData)) {
												newData.apadUserRolesResource = that.currentEditRow.apadUserRolesResource;

												saveResources(newData, resolve, reject);
											}
											else {
												reject();
											}
										}),
									onRowUpdate: (newData, oldData) =>
										new Promise((resolve, reject) => {

											if (isRecordValid(newData)) {
												newData.apadUserRolesResource = that.currentEditRow.apadUserRolesResource;

												saveResources(newData, resolve, reject);
											}
											else {
												reject();
											}
										}),
									onRowDelete: oldData =>
										new Promise((resolve, reject) => {
											deleteResources(oldData, resolve, reject);
										}),
									onRowAddCancelled: rowData =>
										new Promise((resolve, reject) => {
											setState({ selectedUserRoleIds: [], initializeSelectedUserRoleIds: true });
										}),
									onRowUpdateCancelled: rowData =>
										new Promise((resolve, reject) => {
											setState({ selectedUserRoleIds: [], initializeSelectedUserRoleIds: true });
										})
								}
							}
						/>
					</div>

				</div>
				<Dialog
					open={state.openPasswordResetConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Reset Password
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to reset password for login id "{state.resetPasswordRowData ? state.resetPasswordRowData.loginId : ""}" ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={resetPasswordYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={closeDialog} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>


				<Dialog
					open={state.openDeleteConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Delete Record
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete the login id "{state.deleteRowData ? state.deleteRowData.loginId : ""}" ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={deleteResources} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={closeDeleteDialog} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);

} 