import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';

export class UserRolesResources extends React.Component {
	constructor() {
		super();

		this.state = {
			userRolesList: []
		};

		this.popover_handleClickOpen = this.popover_handleClickOpen.bind(this);
		this.popover_handleClose = this.popover_handleClose.bind(this);
		this.userRoles_changeHandler = this.userRoles_changeHandler.bind(this);
	}

	componentDidMount() {
		var userRolesList = this.props.info.userRolesList;

		userRolesList.map((userRolesResource, index) => (
			userRolesResource.checked = this.props.info.apadUserRolesResource.findIndex(urr => urr.userRoleId === userRolesResource.userRoleId) > -1));

		this.setState({ userRolesList: userRolesList });
	}

	userRoles_changeHandler(event) {
		var userRolesList = this.state.userRolesList;
		userRolesList.find(ur => ur.userRoleId === parseInt(event.target.value)).checked = event.target.checked;

		this.setState({ userRolesList: userRolesList });
	}

	popover_handleClickOpen(event) {
		this.setState({ anchorEl: event.currentTarget });
	}

	popover_handleClose(event) {
		this.setState({ anchorEl: null });

		if (this.props.onClose != null) {
			this.props.onClose(this.state.userRolesList.filter(ur => ur.checked));
		}
	}

	render() {
		var that = this;

		return (
			<div>
				<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.popover_handleClickOpen}>
					<SettingsIcon />
				</IconButton>
				<Popover
					open={this.state.anchorEl != null}
					anchorEl={this.state.anchorEl}
					onClose={this.popover_handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					{
						this.state.userRolesList.map((userRolesResource, index) => (
							<MenuItem key={index}>
								<Checkbox key={index+1} color="default" checked={userRolesResource.checked} value={userRolesResource.userRoleId} onChange={that.userRoles_changeHandler} />
								<ListItemText key={index+2} primary={userRolesResource.userRoleName} />
							</MenuItem>
						))
					}
				</Popover>
			</div>
		);
	}
}
