import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Authentication/login';
import LoginError from './components/Authentication/LoginError';
import CompanyConfiguration from './components/CompanyConfig/CompanyConfiguration';
import Resources from './components/Resources/Resources';
import UserRoles from './components/UserRoles/UserRoles';
import NonMappedInvoices from './components/NonMappedInvoices/NonMappedInvoices';
import ManageInvoiceModel from './components/InvoiceModels/ManageInvoiceModel';
import InvoiceDocument from './components/InvoiceDocument/InvoiceDocument';
import Dashboard from './components/Dashboard/Dashboard';
import PaymentDashboard from './components/PaymentDashboard/PaymentDashboard';
import Currencies from './components/Setup/Currencies';
import PaymentTerms from './components/Setup/PaymentTerms';
import Taxes from './components/Setup/Taxes';
import Vendors from './components/Setup/Vendors';
import POHeaders from './components/Setup/POHeaders';
import Level2s from './components/Setup/Level2s';
import CostTypes from './components/Setup/CostTypes';
import APPayments from './components/Setup/APPayments';
import EmployeesReport from './components/Reports/EmployeesReport';
import VendorsReport from './components/Reports/VendorsReport';
import PurchaseOrdersReport from './components/Reports/PurchaseOrdersReport';
import InsertionOrdersReport from './components/Reports/InsertionOrdersReport';
import VendorInvoicesReport from './components/Reports/VendorInvoicesReport';
import VendorInvoicesWithPaymentStatusReport from './components/Reports/VendorInvoicesWithPaymentStatusReport';
import InvoicesPendingPostReport from './components/Reports/InvoicesPendingPostReport';
import InvoicesApprovalHistoryReport from './components/Reports/InvoicesApprovalHistoryReport';

import { stringIsNullOrEmpty, isNullOrUndefined } from './components/Common/Utilities';

export default class CustomRoutes extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const getUserInfo = () => isNullOrUndefined(localStorage.userInfo) ? null : JSON.parse(localStorage.userInfo);
		const isTokenExpired = () => isNullOrUndefined(localStorage.tokenExpiry) || Date.now() > Date.parse(localStorage.tokenExpiry);

		const queryString = window.location.search;
		const searchParams = new URLSearchParams(queryString);
		const ssoToken = searchParams.get('ssotoken');

		const getInvoicesRoute = (basePath, modelDefId) => (
			<>
				<Route exact path={basePath + '/ManageNonMappedInvoices/'} element={<NonMappedInvoices key={modelDefId} modelDefId={modelDefId} basePath={basePath} inProgress={this.props.inProgress} />} />
				<Route exact path={basePath + '/ManageInvoiceModels/'} element={<ManageInvoiceModel key={modelDefId} modelDefId={modelDefId} basePath={basePath} inProgress={this.props.inProgress} />} />
				<Route exact path={basePath + '/ManageInvoiceDocuments/'} element={<InvoiceDocument key={modelDefId} modelDefId={modelDefId} basePath={basePath} inProgress={this.props.inProgress} />} />
				<Route exact path={basePath + '/Dashboard/'} element={<Dashboard key={modelDefId} modelDefId={modelDefId} inProgress={this.props.inProgress} />} />
				<Route exact path={basePath + '/PaymentDashboard/'} element={<PaymentDashboard key={modelDefId} modelDefId={modelDefId} inProgress={this.props.inProgress} />} />
				<Route exact path={basePath + '/'} element={(<Dashboard key={modelDefId} modelDefId={modelDefId} inProgress={this.props.inProgress} />)} />
			</>
		);

		return (
			<div>
				<Routes >
					{
						(getUserInfo() == null || isTokenExpired() || !stringIsNullOrEmpty(ssoToken)) ?
							<Route exact path='/' element={<Login inProgress={this.props.inProgress} />} /> :
							(isNullOrUndefined(getUserInfo().invoiceType)) ?
								<Route exact path='/' element={<Home />} /> :
								<Route exact path='/' element={<Dashboard modelDefId={getUserInfo().invoiceType.includes('1') ? 1 : 2} inProgress={this.props.inProgress} />} />

					}
					{

						(getUserInfo() == null || isTokenExpired() || !stringIsNullOrEmpty(ssoToken)) ?
							<Route exact path='/Login' element={<Login inProgress={this.props.inProgress} />} /> :
							(isNullOrUndefined(getUserInfo().invoiceType)) ?
								<Route exact path='/Login' element={<Home />} /> :
								<Route exact path='/Login' element={<Dashboard modelDefId={getUserInfo().invoiceType.includes('1') ? 1 : 2} inProgress={this.props.inProgress} />} />

					}
					<Route exact path='/LoginError' element={(<LoginError inProgress={this.props.inProgress} />)} />
					<Route exact path='/CompanyConfiguration' element={(<CompanyConfiguration inProgress={this.props.inProgress} />)} />
					<Route exact path='/Resources' element={(<Resources inProgress={this.props.inProgress} />)} />
					<Route exact path='/UserRoles' element={(<UserRoles inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/Currencies' element={(<Currencies inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/PaymentTerms' element={(<PaymentTerms inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/Taxes' element={(<Taxes inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/Vendors' element={<Vendors inProgress={this.props.inProgress} />} />
					<Route exact path='/Setup/InsertionOrders' element={(<POHeaders modelDefId={1} key={1} inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/PurchaseOrders' element={(<POHeaders modelDefId={2} key={2} inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/Projects' element={(<Level2s inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/CostTypes' element={(<CostTypes inProgress={this.props.inProgress} />)} />
					<Route exact path='/Setup/APPayments' element={(<APPayments inProgress={this.props.inProgress} />)} />
					{getInvoicesRoute('/Media', 1)}
					{getInvoicesRoute('/Media/', 1)}
					{getInvoicesRoute('/Production', 2)}
					{getInvoicesRoute('/Production/', 2)}
					<Route exact path='/Reports/Employees' element={(<EmployeesReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/Vendors' element={(<VendorsReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/PurchaseOrders' element={(<PurchaseOrdersReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/InsertionOrders' element={(<InsertionOrdersReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/VendorInvoices' element={(<VendorInvoicesReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/VendorInvoicesWithPaymentStatus' element={(<VendorInvoicesWithPaymentStatusReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/InvoicesPendingPost' element={(<InvoicesPendingPostReport inProgress={this.props.inProgress} />)} />
					<Route exact path='/Reports/InvoicesApprovalHistory' element={(<InvoicesApprovalHistoryReport inProgress={this.props.inProgress} />)} />
				</Routes>

			</div>
		);
	}
}