import * as React from 'react';
import { InputCheckbox } from '../FormFieldControls/InputCheckbox';
import { LabelText } from '../FormFieldControls/LabelText';
import { TextFieldWithLabel } from '../FormFieldControls/TextFieldWithLabel';
import './CompanyConfig.css'
import $ from 'jquery';
import { isNullOrUndefined } from '../Common/Utilities';

export class APInvoiceLandingSetup extends React.Component {
	constructor(props) {
		super(props);

		this.componentDidMount = this.componentDidMount.bind(this);

		this._changeHandler = this._changeHandler.bind(this);
	}

	componentDidMount() {
		var that = this;

		$('input[type="checkbox"][name="nexelusOwnedStorageFlag"],[name="clientOwnedStorageFlag"]').click(function () {
			$('input[type="checkbox"][name="nexelusOwnedStorageFlag"],[name="clientOwnedStorageFlag"]').each(function () {
				that.props.company[this.name] = "N";
				$(this).parent().siblings()[1].firstElementChild.firstElementChild.readOnly = true;
			});

			that.props.company[this.name] = "Y";
			$(this).parent().siblings()[1].firstElementChild.firstElementChild.readOnly = false;
		});
	}

	_changeHandler(event) {
		var modelDefId = parseInt(event.target.id.split("_")[1], 10);
		event.target.dataset.name = event.target.name.split("_")[0];

		this.props.changeHandler(event, modelDefId, "companyInvoiceConfigurations");
	}
	getUseMediaDeliveryTag(that, useDeliveryAmountForMatching) {
		return isNullOrUndefined(useDeliveryAmountForMatching) ? <div></div> :
		<div className="form-group">
			<div className="labeldiv col-sm-1">
					<InputCheckbox checkboxInfo={useDeliveryAmountForMatching} changeHandler={that._changeHandler} />
			</div>
			<div className="labeldiv col-md-3">
				<LabelText labelInfo={useDeliveryAmountForMatching} />
			</div>
		</div>
	}	
	render() {
		var that = this;
		var companyInvoiceConfigurationList = [];

		const nexelusOwnedStorageFlag = {
			checkboxId: "chkNexelusOwnedStorageFlag",
			checkboxName: "nexelusOwnedStorageFlag",
			checkboxValue: this.props.company.nexelusOwnedStorageFlag
		}
		const nexelusS3StorageConnectionString = {
			labelId: 'lblNexelusS3StorageConnectionString',
			labelText: 'Nexelus owned storage FTP connection string',

			textboxPlaceholder: "Nexelus owned storage FTP connection string",
			textboxId: "txtNexelusS3StorageConnectionString",
			textboxName: "nexelusS3StorageConnectionString",
			readOnly: this.props.company.nexelusOwnedStorageFlag === "Y" ? false : true,
			textboxValue: this.props.company.nexelusS3StorageConnectionString
		}
		const clientOwnedStorageFlag = {
			checkboxId: "chkClientOwnedStorageFlag",
			checkboxName: "clientOwnedStorageFlag",
			checkboxValue: this.props.company.clientOwnedStorageFlag
		}
		const clientFtpSiteConnectionString = {
			labelId: 'lblClientFtpSiteConnectionString',
			labelText: 'Client owned FTP ConnectionString',

			textboxPlaceholder: "Client owned FTP ConnectionString",
			textboxId: "txtClientFtpSiteConnectionString",
			textboxName: "clientFtpSiteConnectionString",
			readOnly: this.props.company.clientOwnedStorageFlag === "Y" ? false : true,
			textboxValue: this.props.company.clientFtpSiteConnectionString
		}

		this.props.company.companyInvoiceConfigurations.map(function (companyInvoiceConfiguration, index) {
			const invoiceCollectionEmailFlag = {
				checkboxId: "chkInvoiceCollectionEmailFlag_" + companyInvoiceConfiguration.modelDefId,
				checkboxName: "invoiceCollectionEmailFlag_" + companyInvoiceConfiguration.modelDefId,
				checkboxValue: companyInvoiceConfiguration.invoiceCollectionEmailFlag
			}
			const invoiceCollectionEmailString = {
				labelId: "lblInvoiceCollectionEmailString_" + companyInvoiceConfiguration.modelDefId,
				labelText: 'Nexelus e-mail to route AP Invoices',

				textboxPlaceholder: "Nexelus e-mail to route AP Invoices",
				textboxId: "txtInvoiceCollectionEmailString_" + companyInvoiceConfiguration.modelDefId,
				textboxName: "invoiceCollectionEmailString_" + companyInvoiceConfiguration.modelDefId,
				readOnly: companyInvoiceConfiguration.invoiceCollectionEmailFlag === "Y" ? false : true,
				textboxValue: companyInvoiceConfiguration.invoiceCollectionEmailString
			}
			const useDeliveryAmountForMatching = (companyInvoiceConfiguration.modelDefId !== 1) ? null : {
				checkboxId: "chkUseDeliveryAmountForMatching_" + companyInvoiceConfiguration.modelDefId,
				checkboxName: "useDeliveryAmountForMatching_" + companyInvoiceConfiguration.modelDefId,
				checkboxValue: companyInvoiceConfiguration.useDeliveryAmountForMatching,

				labelId: "lblUseDeliveryAmountForMatching_" + companyInvoiceConfiguration.modelDefId,
				labelText: 'Use Delivery Amount as basis for matching'
			}
			const useSendIOCreatorEmail = {
				checkboxId: "chkSendIOCreatorEmail_" + companyInvoiceConfiguration.modelDefId,
				checkboxName: "sendIOCreatorEmail_" + companyInvoiceConfiguration.modelDefId,
				checkboxValue: companyInvoiceConfiguration.sendIOCreatorEmail ? companyInvoiceConfiguration.sendIOCreatorEmail :'N',
				labelId: "lblSendIOCreatorEmail_" + companyInvoiceConfiguration.modelDefId,
				labelText: 'Send first email for first level of approval to IO/PO creator'
			}
			
			const autoPostInvoice = {
				checkboxId: "chkAutoPostInvoice_" + companyInvoiceConfiguration.modelDefId,
				checkboxName: "autoPostInvoice_" + companyInvoiceConfiguration.modelDefId,
				checkboxValue: companyInvoiceConfiguration.autoPostInvoice,

				labelId: "lblAutoPostInvoice_" + companyInvoiceConfiguration.modelDefId,
				labelText: "Auto Post Invoice"
			}
			companyInvoiceConfigurationList.push(<div key={index}>
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 grid_row_2" style={{ marginBottom: 10 }}>
					<div className="col-lg-5 col-md-5 col-sm-5 col-xs-4 grid_mainheading">
						<h3>
							{companyInvoiceConfiguration.masterDefName}
						</h3>
					</div>
				</div>
				<div className="col-md-12 form-group zero-padding zero-margin">
					<fieldset>
						<div className="form-group">
							<div className="labeldiv col-sm-1">
								<InputCheckbox checkboxInfo={invoiceCollectionEmailFlag} changeHandler={this._changeHandler} />
							</div>
							<TextFieldWithLabel info={invoiceCollectionEmailString}
								validate={this.validateEmail}
								errorMessage="Email is invalid"
								emptyMessage="Email is required"
								changeHandler={this._changeHandler} />
						</div>
						{that.getUseMediaDeliveryTag(that, useDeliveryAmountForMatching)}
						<div className="form-group">
							<div className="labeldiv col-sm-1">
								<InputCheckbox checkboxInfo={autoPostInvoice} changeHandler={this._changeHandler} />
							</div>
							<div className="labeldiv col-md-3">
								<LabelText labelInfo={autoPostInvoice} />
							</div>
						</div>
						<div className="form-group">
							<div className="labeldiv col-sm-1">
								<InputCheckbox checkboxInfo={useSendIOCreatorEmail} changeHandler={this._changeHandler} />
							</div>
							<div className="labeldiv col-md-3">
								<LabelText labelInfo={useSendIOCreatorEmail} />
							</div>
						</div>
					</fieldset>
				</div>
			</div>);
		}, this);

		return (
			<div>
				<div className="col-md-12 form-group zero-padding zero-margin">
					<fieldset>
						<div className="form-group">
							<div className="labeldiv col-sm-1">
								<InputCheckbox checkboxInfo={nexelusOwnedStorageFlag} changeHandler={this.props.changeHandler} />
							</div>
							<TextFieldWithLabel info={nexelusS3StorageConnectionString} changeHandler={this.props.changeHandler} />
						</div>
						<div className="form-group">
							<div className="labeldiv col-sm-1">
								<InputCheckbox checkboxInfo={clientOwnedStorageFlag} changeHandler={this.props.changeHandler} />
							</div>
							<TextFieldWithLabel info={clientFtpSiteConnectionString} changeHandler={this.props.changeHandler} />
						</div>
					</fieldset>
				</div>
				{companyInvoiceConfigurationList}
			</div>
		);
	}
}
