import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import withRouter from '../RoutingSetup';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import sha256 from 'js-sha256';
import { stringIsNullOrEmpty, defaultStringIfNullOrEmpty, blankStringIfNullOrEmpty, isNullOrUndefined } from '../Common/Utilities';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { loginUser } from '../../store/action/loginAction';
import './login.css';
import 'react-toastify/dist/ReactToastify.css';

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			email: "",
			password: "",
			confirmPassword: "",
			companyCode: 0,
			userRoleId: 0,
			companyList: [],
			userRolesList: [],
			LoginErrors: "",
			checkboxImage: 'checked',
			inProgress: true,
			showLegacyLogin: false
		};

		localStorage.removeItem("ssoType");

		this.input_changeHandler = this.input_changeHandler.bind(this);
		this.input_keyPressHandler = this.input_keyPressHandler.bind(this);
		this.company_changeHandler = this.company_changeHandler.bind(this);
		this.userRole_changeHandler = this.userRole_changeHandler.bind(this);
		this.login_clickHandler = this.login_clickHandler.bind(this);
		this.changePassword_clickHandler = this.changePassword_clickHandler.bind(this);
		this.continue_clickHandler = this.continue_clickHandler.bind(this);
		this.cancel_clickHandler = this.cancel_clickHandler.bind(this);
		this.renderLogin = this.renderLogin.bind(this);
		this.renderPasswordChange = this.renderPasswordChange.bind(this);
	}

	componentDidMount() {
		var isTokenExpired = stringIsNullOrEmpty(localStorage.tokenExpiry) || Date.now() > Date.parse(localStorage.tokenExpiry);

		const queryString = window.location.search;
		const searchParams = new URLSearchParams(queryString);
		const ssoToken = searchParams.get('ssotoken');
		
		if (!stringIsNullOrEmpty(ssoToken)) {
			const loginId = searchParams.get('loginId');
			const companyCode = searchParams.get('companyCode');
			
			var authenticatedUser = undefined;

			if (!isNullOrUndefined(localStorage.authenticatedUser)) {
				authenticatedUser = JSON.parse(localStorage.authenticatedUser);
            }

			if (!isNullOrUndefined(authenticatedUser) && (authenticatedUser.companyCode !== companyCode || authenticatedUser.loginId !== loginId)) {
				localStorage.removeItem("token");
				localStorage.removeItem("tokenExpiry");
				localStorage.removeItem("authenticatedUser");
				localStorage.removeItem("userInfo");
			} else {

            }
        }
		
		if (isTokenExpired) {
			localStorage.removeItem("ssoType");
			localStorage.removeItem("token");
			localStorage.removeItem("tokenExpiry");
			localStorage.removeItem("authenticatedUser");
			localStorage.removeItem("userInfo");
		}

		const queryMode = searchParams.get('querymode');

		if (queryMode === "on") {
			localStorage.setItem("ssoType", "");
		}

		if (localStorage.ssoType === undefined) {
			this.fetchSignInInfo();
		}
		else {
			this.signIn();
		}
	}

	handleSubmit(event) {
	}

	input_changeHandler(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	input_keyPressHandler(event) {
		if (event.charCode === 13) {
			event.preventDefault();

			this.login_clickHandler(event);
		}
	}

	company_changeHandler(event) {
		var companyCode = parseInt(event.target.value, 10);
		var userRolesList = this.state.companyList.find(c => c.companyCode === companyCode).userRolesList;
		var userRoleId = userRolesList[0].userRoleId;

		this.setState(
			{
				companyCode: companyCode,
				userRoleId: userRoleId,
				userRolesList: userRolesList
			});
	}

	userRole_changeHandler(event) {
		this.setState({ userRoleId: parseInt(event.target.value, 10) });
	}

	continue_clickHandler(event) {

	}

	cancel_clickHandler(event) {

	}

	checkbox_clickHandler(checkbox) {
		if (checkbox === 'checked') {
			this.setState({ checkboxImage: 'unchecked' });
		}
		else {
			this.setState({ checkboxImage: 'checked' });
		}
	}

	changePassword_clickHandler(event) {
		var that = this;
		var password = sha256(this.state.password);
		var confirmPassword = sha256(this.state.confirmPassword);

		if (password !== confirmPassword) {
			toast.error("Confirm Password doesn't match");
			return;
		}

		var formData = {
			Username: this.state.username,
			Password: sha256(this.state.password),
			ConfirmPassword: sha256(this.state.confirmPassword),
			companyCode: this.state.companyCode,
			userRoleId: this.state.userRoleId
		}

		const token = localStorage.token;
		const userInfo = JSON.parse(localStorage.userInfo);

		this.setState({ inProgress: true });

		httpPost("api/auth/changepassword", formData)
			.then((response) => {
				that.setState({ inProgress: false });

				if (userInfo.passwordResetFlag === 'Y') {
					userInfo.passwordResetFlag = 'N';
					localStorage.setItem("userInfo", JSON.stringify(userInfo));
				}

				let searchParams = new URLSearchParams(that.props.location.search);

				that.props.userLoginFetch(userInfo);
				that.props.tokenHandler(token);

				if (!stringIsNullOrEmpty(userInfo.invoiceType)) {
					if (userInfo.invoiceType.includes('1')) {
						//that.props.history.push({
						//	pathname: "/Media/Dashboard",
						//	search: searchParams.toString()
						//});
						pathName = "/Media/Dashboard";
					}
					else if (userInfo.invoiceType.includes('2')) {
						//that.props.history.push({
						//	pathname: "/Production/Dashboard",
						//	search: searchParams.toString()
						//});
						pathName = "/Production/Dashboard";
					}
				}
				var newRelativePathQuery = pathName;
				if (!stringIsNullOrEmpty(searchParams.toString())) {
					newRelativePathQuery += '?' + searchParams.toString();
				}				
				window.location = newRelativePathQuery;

			}).catch((error) => {
				if (userInfo && userInfo.passwordResetFlag === 'Y') {
					userInfo.passwordResetFlag = 'N';
					localStorage.setItem("userInfo", JSON.stringify(userInfo));
				}

				that.setState({ inProgress: false });

				if (error && error.response && error.response.data) {
					toast.error(error.response.data);
					console.log("login error", error.response);
				}
				else {
					console.log("login error");
				}
			});
	}

	login_clickHandler(event) {
		var formData = {
			Username: this.state.username,
			Password: sha256(this.state.password),
			email: this.state.email,
			companyCode: this.state.companyCode,
			userRoleId: this.state.userRoleId
		}

		this.authenticateUser(formData, this.state.ssoToken);
	}

	authenticateUser(formData, ssoToken) {
		var that = this;
		var config = null;
		var url = "api/auth/";

		if (!stringIsNullOrEmpty(formData.email)) {
			url += "ssoauthenticate";
		}
		else {
			config = { withCredentials: true };
			url += "authenticate";
		}

		this.setState({ inProgress: true });

		httpPost(url, formData, config)
			.then((response) => {

				var pathName = "/";

				that.setState({ inProgress: false });

				if (response.message) {
					// Here you should have logic to handle invalid login credentials.
					// This assumes your Rails API will return a JSON object with a key of
					// 'message' if there is an error
				}
				else {
					var data = response.data;

					let searchParams = new URLSearchParams(that.props.location.search);

					const ssoToken = searchParams.get('ssotoken');
					const email = searchParams.get('email');

					searchParams.delete("ssotoken");
					searchParams.delete("email");
					searchParams.delete("loginId");
					searchParams.delete("companyCode");
					searchParams.delete("userRoleId");
					searchParams.delete("userRoleName");
					
					if (data.token && data.token.length > 0) {
						localStorage.setItem("token", data.token);
						localStorage.setItem("tokenExpiry", data.tokenExpiry);
						localStorage.setItem("userInfo", JSON.stringify(data.selectedUserInfo));
						localStorage.setItem("authenticatedUser", JSON.stringify(data));
						if (stringIsNullOrEmpty(formData.email)) {
							if (data.selectedUserInfo.passwordResetFlag === 'Y') {
								this.setState({ passwordResetFlag: true });
								return;
							}
						}
						//that.props.userLoginFetch(data.selectedUserInfo);
						//that.props.tokenHandler(data.token);

						if (!stringIsNullOrEmpty(data.selectedUserInfo.invoiceType)) {
							if (data.selectedUserInfo.invoiceType.includes('1')) {
								pathName = "/Media/Dashboard";
							}
							else if (data.selectedUserInfo.invoiceType.includes('2')) {
								pathName = "/Production/Dashboard";
							}
						}
					}
					else {
						var userInfo = data.selectedUserInfo === null ? data.authenticatedUserInfoList[0] : data.selectedUserInfo;
						var companyCode = userInfo.companyCode;
						var userRoleId = userInfo.userRolesList[0].userRoleId;

						that.setState(
							{
								companyCode: companyCode,
								userRoleId: userRoleId,
								email: email,
								ssoToken: ssoToken,
								companyList: data.selectedUserInfo === null ? data.authenticatedUserInfoList : [],
								userRolesList: userInfo.selectedUserRoles === null ? userInfo.userRolesList : [],
								showLegacyLogin: true
							});
					}

					if (!stringIsNullOrEmpty(localStorage.token)) {

						var sParam = searchParams.toString();
						var newRelativePathQuery = pathName;
						if (!stringIsNullOrEmpty(sParam)) {
							newRelativePathQuery += '?' + sParam;
						}
						window.location = newRelativePathQuery;
					}
				}
			}).catch((error) => {
				that.setState({ inProgress: false, showLegacyLogin: true });
				
				if (error && error.response && error.response.data) {
					toast.error(error.response.data);
					console.log("login error", error.response);
				}
				else {
					console.log("login error");
				}
			});
	}

	fetchSignInInfo() {
		var that = this;

		httpGet("/api/auth/signininfo")
			.then((response) => {
				var signInInfo = response.data;

				localStorage.setItem("ssoType", blankStringIfNullOrEmpty(signInInfo.ssoType));
				localStorage.setItem("clientLogo", blankStringIfNullOrEmpty(signInInfo.clientLogo));

				that.signIn();
			}).catch((error) => {
				that.setState({ inProgress: false });
				toast.error('An error occurred while signing on');
			});
	}

	signIn() {
		const queryString = window.location.search;
		const searchParams = new URLSearchParams(queryString);

		const ssoToken = searchParams.get('ssotoken');
		const email = searchParams.get('email');
		const companyCode = parseInt(searchParams.get('companyCode'),10);		
		const loginId = searchParams.get('loginId');
		const userRoleName = searchParams.get('userRoleName');
		const userRoleId = parseInt(searchParams.get('userRoleId'), 10);

		if (!stringIsNullOrEmpty(ssoToken) && !stringIsNullOrEmpty(email)) {
			var formData = {
				email: email,
				companyCode: this.state.companyCode === 0 ? companyCode : this.state.companyCode,
				userRoleId: this.state.userRoleId === 0 ? userRoleId : this.state.userRoleId,
				userName: (stringIsNullOrEmpty(this.state.username) ? loginId : this.state.username),
				userRoleName: (stringIsNullOrEmpty(this.state.userRoleName) ? userRoleName : this.state.userRoleName),
			}

			this.authenticateUser(formData, ssoToken);
		}
		else {
			var showLegacyLogin = stringIsNullOrEmpty(localStorage.ssoType);

			if (!showLegacyLogin) {
				window.location.pathname = "/ssoauthentication/login";
			}

			this.setState({ showLegacyLogin: showLegacyLogin, inProgress: false });
		}
	}

	getClientLogo() {
		return !stringIsNullOrEmpty(localStorage.clientLogo) ? localStorage.clientLogo : "images/nexelus-logo-200.png";
	}

	renderPasswordChange() {
		return (
			<form id="LoginForm">
				<div className="container-fluid">
					<div className="loginScreen zero-padding">
						<div className="col-md-4 loginDetail ">
							<img src="images/nexelus-logo-200.png" alt="Nexelus Logo" />
							<h3>Welcome</h3>
							<hr />
							<p><span id="YearPart"></span></p>
							<p>All rights reserved. This product is protected by US and International copyright laws.</p>
						</div>
						<div className="col-md-8 loginForm">
							<div className="errorMessage col-md-12 non-okta" id="idTdErrorMessage"></div>
							<div className="form-group  non-okta">
								<div className="input-group mb-2 mr-sm-2 mb-sm-0">
									<div className="input-group-addon"><i className="fa fa-lock" aria-hidden="true"></i></div>
									<input type="password" className="form-control password" id="password" name="password" maxLength="40" value={this.state.password} onChange={this.input_changeHandler} onKeyPress={this.input_keyPressHandler} />
								</div>
							</div>
							<div className="form-group  non-okta">
								<div className="input-group mb-2 mr-sm-2 mb-sm-0">
									<div className="input-group-addon"><i className="fa fa-lock" aria-hidden="true"></i></div>
									<input type="password" className="form-control confirmPassword" id="confirmPassword" name="confirmPassword" maxLength="40" value={this.state.confirmPassword} onChange={this.input_changeHandler} onKeyPress={this.input_keyPressHandler} />
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-12 zero-padding non-okta" id="idTdLoginButtons">
									<div className="form-group" id="idDivLoginButtons">
										<button type="button" id="aIdLoginButton" className="btn btn-primary btn_login" onClick={this.changePassword_clickHandler}>
											Change Password</button>
									</div>
								</div>
								<div className="col-md-12 zero-padding" id="idTdLoginAsButtons">
									<div className="form-group" id="idDivLoginAsButtons" style={{ display: "none" }}>
										<button type="button" id="btnCancel" onClick={this.cancelChangePassword_clickHandler} className="btn btn-default">Cancel</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 loginScreenImg">
						<div className="clientLogo">
							<img src={this.getClientLogo()} alt="Logo" />
						</div>
					</div>
				</div>
			</form >
		);
	}

	renderLogin() {
		return <form id="LoginForm">
			<div className="container-fluid">
				<div className="loginScreen zero-padding">
					<div className="col-md-4 loginDetail ">
						<img src="images/nexelus-logo-200.png" alt="Nexelus Logo" />
						<h3>Welcome</h3>
						<hr />
						<p><span id="YearPart"></span></p>
						<p>All rights reserved. This product is protected by US and International copyright laws.</p>
					</div>
					<div className="col-md-8 loginForm">
						<div className="errorMessage col-md-12 non-okta" id="idTdErrorMessage"></div>
						<div className="form-group  non-okta">
							<div className="input-group mb-2 mr-sm-2 mb-sm-0">
								<div className="input-group-addon"><i className="fa fa-user" aria-hidden="true"></i></div>
								<input type="text" className="form-control email" id="username" name="username" maxLength="40" value={defaultStringIfNullOrEmpty(this.state.email, this.state.username)} onChange={this.input_changeHandler} onKeyPress={this.input_keyPressHandler} />
							</div>
						</div>
						<div className="form-group  non-okta" style={{ display: this.state.companyList.length > 1 || this.state.userRolesList.length > 1 ? "none" : "" }}>
							<div className="input-group mb-2 mr-sm-2 mb-sm-0">
								<div className="input-group-addon"><i className="fa fa-lock" aria-hidden="true"></i></div>
								<input type="password" className="form-control password" id="password" name="password" maxLength="40" value={this.state.password} onChange={this.input_changeHandler} onKeyPress={this.input_keyPressHandler} />
							</div>
						</div>
						<div className="form-group " style={{ display: this.state.companyList.length > 1 ? "" : "none" }}>
							<div className="input-group mb-2 mr-sm-2 mb-sm-0" id="idDivCompanySelect">
								<div className="input-group-addon"><i className="material-icons">&#xE0AF;</i></div>
								<select id="cboCompany" className="form-control" onChange={this.company_changeHandler} onKeyPress={this.input_keyPressHandler}>
									{
										this.state.companyList.map(function (company, index) {
											return <option key={index} value={company.companyCode}>{company.coName}</option>
										})
									}
								</select>
							</div>
						</div>
						<div className="form-group " style={{ display: this.state.userRolesList.length > 0 ? "" : "none" }}>
							<div className="input-group mb-2 mr-sm-2 mb-sm-0" id="idDivLoginAsSelect">
								<div className="input-group-addon"><i className="fa fa-users" aria-hidden="true"></i></div>
								<select id="cboUserRole" className="form-control" value={this.state.userRoleId} onChange={this.userRole_changeHandler} onKeyPress={this.input_keyPressHandler}>
									{
										this.state.userRolesList.map(function (userRoles, index) {
											return <option key={index} value={userRoles.userRoleId}>{userRoles.userRoleName}</option>
										})
									}
								</select>
							</div>
						</div>
						<div className="form-group checkbox_div non-okta" id="idDivRememberChkBox" style={{ display: "none" }}>
							<label>
								<span><img id="chkRememberMe" src={"images/" + this.state.checkboxImage + ".gif"} border="0" onClick={() => { this.checkbox_clickHandler(this.state.checkboxImage) }} alt="" /></span> Remember me</label>
						</div>
						<div className="form-group">
							<div className="col-md-12 zero-padding non-okta" id="idTdLoginButtons">
								<div className="form-group" id="idDivLoginButtons">
									<button type="button" id="aIdLoginButton" className="btn btn-primary btn_login" onClick={this.login_clickHandler}>
										Login</button>
								</div>
							</div>
							<div className="col-md-12 zero-padding" id="idTdLoginAsButtons">
								<div className="form-group" id="idDivLoginAsButtons" style={{ display: "none" }}>
									<button type="button" id="btnContinue" onClick={this.continue_clickHandler} className="btn btn-primary">Continue</button>
									<button type="button" id="btnCancel" onClick={this.cancel_clickHandler} className="btn btn-default">Cancel</button>
								</div>
							</div>
						</div>
						<div className="form-group okta" style={{ height: "250px", position: "relative", display: "none" }}>
							<div id="poweredByOKTNote" className="login-mode-notice" style={{ marginTop: "20%", marginBottom: "20px" }}>Powered by<img src="images/okta.png" alt="OKTA" style={{ width: "78px" }} /></div>
							<div className="okta-footer" style={{ position: "absolute", bottom: 0, left: 0 }}>
								<div id="dvActiveDirNotif" className="col-md-12 zero-padding  active-dir-notice">Please use your active directory credentials to log in</div>
								<div id="dvOKTANotification" className="col-md-12 zero-padding login-mode-notice  boldAndRed">Application will authenticate using OKTA</div>
								<div id="dvOKTALogoutNotification1" className="col-md-12 zero-padding login-mode-notice">You have been successfully logged out, please close the window or click <a href="">here</a> to Login again.</div>
								<div id="dvOKTAunknownNotification1" className="col-md-12 zero-padding login-mode-notice">Your account does not exist in Nexelus. Please contact your Administrator or click <a href="">here</a> to redirect to OKTA.</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3 loginScreenImg">
					<div className="clientLogo">
						<img src={this.getClientLogo()} alt="Logo" />
					</div>
				</div>
			</div>
		</form >
	}

	render() {
		var formMarkUp = <></>;

		if (this.state.showLegacyLogin) {
			if (localStorage.token && localStorage.userInfo) {
				const userInfo = JSON.parse(localStorage.userInfo);

				if (userInfo.passwordResetFlag === 'Y') {
					formMarkUp = this.renderPasswordChange();
				}
			}
			else {
				formMarkUp = this.renderLogin();
			}
		}

		return (
			<div>
				{formMarkUp}
				<ToastContainer
					position="top-right"
					newestOnTop
					closeOnClick
				/>
				<Backdrop style={{ zIndex: 2000, color: '#fff' }} open={this.state.inProgress}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	userLoginFetch: userInfo => dispatch(loginUser(userInfo))
})

export default connect(null, mapDispatchToProps)(withRouter(Login));