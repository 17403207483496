import React, {  useCallback, useImperativeHandle,  useState } from 'react';
import {
    DataGridPro, GridToolbarFilterButton
    , GridToolbarColumnsButton, GridToolbarExport, GridToolbarContainer
    , GridToolbarQuickFilter, useGridApiRef
} from '@mui/x-data-grid-pro';
import {  Typography, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Styles = theme => ({
    root: {
        '& .MuiDataGrid-main': {
            fontSize: '0.75rem',
            padding: '8px',
            color: 'inherit',
            border: 0,
            cursor: 'pointer',
            margin: 0,
            outline: 0,
            padding: 0,
            userSelect: 'none',
            borderRadius: 0,
            textDecoration: 'none',
            backgroundColor: 'transparent',
            height: 560
            //height:576
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            overflow: 'hidden !important',
            fontWeight: 500,
            whiteSpace: 'break-spaces',
            lineHeight: '1.4em',
            maxHeight: 'unset !important',
            height: 'fit-content'
        },
        '& .MuiDataGrid-footerContainer, & .MuiToolbar-gutters p': {
            color: 'inherit', fontSize: '0.75rem'
        },
        '&': {
            clear: 'both'
        },
        '&::before': {
            clear: 'both'
        },
        '&::after': {
            clear: 'both'
        },
        '& .MuiDataGrid-cell,& .MuiDataGrid-cell:focus': {
            whiteSpace: 'break-spaces !important',
            //minHeight: 'unset !important',
            maxHeight: 'unset !important',
            padding: '0 10px !important',
            outline: 'none'
        },
        '& .MuiDataGrid-row': {
            //minHeight: 'unset !important',
            maxHeight: 'unset !important'
        },
        '& .MuiDataGrid-cell .MuiInput-input, .MuiDataGrid-editInputCell': {
            //border: 0,
            //borderBottom: '1px solid',
            //outline: 0
            color: '#414141',
            height: '29px',
            padding: '7px 10px 7px 10px',
            width: '66%',
            outline: 'none',
            border: '1px solid #c6c6c6',
            borderRadius: 6,
            transition: '0.3s ease-in-out all'
        },
        '& .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-cell:focus': {
            outline:'none  !important'
        },
        '& .MuiDataGrid-editInputCell, .MuiDataGrid-editInputCell input': {
            margin: 0,
           // padding: '0 !important',
            padding: '7px 4px 7px 4px  !important',
            FontFace: 'Lato, sans-serif !important',
            fontSize: '12px'
        },
        '& .MuiToolbar-gutters p': {
            margin: 0
        },
        '& .MuiTablePagination-select': {
            padding: "4px 0 2px", fontSize: '0.75rem'
        },
        '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            padding: '0 10px !important'
        },
        '& [data-field="actions"]': {
            textAlign: 'center'
        },
        '& div.MuiDataGrid-toolbarContainer':{
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start'
        },
        '& div.MuiDataGrid-toolbarContainer .MuiBox-root': {
            display: 'none'
        },
         '& .MuiButtonBase-root.MuiButton-root.MuiButton-text': {
            color: 'inherit', fontSize: '0.75rem'
        }
    }
});

function EnhancedDataGrid(props) {
    const apiRef = useGridApiRef();
    let [pageSize, setPageSize] = useState(props.pageSize || 10)
    const [allVisbleColumn, setAllVisibleColumn] = React.useState(true);
    const [filterText, setFilterText] = useState('');
    const onPageSizeChange = useCallback((newPageSize) => {
        if (this.props.onPageSizeChange && typeof (this.props.onPageSizeChange) == 'function') {
            this.props.onPageSizeChange(newPageSize)
        }
        setPageSize(newPageSize)
    })
    const onSelectionModelChange = useCallback((model) => {
        if (props.onSelectionChange && typeof (props.onSelectionChange) == 'function') {
            let selectedRows = [];
            (props.apiRef || apiRef).current.getSelectedRows().forEach((e) => {
                selectedRows.push(e);
            })
            props.onSelectionChange(selectedRows);
        }
    })
    const toolbarmethods = React.useMemo(() => {
        return {
            getfiltertext: (text) => setFilterText(text)
        } 
    })


    const onColumnVisbilityModelChange = React.useCallback((newModel) => {
        if (props?.options?.columnsButton) {
            let modelLength = Object.keys(newModel).length;
            let someChecked = Object.keys(newModel).some(e => newModel[e]);
            let isAllVisible = modelLength === 0 || someChecked
                || (!someChecked && modelLength < props.columns.length);
            setAllVisibleColumn(isAllVisible);
        }

    }, []);

   
    const expandIcon = () => {
      return  <ExpandMoreIcon
            sx={{
                transform: 'rotate(270deg)',
                transition: (theme) =>
                    theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                    }),
            }}
            fontSize="inherit"
        />
    };
    const collapeIcon = () => {
        return <ExpandMoreIcon
            sx={{
                transform: 'rotate(0deg)',
                transition: (theme) =>
                    theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                    }),
            }}
            fontSize="inherit"
        />
    };
    
    useImperativeHandle(props.gridRef, () => {
        return {
            ...apiRef.current,
            filterText: filterText,
            pageSize: pageSize
        }
    }, [pageSize, filterText, apiRef.current]);



    var psize = pageSize;
    var pagingOptions = [5, 10, 20, 50, 100];
    if (props.rowsPerPageOptions) {
        pagingOptions = props.rowsPerPageOptions;
    }
    return (<DataGridPro
        {...props}
        sx={{
            ...props.sx,
            '& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within,& .MuiDataGrid-cell:focus-within': {
                outline: 'none !important', padding: '0 10px !important'
            },
            '& .MuiDataGrid-columnHeader': {
                outline: 'none !important'
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                height: '0.7em',
                width: '0.7em'
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:vertical , .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:horizontal': {
                background: '#B2b2b2', borderRadius: 2, opacity: '0.5'
            }
        }}
        apiRef={props.apiRef || apiRef}
        className={props.classes.root}
        initialState={{
            ...props.initialState,
            pagination: { paginationModel: { pageSize: psize } },
            filter: {
                filterModel: {
                    items: [],
                    quickFilterExcludeHiddenColumns: false,
                },
            },
        }}
        pageSizeOptions={pagingOptions}
        disableColumnFilter={!props?.options?.filtering}
        disableColumnSelector={!props?.options?.columnsButton}
        disable
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnMenu
        onPageSizeChange={onPageSizeChange}
        checkboxSelection={props && props.onSelectionChange && typeof (props.onSelectionChange) == 'function'}
        onRowSelectionModelChange={onSelectionModelChange}
        slots={{
            toolbar: DataGridTitle,
            detailPanelExpandIcon: expandIcon, //Slots used for icons
            detailPanelCollapseIcon: collapeIcon,
        }}
        onColumnVisibilityModelChange={onColumnVisbilityModelChange}
        slotProps={{
            panel: {
                placement: "bottom-end"
            },
            filterPanel: {
                disableAddFilterButton: false,
                disableRemoveAllButton: false,
            },
            toolbar: {
                title: props.title,
                
                showquickfilter: props.nofilter?0:1,
                customcomponent: props.customcomponent || null,
                printoptions: {
                    disableToolbarButton: true
                },
                isallcolumnvisible: allVisbleColumn? 1 : 0,                
                columnsselector: props?.options?.columnsButton?.toString(),
                filtering: props?.options?.filtering?.toString(),
                
                csvbutton: props?.options?.exportButton?.toString(),
                filterref: toolbarmethods,
                csvoptions: {
                    allColumns: !props?.options?.exportAllData,
                    fileName: props?.options?.exportFileName,
                    disableToolbarButton: !props?.options?.exportButton
                }
            }
        }}
    />)
}

function DataGridTitle(props) {
    let max_size = 12;
    let column_size = 6 - (props.customcomponent && 1 || 0) - (props.filtering && 1 || 0) - (props.columnsselector && 1 || 0) - (props.csvbutton && 1 || 0);
    let columns_size_2 = max_size - column_size;
    let toolbarSize = 4 + Math.floor((props.filtering && 0.5 || 0) + (props.columnsselector && 0.5 || 0) + (props.csvbutton && 0.5 || 0))

    return (
        <div style={{ width: '100%' }} id="dataGridtitle">
            <Grid key={1} item sx={{ pt: 1, pl: 1, pr: 1, pb: 1 }} container xs={12}>
                <Grid key={1} sx={{
                    '&': {
                        display: 'flex', marginTop: '10px !important',
                        alignItems: 'center', height: 'fit-content'
                    }
                }} item xs={column_size}>
                    <div style={{ width: "100%", display: "flex", textOverflow: 'elipses', }}>
                        <Typography key={1} style={{
                            width: 'calc(100% - 0px)',
                            maxWidth: 'calc(100% - 0px)',
                            paddingLeft: '10px',

                            overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                        }}
                            title={props.title}
                            variant="h6">{props.title}</Typography>
                    </div>
                </Grid>
                <Grid key={2} item xs={columns_size_2}>
                    <div style={{ width: '100%' }}>
                        <Grid key={1} container item xs={12} justifyContent="flex-end">
                            <Grid key={1} sx={{ pr: (props.customcomponent) ? 1 : 0 }}
                                style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                                item xs={(props.customcomponent) ? toolbarSize : 12} alignItems="flex-end">
                                <GridCustomToolBar key={1}
                                    sx={{
                                        '& .MuiInput-underline:before': {
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important'
                                        },
                                        '& .MuiFormControl-root[aria-label="Search"]': {
                                            paddingBottom: '2px'
                                        }
                                    }}
                                    {...props}
                                />
                            </Grid >
                            {
                                (props.customcomponent) ?
                                    <Grid key={2} alignItems="center" >
                                        {props.customcomponent}
                                    </Grid> : <></>
                            }

                        </Grid>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

function GridCustomToolBar({ sx, ...props }) {
    const [text, setText] = React.useState('');
    return (
        <GridToolbarContainer sx={sx}>
            <GridCustomToolBarFilterButton {...props}></GridCustomToolBarFilterButton>
            <GridToolbarColumnsButton {...props }></GridToolbarColumnsButton>
            <GridCustomToolBarExport {...props}></GridCustomToolBarExport>
            {props.showquickfilter==1 ? 
                <GridToolbarQuickFilter quickFilterParser={(searchInput) => {
                    props.filterref?.getfiltertext(searchInput);
                    return searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')

                }
                } {...props}></GridToolbarQuickFilter>
                :null 
            }
        </GridToolbarContainer>
    )
}
function GridCustomToolBarExport(props) {
    return (
        !props.isallcolumnvisible ?
        <div onClick={(event) => { toast.error('At least 1 column should be visbile to export'); }}>
            <GridToolbarExport disabled={true}></GridToolbarExport>
        </div> :
        <GridToolbarExport csvOptions={props.csvoptions} printOptions={props.printoptions}></GridToolbarExport>
    )
}
function GridCustomToolBarFilterButton(props) {
    return (
        !props.isallcolumnvisible ?
            <div onClick={(event) => { toast.error('At least 1 column should be visbile to apply filter'); }}>
                <GridToolbarFilterButton disabled={true} {...props}></GridToolbarFilterButton>
        </div>:
            <GridToolbarFilterButton {...props}></GridToolbarFilterButton>
    )
}
export default withStyles(Styles)(EnhancedDataGrid);