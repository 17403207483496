var toCurrencyFormat = function (value) {
	if (isNaN(value) || stringIsNullOrEmpty(value)) {
		const zero = 0;
		return zero.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	}

	return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

exports.formatCurrency = toCurrencyFormat;

var toNumberFormat = function (value) {
	var number = toCurrencyFormat(value).replace(/\$/g, '');
	if (round(number, 2) == 0.00 || round(number, 2) == "-0.00") {
		number = '0.00';
	}
	return number;
}

exports.formatNumber = toNumberFormat;

const round = (number, decimalPlaces) => {
	const factorOfTen = Math.pow(10, decimalPlaces);
	return Math.round(number * factorOfTen) / factorOfTen;
}
var isNullOrUndefined = function (value) {
	return value === undefined || value == null;
}

exports.isNullOrUndefined = isNullOrUndefined;

var stringIsNullOrEmpty = function (value) {
	return value === undefined || value == null || (value != null && typeof value === "string" && value.length === 0);
}

exports.stringIsNullOrEmpty = stringIsNullOrEmpty;

var blankStringIfNullOrEmpty = function (value) {
	return value === undefined || value == null ? "" : value;
}

exports.blankStringIfNullOrEmpty = blankStringIfNullOrEmpty;

var defaultStringIfNullOrEmpty = function (value, defaultValue) {
	return stringIsNullOrEmpty(value) ? defaultValue : value;
}

exports.defaultStringIfNullOrEmpty = defaultStringIfNullOrEmpty;

var zeroIfNull = function (value) {

		return value === undefined || value == null || isNaN(value) ? 0 : value;	
}

exports.zeroIfNull = zeroIfNull;

var checkForUnAuthorizedException = function (compObj, error) {
	if (error.response.status === 401) {
		//let searchParams = new URLSearchParams(compObj.props.location.search);
		localStorage.removeItem("token");
		localStorage.removeItem("tokenExpiry");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("authenticatedUser");
		document.location.href = "/logout";
		//compObj.props.history.push({
		//	pathname: "/login",
		//	//search: searchParams.toString()
		//});
	}
}

exports.checkForUnAuthorizedException = checkForUnAuthorizedException;

var addDaysInDate = function addDays(date, number) {
	const newDate = new Date(date);
	return new Date(newDate.setDate(newDate.getDate() + number));
}

exports.addDaysInDate = addDaysInDate;



var formatDate =(date)=>{
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	seconds = seconds < 10 ? '0' + seconds : seconds;
	var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
	return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + ",  " + strTime
}

exports.formatDate = formatDate;

var FormatToInputDate = (date) => {
	try {
		if (date) {
			let _date = new Date(date);
			let month = _date.getMonth() + 1;
			if (parseInt(month) < 10) { month = '0' + month }
			return (_date.getFullYear() + '-' + month + "-" + _date.getDate());
		}
		else {
			return date;
		}
	}
	catch (ex) {
		return date;
	}
	
}

exports.FormatToInputDate = FormatToInputDate;


const objectClone = function skeleton(source, isArray) {
	var o = Array.isArray(source) ? [] : {};
	for (var key in source) {
		if (source.hasOwnProperty(key)) {
			var t = typeof source[key];
			o[key] = t == 'object' ? skeleton(source[key]) : { string: '', number: 0, boolean: false }[t];
		}
	}
	return o;
}
exports.objectClone = objectClone;

const uuidv4 = function () {
	return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}
exports.uuid = uuidv4;
