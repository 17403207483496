import * as React from 'react';
import { TextFieldWithLabel } from '../FormFieldControls/TextFieldWithLabel';
import { LabelText } from '../FormFieldControls/LabelText';
import { InputCheckbox } from '../FormFieldControls/InputCheckbox';
import './CompanyConfig.css'
import $ from 'jquery';

export class CompanyRoutingSetup extends React.Component {
	constructor(props) {
		super(props);

		this._changeHandler = this._changeHandler.bind(this);
		this._ddlNumberOfApproval_changeHandler = this._ddlNumberOfApproval_changeHandler.bind(this);

		this.state = {
			ddlNumberOfApprovalRulesChanged: false
		};
	}

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		var that = this;
		const modelDefId = this.props.companyApprovalConfiguration.modelDefId;

		$('li[id^="tabApprovalLevel_' + modelDefId + '"]').on('click', function () {
			that.showHideTab(this);
		});

		if ($('li[id*="tabApprovalLevel_' + modelDefId + '"].active').length === 0) {
			this.showHideTab($('li[id="tabApprovalLevel_' + modelDefId + '_1"]')[0]);
		}
		else if (this.state && this.state.ddlNumberOfApprovalRulesChanged) {
			this.showHideTab($('li[id*="tabApprovalLevel_' + modelDefId + '"].active')[0]);
			this.setState({ ddlNumberOfApprovalRulesChanged: false });
		}
	}

	showHideTab(currentTab) {
		const modelDefId = currentTab.id.split("_")[1];

		$('div[id^="cntApprovalLevel_' + modelDefId + '"]').hide();
		$('li[id^="tabApprovalLevel_' + modelDefId + '"]').removeClass('active');

		$('div.' + currentTab.className).show();
		$(currentTab).addClass('active');
	}

	_changeHandler(event) {
		this.props.changeHandler(event, this.props.companyApprovalConfiguration.modelDefId, "companyApprovalConfigurations");
	}

	_ddlNumberOfApproval_changeHandler(event) {
		this.setState({ ddlNumberOfApprovalRulesChanged: true });

		this._changeHandler(event);
	}

	render() {
		var companyApprovalConfiguration = this.props.companyApprovalConfiguration;
		const modelDefId = companyApprovalConfiguration.modelDefId;

		const approvalLevels = [
			{
				approvalLevelShortName: {
					labelId: 'lblApprovalLevel1ShortName',
					labelText: 'Short Name',

					textboxPlaceholder: "Short Name",
					textboxId: "txtApprovalLevel1ShortName",
					textboxName: "approvalLevel1ShortName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel1ShortName
				},
				approvalLevelName: {
					labelId: 'lblApprovalLevel1Name',
					labelText: 'Name',

					textboxPlaceholder: "Name",
					textboxId: "txtApprovalLevel1Name",
					textboxName: "approvalLevel1Name",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel1Name
				},
				approvalLevelStatusName: {
					labelId: 'lblApprovalLevel1StatusName',
					labelText: 'Status Name',

					textboxPlaceholder: "Status Name",
					textboxId: "txtApprovalLevel1StatusName",
					textboxName: "approvalLevel1StatusName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel1StatusName
				},
				approvalLevelAcceptName: {
					labelId: 'lblApprovalLevel1AcceptName',
					labelText: 'Accept Name',

					textboxPlaceholder: "Accept Name",
					textboxId: "txtApprovalLevel1AcceptName",
					textboxName: "approvalLevel1AcceptName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel1AcceptName
				},
				approvalLevelRejectName: {
					labelId: 'lblApprovalLevel1RejectName',
					labelText: 'Reject Name',

					textboxPlaceholder: "Reject Name",
					textboxId: "txtApprovalLevel1RejectName",
					textboxName: "approvalLevel1RejectName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel1RejectName
				},
				approvalLevelAllowInvoiceEditingFlag: {
					labelId: 'lblApprovalLevel1AllowInvoiceEditingFlag',
					labelText: 'Allow Invoice Editing',

					checkboxId: "chkApprovalLevel1AllowInvoiceEditingFlag",
					checkboxName: "approvalLevel1AllowInvoiceEditingFlag",
					checkboxValue: companyApprovalConfiguration.approvalLevel1AllowInvoiceEditingFlag
				}
			},
			{
				approvalLevelShortName: {
					labelId: 'lblApprovalLevel2ShortName',
					labelText: 'Short Name',

					textboxPlaceholder: "Short Name",
					textboxId: "txtApprovalLevel2ShortName",
					textboxName: "approvalLevel2ShortName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel2ShortName
				},
				approvalLevelName: {
					labelId: 'lblApprovalLevel2Name',
					labelText: 'Name',

					textboxPlaceholder: "Name",
					textboxId: "txtApprovalLevel2Name",
					textboxName: "approvalLevel2Name",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel2Name
				},
				approvalLevelStatusName: {
					labelId: 'lblApprovalLevel2StatusName',
					labelText: 'Status Name',

					textboxPlaceholder: "Status Name",
					textboxId: "txtApprovalLevel2StatusName",
					textboxName: "approvalLevel2StatusName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel2StatusName
				},
				approvalLevelAcceptName: {
					labelId: 'lblApprovalLevel2AcceptName',
					labelText: 'Accept Name',

					textboxPlaceholder: "Accept Name",
					textboxId: "txtApprovalLevel2AcceptName",
					textboxName: "approvalLevel2AcceptName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel2AcceptName
				},
				approvalLevelRejectName: {
					labelId: 'lblApprovalLevel2RejectName',
					labelText: 'Reject Name',

					textboxPlaceholder: "Reject Name",
					textboxId: "txtApprovalLevel2RejectName",
					textboxName: "approvalLevel2RejectName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel2RejectName
				},
				approvalLevelAllowInvoiceEditingFlag: {
					labelId: 'lblApprovalLevel2AllowInvoiceEditingFlag',
					labelText: 'Allow Invoice Editing',

					checkboxId: "chkApprovalLevel2AllowInvoiceEditingFlag",
					checkboxName: "approvalLevel2AllowInvoiceEditingFlag",
					checkboxValue: companyApprovalConfiguration.approvalLevel2AllowInvoiceEditingFlag
				}
			},
			{
				approvalLevelShortName: {
					labelId: 'lblApprovalLevel3ShortName',
					labelText: 'Short Name',
					textboxPlaceholder: "Short Name",
					textboxId: "txtApprovalLevel3ShortName",
					textboxName: "approvalLevel3ShortName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel3ShortName
				},
				approvalLevelName: {
					labelId: 'lblApprovalLevel3Name',
					labelText: 'Name',

					textboxPlaceholder: "Name",
					textboxId: "txtApprovalLevel3Name",
					textboxName: "approvalLevel3Name",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel3Name
				},
				approvalLevelStatusName: {
					labelId: 'lblApprovalLevel3StatusName',
					labelText: 'Status Name',

					textboxPlaceholder: "Status Name",
					textboxId: "txtApprovalLevel3StatusName",
					textboxName: "approvalLevel3StatusName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel3StatusName
				},
				approvalLevelAcceptName: {
					labelId: 'lblApprovalLevel3AcceptName',
					labelText: 'Accept Name',

					textboxPlaceholder: "Accept Name",
					textboxId: "txtApprovalLevel3AcceptName",
					textboxName: "approvalLevel3AcceptName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel3AcceptName
				},
				approvalLevelRejectName: {
					labelId: 'lblApprovalLevel3RejectName',
					labelText: 'Reject Name',

					textboxPlaceholder: "Reject Name",
					textboxId: "txtApprovalLevel3RejectName",
					textboxName: "approvalLevel3RejectName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel3RejectName
				},
				approvalLevelAllowInvoiceEditingFlag: {
					labelId: 'lblApprovalLevel3AllowInvoiceEditingFlag',
					labelText: 'Allow Invoice Editing',

					checkboxId: "chkApprovalLevel3AllowInvoiceEditingFlag",
					checkboxName: "approvalLevel3AllowInvoiceEditingFlag",
					checkboxValue: companyApprovalConfiguration.approvalLevel3AllowInvoiceEditingFlag
				}
			},
			{
				approvalLevelShortName: {
					labelId: 'lblApprovalLevel4ShortName',
					labelText: 'Short Name',
					textboxPlaceholder: "Short Name",
					textboxId: "txtApprovalLevel4ShortName",
					textboxName: "approvalLevel4ShortName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel4ShortName
				},
				approvalLevelName: {
					labelId: 'lblApprovalLevel4Name',
					labelText: 'Name',

					textboxPlaceholder: "Name",
					textboxId: "txtApprovalLevel4Name",
					textboxName: "approvalLevel4Name",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel4Name
				},
				approvalLevelStatusName: {
					labelId: 'lblApprovalLevel4StatusName',
					labelText: 'Status Name',

					textboxPlaceholder: "Status Name",
					textboxId: "txtApprovalLevel4StatusName",
					textboxName: "approvalLevel4StatusName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel4StatusName
				},
				approvalLevelAcceptName: {
					labelId: 'lblApprovalLevel4AcceptName',
					labelText: 'Accept Name',

					textboxPlaceholder: "Accept Name",
					textboxId: "txtApprovalLevel4AcceptName",
					textboxName: "approvalLevel4AcceptName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel4AcceptName
				},
				approvalLevelRejectName: {
					labelId: 'lblApprovalLevel4RejectName',
					labelText: 'Reject Name',

					textboxPlaceholder: "Reject Name",
					textboxId: "txtApprovalLevel4RejectName",
					textboxName: "approvalLevel4RejectName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel4RejectName
				},
				approvalLevelAllowInvoiceEditingFlag: {
					labelId: 'lblApprovalLevel4AllowInvoiceEditingFlag',
					labelText: 'Allow Invoice Editing',

					checkboxId: "chkApprovalLevel4AllowInvoiceEditingFlag",
					checkboxName: "approvalLevel4AllowInvoiceEditingFlag",
					checkboxValue: companyApprovalConfiguration.approvalLevel4AllowInvoiceEditingFlag
				}
			},
			{
				approvalLevelShortName: {
					labelId: 'lblApprovalLevel5ShortName',
					labelText: 'Short Name',
					textboxPlaceholder: "Short Name",
					textboxId: "txtApprovalLevel5ShortName",
					textboxName: "approvalLevel5ShortName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel5ShortName
				},
				approvalLevelName: {
					labelId: 'lblApprovalLevel5Name',
					labelText: 'Name',

					textboxPlaceholder: "Name",
					textboxId: "txtApprovalLevel5Name",
					textboxName: "approvalLevel5Name",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel5Name
				},
				approvalLevelStatusName: {
					labelId: 'lblApprovalLevel5StatusName',
					labelText: 'Status Name',

					textboxPlaceholder: "Status Name",
					textboxId: "txtApprovalLevel5StatusName",
					textboxName: "approvalLevel5StatusName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel5StatusName
				},
				approvalLevelAcceptName: {
					labelId: 'lblApprovalLevel5AcceptName',
					labelText: 'Accept Name',

					textboxPlaceholder: "Accept Name",
					textboxId: "txtApprovalLevel5AcceptName",
					textboxName: "approvalLevel5AcceptName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel5AcceptName
				},
				approvalLevelRejectName: {
					labelId: 'lblApprovalLevel5RejectName',
					labelText: 'Reject Name',

					textboxPlaceholder: "Reject Name",
					textboxId: "txtApprovalLevel5RejectName",
					textboxName: "approvalLevel5RejectName",
					readOnly: false,
					textboxValue: companyApprovalConfiguration.approvalLevel5RejectName
				},
				approvalLevelAllowInvoiceEditingFlag: {
					labelId: 'lblApprovalLevel5AllowInvoiceEditingFlag',
					labelText: 'Allow Invoice Editing',

					checkboxId: "chkApprovalLevel5AllowInvoiceEditingFlag",
					checkboxName: "approvalLevel5AllowInvoiceEditingFlag",
					checkboxValue: companyApprovalConfiguration.approvalLevel5AllowInvoiceEditingFlag
				}
			}
		]
		var tabHeaderList = [];
		var tabContentList = [];

		approvalLevels.map(function (approvalLevel, index) {
			{
				if (index < companyApprovalConfiguration.routingNumberOfApprovalLevels) {
					tabHeaderList.push(<li key={index} id={"tabApprovalLevel_" + modelDefId + "_" + (index + 1)} className={"ApprovalLevel_" + modelDefId + "_" + (index + 1)}><a>Approval Level {index + 1}</a></li>);

					tabContentList.push(
						<div key={index} id={"cntApprovalLevel_" + modelDefId + "_" + (index + 1)} className={"col-md-12 zero-padding ApprovalLevel_" + modelDefId + "_" + (index + 1)}>
							<div className="form-group">
								<TextFieldWithLabel info={approvalLevel.approvalLevelShortName} changeHandler={this._changeHandler} />
							</div>
							<div className="form-group">
								<TextFieldWithLabel info={approvalLevel.approvalLevelName} changeHandler={this._changeHandler} />
							</div>
							<div className="form-group">
								<TextFieldWithLabel info={approvalLevel.approvalLevelStatusName} changeHandler={this._changeHandler} />
							</div>
							<div className="form-group">
								<TextFieldWithLabel info={approvalLevel.approvalLevelAcceptName} changeHandler={this._changeHandler} />
							</div>
							<div className="form-group">
								<TextFieldWithLabel info={approvalLevel.approvalLevelRejectName} changeHandler={this._changeHandler} />
							</div>
							<div className="form-group">
								<div className="labeldiv col-md-3">
									<LabelText labelInfo={approvalLevel.approvalLevelAllowInvoiceEditingFlag} />
								</div>
								<div className="labeldiv col-md-3">
									<InputCheckbox checkboxInfo={approvalLevel.approvalLevelAllowInvoiceEditingFlag} changeHandler={this._changeHandler} />
								</div>
							</div>
						</div>
					);
				}
			}
		}, this);

		const routingNumberOfApprovalLevels = {
			labelId: 'lblRoutingNumberOfApprovalLevels',
			labelText: 'Routing Number of Approval Levels',

			dropdownPlaceholder: "Routing Number of Approval Levels",
			dropdownId: "ddlRoutingNumberOfApprovalLevels",
			dropdownValue: companyApprovalConfiguration.routingNumberOfApprovalLevels
		}
		return (
			<div>
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 grid_row_2" style={{ marginBottom: 10 }}>
					<div className="col-lg-5 col-md-5 col-sm-5 col-xs-4 grid_mainheading">
						<h3>
							{companyApprovalConfiguration.masterDefName}
						</h3>
					</div>
				</div>
				<div className="col-md-12 form-group">
					<div className="form-group">
						<div className="labeldiv col-md-3">
							<LabelText labelInfo={routingNumberOfApprovalLevels} />
						</div>
						<div className="fielddiv col-md-3">
							<select id="ddlRoutingNumberOfApprovalLevels" name="routingNumberOfApprovalLevels" className="drpChannel form-box form-control non-auth noDirty" value={companyApprovalConfiguration.routingNumberOfApprovalLevels} onChange={this._ddlNumberOfApproval_changeHandler}>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
							</select>
						</div>
					</div>
					<div className="form-group">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub_navigations">
							<div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 nav-padding">
								<ul>
									{tabHeaderList}
								</ul>
							</div>
						</div>
					</div>
					<div className="form-group">
						<fieldset>
							{tabContentList}
						</fieldset>
					</div>
				</div>
			</div>
		);
	}
}
