import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import PaymentTermsMaps from './PaymentTermsMaps';
import EnhancedDataGrid from '../Common/EnhancedDataGrid'; import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class PaymentTerms extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openPaymentTermsMapsDialog: false
		};
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.editPaymentTermsMaps_clickHandler = this.editPaymentTermsMaps_clickHandler.bind(this);
		this.paymentTermsMapsDialog_closeHandler = this.paymentTermsMapsDialog_closeHandler.bind(this);
	}
	getRefreshButton = () => {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginTop: 10, display: 'none' }}>
			<RefreshIcon />
		</IconButton>;
	}

	refresh_clickHandler(event) {
		this.props.inProgress(true);

		let functionName = "getPaymentTerms";
		let messageName = "Payment Terms";
		httpGet("/api/Adapter/getSyncData/" + functionName)
			.then(response => {
				var data = response.data;
				this.props.inProgress(false);
				if (data)
					toast.success(messageName + ' Fetched Successfully');
				else
					toast.error('Error fetching ' + messageName);

			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Error fetching ' + messageName);
			});
	}
	componentDidMount() {
		this.fetchPaymentTerms();
	}

	editPaymentTermsMaps_clickHandler(event) {
		event.preventDefault();

		this.setState({ openPaymentTermsMapsDialog: true, selectedTermsCode: event.target.dataset.termscode });
	}

	paymentTermsMapsDialog_closeHandler(event) {
		this.setState({ openPaymentTermsMapsDialog: false });
	}

	fetchPaymentTerms() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/paymentterms')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				let records = (data.records || []).map((e, i) => { let d = { ...e, rowId: i }; return d; });
				that.setState({ data: records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			termsCode: this.state.selectedTermsCode,
			inProgress: this.props.inProgress
		}
		let count = 1;

		return (
			<div>
				<h1>Payment Terms</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							title={"Last Updated: " + this.state.lastUpdated}
							customcomponent={this.getRefreshButton()}
							columns={[
								{
									headerName: 'Code',
									field: 'termsCode',
									flex:0.4
								},
								{
									headerName: 'Description',
									field: 'termsDesc',
									flex : 1
								},
								{
									headerName: 'Days Due',
									field: 'daysDue',
									type: "numeric",
									headerAlign: 'right',
									align: 'right',
									flex:0.5,
									valueGetter: ({ value }) => (value && Number(value)) || 0,
								},
								{
									headerName: 'Mappings',
									headerAlign: 'center',
									field: 'mappings', resizable: false, sortable: false,
									align: 'center',
									width: 150,
									renderCell: rowData => <Link data-termscode={rowData.row.termsCode} href="#" onClick={this.editPaymentTermsMaps_clickHandler}>Edit</Link>
								}
							]}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
				<Dialog open={this.state.openPaymentTermsMapsDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<PaymentTermsMaps info={info} onClose={this.paymentTermsMapsDialog_closeHandler} />
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}
