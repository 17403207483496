import axios from 'axios';

function defaultConfig() {
	return {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${localStorage.token}`
		}
	};
}

export function httpGet(url, config) {
	if (!(config instanceof Object)) {
		config = defaultConfig();
	}

	return new Promise((resolve, reject) => {
		axios.get(url, config)
			.then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
	});
}

export function httpPost(url, data, config) {
	if (!(config instanceof Object)) {
		config = defaultConfig();
	}

	return new Promise((resolve, reject) => {
		axios.post(url, data, config)
			.then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
	});
}

export function httpPut(url, data, config) {
	if (!(config instanceof Object)) {
		config = defaultConfig();
	}

	return new Promise((resolve, reject) => {
		axios.put(url, data, config)
			.then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
	});
}

export function httpDelete(url, config) {
	if (!(config instanceof Object)) {
		config = defaultConfig();
	}

	return new Promise((resolve, reject) => {
		axios.delete(url, config)
			.then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
	});
}

