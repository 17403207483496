import * as React from 'react';
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import EnhancedDataGrid from '../Common/EnhancedDataGrid';
import RemoveIcon from '@mui/icons-material/Remove';
import { Check } from '@mui/icons-material';


export default class PurchaseOrdersReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		this.fetchPurchaseOrders();
	}

	fetchPurchaseOrders() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/purchaseorders')
			.then((response) => {
				//var data = response.data;
				var data = (response.data || []).map((e, i) => { let d = { ...e, rowId: i }; return d });
				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>Purchase Orders</h1>
				<div className="form-group">
					<div style={{ display: 'block', overflow: 'auto', width: '100%' }}>
						<EnhancedDataGrid rows={this.state.data}
							disableColumnMenu
							pagination
							pageSize={10}
							
							columns={[
								{
									headerName: 'IO/PO Number',
									field: 'poCode',
									width: 150, hideable: false
								},
								{
									headerName: 'Project',
									field: 'level2Key',
									width: 240,
								},
								{
									headerName: 'Project Name',
									field: 'level2Description',
									width: 350,
								},
								{
									headerName: 'Activity',
									field: 'level3Key',
									width: 250,
								},
								{
									headerName: 'Due Date',
									field: 'dueDate',
									type: 'date',
									valueGetter: ({ value }) => (value && new Date(value)),
									width: 200,
								},
								{
									headerName: 'Quantity',
									field: 'quantity',
									type: 'numeric', align: 'right',
									headerAlign: 'right',
									valueGetter: ({ value }) => (value && Number(value)) || 0,
									width: 150,
								},
								{
									headerName: 'Net Cost',
									field: 'netCost',
									type: 'currency',
									align: 'right',
									headerAlign: 'right',
									width: 200,
									renderCell: rowData => formatNumber(rowData.row.netCost) + " " + rowData.row.currencyCode
								},
								{
									headerName: 'Match to Date Net',
									field: 'matchToDateNet',
									type: 'numeric', align: 'right',
									headerAlign: 'right',
									valueGetter: ({ value }) => (value && Number(value)) || 0,
									width: 150,
								},
								{
									headerName: 'Closed',
									field: 'closeFlag',
									type: 'boolean',
									renderCell: (props) => {
										return (
											(props?.row?.activeFlag) ?
												<Check key={props?.row.rowId} color={'action'} /> :
												<RemoveIcon key={props?.row.rowId} color={'action'} />

										)
									},
									width: 150,
								}					
							]}
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 40, 50],
								maxBodyHeight: 670,
								filtering: true,
								columnsButton: true,
								exportButton: true,
								exportAllData: true,
								exportFileName: "PurchaseOrders"
							}}
							getRowId={(row) => row.rowId}
							disableSelectionOnClick
						/>
					</div>
				</div>
			</div>
		);
	}
}
